<template>
  <transition name="slide">
    <div
      v-if="isVisible"
      style="background-position: 100% 40%"
      class="absolute z-30 left-0 right-0 bottom-0 h-[40%] bg-cover"
      :style="{ backgroundImage: `url('${image}')` }">
      <div class="flex items-center justify-center -mt-6">
        <svg
          class="w-12 post-shadow text-white"
          data-name="Layer 1"
          viewBox="0 0 64 64"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.84 55.91 32 41.77l14.13 14.14 6.56-6.56-5.14-5.14 5.14-5.14-5.13-5.13 5.16-5.16L32 8.09l-20.7 20.7 5.13 5.13-5.16 5.16 5.14 5.14-5.14 5.14zm28.28-2.83L32 38.94 17.84 53.09l-3.73-3.73L32 31.49l17.86 17.87zm-32-24.3L32 10.91l17.89 17.87-3.73 3.73L32 18.37 17.86 32.5zm3.71 6.54L32 21.22l17.86 17.85-3.73 3.73L32 28.66 17.84 42.8l-3.73-3.73z"/>
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'SwiperPreview',
  components: {},
  props: {
    image: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  computed: {
    ...mapWritableState(useAuthStore, ['showSwiperPreview'])
  },
  mounted () {
    const vm = this

    setTimeout(() => {
      vm.isVisible = true
    }, 2000)

    setTimeout(() => {
      vm.isVisible = false
    }, 4000)

    setTimeout(() => {
      vm.showSwiperPreview = false
    }, 4500)
  },
  methods: {}
}
</script>

<style>
  .slide-enter-active {
    animation: slideOutUp 0.5s;
  }
  .slide-leave-active {
    animation: slideOutDown 0.5s;
  }

  @keyframes slideOutUp {
    from {
      transform: translate3d(0, 100%, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes slideOutDown {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(0, 100%, 0);
    }
  }
</style>
