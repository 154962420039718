<template>
  <CreatorAlt v-if="showAltCreator"/>

  <CreatorBase v-else/>
</template>

<script>
import CreatorBase from '@/components/Creator/CreatorBase'
import CreatorAlt from '@/components/CreatorAlt/CreatorAlt'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { mapState } from 'pinia'

export default {
  name: 'CreatorView',
  components: {
    CreatorBase,
    CreatorAlt
  },
  computed: {
    ...mapState(useAppStore, [
      'altSecretState',
      'secretToken'
    ]),
    ...mapState(useAuthStore, [
      'landingPath',
      'isAuthenticated'
    ]),
    ...mapState(useUserStore, [
      'user',
      'userId'
    ]),
    showAltCreator () {
      let landingPath = this.isAuthenticated ? this.user?.landingPath : this.landingPath?.path

      if (typeof landingPath === 'string' || landingPath instanceof String) {
        if (landingPath.charAt(0) === '/') {
          landingPath = landingPath.substring(1)
        }

        landingPath = landingPath.replace(/\/.*/, '')
      }

      const userPathCheck = this.$route.path.includes(landingPath)

      return this.altSecretState && this.secretToken && userPathCheck
    }
  }
}
</script>

