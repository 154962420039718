<template>
  <label
    class="custom-file-upload h-80 relative rounded"
    @dragover.prevent="dragover"
    @dragleave="dragleave"
    @drop.prevent="drop">
    <input
      id="upload-main-photo"
      ref="photoUpload"
      class="hidden"
      accept=".jpg, .jpeg"
      type="file"
      @change="onFileUpload">
    {{ $t("auth.photo_upload.description") }}
    <img
      v-if="src"
      class="absolute inset-0 object-cover h-full w-full rounded"
      :src="src"
      alt="Your photo">
  </label>

  <div
    :class="[src ? 'visible' : 'invisible']"
    class="flex items-center justify-around my-2">
    <a
      class="cursor-pointer btn"
      @click="clearSrc">
      {{ $t("auth.photo_upload.remove") }}
    </a>

    <label
      class="cursor-pointer btn"
      for="upload-main-photo">
      {{ $t("auth.photo_upload.change") }}
    </label>
  </div>

  <button
    :disabled="!file || isLoading"
    class="btn btn-primary btn-block h-12"
    @click="getStarted">
    <span v-show="!isLoading">
      {{ $t("auth.photo_upload.get_started_btn") }}
    </span>

    <LightSpinner
      v-show="isLoading"
      class="h-6 w-6 bg-white"/>
  </button>
</template>

<script>

import LightSpinner from '@/components/Functional/LightSpinner'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useQuizStore } from '@/stores/quiz'

export default {
  name: 'ThePhotoUpload',
  components: {
    LightSpinner
  },
  data () {
    return {
      file: null,
      src: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useQuizStore, [
      'survey',
      'quizAllowed'
    ]),
    ...mapWritableState(useQuizStore, [
      'surveyOpen'
    ]),
    ...mapWritableState(useAuthStore, [
      'pathToRedirect',
      'landingPath'
    ]),
    urlToRedirect () {
      if (this.landingPath.name === 'Creator' || this.landingPath.name === 'Post') {
        return this.landingPath.path
      } else if (this.pathToRedirect.length) {
        return this.pathToRedirect
      } else {
        return '/'
      }
    }
  },
  watch: {
    file (value) {
      if (value) {
        this.src = URL.createObjectURL(value)
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions(useUserStore, [
      'uploadPhoto'
    ]),
    // https://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
    onFileUpload (event) {
      this.file = event.target.files[0]
    },
    dragover (event) {
      event.currentTarget.classList.add('is-active')
    },
    dragleave (event) {
      event.currentTarget.classList.remove('is-active')
    },
    drop (event) {
      this.file = event.dataTransfer.files[0]
    },
    onSubmit () {
      const formData = new FormData()
      formData.append('backtest', this.file, this.file.name)
    },
    clearSrc () {
      this.$refs.photoUpload.value = null
      this.file = null
      this.src = null
    },
    async getStarted () {
      if (this.file) {
        this.isLoading = true

        try {
          await this.uploadPhoto(this.file)

          if (this.$route.query?.r === 'me') {
            await this.$router.push('/me')
          } else {
            await this.$router.push(this.urlToRedirect)
          }

          this.openSurvey()
        } catch (e) {
          this.clearSrc()
        }

        this.isLoading = false
      }
    },
    openSurvey () {
      if (this.survey.showAfterSignup && this.quizAllowed) {
        setTimeout(() => {
          this.surveyCause = 'after_signup'
          this.surveyOpen = true
        }, 300)
      }
    }
  }
}
</script>

<style scoped>
  .custom-file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 12px;
    cursor: pointer;
    font-size: 16px;
    color: var(--font-color);
    transition: background-color 100ms linear;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 6px 1px, 6px 1px, 1px 6px, 1px 6px;
    background-position: left top, right bottom, left bottom, right top;
    animation: border-dance 1s infinite linear;
  }

  .custom-file-upload:hover, .is-active {
    @apply bg-gray-100;
  }

  @keyframes border-dance {
    0% {
      background-position: left top, right bottom, left bottom, right top;
    }
    100% {
      background-position: left 6px top, right 6px bottom, left bottom 6px, right top 6px;
    }
  }
</style>
