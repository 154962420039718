<template>
  <div class="relative flex flex-col mb-1.5">
    <div class="flex flex-col items-center justify-center m-auto text-center mb-2">
      <Avatar class="w-20 mb-2" :user-item="user"/>

      <div class="flex items-center mb-1">
        <p class="h3 leading-5">{{ user.name }}</p>

        <InlineSvg
          v-if="user.tikTokFollowerCount"
          :src="require('@/assets/svg/design/verified.svg')"
          class="ml-0.5 shrink-0"/>
      </div>

      <p v-if="user.username" class="leading-5 font-bold text-gray-500">@{{ user.username }}</p>
    </div>

    <button
      type="button"
      class="absolute right-0 btn py-2 pl-3 pr-0 cursor-pointer"
      @click="close">
      <ImageLoader v-slot="{ setLoaded }">
        <InlineSvg
          :src="crossSVG"
          class="text-gray-400"
          @loaded="setLoaded"/>
      </ImageLoader>
    </button>
  </div>

  <ul class="subscribe-list space-y-1 sm:space-y-1.5 sm:leading-5 mb-3 sm:mb-5 text-sm">
    <li>
      <p v-html="$t('creator.subscribe.points.full_access', {name: user.name})"/>
    </li>

    <li v-if="!secretToken">
      <p v-html="$t('creator.subscribe.points.direct_messages', {name: `<b>${user.name}</b>`})"/>
    </li>

    <li v-if="0">
      <p v-html="$t('creator.subscribe.points.unlock_credits', {number: 'N', name: `<b>${user.name}</b>`})"/>
    </li>

    <li v-if="!secretToken">
      <p v-html="$t('creator.subscribe.points.exclusive_chat', {name: `<b>${user.name}</b>`})"/>
    </li>

    <li>
      <p v-html="$t('creator.subscribe.points.support_creation', {name: `<b>${user.name}</b>`})"/>
    </li>
  </ul>

  <!-- Subscription types -->
  <div
    v-if="(user.subscriptionPlans?.length > 1) && !options.anySubscriptionForFree"
    :class="{
      'grid-cols-2 max-w-[280px] mx-auto': user.subscriptionPlans.length === 2,
      'grid-cols-3': user.subscriptionPlans.length === 3
    }"
    class="grid gap-2 mt-2 mb-3 justify-center">
    <div
      v-for="item in user?.subscriptionPlans"
      :key="item.id"
      :class="{ 'ring-2 ring-inset ring-[#f23459]': item.id === activeSub.id }"
      class="relative h-22 rounded-lg subs-shadow flex flex-col justify-between"
      @click="activeSub = item">
      <span
        v-if="item.discountInPercents"
        class="absolute left-0 right-0 -top-2.5 w-max mx-auto text-[11px] leading-3 rounded-full bg-[#f23459] px-2 py-1 text-white font-semibold">
        {{ item.discountInPercents }}% OFF
      </span>

      <header class="pt-2.5 h-16 flex flex-col items-center justify-center rounded-t-lg">
        <h4 class="text-center text-[#808080] text-base font-bold leading-4">US${{ item.priceInCurrency }}</h4>
        <span class="text-caption">{{ getSubscriptionPeriodByLetter(item.period).per_period }}</span>
      </header>

      <footer
        :class="[item.id === activeSub.id ? 'text-white bg-[#f23459]' : 'text-black bg-[#ebebf2]']"
        class="rounded-b-lg h-6  flex items-center justify-center">
        <h4 class="text-center text-caption uppercase">{{ getSubscriptionPeriodByLetter(item.period).period }}</h4>
      </footer>
    </div>
  </div>

  <button
    v-if="!Number(user.subscriptionPriceInCurrency) && !user.subscriptionPlans?.length"
    type="submit"
    class="btn btn-block btn-primary relative h-[44px]"
    :disabled="isLoading"
    @click="freeSubscription">
    <Spinner v-if="isLoading" class="absolute inset-0 m-auto"/>

    <template v-else>{{ $t('creator.subscribe.for_free') }}</template>
  </button>

  <Form
    v-else
    id="cardForm"
    v-slot="{ errors, setErrors }"
    :initial-values="initialValues"
    @submit="paidSubscription">
    <YupTooltip :i18n="errors.terms">
      <div class="flex items-start">
        <div class="h-5 flex items-center">
          <Field
            id="terms"
            type="checkbox"
            name="terms"
            class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
            :value="true"
            :rules="rules.agreeWithPolicy"
            @focus="setErrors(fieldsToReset)"/>
        </div>

        <div class="ml-3 text-sm">
          <label
            for="terms"
            class="font-medium text-gray-400 text-caption leading-4">
            <VRuntimeTemplate
              :template="$t('creator.subscribe.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
          </label>
        </div>
      </div>
    </YupTooltip>

    <YupTooltip :i18n="errors.recurring">
      <div class="flex items-start mb-4">
        <div class="h-5 flex items-center">
          <Field
            id="recurring"
            type="checkbox"
            name="recurring"
            class="mt-1.5 h-4 w-4 text-primary border border-primary rounded focus:ring-blue-300"
            :value="true"
            :rules="rules.agreeWithRecurring"
            @focus="setErrors(fieldsToReset)"/>
        </div>

        <div class="ml-3 text-sm">
          <label
            for="recurring"
            class="font-medium text-gray-400 text-caption leading-4">
            <VRuntimeTemplate
              v-if="!user.subscriptionPlans?.length || activeSub.period === 'm'"
              :template="$t('creator.subscribe.recurring_agreement', {
                recurring_policy: recurringPolicy,
                date_day: currentDayOfMonth(),
                price: priceInCurrency
              })"/>

            <VRuntimeTemplate
              v-else-if="activeSub.period === 'w'"
              :template="$t('creator.subscribe.recurring_agreement_week', {
                recurring_policy: recurringPolicy,
                week_day: $dayjs().format('dddd'),
                price: priceInCurrency
              })"/>

            <VRuntimeTemplate
              v-else-if="activeSub.period === 'y'"
              :template="$t('creator.subscribe.recurring_agreement_year', {
                recurring_policy: recurringPolicy,
                date_day: currentDayOfMonth(),
                month: $dayjs().format('MMMM'),
                price: priceInCurrency
              })"/>
          </label>
        </div>
      </div>
    </YupTooltip>

    <template v-if="!isLoading">
      <AlertMessage
        v-if="isPending"
        type="warning"
        class="mb-2.5"
        heading="Transaction pending, check later or contact online support"/>

      <AlertMessage
        v-else-if="isFailed || errorMessage"
        type="error"
        class="mb-3"
        :heading="errorMessage || 'Previous transaction failed'"
        :text="errorDescription"/>

      <AlertMessage
        v-else-if="showWarnUpdatingCard"
        type="info"
        class="mb-3"
        heading="When making a payment, you will have to update your card details once more!"/>
    </template>

    <!-- Special case for users who allowed for any subscriptions for free -->
    <button
      v-if="options.anySubscriptionForFree"
      type="button"
      class="btn btn-block btn-primary relative h-[44px]"
      :disabled="isLoading || isPending"
      @click="freeSubscription">
      <Spinner
        v-show="isLoading"
        class="absolute inset-0 m-auto"/>

      <span v-show="!isLoading">
        {{
          Number(priceInCurrency) ? $t('creator.subscribe.for_price', { price: priceInCurrency }) : $t('creator.subscribe.for_free')
        }}
      </span>
    </button>

    <button
      v-else-if="Number(user.subscriptionPriceInCurrency) || user.subscriptionPlans?.length"
      class="btn btn-block btn-primary relative h-[44px]"
      :disabled="isLoading || isPending">
      <Spinner
        v-show="isLoading"
        class="absolute inset-0 m-auto"/>

      <span v-show="!isLoading">
        {{ $t('creator.subscribe.for_price', { price: priceInCurrency }) }}
      </span>
    </button>
  </Form>

  <p class="text-sm text-gray-400 text-center mt-2">
    {{ $t('creator.subscribe.cancel_any_time') }}
  </p>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import ImageLoader from '@/components/Functional/ImageLoader'
import crossSVG from '@/assets/svg/design/cross.svg'
import app from '@/main'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import YupTooltip from '@/components/Functional/YupTooltip'
import { Field, Form } from 'vee-validate'
import helpers from '@/helpers'
import { usePaymentStore } from '@/stores/payment'
import AlertMessage from '@/components/Functional/AlertMessage.vue'
import Spinner from '@/components/Functional/Spinner.vue'
import uniqid from 'uniqid'

export default {
  name: 'SubscribeContent',
  components: {
    Avatar,
    ImageLoader,
    Field,
    Form,
    YupTooltip,
    AlertMessage,
    Spinner
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: null
    }
  },
  emits: [
    'subscribed',
    'modal-closed'
  ],
  data () {
    return {
      activeSub: null,
      payment: null,
      errorMessage: null,
      errorDescription: null,
      successUrl: null,
      crossSVG: crossSVG,
      isLoading: false,
      initialValues: {
        agreeWithPolicy: false,
        agreeWithRecurring: false
      },
      fieldsToReset: {
        agreeWithPolicy: false,
        agreeWithRecurring: false
      },
      rules: {
        agreeWithRecurring: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} })),
        agreeWithPolicy: this.$yup.boolean()
          .required({ key: 'yup.custom.terms_and_conditions', values: {} })
          .oneOf([true], () => ({ key: 'yup.custom.terms_and_conditions', values: {} }))
      }
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'secretToken',
      'currentTime',
      'endlessPaymentCreated'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(useAuthStore, [
      'options'
    ]),
    ...mapState(usePaymentStore, [
      'activePaymentMethod',
      'paymentMethods',
      'subscriptionPayments',
      'showWarnUpdatingCard'
    ]),
    ...mapWritableState(usePaymentStore, [
      'pathBeforeBuying'
    ]),
    ...mapWritableState(useSubscriptionsStore, [
      'recentSubscriptions'
    ]),
    subscriptionPayment () {
      return this.subscriptionPayments.find(item => item.id.includes('subscription_' + this.user.userId))
    },
    isPending () {
      return this.subscriptionPayment && this.subscriptionPayment && ['created', 'payment_pending', 'payment_accepted'].includes(this.subscriptionPayment.status)
    },
    isFailed () {
      return this.subscriptionPayment && ['failed', 'cancelled'].includes(this.subscriptionPayment.status)
    },
    priceInCurrency () {
      return this.activeSub ? this.activeSub.priceInCurrency : this.user.subscriptionPriceInCurrency
    },
    termsOfService () {
      return `<a
                href="${window.one2fan.origin}/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="${window.one2fan.origin}/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    },
    recurringPolicy () {
      return `<a
                href="${window.one2fan.origin}/recurring-policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.recurring_policy') }}
              </a>`
    }
  },
  watch: {
    subscriptionPayment (payment) {
      if (payment?.status === 'succeeded') {
        this.errorMessage = null
        this.errorDescription = null

        this.purchaseDelete(payment.purchaseId)
        this.close()
        this.$emit('modal-closed')

        if (this.successUrl && this.isVisible) {
          this.$router.push({ redirect: window.location.href = this.successUrl })
        }
      }
      else if (['failed', 'cancelled'].includes(payment?.status)) {
        this.errorMessage = payment?.error || 'Payment failed'
        this.purchaseDelete(payment.purchaseId)
      }
    }
  },
  async mounted () {
    if (this.subscriptionPayment) {
      const purchaseId = this.subscriptionPayment.purchaseId
      await this.purchaseGet(purchaseId)

      if (this.subscriptionPayment.status === 'created') {
        await this.purchaseCancel(purchaseId)
          .then(() => {
            this.purchaseDelete(purchaseId)
          })
      }
    }

    if (this.user.subscriptionPlans?.length) {
      await this.$nextTick()
      this.activeSub = this.user.subscriptionPlans[0]
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'checkoutSessionCreate',
      'purchase',
      'purchaseGet',
      'purchaseCreate',
      'purchaseDelete',
      'purchaseCancel'
    ]),
    getSubscriptionPeriodByLetter (letter) {
      const periods = {
        w: {
          period: this.$t('subscriptions.period.weekly'),
          per_period: this.$t('subscriptions.period.per_week')
        },
        m: {
          period: this.$t('subscriptions.period.monthly'),
          per_period: this.$t('subscriptions.period.per_month')
        },
        y: {
          period: this.$t('subscriptions.period.annually'),
          per_period: this.$t('subscriptions.period.per_year')
        }
      }

      return periods[letter]
    },
    ordinalSuffixOf (i) {
      const j = i % 10
      const k = i % 100

      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    },
    currentDayOfMonth () {
      return this.ordinalSuffixOf(this.$dayjs().locale(this.$i18n.locale).format('D'))
    },
    anotherShit () {
      const currentDay = this.ordinalSuffixOf(this.$dayjs().locale(this.$i18n.locale).format('D'))

      if (this.user.subscriptionPlans?.length) {
        const currentPeriod = this.activeSub.period

        if (currentPeriod === 'w') {
          return currentPeriod
        } else if (currentPeriod === 'm') {
          return currentDay
        } else if (currentPeriod === 'y') {
          return currentPeriod
        }
      } else {
        return currentDay
      }
    },
    async paidSubscription () {
      if (this.isPending || this.isLoading) {
        return
      }

      // 1. Есть сохраненная: карта stripe.purchase
      // 2. Нет сохраненной карты: stripe.purchase

      this.isLoading = true
      this.pathBeforeBuying = '/@' + this.user.username

      // Приоритет подписок: 1 Выбранный план (первый по-умолчанию) 2 Старый способ месячной подписки

      const productId = this.user.subscriptionPlans?.length ?
        `${this.user.userId}:${this.activeSub.id}` : this.user.userId

      if (this.paymentMethods.length) {
        // Remove failed purchase if payment initialized again
        if (['failed', 'cancelled'].includes(this.subscriptionPayment?.status)) {
          this.purchaseDelete(this.subscriptionPayment.purchaseId)
        }

        // Check payment status or init one
        if (this.subscriptionPayment) {
          await this.purchaseGet(this.subscriptionPayment.purchaseId)
        }
        else {
          await this.purchaseCreate({ id: `subscription_${this.user.userId}_${uniqid()}` })
        }

        if (this.endlessPaymentCreated) {
          await new Promise(resolve => setTimeout(resolve, 1000 * 5))
          this.isLoading = false
          return
        }

        this.successUrl = `${window.origin}/checkout/result?is=success&type=subscription&userId=${this.user.userId}`

        await this.purchase({
          productType: 'subscription',
          productId: productId,
          paymentMethodId: this.activePaymentMethod.id,
          successUrl: this.successUrl,
          purchaseId: this.subscriptionPayment.purchaseId
        })
          .then(() => {
            this.errorMessage = null
            this.errorDescription = null
            this.close()
            this.$emit('modal-closed')
            this.isLoading = false

            const isProduction = process.env.NODE_ENV === 'production'

            if (isProduction) {
              // eslint-disable-next-line no-undef
              gtag('event', 'Submit', {
                event_category: 'Form',
                event_label: 'Subscription'
              })
            }
          })
          .catch((error) => {
            if (error?.code === 147) {
              this.errorMessage = error?.message
              this.errorDescription = error?.description
            }
            // Payment is pending
            else if (error?.code === 149) {
              //
            }
            else {
              this.errorMessage = 'Error occured'
            }

            // Remove payment
            if ([147, 105].includes(error?.code)) {
              this.purchaseDelete(this.subscriptionPayment.purchaseId)
            }
          })
          .finally(async () => {
            if (this.subscriptionPayment) {
              await this.purchaseGet(this.subscriptionPayment.purchaseId)

              // If purchase not completed -> wait n seconds to show loading
              if (!['succeeded', 'failed', 'cancelled'].includes(this.subscriptionPayment?.status)) {
                await new Promise(resolve => setTimeout(resolve, 1000 * 10))
              }
            }

            this.isLoading = false
          })
      }
      else {
        await this.checkoutSessionCreate({
          productType: 'subscription',
          productId: productId,
          successUrl: `${window.origin}/checkout/result?is=success&type=subscription&userId=${this.user.userId}`,
          cancelUrl: `${window.origin}/checkout/result?is=cancel&type=subscription&userId=${this.user.userId}`,
          savePaymentMethod: true
        })
          .then(() => {
            this.errorMessage = null
            this.close()
            this.$emit('modal-closed')
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    async freeSubscription () {
      this.isLoading = true

      const res = await app.wsp.sendRequest({
        data: {
          publisherUserId: this.user.userId
        },
        method: 'subscription.create'
      })

      this.isLoading = false

      if (res.error) {
        console.log(res.error.message)
      }

      // Fancy subscribe FREE or PAID (propellerads)
      helpers.addScript('https://my.rtmark.net/p.js?f=sync&lr=1&partner=d9ba97841a8d48f1cc0a97b3935b8c88e07c73a95f4d17efe922305c43208225')

      const isProduction = process.env.NODE_ENV === 'production'

      if (isProduction) {
        // eslint-disable-next-line no-undef
        gtag('event', 'Submit', {
          event_category: 'Form',
          event_label: 'Subscription'
        })
      }

      this.$addEvent(['Subscription'], { userId: this.user.userId })

      const userId = this.user.userId + ''

      setTimeout(() => {
        if (!this.recentSubscriptions.includes(userId)) {
          this.recentSubscriptions.push(userId)
        }
      }, 5000)

      const user = await this.userGet(userId)
      this.$emit('subscribed', user)

      this.close()
      this.$emit('modal-closed')
    },
    async userGet (userId) {
      const res = await app.wsp.sendRequest({ data: { id: userId }, method: 'user.get' })

      if (res.error) {
        console.log(res.error.message)
      }

      return res.data.user
    }
  }
}
</script>

<style>
  .subscribe-list li:before {
    width: 13px;
    height: 20px;
    content: url('../../assets/svg/solid/check.svg');
    @apply mr-2 sm:mr-3
  }

  .subscribe-list li {
    display: flex;
  }

  .dating-heading b {
    @apply text-primary
  }

  .subs-shadow {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  }
</style>

