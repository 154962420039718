<script setup>
  import { useAuthStore } from '@/stores/auth'
  import { storeToRefs } from 'pinia'

  const authStore = useAuthStore()

  const {
    isConnected,
    isOnline,
    tryingToReconnect,
    reconectionAttempts
  } = storeToRefs(authStore)

  const isDebug = process.env.VUE_APP_IS_DEBUG === 'yes'
</script>

<template>
  <div
    v-if="isDebug"
    class="absolute top-0.5 right-0.5 z-10 flex gap-1 items-center">
    <span
      v-if="tryingToReconnect"
      class="bg-orange-500 text-white text-xs font-medium px-2.5 py-0.5 rounded">
      Trying to reconnect {{ reconectionAttempts }}
    </span>

    <span
      v-if="isOnline"
      class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
      Internet ON
    </span>

    <span
      v-else
      class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded">
      Internet OFF
    </span>

    <span
      v-if="isConnected"
      class="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
      WS open
    </span>

    <span
      v-else
      class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded">
      WS closed
    </span>
  </div>
</template>