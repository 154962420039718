<template>
  <div
    ref="textClampRef"
    class="text-clamp"
    :style="{
      overflow: 'hidden',
      maxHeight: realMaxHeight,
    }">
    <span ref="contentRef">
      <slot
        name="before"
        :expand="expand"
        :collapse="collapse"
        :toggle="toggle"
        :clamped="isClamped"
        :expanded="localExpanded"/>

      <span
        ref="textRef"
        :aria-label="text"/>

      <slot
        name="after"
        :expand="expand"
        :collapse="collapse"
        :toggle="toggle"
        :clamped="isClamped"
        :expanded="localExpanded"/>
    </span>
  </div>
</template>

<script>
import { addListener, removeListener } from 'resize-detector'

// See original code
// https://github.com/sherwinshen/vue3-text-clamp/blob/master/package/text-clamp.vue
export default {
  name: 'TextClamp',
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: Number,
      default: 0
    },
    maxLines: {
      type: Number,
      default: 0
    },
    expanded: {
      type: Boolean,
      default: false
    },
    // "start" | "middle" | "end"
    location: {
      type: String,
      default: 'end'
    },
    ellipsis: {
      type: String,
      default: '…'
    },
    autoResize: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:expanded', 'clamp-change'],
  data () {
    return {
      offset: 0,
      localExpanded: false,
      unregisterResizeCallback: null
    }
  },
  computed: {
    realMaxHeight () {
      if (this.localExpanded) return undefined

      if (!this.maxHeight) return undefined

      return `${this?.maxHeight}px`
    },
    isClamped () {
      if (!this.text) return false
      return this.offset !== this.text.length
    },
    valueToWatch1 () {
      return [this.maxLines, this.maxHeight, this.ellipsis, this.location, this.isClamped].join()
    },
    valueToWatch2 () {
      return [this.text, this.autoResize].join()
    },
    realText () {
      return this.isClamped ? this.clampedText : this.text
    },
    clampedText () {
      if (this.location === 'start') {
        return this.ellipsis + (this.text.slice(0, this.offset) || '').trim()
      }

      if (this.location === 'middle') {
        const split = Math.floor(this.offset / 2)

        return (this.text.slice(0, split) || '').trim() + this.ellipsis + (this.text.slice(-split) || '').trim()
      }

      return (this.text.slice(0, this.offset) || '').trim() + this.ellipsis
    }
  },
  watch: {
    expanded (value) {
      this.localExpanded = value
    },
    localExpanded: {
      handler (value) {
        if (value) {
          this.clampAt(this.text.length)
        } else {
          this.update()
        }
        if (this.expanded !== value) {
          this.$emit('update:expanded', value)
        }
      },
      deep: true,
      immediate: true
    },
    isClamped: {
      async handler (value) {
        await this.$nextTick()
        this.$emit('clamp-change', value)
      },
      immediate: true
    },
    async valueToWatch1 () {
      await this.$nextTick()

      this.update()
    },
    async valueToWatch2 () {
      await this.$nextTick()

      this.init()
    }
  },
  beforeMount () {
    this.localExpanded = !!this.expanded
  },
  mounted () {
    this.init()
  },
  unmounted () {
    this.cleanUp()
  },
  methods: {
    applyChange () {
      this.$refs.textRef && (this.$refs.textRef.textContent = this.realText)
    },
    update () {
      if (this.localExpanded) return

      this.applyChange()

      if (this.isOverflow() || this.isClamped) {
        this.search()
      }
    },
    init () {
      if (!this.text) return

      this.offset = this.text.length

      this.cleanUp()

      if (this.autoResize && this.$refs.textClampRef) {
        addListener(this.$refs.textClampRef, this.update)

        this.unregisterResizeCallback = () => {
          this.$refs.textClampRef && removeListener(this.$refs.textClampRef, this.update)
        }
      }

      this.update()
    },
    cleanUp () {
      this.unregisterResizeCallback?.()
    },
    isOverflow () {
      if (!this.maxLines && !this.maxHeight) return false

      if (!this.$refs.textClampRef) return false

      if (this.maxLines && this.getLines() > this.maxLines) return true

      if (this.maxHeight && this.$refs.textClampRef.scrollHeight > this.$refs.textClampRef.offsetHeight) return true

      return false
    },
    getLines () {
      if (!this.$refs.contentRef) return 0

      // see https://developer.mozilla.org/zh-CN/docs/Web/API/Element/getClientRects
      return Object.keys(
        Array.prototype.slice.call(this.$refs.contentRef.getClientRects()).reduce((prev, { top, bottom }) => {
          const key = `${top}/${bottom}`
          if (!prev[key]) {
            prev[key] = true
          }
          return prev
        }, {})
      ).length
    },
    search (...range) {
      const [from = 0, to = this.offset] = range

      if (to - from <= 3) {
        this.stepToFit()

        return
      }

      const target = Math.floor((to + from) / 2)

      this.clampAt(target)

      if (this.isOverflow()) {
        this.search(from, target)
      } else {
        this.search(target, to)
      }
    },
    clampAt (offset) {
      this.offset = offset
      this.applyChange()
    },
    stepToFit () {
      this.fill()
      this.clamp()
    },
    fill () {
      while ((!this.isOverflow() || this.getLines() < 2) && this.offset < this.text.length) {
        this.moveEdge(1)
      }
    },
    clamp () {
      while (this.isOverflow() && this.getLines() > 1 && this.offset > 0) {
        this.moveEdge(-1)
      }
    },
    moveEdge (steps) {
      this.clampAt(this.offset + steps)
    },
    expand () {
      this.localExpanded = true
    },
    collapse () {
      this.localExpanded = false
    },
    toggle () {
      this.localExpanded = !this.localExpanded
    }
  }
}
</script>

