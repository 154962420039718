<template>
  <div
    class="relative"
    @mousedown="start"
    @touchstart="start"
    @mouseleave="stop"
    @mouseup="stop"
    @touchend="stop"
    @touchcancel="stop">
    <slot/>
  </div>
</template>

<script>

export default {
  name: 'LongTap',
  components: {},
  emits: ['long-tap'],
  data () {
    return {
      interval: false,
      count: 0
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    longTap () {
      this.count++
      this.$emit('long-tap')
      console.log(this.count)
    },
    start () {
      if (!this.interval) {
        this.interval = setInterval(() => this.longTap(), 400)
      }
    },
    stop () {
      clearInterval(this.interval)
      this.interval = false
      this.count = 0
    }
  }
}
</script>

