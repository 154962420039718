import chatState from '@/components/Chat/Store/chat-state.js'
import { useChatStore } from '@/stores/chat.js'
import { useAuthStore } from '@/stores/auth.js'
import { useAppStore } from '@/stores/app.js'
import app from '@/main'

const {
  posts
} = chatState

async function userPostsGet () {
  const chatStore = useChatStore()
  const authStore = useAuthStore()
  const appStore = useAppStore()

  const params = {
    username: chatStore.chatUser.username,
    contentType: null,
    limit: 10
  }

  if (appStore.secretToken) {
    params.postUnlockToken = appStore.secretToken
  }

  params.installId = authStore.installId ? authStore.installId : null

  try {
    const { data } = await app.wsp.sendRequest({
      data: params,
      method: 'user.posts.get'
    })

    posts.value = data.posts
  } catch (e) {
    console.error(e)
  }
}

export {
    userPostsGet
}