<template>
  <div class="relative h-full w-full">
    <ThePostsSwiper
      :posts="uniqPosts"
      :users="[user]"
      @slide-changed="slideChanged"
      @reach-end="reachEnd"
      @liked="liked"
      @shared="shared"
      @subscribed="subscribed"
      @unlocked="unlocked"/>
    <div
      v-if="isLoading"
      class="bg-white absolute inset-0 h-full w-full z-10 flex justify-center items-center overflow-hidden">
      <Spinner size="big"/>
    </div>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePostStore } from '@/stores/post'
import Spinner from '@/components/Functional/Spinner'
import { usePaymentStore } from '@/stores/payment'
import { useUserStore } from '@/stores/user'
import uniqid from 'uniqid'

export default {
  name: 'ThePost',
  components: {
    ThePostsSwiper,
    Spinner
  },
  props: {},
  setup () {
    const postStore = usePostStore()
    const initialPost = postStore.initialPost
    const user = postStore.user

    useMeta({
      title: `${user.name} on One2fan`,
      og: {
        title: `${user.name} on One2fan`,
        description: `${initialPost.description}`,
        site_name: 'Fancy',
        type: 'website'
      }
    })
  },
  data () {
    return {
      buffered: 0,
      isLoading: true
    }
  },
  computed: {
    ...mapState(usePostStore, [
      'user',
      'uniqPosts',
      'loadingState'
    ]),
    ...mapWritableState(usePostStore, [
      'posts',
      'initialPost'
    ]),
    ...mapState(usePaymentStore, [
      'activePaymentMethod',
      'payments',
      'postPayments'
    ]),
    postPayment () {
      return this.postPayments.find(item => item.id.includes('post_' + this.$route.params.postId))
    },
    ...mapState(useUserStore, [
      'balance'
    ])
  },
  async beforeMount () {
    const postId = this.$route.params.postId

    // Check should we pay by card
    if (postId && this.$route.query.unlock && JSON.parse(this.$route.query.unlock)) {
      const post = this.uniqPosts.find((item) => item.id === this.$route.params.postId)
      const postNumber = post.number
      const userId = post.userId
      const isLocked = post.locked
      const cost = post.price

      if (postNumber && userId && isLocked) {
        await this.unlockPost(userId, postNumber, postId, cost)
          .finally(() => {
            this.isLoading = false
          })
      }
    } else {
      this.isLoading = false
    }

    // Remove all query params
    if (this.$route.query.unlock) {
      window.history.replaceState(null, null, window.location.pathname)
    }

    if (!postId) return

    if (!this.initialPost) {
      await this.postGet(postId)
    }

    await this.userPostsGet()
  },
  methods: {
    ...mapActions(usePostStore, [
      'postGet',
      'userPostsGet',
      'postShared',
      'likeToggle'
    ]),
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet',
      'postPurchase',
      'purchaseGet',
      'purchaseCreate'
    ]),
    async unlockPost (userId, postNumber, postId, cost) {
      const data = {
        userId: String(userId),
        number: postNumber,
        cost: cost
      }

      if (this.balance >= cost) {
        const res = await this.postPurchase(data)
        this.updatePost(res, postId)

        return
      }

      data.purchaseCreditsIfNotEnough = true

      // Re-check payments methods
      if (!this.activePaymentMethod.id) {
        await this.paymentMethodsGet()
      }

      if (this.activePaymentMethod.id) {
        data.paymentMethodId = this.activePaymentMethod.id

        // Check payment status or init one
        if (this.postPayment) {
          await this.purchaseGet(this.postPayment.purchaseId)
        }
        else {
          await this.purchaseCreate({ id: `post_${this.post.id}_${uniqid()}` })
        }

        if (this.postPayment?.purchaseId) {
          data.purchaseId = this.postPayment.purchaseId
        }

        const res = await this.postPurchase(data)

        this.updatePost(res, postId)
      }
    },
    updatePost (res, postId) {
      if (res?.post.id) {
        // Update purchased post
        this.$nextTick().then(() => {
          this.posts = this.posts.map((item) => {
            return item.id === postId ? res.post : item
          })


          this.initialPost = res.post
        })
      }
    },
    slideChanged ({ index }) {
      if (index % 5 === 0) {
        if (this.uniqPosts.length - index <= 5 && this.loadingState !== 'loaded-all') {
          this.userPostsGet()
        }
      }
    },
    async reachEnd () {
      if (this.loadingState !== 'loaded-all' && this.loadingState !== 'loading') {
        await this.userPostsGet()
      }
    },
    liked (postId) {
      this.likeToggle(postId)
    },
    shared (postId) {
      this.postShared(postId)
    },
    subscribed (user) {
      this.$router.push(`/@${user.username}`)
    },
    unlocked ({ post }) {
      // Update all Posts (even duplicates)
      this.posts.forEach((item, index) => {
        if (item.id === post.id) {
          this.posts[index] = post
        }
      })
    }
  }
}
</script>
