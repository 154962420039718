import app from '@/main'
import { defineStore } from 'pinia'

export const useSubscriptionsStore = defineStore({
  id: 'subscriptions',
  state: () => ({
    subscriptions: [],
    // FAN-529: https://wiggum.atlassian.net/browse/FAN-529
    // IDs of recent subscriptions to show badge about new message
    recentSubscriptions: [],
    userHasPaidSubscription: false
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'recentSubscriptions'
        ]
      }
    ]
  },
  actions: {
    async subscriptionsGet () {
      const res = await app.wsp
        .sendRequest({
          data: {},
          method: 'subscriptions.get'
        })

      if (res.error) {
        throw res.error
      }

      if (res.data?.subscriptions.length) {
        const found = res.data.subscriptions.find(item => !!item.price)

        if (found) {
          this.userHasPaidSubscription = true
        }

        this.subscriptions = res.data.subscriptions

        return res.data.subscriptions
      }
    },
    toggleSubscriptionStatus (userId) {
      const index = this.subscriptions.findIndex((item) => item.publisherUser.userId === userId)

      if (index < 0) return

      const status = this.subscriptions[index].publisherUser.subscribed

      if (status !== undefined) {
        this.subscriptions[index].publisherUser.subscribed = !status
      }
    }
  }
})
