import { defineStore } from 'pinia'

export const useLogStore = defineStore({
  id: 'logbook',
  state: () => ({
    latestEvents: []
  }),
  actions: {
    addEvent (event) {
      if (this.latestEvents.length >= 20) {
        this.latestEvents.pop()
      }

      this.latestEvents.push(event)
    }
  }
})

