<template>
  <div
    class="flex flex-wrap items-center w-100"
    :class="{ 'justify-end': message.senderId === userId }">
    <!--<pre>{{ message.highlighted }}</pre>-->

    <!-- Logged user's message -->
    <!-- Message status: 0 - means is not paid -->
    <template v-if="message.senderId === userId">
      <ExclamationIcon
        v-if="message.status === 0"
        class="w-6 mr-1.5 cursor-pointer"
        @click="resend(message.messageId, message.status)"/>

      <div
        class="chat-message noselect me"
        :class="{
          'highlighted': message.type === 15 || message.highlighted,
          'cursor-pointer': !balance,
          'need-pay cursor-pointer': message.status === 0
        }"
        @click="resend(message.messageId, message.status)">
        <ChatDeleteMessage :message="message.status === 0 ? null : message">
          <div class="flex justify-end items-end">
            <span
              v-if="message.type === 15 && !message.text"
              class="noselect pr-0.5">
              I’ve sent you ${{ message.tipAmount }}
            </span>

            <span
              v-else
              class="noselect pr-0.5 whitespace-pre-line"
              v-html="linkify(message.text)"/>

            <div class="flex items-center">
              <div class="noselect text-caption px-1 whitespace-nowrap flex justify-center content-center">
                {{ dateToTime(message.created) }}
              </div>
            </div>
          </div>
        </ChatDeleteMessage>
      </div>

      <div
        v-if="message.status === 0"
        class="w-full"/>

      <div
        v-if="message.status === 0"
        class="text-right text-caption text-gray-500">
        {{ $t('modals.no_credits.not_enough_credits') }}
      </div>

      <!-- Delivery status -->
      <div
        v-if="message.status !== 0"
        class="text-right text-caption text-gray-400 w-full noselect">
        <div class="flex items-center justify-end">
          <div class="flex items-center">
            <InlineSvg
              v-show="message.status === 9"
              :src="require('@/assets/svg/design/viewed.svg')"/>

            <span class="ml-1">
              {{ message.status === 8 ? $t('chat.delivered') : $t('chat.viewed') }}&nbsp;
            </span>
          </div>

          <div
            v-show="message.ttl || message.ttlAfterRead"
            class="flex items-center ml-1">
            <InlineSvg
              class="text-transparent"
              :src="require('@/assets/svg/design/timer.svg')"/>

            <span class="ml-1">
              Secret message
            </span>
          </div>
        </div>
      </div>

      <!-- Tip message-->
      <template v-if="message.type === 15 && message.tipAmount && message.text">
        <div
          class="chat-message noselect me highlighted"
          @click="resend(message.messageId, message.status)">
          <div>
            <div class="flex justify-end items-end">
              <span class="noselect pr-0.5">
                I’ve sent you ${{ message.tipAmount }}
              </span>

              <div class="flex items-center">
                <div class="noselect text-caption px-1 whitespace-nowrap flex justify-center content-center">
                  {{ dateToTime(message.created) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Delivery status for Tip -->
        <div
          v-if="message.status !== 0"
          class="text-right text-caption text-gray-400 w-full noselect">
          <div class="flex items-center justify-end">
            <div class="flex items-center">
              <InlineSvg
                v-show="message.status === 9"
                :src="require('@/assets/svg/design/viewed.svg')"/>

              <span class="ml-1">
                {{ message.status === 8 ? $t('chat.delivered') : $t('chat.viewed') }}&nbsp;
              </span>
            </div>
          </div>
        </div>
      </template>
    </template>

    <!-- Tech message-->
    <template v-else-if="message.type === 9">
      <div class="message">
        <div class="flex justify-end items-end">
          <span
            class="whitespace-pre-line"
            v-html="linkify(message.text)"/>

          <div class="flex items-center">
            <div class="text-caption px-1 whitespace-nowrap flex justify-center content-center">
              {{ dateToTime(message.created) }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Chat user's message -->
    <template v-else>
      <div
        :class="{
          'mass': isMass,
          'promo': isPromo,
          'hello': checkIfMessageCaptionsEnabled(message.type) && !isPromo || (options.prelandingId === 'apreland' && !isPremiumSupportUser),
          'companion': !checkIfMessageCaptionsEnabled(message.type) && !isPromo,
        }"
        class="chat-message">
        <div class="flex justify-end items-end">
          <span
            class="whitespace-pre-line"
            v-html="linkify(message.text)"/>

          <div class="flex items-center">
            <div
              :class="[checkIfMessageCaptionsEnabled(message.type) && !isPromo ? 'text-[#947076]' : 'text-gray-400']"
              class="text-caption px-1 whitespace-nowrap flex justify-center content-center">
              {{ dateToTime(message.created) }}
            </div>
          </div>
        </div>

        <div
          v-if="options.prelandingId === 'apreland' && !isPremiumSupportUser"
          class="flex items-center gap-1">
          <InlineSvg
            :src="require('@/assets/svg/design/i_autoreply.svg')"
            class="h-2 w-2"/>

          <span class="text-[10px] leading-3 text-[#837d7c]">
            @{{ chatUser.username }} AI assistant
          </span>
        </div>

        <div
          v-if="isPromo || isMass"
          class="flex items-center gap-1">
          <InlineSvg
            :src="require('@/assets/svg/design/logo-small.svg')"
            class="h-2 w-2"/>

          <span class="text-[10px] leading-3 text-[#837d7c]">
            {{ isMass ? 'Mass sending' : 'Ad promotion' }}
          </span>
        </div>

        <div
          v-if="(checkIfMessageCaptionsEnabled(message.type) && user?.username) && options.prelandingId !== 'apreland'"
          class="mt-1 flex">
          <InlineSvg
            class="w-4 h-4 opacity-40"
            :src="require('@/assets/svg/design/logo-small.svg')"/>

          <p class="ml-1 text-[10px] leading-3 text-[#926b73]">
            {{ $t('chat.message.automated_reply', { username: chatUser.name }) }}
          </p>
        </div>
      </div>

      <div
        v-show="message.ttl || message.ttlAfterRead"
        class="text-right text-caption text-gray-400 w-full noselect mt-1">
        <div class="flex items-center ml-1">
          <InlineSvg
            class="text-transparent"
            :src="require('@/assets/svg/design/timer.svg')"/>

          <span class="ml-1">
            Secret message
          </span>
        </div>
      </div>
    </template>
  </div>

  <Modal
    v-slot="{ closeModal }"
    ref="notEnoughCreditsModal"
    name="NoCredits"
    position="bottom"
    overflow-class="overflow-y-auto"
    content-class="bg-white p-5"
    wrapper-class=""
    rounded-class="rounded-t-2.5xl"
    :show-close-cross="false"
    :has-indents="false">
    <NoCreditsContent @close="closeModal()"/>
  </modal>
</template>

<script>
import ExclamationIcon from '@/assets/svg-vue/ExclamationIcon'
import Modal from '@/components/Functional/Modal'
import NoCreditsContent from '@/components/ModalContents/chat/NoCreditsContent'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useChatStore } from '@/stores/chat'
import { usePaymentStore } from '@/stores/payment'
import { useQuizStore } from '@/stores/quiz'
import sanitizeHtml from 'sanitize-html'
import ChatDeleteMessage from '@/components/Chat/ChatMessages/ChatDeleteMessage'
import { clientEventTrack } from '@/helpers/methods'

export default {
  name: 'ChatTextMessage',
  components: {
    ExclamationIcon,
    Modal,
    NoCreditsContent,
    ChatDeleteMessage
  },
  props: {
    message: {
      type: Object,
      default: () => {},
      required: true
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useUserStore, [
      'userId',
      'balance',
      'rates',
      'user',
      'features',
      'options',
      'premiumSupportAgentUserId'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    ...mapState(useChatStore, [
      'chatId',
      'chatUser'
    ]),
    ...mapWritableState(usePaymentStore, [
      'pathBeforeBuying'
    ]),
    ...mapState(useQuizStore, [
      'survey'
    ]),
    isPromo () {
      return this.message.designId === 'promo'
    },
    isMass () {
      return this.message.designId === 'mass'
    }
  },
  mounted () {},
  methods: {
    ...mapActions(usePaymentStore, [
      'messageResend'
    ]),
    clientEventTrack: clientEventTrack,
    checkIfMessageCaptionsEnabled (messageType) {
      return this.survey.messageCaptionsEnabled && messageType === 16
    },
    dateToTime (value) {
      const date = this.$dayjs.unix(value)

      return date.locale(this.$i18n.locale).format('h:mm A')
    },
    resend (messageId, status) {
      if (status !== 0) {
        return
      }

      if (this.balance >= this.rates.costOnlineChat) {
        this.messageResend(messageId, this.chatId)
      } else {
        this.pathBeforeBuying = '/chat/' + this.chatId
        this.$refs.notEnoughCreditsModal.openModal()
        this.clientEventTrack({ typeName: 'not_ehough_credits_popup_shown' })
      }
    },
    linkify (inputText) {
      // URLs starting with http://, https://, or ftp://
      const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])/gim
      const result = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

      return sanitizeHtml(result)
    }
  }
}
</script>

<style>
  .chat-message {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative break-words;
    word-break: break-word;
    max-width: 80%;
  }
  .chat-message a {
    text-decoration: underline;
  }
  .technical {
    @apply border border-gray-100 bg-white;
  }
  .companion {
    @apply bg-white text-gray-900;
    filter: drop-shadow(0px 5px 8px rgba(188, 203, 209, 0.4));
  }
  .companion:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail.svg");
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  .me.highlighted {
    background: linear-gradient(180deg, #FF5EB5 0%, #F2346D 100%);
    color: white !important;
  }
  .me {
    @apply text-white;
    background: linear-gradient(180deg, #0BC6DF 0%, #05AED3 100%);
  }
  .me.need-pay {
    background: linear-gradient(180deg, #F23459 0%, #E80E38 100%);
  }
  .me.highlighted:before {
    background-image: url("~@/assets/svg/design/tail-tip.svg");
  }
  .me.need-pay:before {
    background-image: url("~@/assets/svg/design/tail_me_need_pay.svg");
  }
  .me.highlighted {
    @apply text-gray-800;
  }

  .me:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail_me.svg");
    position: absolute;
    bottom: 0;
    right: -6px;
  }

  .chat-message.hello {
    background: linear-gradient(#fedbe1, #f1a9b7);
    filter: drop-shadow(0px 5px 10px rgba(188, 203, 209, 0.4));
  }

  .chat-message.hello:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail_hi.svg");
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  .chat-message.promo {
    background: linear-gradient(180deg, #E1E1E1 0%, #CEC4C2 100%);
  }

  .chat-message.promo:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail_promo.svg");
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  .chat-message.mass {
    background: linear-gradient(180deg, #FFD8DF 0%, #F1A9B7 100%);
  }

  .chat-message.mass:before {
    background-image: url("~@/assets/svg/design/tail_pink.svg");
  }

  .chat-message.mass.no-message:before {
    background-image: url("~@/assets/svg/design/tail_pink_short.svg");
  }
</style>
