<template>
  <Header
    :name="$t('auth.signup.registration')"
    class="mb-6"/>

  <div class="container pb-4">
    <h2 class="h1 mb-6">
      {{ $t('auth.signup.sign_up_for_free') }}
    </h2>

    <SignUpForm :show-heading="false"/>
  </div>
</template>

<script>
import SignUpForm from '@/components/SignUpForm'
import Header from '@/components/Functional/Header'

export default {
  name: 'SignUp',
  components: {
    SignUpForm,
    Header
  },
  data () {
    return {
    }
  },
  computed: {},
  created () {},
  methods: {}
}
</script>

