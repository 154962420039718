<template>
  <div class="flex items-start justify-between mb-5">
    <h2 class="h2">
      {{ $t('modals.remove_card.heading') }}
    </h2>

    <CloseCrossButton @click="emitClose"/>
  </div>

  <p class="mb-5">
    {{ $t('modals.remove_card.descriptions') }}
  </p>

  <button
    class="btn btn-primary btn-block mb-3"
    @click="removeCard">
    {{ $t('common.yes') }}
  </button>

  <button
    class="btn btn-secondary btn-block"
    @click="emitClose">
    {{ $t('common.no') }}
  </button>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapActions, mapWritableState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'

export default {
  name: 'RemoveCardContent',
  components: {
    CloseCrossButton
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        paymentMethodId: null
      })
    }
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {
    ...mapWritableState(usePaymentStore, [
      'paymentMethods',
      'selectedPaymentMethod'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodDelete'
    ]),
    async emitClose () {
      await this.$emit('close')
    },
    async removeCard () {
      if (this.values.paymentMethodId) {
        await this.paymentMethodDelete(this.values.paymentMethodId)

        this.paymentMethods = this.paymentMethods.filter((item) => {
          return item.id !== this.values.paymentMethodId
        })

        if (this.paymentMethods.length) {
          this.selectedPaymentMethod = this.paymentMethods[0]
        } else {
          this.selectedPaymentMethod = { id: null }
        }
      }

      await this.emitClose()
    }
  }
}
</script>

