<template>
  <div class="container py-5">
    <div class="h-full w-full max-w-full flex flex-col placeholder">
      <div style="height: 76px" class="mb-3">
        <div
          style="width: 200px; height: 30px"
          class="placeholder-item rounded-full"/>
        <div class="py-1"/>
        <div
          style="width: 300px; height: 30px"
          class="placeholder-item rounded-full"/>
      </div>

      <div
        style="width: 315px; height: 22px"
        class="placeholder-item rounded-full mb-5"/>

      <div class="grid grid-flow-col gap-1 auto-cols-max overflow-auto mb-4 scrollbar-thin">
        <div class="h-34 w-[95px] sm:w-[115px] rounded-lg placeholder-item"/>
        <div class="h-34 w-[95px] sm:w-[115px] rounded-lg placeholder-item"/>
        <div class="h-34 w-[95px] sm:w-[115px] rounded-lg placeholder-item"/>
        <div class="h-34 w-[95px] sm:w-[115px] rounded-lg placeholder-item"/>
        <div class="h-34 w-[95px] sm:w-[115px] rounded-lg placeholder-item"/>
      </div>

      <div class="flex">
        <div class="h-4 w-4 placeholder-item rounded mr-3"/>
        <div>
          <div
            style="width: 340px; height: 16px"
            class="rounded-full placeholder-item mb-1"/>
          <div
            style="width: 320px; height: 16px"
            class="rounded-full placeholder-item mb-1"/>
          <div
            style="width: 120px; height: 16px"
            class="rounded-full placeholder-item"/>
        </div>
      </div>

      <div
        style="height: 46px"
        class="mb-3 mt-8 placeholder-item rounded-lg"/>
      <div
        style="height: 46px"
        class="mb-3 placeholder-item rounded-lg"/>

      <table class="mt-10 w-full text-caption table-fixed border-separate">
        <thead>
          <tr>
            <th class="w-1/4"/>
            <th class="w-2/5"/>
          </tr>
        </thead>

        <tbody class="align-top">
          <tr>
            <td>
              <div
                style="width: 65%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 50%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 65%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 50%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 60%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 55%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 60%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 55%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 65%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 50%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 70%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 60%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>

          <tr>
            <td>
              <div
                style="width: 70%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
            <td class="text-gray-500 pb-2">
              <div
                style="width: 60%; height: 20px"
                class="rounded-full placeholder-item"/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CreditsPlaceholder',
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

