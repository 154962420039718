import { defineStore } from 'pinia'
import { ref } from 'vue'
import app from '@/main'

export const useEventsStore = defineStore('events', () => {
  const loadingStatus = ref('')
  const limit = ref(15)
  const thereAreEvents = ref(false) // Hack to show badge in the menu

  function dialogGetList (afterRevision = null, lmt = null) {
    return app.wsp.sendRequest({
      data: {
        afterRevision: afterRevision,
        limit: lmt || limit.value,
        filterRelation: 1,
        orderByRevision: 'desc'
      },
      method: 'dialog.getList'
    })
  }

  function acceptInviteClicked (userId) {
    return app.wsp.sendRequest({
      data: {
        userId
      },
      method: 'acceptInvite.clicked'
    })
  }

  return {
    loadingStatus,
    limit,
    dialogGetList,
    acceptInviteClicked,
    thereAreEvents
  }
})
