import { h, render } from 'vue'
import { uid } from 'radash'

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Usage

// import ModalWindow
// import ComponentContent
// modalWindowProps = { wrapperClass: 'max-w-2xl overflow-y-auto rounded-xl bg-white p-4 shadow-lg' }
// componentContent = { heading: 'hello world' }
// createComponent(ModalWindow, modalWindowProps, ComponentContent, componentContent)

function createComponent (wrapperComponent, wrapperProps = {}, componentContent = {}, componentProps = {}, id = null) {
  let el = document.createElement('div')
  document.body.appendChild(el)

  el.id = id ? id : `${uid(6)}-modal`

  let vNode = null

  function destroy () {
    render(null, el)
    vNode = null
    el.remove()
    el = null
  }

  componentProps = { ...componentProps, onDestroy: destroy }
  componentContent = h(componentContent, componentProps)

  wrapperProps = { ...wrapperProps, onDestroy: destroy }
  vNode = h(wrapperComponent, wrapperProps, () => componentContent)

  render(vNode, el)
  return { vNode, destroy }
}


export {
  timeout,
  createComponent
}
