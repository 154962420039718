<template>
  <notifications
    ref="test1"
    v-touch:swipe.left="swipeHandler"
    v-touch:swipe.right="swipeHandler"
    v-touch:swipe.top="swipeHandler"
    v-touch:swipe.bottom="swipeHandler"
    group="timer"
    :class="fadeOutClasses"
    :width="getScreenWidth() > 1000 ? '360px' : '100%'"
    :style="{ top: '74px' }"
    :speed="200"
    :duration="3000"
    :max="1"
    position="center">
    <template #body="props">
      <div class="fancy-message rounded-xl relative">
        <button class="absolute right-1 top-1 p-0 m-0" @click="close">
          <svg
            class="text-gray-600 opacity-50 inline-block w-6 h-6 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path
              d="m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
          </svg>
        </button>

        <div
          class="flex justify-center w-full no-underline"
          @click="props.close">
          <div class="flex flex-col flex-1 justify-center">
            <h3
              v-if="props.item.title"
              class="mt-0 text-base mr-3">
              {{ props.item.title }}
            </h3>

            <p class="line-clamp-2 p-0 m-0 text-sm">
              {{ props.item.text }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import '../../../node_modules/animate.css/animate.css'

export default {
  name: 'ClockTimerMessage',
  components: {},
  data () {
    return {
      fadeOutClasses: '',
      screenWidth: 1000
    }
  },
  computed: {},
  mounted () {},
  methods: {
    getScreenWidth () {
      return window.screen.width
    },
    swipeHandler (direction, event) {
      let animationDirection = ''

      if (direction === 'bottom') {
        animationDirection = 'Down'
      } else if (direction === 'top') {
        animationDirection = 'Up'
      } else if (direction === 'left') {
        animationDirection = 'Left'
      } else if (direction === 'right') {
        animationDirection = 'Right'
      }

      this.fadeOutClasses = `animate__fadeOut${animationDirection} animate__animated animate__faster`

      this.$notify({
        group: 'timer',
        clean: true
      })

      const vm = this

      setTimeout(() => {
        vm.fadeOutClasses = ''
      }, 500)
    },
    close () {
      this.$notify({
        group: 'timer',
        clean: true
      })
    }
  }
}
</script>

<style scoped>
  .fancy-message {
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.13);
    background: #FFFFFF;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
