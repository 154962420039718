<template>
  <div
    ref="root"
    aria-describedby="tooltip">
    <slot>
      Tooltip
    </slot>

    <div
      ref="tooltip"
      class="tooltip"
      role="tooltip">
      <div>
        {{ capitalizeFirstLetter(content) }}
      </div>

      <div
        class="arrow"
        data-popper-arrow/>
    </div>
  </div>
</template>

<script>
// https://popper.js.org/docs/v2/tutorial/#full-code
import { createPopper } from '@popperjs/core'

export default {
  name: 'FormValidationTooltip',
  components: {},
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      popperInstance: null,
      root: null,
      tooltip: null
    }
  },
  computed: {},
  watch: {
    content (value) {
      value ? this.show() : this.hide()
    }
  },
  mounted () {
    this.create()
  },
  beforeUnmount () {
    this.destroy()
  },
  methods: {
    create () {
      this.root = this.$refs.root
      this.tooltip = this.$refs.tooltip

      this.popperInstance = createPopper(this.root, this.tooltip, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8]
            }
          }
        ]
      })
    },
    destroy () {
      if (this.popperInstance) {
        this.popperInstance.destroy()
        this.popperInstance = null
      }
    },
    show () {
      this.tooltip.setAttribute('data-show', '')
      this.create()
    },
    hide () {
      this.tooltip.removeAttribute('data-show')
      this.destroy()
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style>
  .tooltip {
    background: #333;
    color: white;
    font-weight: bold;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: none;
  }

  .tooltip[data-show] {
    display: block;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  .arrow::before {
    content: '';
    transform: rotate(45deg);
    background: #333;
  }

  .tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
  }

  .tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
  }

  .tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
  }

  .tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
  }
</style>
