<template>
  <div>
    <div class="flex flex-col items-center relative">
      <div class="absolute left-0 top-0 inline-block">
        <a
          class="flex items-center cursor-pointer text-gray-500"
          @click="reportUser">
          <InlineSvg
            :src="require('@/assets/svg/design/flag.svg')"
            class="h-4 w-4 text-white stroke-gray-500"
            @click="closeModal"/>

          <span class="text-caption ml-1">
            {{ $t('common.report') }}
          </span>
        </a>
      </div>

      <div class="relative">
        <Avatar
          class="h-20 w-20 mb-2"
          :user-item="values.user"/>

        <div class="absolute bottom-1.5 right-0 text-caption">
          <InlineSvg
            v-if="(values.index || values.index === 0) && values.index < 3"
            class="w-7"
            :src="require(`@/assets/svg/design/tops/${coins[values.index]}-medal.svg`)"/>
        </div>
      </div>

      <h3 class="flex items-center">
        <span class="font-bold">
          {{ values.user.name }}
        </span>

        <span
          v-show="values.user.online"
          class="flex items-center ml-1.5">
          <span class="text-caption text-[#0DAE7D] font-normal">online</span>
          <span class="block h-2 w-2 rounded-full ring-2 ring-white bg-green-300 ml-1"/>
        </span>
      </h3>

      <h4 class="text-gray-500 font-bold">
        @{{ values.user.username }}
      </h4>
    </div>

    <div class="text-center mt-3 mb-2 text-base text-[#ACAEB0] font-bold">
      #{{ values.index + 1 }}
    </div>

    <!-- Credits spent -->
    <div
      v-if="realCreditsSpent"
      class="flex justify-center items-center mt-3">
      <span
        v-if="realCreditsSpent > 5000"
        class="text-[#FFBA57] font-semibold">
        >5000
      </span>

      <span
        v-else
        class="text-[#FFBA57] font-semibold">
        {{ realCreditsSpent }}
      </span>

      <InlineSvg
        class="w-4 ml-1"
        :src="require(`@/assets/svg/design/coin.svg`)"/>
    </div>

    <!-- About -->
    <div
      v-if="values.user?.about"
      class="mt-3">
      <p class="text-sm text-gray-700 not-italic">
        {{ values.user.about }}
      </p>
    </div>

    <!-- Interests -->
    <div
      v-if="values.user.interests.length"
      class="mt-3">
      <div class="flex flex-wrap">
        <div
          v-for="(item, index) in values.user.interests.split(',')"
          :key="index"
          class="px-3 py-1.5 text-sm text-white bg-primary rounded-full mr-2 mt-2 capitalize">
          {{ item }}
        </div>
      </div>
    </div>


    <Modal
      v-slot="{ closeModal, values }"
      ref="reportModal"
      name="ReportUser">
      <BlockUserContent
        :heading="$t('creator.block.report_user')"
        :values="values"
        @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import BlockUserContent from '@/components/ModalContents/BlockUserContent'


export default {
  name: 'TopsUserProfile',
  components: {
    Avatar,
    BlockUserContent
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        user: null,
        realCreditsSpent: null,
        index: null
      })
    }
  },
  data () {
    return {
      coins: {
        0: 'gold',
        1: 'silver',
        2: 'copper'
      }
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    getTikTokUsername () {
      const user = this.values.user

      return user.tikTokUsername.charAt(0) === '@' ? user.tikTokUsername : `@${user.tikTokUsername}`
    },
    reportUser () {
      if (!this.values.user.userId) {
        return
      }

      this.$refs.reportModal.openModal({
        userId: this.values.user.userId,
        username: this.values.user.username
      })
    }
  }
}
</script>

