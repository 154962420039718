<template>
  <div class="chat-messages">
    <div class="placeholder justify-center text-caption text-gray-400 text-center">
      <div class="placeholder-item rounded-xl text-sm leading-4 w-32 h-4">&#160;</div>
    </div>

    <div class="my-2 w-full max-w-full rounded-2xl">
      <div class="placeholder flex flex-wrap items-center w-100 justify-end">
        <div
          class="message-item placeholder-item"
          style="width: 119px; height: 38px">
          <div class="flex justify-end items-end">
              &#160;
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 w-full max-w-full rounded-2xl">
      <div class="placeholder flex flex-wrap items-center w-100 justify-end">
        <div
          class="message-item placeholder-item"
          style="width: 164px; height: 38px">
          <div class="flex justify-end items-end">
              &#160;
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 w-full max-w-full rounded-2xl">
      <div class="placeholder flex flex-wrap items-center w-100">
        <div
          class="message-item placeholder-item"
          style="width: 298px; height: 38px">
          <div class="flex justify-end items-end">
              &#160;
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 w-full max-w-full rounded-2xl">
      <div class="placeholder flex flex-wrap items-center w-100">
        <div
          class="message-item placeholder-item"
          style="width: 331px; height: 60px">
          <div class="flex justify-end items-end">
              &#160;
          </div>
        </div>
      </div>
    </div>

    <div class="my-2 w-full max-w-full rounded-2xl">
      <div class="placeholder flex flex-wrap items-center w-100 justify-end">
        <div
          class="message-item placeholder-item"
          style="width: 113px; height: 38px">
          <div class="flex justify-end items-end">
              &#160;
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChatBodyPlaceholder',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .chat-messages {
    @apply px-4 h-full
  }
  .message-item {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative;
    max-width: 80%;
  }
</style>
