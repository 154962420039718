<template>
  <div class="px-4">
    <div class="mt-4">
      <h4 class="text-base font-bold">
        {{ $t('creator.profile.interests') }}
      </h4>

      <div v-if="creator.interests" class="flex flex-wrap">
        <div
          v-for="(item, index) in creator.interests.split(',')"
          :key="index"
          class="px-3 py-1 text-base border border-gray-300 rounded-full mr-2 mt-2">
          {{ item }}
        </div>
      </div>

      <p v-else class="text-sm text-gray-500">
        {{ $t('creator.profile.no_data') }}
      </p>
    </div>

    <div class="mt-4 mb-4">
      <h4 class="text-base font-bold">
        {{ $t('creator.profile.details') }}
      </h4>

      <div class="mt-2 grid gap-2 grid-cols-2">
        <div>
          <h5 class="text-sm">
            {{ $t('creator.profile.live_in') }}
          </h5>

          <address class="text-sm text-gray-500 not-italic">
            {{ creator.location.name }}
          </address>
        </div>

        <div>
          <h5 class="text-sm">
            {{ $t('creator.profile.speaks') }}
          </h5>

          <p
            v-for="(item, index) in creator.languages"
            :key="index"
            class="text-sm text-gray-500">
            {{ item }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    creator: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
