<template>
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10zM0 11.002C0 4.926 4.925 0 11 0s11 4.926 11 11.002c0 6.076-4.925 11.001-11 11.001S0 17.078 0 11.002zM11 17.3a1.1 1.1 0 100-2.2 1.1 1.1 0 000 2.2zM10 4.8h2V8c0 1-.8 5.4-.8 5.4h-.4S10 9 10 8V4.8z"
      fill="#FF3B30"/>
  </svg>
</template>

<script>

export default {
  name: 'ExclamationIcon'
}

</script>
