export default {
  install: () => {
    // Create dynamic height css variable
    const appHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)

    window.addEventListener('resize', appHeight)
    window.addEventListener('orientationchange', appHeight)
    appHeight()

    // Dynamic width
    const appWidth = () => document.documentElement.style.setProperty('--app-width', `${window.innerWidth}px`)

    window.addEventListener('resize', appWidth)
    window.addEventListener('orientationchange', appWidth)
    appWidth()

    window.addEventListener('scroll', () => {
      document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
    })
  }
}
