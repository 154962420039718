<template>
  <teleport
    ref="modal"
    to="body">
    <transition name="fade">
      <div
        v-if="isVisible"
        class="fixed inset-0 overflow-hidden flex flex-col"
        :class="[{
          'justify-center': position === 'center',
          'justify-end': position === 'bottom'
        }, zIndexClass]">
        <!-- Overlay -->
        <div
          class="absolute inset-0 bg-gray-700 opacity-75"
          @click.stop="allowCloseByOverlay ? closeModal() : undefined"/>

        <!-- Close button -->
        <button
          v-if="showCloseCross"
          class="absolute z-40 top-2 left-2 btn py-2 pl-3 pr-0 cursor-pointer post-shadow"
          @click.stop="closeModal">
          <InlineSvg
            :src="require('@/assets/svg/design/close-arrow.svg')"
            class="text-gray-100 w-6 h-6 post-shadow"/>
        </button>

        <!-- Content -->
        <div
          ref="content"
          class="w-full max-w-md mx-auto"
          :class="[
            {
              'px-4': hasIndents,
            },
            isFullHeight ? 'h-full' : 'mt-5',
            wrapperClass,
            overflowClass
          ]"
          @click.stop>
          <div
            :class="[contentClass, overflowClass, isRounded && roundedClass]"
            class="relative h-full"
            style="-webkit-overflow-scrolling: touch;"
            @click.stop>
            <!-- Desktop Close button -->
            <button
              v-if="showDesktopCloseCross"
              class="absolute z-40 top-2 -left-10 btn cursor-pointer p-0"
              @click.stop="closeModal">
              <ImageLoader v-slot="{ setLoaded }">
                <InlineSvg
                  :src="timesCircleSVG"
                  class="text-gray-400 w-8 h-8"
                  @loaded="setLoaded"/>
              </ImageLoader>
            </button>

            <slot
              :is-visible="isVisible"
              :values="values"
              :close-modal="closeModal"/>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>

/*
  Usage:

  <Modal
    v-slot="{ closeModal }"
    ref="subscriptionModal"
    name="SomeName">
    <h1>Any</div>
    <p>Content</p>
  </Modal>
*/

import ImageLoader from '@/components/Functional/ImageLoader'
import timesCircleSVG from '@/assets/svg/light/times-circle.svg'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'

const body = document.body

export default {
  name: 'Modal',
  components: {
    ImageLoader
  },
  props: {
    name: {
      type: String,
      default: 'Default'
    },
    // center / bottom
    position: {
      type: String,
      default: 'center'
    },
    // Allow close by clicking outside of content
    allowCloseByOverlay: {
      type: Boolean,
      default: true
    },
    // Is modal has left/right padding
    hasIndents: {
      type: Boolean,
      default: true
    },
    isRounded: {
      type: Boolean,
      default: true
    },
    isFullHeight: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: 'bg-white p-6'
    },
    wrapperClass: {
      type: String,
      default: ''
    },
    roundedClass: {
      type: String,
      default: 'rounded-2.5xl'
    },
    showCloseCross: {
      type: Boolean,
      default: false
    },
    showDesktopCloseCross: {
      type: Boolean,
      default: false
    },
    zIndexClass: {
      type: String,
      default: 'z-50'
    },
    overflowClass: {
      type: String,
      default: ''
    }
  },
  emits: ['close', 'opened'],
  data () {
    return {
      isVisible: false,
      timesCircleSVG,
      values: {},
      scrollPosition: 0,
      allowUnblockScroll: true
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isIPhone'
    ])
  },
  beforeUnmount () {
    body.classList.remove('modal-active')
  },
  methods: {
    async openModal (values) {
      this.$addEvent([`Open ${this.name} modal`])

      this.showDialog()

      await this.$nextTick()
      this.values = values
      this.isVisible = true

      await this.$emit('opened')
    },
    async closeModal () {
      if (this.isVisible) {
        this.$addEvent([`Close ${this.name} modal`])
      }

      this.values = {}
      this.isVisible = false
      await this.$nextTick()

      setTimeout(() => {
        this.closeDialog()
        this.$emit('close')
      }, 300)

      await this.$emit('close')
    },
    // Prevent Page Scrolling When a Modal is Open
    // https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
    showDialog () {
      const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
      const body = document.body

      // Prevent to unblock body scroll if second modal opened
      if (body.dataset.modal) {
        this.allowUnblockScroll = false
      } else {
        body.dataset.modal = 'true'
      }

      body.style.position = 'fixed'
      body.style.top = `-${scrollY}`
    },
    closeDialog () {
      if (this.allowUnblockScroll) {
        const body = document.body

        delete body.dataset.modal
        const scrollY = body.style.top

        body.style.position = ''
        body.style.top = ''

        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }
  }
}
</script>
