<template>
  <div class="h-full flex flex-col justify-between overflow-auto py-5">
    <div class="px-4">
      <div class="w-full flex justify-between items-center mb-8">
        <a
          class="logo w-4 h-6 inline-block"
          :href="`${$window.one2fan.origin}/home`">
          <InlineSvg
            :src="require('@/assets/svg/design/logo-name.svg')"/>
        </a>

        <InlineSvg
          :src="require('@/assets/svg/design/cross2.svg')"
          class="cursor-pointer h-5 w-5"
          @click="closeSidebar"/>
      </div>

      <nav class="space-y-3.5 mb-8">
        <a
          :href="`${$window.one2fan.origin}/home`"
          class="flex items-center mb-1.5 group hover:text-primary-press font-bold">
          <InlineSvg
            :src="require('@/assets/svg/navbar/home.svg')"
            class="cursor-pointer w-5 mr-2"/>

          {{ $t('nav.sidebar.main_page') }}
        </a>

        <a
          :href="`${$window.one2fan.origin}/how`"
          class="flex items-center group hover:text-primary-press stroke-current font-bold">
          <InlineSvg
            :src="require('@/assets/svg/design/star.svg')"
            class="cursor-pointer w-5 mr-2"/>
          {{ $t('nav.sidebar.become_a_creator') }}
        </a>

        <a
          href="https://one2fan.com/blog"
          target="_blank"
          class="flex items-center group font-bold hover:text-primary-press hover:stroke-primary">
          <InlineSvg
            :src="require('@/assets/svg/design/one2fan-blog.svg')"
            class="cursor-pointer w-5 mr-2"/>
          One2fan Blog
        </a>
      </nav>

      <template v-if="isConnected">
        <div v-if="isAuthenticated">
          <!-- User's avatar -->
          <div class="flex items-center justify-center radius rounded-xl bg-gray-100 p-2">
            <router-link
              class="h-10 w-10 mr-2"
              to="/me">
              <Avatar :user-item="user"/>
            </router-link>


            <div class="flex-1 flex justify-between">
              <router-link to="/me">
                <h3 class="overflow-ellipsis overflow-hidden text-sm font-semibold">
                  {{ user.name }}
                </h3>

                <span
                  v-show="user.username"
                  class="text-gray-500">@{{ user.username }}</span>
              </router-link>
            </div>
          </div>

          <section v-if="subscriptions.length > 0" class="my-5">
            <h3 class="text-caption py-1 pb-1.5 text-gray-600 font-bold">
              {{ $t('nav.sidebar.following_accounts') }}
            </h3>

            <div
              v-for="(item) in subscriptions.slice(0, 3)"
              v-show="item.publisherUser.name"
              :key="item.publisherUserId"
              class="flex flex-1 py-2">
              <router-link :to="`/@${item.publisherUser.username}`" class="flex">
                <Avatar
                  class="h-8 w-8 mr-2"
                  :show-status="true"
                  :user-item="item.publisherUser"/>

                <div class="flex flex-col flex-1">
                  <h3 class="text-sm leading-4 font-normal">{{ item.publisherUser.name }}</h3>

                  <a class="text-caption text-gray-600 leading-4">@{{ item.publisherUser.username }}</a>
                </div>
              </router-link>
            </div>

            <router-link
              to="/subscriptions"
              class="flex items-center font-semibold text-caption text-primary mt-3">
              {{ $t('nav.sidebar.see_more') }}

              <InlineSvg
                :src="require('@/assets/svg/design/arrow-right.svg')"
                class="cursor-pointer ml-1"/>
            </router-link>
          </section>
        </div>

        <div v-else>
          <button
            class="btn btn-primary btn-block"
            @click="closeSidebar(); showLogIn()">
            {{ $t('common.login') }}
          </button>
        </div>
      </template>
    </div>

    <div class="px-4">
      <a
        v-if="currentLanguage"
        class="flex items-center text-caption cursor-pointer mb-3"
        @click="showLangSwitcher">
        <span class="text-[16px]">{{ currentLanguage.flag }}</span>
        <span class="pl-1.5 pb-0.5">{{ currentLanguage.name }}</span>

        <InlineSvg
          :src="require('@/assets/svg/design/arrow-right.svg')"
          class="cursor-pointer ml-1 mb-0.5"/>
      </a>

      <!-- Companty -->
      <div class="mb-2">
        <h4 class="text-[#808080] font-bold text-caption mb-3">
          {{ $t('nav.sidebar.company') }}
        </h4>

        <div class="flex items-center gap-4">
          <a
            href="https://twitter.com/one2fan_"
            class="text-[#bfbfbf] hover:text-gray-500"
            target="_blank">
            <InlineSvg
              class="w-6 h-6"
              :src="require('@/assets/fontawesome/brands/x-twitter.svg')"/>
          </a>

          <a
            href="https://www.tiktok.com/@one2.fan"
            class="text-[#bfbfbf] hover:text-gray-500"
            target="_blank">
            <InlineSvg
              class="w-6 h-6"
              :src="require('@/assets/fontawesome/brands/tiktok.svg')"/>
          </a>

          <a
            href="https://t.me/one2fan"
            class="text-[#bfbfbf] hover:text-gray-500"
            target="_blank">
            <InlineSvg
              class="w-6 h-6"
              :src="require('@/assets/fontawesome/brands/telegram.svg')"/>
          </a>

          <a
            v-if="0"
            href="#"
            class="text-[#bfbfbf] hover:text-gray-500"
            target="_blank">
            <InlineSvg
              class="w-6 h-6"
              :src="require('@/assets/fontawesome/brands/tiktok.svg')"/>
          </a>
        </div>
      </div>

      <ul class="flex flex-wrap gap-x-3">
        <li
          v-if="premiumSupportAgentUserId"
          class="text-caption py-1">
          <a :href="`/chat/${premiumSupportAgentUserId}`">
            {{ $t('nav.sidebar.support') }}
          </a>
        </li>

        <li
          v-else
          class="text-caption py-1">
          <a href="mailto:support@one2fan.com">
            {{ $t('nav.sidebar.support') }}</a>
        </li>

        <li class="text-caption py-1">
          <a href="mailto:hello@one2fan.com">
            {{ $t('nav.sidebar.contact') }}</a>
        </li>

        <li class="text-caption py-1">
          <a :href="`${$window.one2fan.origin}/terms`">
            {{ $t('nav.sidebar.terms') }}</a>
        </li>

        <li class="text-caption py-1">
          <a :href="`${$window.one2fan.origin}/policy`">
            {{ $t('nav.sidebar.privacy') }}</a>
        </li>

        <li class="text-caption py-1">
          <a :href="`${$window.one2fan.origin}/contract`">
            {{ $t('nav.sidebar.cnf_mutual_contract') }}</a>
        </li>

        <li class="text-caption py-1">
          <a :href="`${$window.one2fan.origin}/ip-infringement-policy`">
            {{ $t('nav.sidebar.ip_infringement_policy') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>

import { mapActions, mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import Avatar from '@/components/Functional/Avatar'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import helpers from '@/helpers'

export default {
  name: 'SidebarContent',
  components: {
    Avatar
  },
  props: {
    closeSidebar: {
      type: Function,
      default: () => {
      }
    }
  },
  emits: ['open-lang-switcher'],
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isConnected'
    ]),
    ...mapState(useUserStore, [
      'user',
      'premiumSupportAgentUserId'
    ]),
    ...mapState(useSubscriptionsStore, [
      'subscriptions'
    ]),
    ...mapState(useAppStore, [
      'supportedLocales'
    ]),
    ...mapWritableState(useAuthStore, [
      'loginIsVisible'
    ]),
    currentLanguage () {
      const currentLocale = this.supportedLocales.find(item => item.code === this.$i18n.locale)

      if (currentLocale) {
        return currentLocale
      }
      else {
        return {
          code: 'en',
          name: 'English',
          flag: '🇬🇧'
        }
      }
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.subscriptionsGet()
    }
  },
  methods: {
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet'
    ]),
    showLogIn () {
      helpers.showLogIn()
    },
    async showLangSwitcher () {
      await this.$emit('open-lang-switcher')

      await this.$nextTick()

      this.closeSidebar()
    }
  }
}
</script>

<style>
  .logo:focus {
    outline: none;
    box-shadow: none;
  }
</style>
