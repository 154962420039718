<template>
  <Modal
    ref="modalQuiz"
    name="QuizModal"
    position="bottom"
    overflow-class="overflow-y-auto"
    rounded-class="rounded-t-2.5xl"
    content-class="bg-white pb-5 pt-6"
    :has-indents="false"
    :allow-close-by-overlay="true"
    :show-desktop-close-cross="false"
    @close="modalClosed"
    @opened="modalOpened">
    <div class="overflow-hidden bg-white">
      <div class="flex flex-end justify-between pl-6 mb-2">
        <div class="max-w-[65%]">
          <h3 class="h1">
            {{ items[activeIndex].question.text }}
          </h3>

          <div class="flex items-center mt-8">
            <div class="mr-2 flex flex-col items-end">
              <h4 class="text-[17px] font-semibold leading-5">
                {{ creator.name }}
              </h4>

              <p class="text-[#86878B] text-[15px] leading-5">
                @{{ creator.username }}
              </p>
            </div>

            <div class="relative">
              <Avatar
                class="w-[50px] h-[50px]"
                :show-status="false"
                :user-item="creator"/>

              <InlineSvg
                class="inline-block text-white absolute -top-9 -right-5"
                :src="require('@/assets/svg/design/quiz-decoration.svg')"/>
            </div>
          </div>
        </div>

        <img
          class="h-[160px] inline-block mt-10 -mr-10"
          :src="require(`@/assets/images/quiz/${items[activeIndex].emoji}.png`)"
          alt="">
      </div>

      <swiper
        class="mt-4"
        :slides-per-view="1"
        :keyboard="false"
        :allow-touch-move="false"
        :allow-slide-prev="false"
        :space-between="20"
        :auto-height="false"
        @swiper="onSwiper"
        @reach-end="reachEnd"
        @active-index-change="activeIndexChange"
        @slide-change="onSlideChange">
        <swiper-slide
          v-for="(item, index) in items"
          :key="index"
          class="space-y-2 px-6 flex flex-col justify-end">
          <template v-if="item">
            <div
              v-for="(answer, i) in item.answers"
              :key="i"
              :class="[answers[index]?.answer.number === answer.number ? 'bg-[#f23459] border-[#f23459]' : 'border-[#e7e7eb]']"
              class="flex items-center border px-4 py-5 rounded-[4px] cursor-pointer"
              @click="selectAnswer(index, answer, item.question)">
              <div
                :class="[answers[index]?.answer.number === answer.number ? 'bg-white border-white checkbox-checked' : 'border-[#c3c4cc]']"
                class="border-2 rounded-full p-2 mr-3 relative"/>

              <p
                :class="[answers[index]?.answer.number === answer.number ? 'text-white font-semibold' : 'text-black']">
                {{ answer.text }}
              </p>
            </div>
          </template>
        </swiper-slide>
      </swiper>

      <!-- Pagination dots -->
      <div class="px-4 pt-5 flex items-center justify-center">
        <div class="flex items-center justify-center space-x-2">
          <div
            v-for="(item, index) in items"
            :key="index"
            :class="{ 'active': index === activeIndex }"
            class="quiz-pagination"/>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useQuizStore } from '@/stores/quiz'
import { useUserStore } from '@/stores/user'
import { Swiper, SwiperSlide } from 'swiper/vue'
import quizData from '@/mixins/quizData'
import uniqid from 'uniqid'
import Avatar from '@/components/Functional/Avatar'


let swiper = null

export default {
  name: 'QuizSurvey',
  components: {
    Swiper,
    SwiperSlide,
    Avatar
  },
  mixins: [quizData],
  data () {
    return {
      activeIndex: 0,
      allowSelection: true,
      chatId: null,
      creator: {}
    }
  },
  computed: {
    ...mapWritableState(useQuizStore, [
      'surveyOpen',
      'surveyWasOpened',
      'answers',
      'surveyCause',
      'quizReset'
    ]),
    ...mapState(useQuizStore, [
      'survey'
    ]),
    ...mapState(useUserStore, [
      'user',
      'landingPath'
    ]),
    items () {
      return [
        {
          emoji: 'eyes',
          question: {
            number: 1,
            text: this.$t('quiz.question_1')
          },
          answers: [
            {
              number: 101,
              text: this.$t('quiz.answer_101')
            },
            {
              number: 102,
              text: this.$t('quiz.answer_102')
            },
            {
              number: 103,
              text: this.$t('quiz.answer_103')
            },
            {
              number: 104,
              text: this.$t('quiz.answer_104')
            }
          ]
        },
        this.answers[0] ? this.quizData.get(this.answers[0].answer.number) : null,
        this.answers[1] ? this.quizData.get(this.answers[1].answer.number) : null
      ]
    }
  },
  watch: {
    // Watch global state surveyOpen to open modal
    surveyOpen (value) {
      if (value) {
        this.openQuizModal()
      }
    }
  },
  async mounted () {
    this.items.forEach(item => {
      this.preloadImage(item.emoji)
    })
  },
  methods: {
    ...mapActions(useQuizStore, [
      'surveyQuestionAnswered',
      'surveyShown'
    ]),
    onSlideChange () {
    },
    activeIndexChange (swiper) {
      this.activeIndex = swiper.realIndex
    },
    reachEnd () {
    },
    onSwiper (swpr) {
      swiper = swpr
    },
    selectAnswer (index, answer, question) {
      const vm = this

      if (this.allowSelection) {
        this.allowSelection = false

        this.answers[index] = {
          question,
          answer
        }

        this.surveyQuestionAnswered(
          {
            questionNumber: ++index,
            answerNumber: answer.number
          })

        if (swiper.isEnd) {
          setTimeout(async () => {
            // Send message
            const name = vm.user.name
            const latestAnswerNumber = vm.answers.at(-1).answer.number
            const messageToSend = vm.messageAfterQuiz.get(latestAnswerNumber)(name)
            const chatId = vm.chatId

            if (chatId) {
              await vm.sendMessage(messageToSend, chatId, latestAnswerNumber)
              this.pushToChat(chatId)
            }

            this.$refs.modalQuiz.closeModal()

            this.surveyCause = ''
          }, 250)
        }
        else {
          setTimeout(() => {
            swiper.slideNext()

            vm.allowSelection = true
          }, 250)
        }
      }
    },
    async modalOpened () {
      this.getUserInfo()
      //
      // TRACK
      //
      this.surveyShown()

      // State for debug
      this.quizReset = false

      // Modal with quiz was open therefore - quiz shown
      this.surveyWasOpened = true
    },
    modalClosed () {
      this.surveyOpen = false
      this.surveyCause = ''
    },
    openQuizModal () {
      this.$refs.modalQuiz.openModal()
      this.allowSelection = true
      this.surveyOpen = true
      this.activeIndex = 0
      swiper.slideTo(0)
    },
    preloadImage (emoji) {
      const preloadingImg = new Image()

      preloadingImg.src = require(`@/assets/images/quiz/${emoji}.png`)
    },
    pushToChat (chatId) {
      setTimeout(() => {
        this.$router.push(`/chat/${chatId}`)
      }, 300)
    },
    async getUserInfo () {
      const data = {}

      if (this.survey?.lastPaidSubscriptionCreationPublisherUserId) {
        data.id = this.survey.lastPaidSubscriptionCreationPublisherUserId
      }
      else if (this.user.landingPath.includes('@')) {
        let landingPath = this.user.landingPath

        if (landingPath.includes('/')) {
          landingPath = landingPath.replace(/\/.*/, '')
        }

        data.username = landingPath.replace('@', '')
      }

      if (data?.id || data?.username) {
        const res = await this.wsp.sendRequest({
          data: data,
          method: 'user.get'
        })

        if (res?.data?.user) {
          this.creator = res.data.user
          this.chatId = res.data.user.userId
        }
      }
    },
    async sendMessage (message, chatId, surveyResultId) {
      try {
        await this.wsp.sendRequest({
          data: {
            text: message || '',
            receiverId: chatId,
            clientId: uniqid(),
            ttlAfterRead: null,
            highlighted: true,
            surveyResultId: surveyResultId ? surveyResultId.toString() : null
          },
          method: 'message.send'
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style>
  .quiz-pagination {
    height: 9px;
    width: 9px;
    background-color: #cccccc;
    border-radius: 50%;
    display: inline-block;
  }

  .quiz-pagination.active {
    height: 11px;
    width: 11px;
    background-color: #f23459;
  }

  .checkbox-checked::after {
    background: url(@/assets/svg/design/checkbox-check.svg);
    background-position: center;
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>

