import { defineStore } from 'pinia'

export const useInvitesStore = defineStore({
  id: 'invites',
  state: () => ({
    // Show fast invites only first time
    initialTime: {},
    initialTimeAuth: {}
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'initialTime',
          'initialTimeAuth'
        ]
      }
    ]
  }
})
