<template>
  <div class="observer"/>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['intersect'],
  data: () => ({
    observer: null
  }),
  mounted () {
    const options = this.options || {}

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, options)

    this.observer.observe(this.$el)
  },
  beforeUnmount () {
    this.observer.disconnect()
  }
}
</script>

<style>
  .observer {
    padding: 1px;
  }
</style>
