<script setup>

</script>

<template>
  <div class="mt-32 flex flex-col items-center justify-center">
    <img 
      class="w-22 h-22 mb-5"
      src="@/assets/images/emoji/hugging-face.png"
      alt="Hugging face">

    <div class="text-center">
      <h3 class="mb-2 text-lg">
        {{ $t('events.nothing_to_see_here') }}
      </h3>

      <p class="max-w-xs px-4 text-sm whitespace-pre-line">
        {{ $t('events.come_back_later') }}
      </p>
    </div>
  </div>
</template>