<template>
  <div class="flex flex-col justify-center items-center">
    <!-- https://gist.github.com/barneycarroll/5244258 -->
    <label
      :style="{ background: `url(${profilePhoto})`}"
      class="file-container tint tinted cursor-pointer mt-4">
      <svg
        width="27"
        class="file-container-icon text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512">
        <path d="M324.3 64c3.3 0 6.3 2.1 7.5 5.2l22.1 58.8H464c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16h110.2l20.1-53.6c2.3-6.2 8.3-10.4 15-10.4h131m0-32h-131c-20 0-37.9 12.4-44.9 31.1L136 96H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V144c0-26.5-21.5-48-48-48h-88l-14.3-38c-5.8-15.7-20.7-26-37.4-26zM256 408c-66.2 0-120-53.8-120-120s53.8-120 120-120 120 53.8 120 120-53.8 120-120 120zm0-208c-48.5 0-88 39.5-88 88s39.5 88 88 88 88-39.5 88-88-39.5-88-88-88z"/>
      </svg>

      <input
        id="main_photo_input"
        accept="image/*"
        type="file"
        @change="onFileSelected">
    </label>

    <label
      for="main_photo_input"
      class="text-sm mt-4 cursor-pointer">
      {{ $t("user.me_edit.change_photo") }}
    </label>
  </div>
</template>

<script>

import { mapState, mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
  name: 'MainPhotoUploader',
  components: {},
  props: {},
  data () {
    return {
      selectedImage: null
    }
  },
  computed: {
    ...mapState(useUserStore, ['user']),
    profilePhoto () {
      const photos = this.user.photos.items
      return photos.length ? photos[0].links.c640x640 : `/svg/${this.user.sex === 1 ? 'user' : 'woman'}-placeholder.svg`
    }
  },
  mounted () {},
  methods: {
    ...mapActions(useUserStore, ['updateMainPhoto']),
    onFileSelected (event) {
      this.selectedImage = event.target.files[0]

      if (this.selectedImage) {
        this.updateMainPhoto(this.selectedImage)
      }
    }
  }
}
</script>

<style scoped>
  .file-container {
    height: 85px;
    width: 85px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover !important;
    background-position: bottom center !important;
    background-repeat: no-repeat !important;
  }
  .file-container:focus-within {
     outline: 0;
     box-shadow: 0 0 0 0.2rem rgba(147, 197, 253, 0.25);
   }
  .file-container-icon {
    position: relative;
    z-index: 1;
  }
  .file-container-text {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.07px;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
  }
  .file-container [type=file] {
    cursor: inherit;
    display: block;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }
</style>
