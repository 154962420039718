<template>
  <li
    :class="{ 'tint tinted': item.senderId === userId && item.status === 0 }"
    class="aspect-square relative cursor-pointer bg-gray-700">
    <!--<pre class="text-white">{{ item.attachment.paid }}</pre>-->

    <img
      :src="item.attachment?.fields?.links.c320x320"
      class="block w-full h-full"
      alt=""
      loading="lazy"
      @error="imageLoadingState = 'error'"
      @load="imageLoadingState = 'loaded'">

    <div
      v-show="imageLoadingState === 'loading'"
      class="placeholder placeholder-item absolute inset-0 w-full h-full"/>

    <!-- Top right -->
    <InlineSvg
      v-if="isCurrentItemVideo"
      class="absolute top-2.5 right-2.5 z-20 inline-block w-4 h-4 post-shadow text-white"
      :src="require('@/assets/svg/design/play-video.svg')"/>

    <InlineSvg
      v-else-if="isCurrentItemPhoto"
      class="absolute top-2.5 right-2.5 z-20 inline-block w-4 h-4 post-shadow text-white"
      :src="require('@/assets/svg/design/photo.svg')"/>

    <!-- Center -->
    <div
      v-if="item.attachment.paid === 0"
      class="absolute inset-0 w-full h-full z-20 flex flex-col items-center justify-center">
      <InlineSvg
        class="inline-block h-10 post-shadow text-white"
        :src="require('@/assets/svg/design/lock.svg')"/>

      <span class="text-sm text-white post-shadow mt-1">
        <template v-if="item.senderId === userId">
          <template v-if="item.attachment.mediaType === 'image'">
            {{ $t('chat.message.send_photo') }}
          </template>

          <template v-else>
            {{ $t('chat.message.send_video') }}
          </template>
        </template>

        <template v-else>
          {{ item.attachment.price }} {{ $t('common.credits') }}
        </template>
      </span>
    </div>
  </li>
</template>

<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
  name: 'ChatAttachmentsItem',
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
      },
      required: true
    }
  },
  data () {
    return {
      imageLoadingState: 'loading'
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'userId'
    ]),
    isCurrentItemVideo () {
      return !!this.item.attachment?.fields?.videoUrl || this.item.attachment?.mediaType === 'video'
    },
    isCurrentItemPhoto () {
      return !this.item.attachment?.fields?.videoUrl && this.item.type === 3
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .tint.tinted:before {
    @apply rounded-none;
    background: rgba(0, 0, 0, 0.65);
  }
</style>
