<template>
  <SignUpForm @close="emitClose"/>
</template>

<script>
import SignUpForm from '@/components/SignUpForm'

export default {
  name: 'SignupContent',
  components: {
    SignUpForm
  },
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {
    async emitClose () {
      await this.$emit('close')
    }
  }
}
</script>

