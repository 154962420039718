<template>
  <div class="chat">
    <div class="chat w-full overflow-hidden flex">
      <div class="chat-header">
        <div class="placeholder chat-header-content">
          <LinkBack
            path="/messages"
            class="pr-3 mr-1 text-gray-900"/>

          <div class="flex flex-1">
            <div class="flex">
              <div class="placeholder-item h-8 w-8 mr-2 rounded-full"/>

              <div class="flex flex-col flex-1">
                <div class="placeholder-item rounded-xl text-sm leading-4 w-32 h-4"/>
                <div class="h-1"/>
                <div class="placeholder-item rounded-xl text-caption text-gray-600 leading-4 w-24 h-3"/>
              </div>
            </div>
          </div>

          <svg
            class="h-5 w-5"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.17993 10.5C6.17993 11.0304 5.96922 11.5391 5.59415 11.9142C5.21907 12.2893 4.71036 12.5 4.17993 12.5C3.6495 12.5 3.14079 12.2893 2.76572 11.9142C2.39065 11.5391 2.17993 11.0304 2.17993 10.5C2.17993 9.96957 2.39065 9.46086 2.76572 9.08579C3.14079 8.71071 3.6495 8.5 4.17993 8.5C4.71036 8.5 5.21907 8.71071 5.59415 9.08579C5.96922 9.46086 6.17993 9.96957 6.17993 10.5ZM12.1799 10.5C12.1799 11.0304 11.9692 11.5391 11.5941 11.9142C11.2191 12.2893 10.7104 12.5 10.1799 12.5C9.6495 12.5 9.14079 12.2893 8.76572 11.9142C8.39065 11.5391 8.17993 11.0304 8.17993 10.5C8.17993 9.96957 8.39065 9.46086 8.76572 9.08579C9.14079 8.71071 9.6495 8.5 10.1799 8.5C10.7104 8.5 11.2191 8.71071 11.5941 9.08579C11.9692 9.46086 12.1799 9.96957 12.1799 10.5ZM18.1799 10.5C18.1799 11.0304 17.9692 11.5391 17.5941 11.9142C17.2191 12.2893 16.7104 12.5 16.1799 12.5C15.6495 12.5 15.1408 12.2893 14.7657 11.9142C14.3906 11.5391 14.1799 11.0304 14.1799 10.5C14.1799 9.96957 14.3906 9.46086 14.7657 9.08579C15.1408 8.71071 15.6495 8.5 16.1799 8.5C16.7104 8.5 17.2191 8.71071 17.5941 9.08579C17.9692 9.46086 18.1799 9.96957 18.1799 10.5Z"/>
          </svg>
        </div>
      </div>

      <div class="chat-content">
        <div class="chat-messages">
          <div class="placeholder justify-center text-caption text-gray-400 text-center">
            <div class="placeholder-item rounded-xl text-sm leading-4 w-32 h-4">&#160;</div>
          </div>

          <div class="my-2 w-full max-w-full rounded-2xl">
            <div class="placeholder flex flex-wrap items-center w-100 justify-end">
              <div
                class="message-item placeholder-item"
                style="width: 119px; height: 38px">
                <div class="flex justify-end items-end">
                    &#160;
                </div>
              </div>
            </div>
          </div>

          <div class="my-2 w-full max-w-full rounded-2xl">
            <div class="placeholder flex flex-wrap items-center w-100 justify-end">
              <div
                class="message-item placeholder-item"
                style="width: 164px; height: 38px">
                <div class="flex justify-end items-end">
                    &#160;
                </div>
              </div>
            </div>
          </div>

          <div class="my-2 w-full max-w-full rounded-2xl">
            <div class="placeholder flex flex-wrap items-center w-100">
              <div
                class="message-item placeholder-item"
                style="width: 298px; height: 38px">
                <div class="flex justify-end items-end">
                    &#160;
                </div>
              </div>
            </div>
          </div>

          <div class="my-2 w-full max-w-full rounded-2xl">
            <div class="placeholder flex flex-wrap items-center w-100">
              <div
                class="message-item placeholder-item"
                style="width: 331px; height: 60px">
                <div class="flex justify-end items-end">
                    &#160;
                </div>
              </div>
            </div>
          </div>

          <div class="my-2 w-full max-w-full rounded-2xl">
            <div class="placeholder flex flex-wrap items-center w-100 justify-end">
              <div
                class="message-item placeholder-item"
                style="width: 113px; height: 38px">
                <div class="flex justify-end items-end">
                    &#160;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="placeholder chat-form">
        <div class="h-full w-full px-4 flex items-center jus">
          <div class="placeholder-item w-7 h-7 rounded-full flex-shrink-0 mr-2"/>
          <div class="placeholder-item w-full rounded-full h-10 mr-2"/>
          <div class="placeholder-item w-7 h-7 rounded-full flex-shrink-0 mr-2"/>
          <div class="placeholder-item w-7 h-7 rounded-full flex-shrink-0"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkBack from '@/components/Functional/LinkBack'

export default {
  name: 'ChatPlaceholder',
  components: {
    LinkBack
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .chat {
    @apply md:max-w-md mx-auto flex flex-col h-full;
  }

  .chat-header {
    @apply flex-grow-0 min-h-[57px] h-[57px] border-l border-r border-gray-100
  }

  .chat-header-content {
    @apply h-full px-4 flex items-center
  }

  .chat-content {
    @apply flex-grow overflow-x-hidden overflow-y-visible border border-gray-100
  }

  .chat-messages {
    @apply px-4 h-full
  }

  .chat-form {
    @apply flex-grow-0 min-h-[61px] h-[61px] border-l border-r border-gray-100
  }

  .message-item {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative;
    max-width: 80%;
  }

  /*.file-container[type=file] {*/
  /*  display: block;*/
  /*  filter: alpha(opacity=0);*/
  /*  min-height: 100%;*/
  /*  min-width: 100%;*/
  /*  opacity: 0;*/
  /*  position: absolute;*/
  /*  right: 0;*/
  /*  text-align: right;*/
  /*  top: 0;*/
  /*}*/

  /*.form-input-post {*/
  /*  caret-color: #0A84FF;*/
  /*  @apply bg-transparent border-gray-300 focus:border-gray-300 focus:ring-opacity-0 text-white;*/
  /*}*/

  /*.chat-form {*/
  /*  @apply w-full bg-white border-t border-gray-100 py-2.5 shadow-2xl;*/
  /*  z-index: 1;*/
  /*}*/
</style>
