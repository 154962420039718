<template>
  <div class="relative">
    <img
      style="filter: drop-shadow(1px 2px 8px rgba(233, 68, 90, 0.5));"
      src="/img/icons/favicon.svg"
      alt="Logo"
      class="h-11 w-11 mb-5">

    <button
      class="btn p-1 m-0 absolute right-0 -top-1 rounded-full"
      @click="$emit('close')">
      <InlineSvg :src="require('@/assets/svg/design/times-circle.svg')"/>
    </button>

    <h2 class="mb-3">
      {{ $t('modals.pwa.great_idea') }}
    </h2>

    <p class="text-gray-500 mb-3">
      {{ $t('modals.pwa.install_the_app') }}
    </p>

    <template v-if="showPWAPrompt && deferredPromptPWAFunc && showAppealPWA">
      <button
        class="btn btn-block btn-primary"
        @click="pwaPrompt">
        <div class="flex items-center justify-center">
          <InlineSvg
            class="mr-0.5"
            :src="require('@/assets/svg/design/pwa/download.svg')"/>

          <span class="capitalize">
            {{ $t('modals.pwa.install_app') }}
          </span>
        </div>
      </button>
    </template>

    <div
      v-else-if="showAppealPWA"
      class="rounded-2xl bg-gray-100 py-3 px-4">
      <div class="flex items-center mb-1.5">
        <span class="text-sm mr-2">1. {{ $t('modals.pwa.tap_on') }}</span>

        <div class="shadow-sm bg-white rounded px-2 pt-1 pb-1">
          <InlineSvg
            v-if="isiOS"
            :src="require('@/assets/svg/design/pwa/ios-pwa-1.svg')"/>

          <InlineSvg
            v-else
            :src="require('@/assets/svg/design/pwa/android-pwa-1.svg')"/>
        </div>
      </div>

      <div class="flex items-center">
        <span class="text-sm mr-2">2. {{ $t('modals.pwa.select') }}</span>

        <div class="shadow-sm bg-white rounded px-2 pt-0.5 pb-0.5 font-bold text-sm">
          {{ $t('modals.pwa.add_to_home_screen') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/stores/app'

// A2HS - Add to Home Screen
export default {
  name: 'AppealToPWAContent',
  components: {},
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {
    ...mapWritableState(useAppStore, ['isPWAInstalled']),
    ...mapState(useAppStore, [
      'showAppealPWA',
      'showPWAPrompt',
      'isiOS',
      'isAndroid',
      'deferredPromptPWAFunc'
    ])
  },
  mounted () {
  },
  methods: {
    pwaPrompt () {
      // Show the prompt
      this.deferredPromptPWAFunc.prompt()

      // Wait for the user to respond to the prompt
      this.deferredPromptPWAFunc.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.isPWAInstalled = true
          this.$emit('close')
        }

        this.deferredPromptPWAFunc = null
      })
    }
  }
}
</script>

