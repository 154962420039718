<template>
  <div class="h-full w-full max-w-full flex flex-col placeholder">
    <div class="container mt-3 w-full">
      <div class="flex flex-col justify-center items-center mb-4">
        <div
          style="height: 85px; width: 85px;"
          class="mt-4 rounded-full placeholder-item"/>

        <div class="mt-4 rounded-full placeholder-item" style="width: 94px; height: 22px"/>
      </div>

      <div class="w-full grid gap-1 grid-cols-4 sm:grid-cols-4 md:grid-cols-5 grid-flow-row mb-8">
        <div class="h-20 placeholder-item"/>
        <div class="h-20 placeholder-item"/>
        <div class="h-20 placeholder-item"/>
        <div class="h-20 placeholder-item"/>
        <div class="h-20 placeholder-item"/>
      </div>

      <div>
        <div class="flex items-center mb-3">
          <div class="w-4/12">
            <div
              class="placeholder-item rounded-full"
              style="width: 92px; height: 20px;"/>
          </div>

          <div class="flex-1 rounded-lg placeholder-item" style="height: 46px"/>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-4/12">
            <div
              class="placeholder-item rounded-full"
              style="width: 92px; height: 20px;"/>
          </div>

          <div class="flex-1 rounded-lg placeholder-item" style="height: 46px"/>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-4/12">
            <div
              class="placeholder-item rounded-full"
              style="width: 92px; height: 20px;"/>
          </div>

          <div class="flex-1 rounded-lg placeholder-item" style="height: 46px"/>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-4/12">
            <div
              class="placeholder-item rounded-full"
              style="width: 92px; height: 20px;"/>
          </div>

          <div class="flex-1 rounded-lg placeholder-item" style="height: 46px"/>
        </div>
        <div class="flex items-center mb-3">
          <div class="w-4/12">
            <div
              class="placeholder-item rounded-full"
              style="width: 92px; height: 20px;"/>
          </div>

          <div class="flex-1 rounded-lg placeholder-item" style="height: 46px"/>
        </div>
        <div class="flex items-center mb-3">
          <div class="flex items-center justify-between w-full">
            <div
              class="placeholder-item rounded-full"
              style="width: 134px; height: 20px;"/>

            <div
              class="placeholder-item rounded-full"
              style="width: 44px; height: 24px;"/>
          </div>
        </div>
      </div>

      <div
        class="mt-8 mb-4 placeholder-item rounded-full"
        style="width: 120px; height: 26px"/>

      <div class="flex flex-wrap">
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Biking
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Camping
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Cars
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Cooking
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Dancing
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Diving
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Fashion
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Fishing &amp; Hunting
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Games
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Hobbies &amp; Crafts
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Hockey
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Lying on the beach
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Meditation &amp; Yoga
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Movies
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Museums &amp; Art
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Music &amp; Concerts
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Nature
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Party &amp; Night Clubs
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Reading books
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Sailing
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Shopping
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Sports
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Travelling
        </button>
        <button
          type="button"
          style="height: 30px; width: auto;"
          class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 text-sm leading-4 font-medium rounded-full text-transparent placeholder-item">
          Watching TV
        </button>
      </div>

      <div class="flex items-center justify-center">
        <div
          style="width: 143px; height: 20px"
          class="mt-8 mb-4 rounded-full placeholder-item"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MeEditPlaceholder',
  components: {},
  data () {
    return {
      greeting: 'Hello'
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

