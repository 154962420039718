<script setup>
  import EventItem from '@/components/Events/Includes/EventItem.vue'
  import { storeToRefs } from 'pinia'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useEventsStore } from '@/components/Events/Store/events.js'
  import { onMounted, ref, computed } from 'vue'
  import EventsStub from '@/components/Events/Includes/EventsStub.vue'
  import ObserverHelper from '@/components/Functional/ObserverHelper.vue'

  const dialogsStore = useDialogsStore()
  const { invites } = storeToRefs(dialogsStore)

  const eventsStore = useEventsStore()

  const {
    loadingStatus,
    limit,
    thereAreEvents
  } = storeToRefs(eventsStore)

  const errorMessage = ref(null)
  const initialLoadingDelay = ref(true)

  const isInitialLoading = computed(() => {
    return initialLoadingDelay.value || loadingStatus.value === 'loading'
  })

  const showStub = computed(() => {
    return !isInitialLoading.value && loadingStatus.value === 'loaded-all' && !invites.value.length
  })

  async function intersected (type) {
    if (loadingStatus.value.includes('loading') || loadingStatus.value === 'loaded-all') {
      return
    }

    let afterRevision = null

    try {
      if (type === 'pagination') {
        afterRevision = invites.value[invites.value.length - 1].revision

        loadingStatus.value = 'loading-more'
      } else {
        loadingStatus.value = 'loading'
      }

      const { data, error } = await eventsStore.dialogGetList(afterRevision)

      if (error) {
        errorMessage.value = error?.message ? error.message : 'Unknown error'
        loadingStatus.value = 'loaded-all'
        return
      }

      if (type === 'initial-load') {
        invites.value = data.items
      } else if (type === 'pagination') {
        invites.value.push(...data.items)
      }

      if (data.items.length < limit.value) {
        loadingStatus.value = 'loaded-all'
      } else {
        loadingStatus.value = 'loaded'
      }
    } catch (error) {
      errorMessage.value = error?.message || 'Unknown error'
    } finally {
      thereAreEvents.value = !!invites.value?.length
    }
  }

  onMounted(async () => {
    loadingStatus.value = ''

    setTimeout(() => {
      initialLoadingDelay.value = false
    }, 500)

    intersected('initial-load')
  })
</script>

<template>
  <section>
    <h1 class="mb-6">
      {{ $t('events.title') }}
    </h1>

    <div
      v-if="isInitialLoading"
      class="space-y-3.5">
      <div
        v-for="index in 9"
        :key="index"
        class="skeleton-loading h-[56px] rounded-2xl"/>
    </div>

    <EventsStub v-if="showStub"/>

    <div
      v-show="!isInitialLoading"
      class="space-y-4">
      <EventItem
        v-for="(item, index) in invites"
        :key="index + item.updated"
        :item="item"/>
    </div>

    <ObserverHelper
      v-if="loadingStatus && loadingStatus !== 'loaded-all' && !loadingStatus.includes('loading')"
      @intersect="intersected('pagination')"/>
  </section>
</template>