<template>
  <div class="placeholder flex flex-col">
    <div
      class="placeholder-item mb-4 rounded-full"
      style="width: 135px; height: 38px"/>

    <div class="grid grid-flow-col auto-cols-max gap-1 mb-6">
      <div
        style="width: 98px; height: 32px"
        class="placeholder-item px-3 py-1 rounded-full"/>
      <div
        style="width: 68px; height: 32px"
        class="placeholder-item px-3 py-1 rounded-full"/>
      <div
        style="width: 81px; height: 32px"
        class="placeholder-item px-3 py-1 rounded-full"/>
    </div>

    <div class="mt-5">
      <div class="flex items-center justify-between">
        <div
          class="placeholder-item rounded-full"
          style="width: 95px; height: 18px"/>

        <div
          class="placeholder-item rounded py-1 px-1.5 mr-4"
          style="width: 54px; height: 26px"/>
      </div>

      <div class="grid grid-flow-col auto-cols-max gap-0.5 overflow-hidden mt-2 scrollbar-none">
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
      </div>
    </div>


    <div class="mt-5">
      <div class="flex items-center justify-between">
        <div
          class="placeholder-item rounded-full"
          style="width: 95px; height: 18px"/>

        <div
          class="placeholder-item rounded py-1 px-1.5 mr-4"
          style="width: 54px; height: 26px"/>
      </div>

      <div class="grid grid-flow-col auto-cols-max gap-0.5 overflow-hidden mt-2 scrollbar-none">
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
      </div>
    </div>


    <div class="mt-5">
      <div class="flex items-center justify-between">
        <div
          class="placeholder-item rounded-full"
          style="width: 95px; height: 18px"/>

        <div
          class="placeholder-item rounded py-1 px-1.5 mr-4"
          style="width: 54px; height: 26px"/>
      </div>

      <div class="grid grid-flow-col auto-cols-max gap-0.5 overflow-hidden mt-2 scrollbar-none">
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
      </div>
    </div>


    <div class="mt-5">
      <div class="flex items-center justify-between">
        <div
          class="placeholder-item rounded-full"
          style="width: 95px; height: 18px"/>

        <div
          class="placeholder-item rounded py-1 px-1.5 mr-4"
          style="width: 54px; height: 26px"/>
      </div>

      <div class="grid grid-flow-col auto-cols-max gap-0.5 overflow-hidden mt-2 scrollbar-none">
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
        <div class="placeholder-item w-28 h-36"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DiscoverPlaceholder',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

