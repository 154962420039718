<template>
  <div class="flex flex-col relative h-full">
    <div class="h-full w-full flex flex-col justify-end absolute inset-0 z-30 placeholder">
      <div class="flex justify-between items-end px-4 pb-1.5">
        <div class="mb-1 w-4/6">
          <div class="flex items-center mb-2">
            <div
              style="width: 81px; height: 20px"
              class="mr-1.5 placeholder-item rounded-full mb-0.5"/>

            <div class="placeholder-item h-3 w-3 rounded-full"/>
          </div>

          <div class="flex flex-col">
            <div
              style="width: 277px; height: 16px"
              class="placeholder-item rounded-full mb-1.5"/>

            <div
              style="width: 277px; height: 16px"
              class="placeholder-item rounded-full mb-1.5"/>

            <div
              style="width: 200px; height: 16px"
              class="placeholder-item rounded-full"/>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center mb-3">
          <div class="placeholder-item rounded-full w-14 h-14 mb-4"/>

          <div class="mb-4 flex flex-col items-center">
            <div
              class="inline-block placeholder-item rounded-full mb-1.5"
              style="width: 30px; height: 30px;"/>

            <div
              style="width: 40px; height: 22px"
              class="placeholder-item rounded-full"/>
          </div>

          <div class="mb-4 flex flex-col items-center">
            <div
              class="inline-block placeholder-item rounded-full mb-1.5"
              style="width: 30px; height: 30px;"/>

            <div
              style="width: 40px; height: 22px"
              class="placeholder-item rounded-full"/>
          </div>
        </div>
      </div>

      <div class="px-4 mb-9 mr-2 relative z-30 placeholder">
        <div class="flex items-center flex-wrap">
          <div
            style="width: 180px; height: 28px"
            class="placeholder-item rounded mr-2"/>

          <a
            style="width: 109px; height: 28px"
            class="placeholder-item rounded"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PostPlaceholder',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

