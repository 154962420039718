<template>
  <div class="relative">
    <label
      :class="[loading ? 'invisible' : 'visible']"
      class="relative file-container cursor-pointer"
      @click="showSignUp">
      <InlineSvg
        :src="require('@/assets/svg/design/clip.svg')"
        :class="{
          'hover:text-[#05AED3]': !chatUser.deleted,
          'opacity-30': chatUser.deleted
        }"
        class="text-[#B7BDBF]"/>

      <!-- accept="image/*,video/*" -->
      <input
        v-if="isAuthenticated"
        :disabled="chatUser.deleted"
        accept="image/*"
        type="file"
        @change="uploadAttachment">
    </label>

    <Spinner
      :class="[loading ? 'visible' : 'invisible']"
      class="absolute inset-0"/>
  </div>
</template>

<script>

import Spinner from '@/components/Functional/Spinner'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import helpers from '@/helpers'

export default {
  name: 'ChatPhotoUpload',
  components: {
    Spinner
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated'
    ]),
    ...mapState(useChatStore, [
      'attachmentSupport'
    ]),
    ...mapWritableState(useChatStore, [
      'attachmentSizeUploadError',
      'chatUser'
    ]),
    ...mapState(useUserStore, [
      'balance',
      'rates'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(useChatStore, [
      'sendPhoto',
      'sendVideo'
    ]),
    async uploadAttachment (event) {
      if (event.target.files && event.target.files[0] && !this.loading) {
        this.loading = true
        const attachment = event.target.files[0]

        const megabytes = (attachment.size / 1048576).toFixed()

        if (megabytes > 1024) {
          this.attachmentSizeUploadError = 'Maximum file size is 1GB'
          this.loading = false
          return
        } else {
          this.attachmentSizeUploadError = null
        }

        try {
          if (attachment.type.includes('image')) {
            await this.sendPhoto(attachment)
              .finally(() => {
                this.loading = false
              })
          } else {
            await this.sendVideo(attachment)
              .finally(() => {
                this.loading = false
              })
          }
        } catch (error) {
          this.attachmentSizeUploadError = error?.message ? error.message : 'Upload error'
        }
      }
    },
    showSignUp () {
      if (!this.isAuthenticated) {
        helpers.showSignUp()
      }
    }
  }
}
</script>

<style>
  .file-container [type=file] {
    display: block;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
  }
</style>
