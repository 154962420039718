import { defineStore } from 'pinia'
import app from '@/main'
import { remove } from 'lodash'
import { notify } from '@kyvg/vue3-notification'
import uniqid from 'uniqid'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: {
      balance: null,
      userId: null,
      age: null,
      name: '',
      about: '',
      sex: null,
      location: {
        name: ''
      },
      photos: {
        items: []
      },
      landingPath: ''
    },
    options: {
      rates: {},
      topPayersEnabled: false,
      purchaseConfig: {
        currency: 'USD',
        products: {
          credits: []
        }
      },
      trialCreditsForBankCard: {},
      messageTtlAfterReadAvailable: false,
      tipsEnabled: false,
      prelandingId: null,
      profileCompletenessEnabled: null
    },
    features: {
      chatPrice: {
        enabled: false
      }
    },
    urgentDialogCounts: {
      contacts: null,
      favorites: null,
      invites: null
    },
    unreadMessagesCounter: null,
    premiumSupportCount: null,
    premiumSupportAgentUserId: 0,
    profileCompletionPercentage: null
  }),
  getters: {
    userId: (state) => state.user.userId,
    balance: (state) => state.user.balance,
    photos: (state) => state.user.photos.items,
    mainPhoto: (state) => state.user.photos.items,
    // Photos without first main photo
    filteredPhotos: (state) => state.photos.filter((item, i) => i !== 0),
    rates: (state) => state.options.rates,
    messageTtlAfterReadAvailable: (state) => state.options.messageTtlAfterReadAvailable,
    chatPriceEnabled: (state) => state.features?.chatPrice.enabled
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'user',
          'options',
          'features',
          'urgentDialogCounts',
          'unreadMessagesCounter',
          'userId',
          'balance',
          'photos',
          'mainPhoto',
          'filteredPhotos',
          'rates',
          'cauri',
          'cauriPublicKey',
          'autoPaymentCreditThreshold',
          'premiumSupportAgentUserId'
        ]
      }
    ]
  },
  actions: {
    //
    // Socket Events
    //
    sessionStart (response) {
      if (response.error === undefined) {
        this.features = response.data.features


        // console.log(response)

        this.options = response.data.options
        this.user = response.data.user
        this.urgentDialogCounts = response.data.user.urgentDialogCounts
        this.unreadMessagesCounter = response.data.user.unreadMessagesCounter.count
        this.premiumSupportCount = response.data.user.unreadMessagesCounter.premiumSupportCount

        if (response.data.user.premiumSupportAgentUserId) {
          this.premiumSupportAgentUserId = response.data.user.premiumSupportAgentUserId
        }
      }
    },
    messagesUnread (data) {
      if (data.data !== undefined) {
        this.unreadMessagesCounter = data.data.count
        this.premiumSupportCount = data.data.premiumSupportCount
      }
    },
    urgentCountsChanged (data) {
      if (data.data.counts !== undefined) {
        this.urgentDialogCounts = data.data.counts
      }
    },
    // Get information about user
    async meGet () {
      const res = await app.wsp.sendRequest({ data: {}, method: 'me.get' })

      if (res.error) {
        console.log(res.error.message)
      }

      this.user = res.data

      this.urgentDialogCounts = res.data.urgentDialogCounts
      this.unreadMessagesCounter = res.data.unreadMessagesCounter.count
      this.premiumSupportCount = res.data.unreadMessagesCounter.premiumSupportCount

      if (res.data.premiumSupportAgentUserId) {
        this.premiumSupportAgentUserId = res.data.premiumSupportAgentUserId
      }

      return res.data
    },
    // Update information about user
    async meSave (data) {
      const res = await app.wsp.sendRequest({
        data: data,
        method: 'me.save'
      })

      if (res.error) {
        throw res.error.message
      }

      this.user = res.data

      notify({
        id: uniqid(),
        group: 'success',
        title: app.$i18n.t('common.success'),
        text: app.$i18n.t('notifications.profile_updated')
      })
    },
    async meDelete () {
      await app.wsp.sendRequest({ method: 'me.delete' })

      this.$reset()

      localStorage.clear()
      window.location.reload()
    },
    // Get url for further file upload
    async uploadGetUrl () {
      const res = await app.wsp.sendRequest({
        data: {},
        method: 'upload.getUrl'
      })

      if (res.error !== undefined) return

      return res.data.url
    },
    // Remove photo
    async photosDelete (photoId) {
      if (photoId) {
        const res = await app.wsp.sendRequest({
          data: {
            'ids[]': photoId
          },
          method: 'photos.delete'
        })

        if (res.error) return

        if (res.data.photoIds[0]) {
          remove(this.user.photos.items, function (item) {
            return item.id === res.data.photoIds[0]
          })
        }
      }
    },
    // Set new profile's photo
    async setProfilePhoto (photoId) {
      if (photoId) {
        await this.meSave({
          defaultPhoto: photoId
        })
      }
    },
    // Update main profile's image
    async updateMainPhoto (file) {
      // Get URL to upload
      const url = await this.uploadGetUrl()

      // Check current main photo existence and remove
      if (this.user.photos.items.length) {
        await this.photosDelete(this.user.photos.items[0].id)
      }

      // Upload new photo and get id
      const uploadedFile = await app.$http.post(url, file, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })

      // Set new profile's photo
      await this.setProfilePhoto(uploadedFile.data.data.photo.id)
    },
    async uploadPhoto (file) {
      // Get URL to upload
      const url = await this.uploadGetUrl()

      // Upload new photo and get id
      const uploadedFile = await app.$http.post(url, file, {
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      })

      // Add photo to photos list
      this.user.photos.items.push(uploadedFile.data.data.photo)
    },
    async userBlock (userId) {
      if (!userId) return

      const res = await app.wsp.sendRequest({
        data: {
          id: userId
        },
        method: 'user.block'
      })

      if (res.error) {
        console.log(res.error.message)
      }
    },
    async userChanged (data) {
      if (!this.options.trialCreditsForBankCard.credited && data.data.user.balance > this.user.balance) {
        this.options.trialCreditsForBankCard.credited = true
      }

      this.user = data.data.user

      if (data.data.user.premiumSupportAgentUserId) {
        this.premiumSupportAgentUserId = data.data.user.premiumSupportAgentUserId
      }
    }
  }
})
