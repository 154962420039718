<script setup>
  import { computed, ref } from 'vue'
  import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper.vue'
  import Modal from '@/components/Functional/Modal.vue'
  import chatState from '@/components/Chat/Store/chat-state.js'
  import { userPostsGet } from '@/components/Chat/Store/chat-actions.js'
  import { useChatStore } from '@/stores/chat.js'

  const props = defineProps({
    item: {
      type: Object,
      default: () => ({}),
      required: true
    },
    index: {
      type: Number,
      default: 0,
      required: true
    }
  })

  const previewImage = computed(() => {
    if (props.item.videoPreviewImageUrls) {
      return props.item.videoPreviewImageUrls.c320x320
    } else if (props.item.imageUrls) {
      return props.item.imageUrls.c320x320
    } else {
      return null
    }
  })

  const {
    posts
  } = chatState

  const modalPosts = ref(null)
  const chatStore = useChatStore()

  async function subscribed () {
    await modalPosts.value.closeModal()
    posts.value = []
    await userPostsGet()
    chatStore.chatUser.subscribed = true
  }

  function unlocked (data) {
    const index = posts.value.findIndex((item) => item.id === data?.post.id)

    if (index !== -1) {
      posts.value.splice(index, 1, data?.post)
    }
  }

  function liked (postId) {
    const post = posts.value.find((post) => post.id === postId)
    if (!post) return

    post.liked = !post.liked
    post.likeCount = post.liked ? post.likeCount + 1 : post.likeCount - 1
  }

  function shared (postId) {
    const post = posts.value.find((element) => element.id === postId)
    if (!post) return

    post.shareCount++
  }
</script>

<template>
  <button
    type="button"
    class="h-24 w-24 relative rounded bg-gray-100 text-white"
    @click="modalPosts.openModal({ initialIndex: index })">
    <img
      v-if="previewImage"
      :src="previewImage"
      class="block object-cover w-full h-full absolute inset-0 rounded"
      alt="Post preview">

    <InlineSvg
      v-if="item.type === 0"
      class="w-4 h-4 absolute top-1.5 right-1.5 z-10"
      :src="require('@/assets/fontawesome/regular/play.svg')"/>

    <InlineSvg
      v-else-if="item.type === 1"
      class="w-4 h-4 absolute top-1.5 right-1.5 z-10"
      :src="require('@/assets/fontawesome/regular/image.svg')"/>

    <span
      v-if="item.locked"
      class="absolute inset-0 z-10 m-auto flex flex-col items-center justify-center gap-1">
      <InlineSvg
        class="w-5 h-5"
        :src="require('@/assets/fontawesome/regular/lock-keyhole.svg')"/>

      <span
        v-if="item.price" 
        class="text-xs">
        {{ `${item.price} ${$tc('user.credits.credits', item.price)}` }}
      </span>

      <span
        v-else
        class="text-xs">
        {{ $t('creator.profile.for_subscribers') }}
      </span>
    </span>
  </button>

  <Modal
    ref="modalPosts"
    v-slot="{ isVisible, values, closeModal }"
    name="ThePostsSwiper"
    :is-rounded="false"
    :has-indents="false"
    :is-full-height="true"
    :show-close-cross="false"
    z-index-class="z-10"
    :show-desktop-close-cross="true"
    content-class="h-full bg-gray-700"
    position="center">
    <ThePostsSwiper
      v-if="isVisible"
      :values="values"
      :posts="posts"
      :users="[chatStore.chatUser]"
      :show-close="true"
      @close="closeModal"
      @liked="liked"
      @shared="shared"
      @avatar-clicked="closeModal"
      @unlocked="unlocked"
      @subscribed="subscribed"/>
  </Modal>
</template>