<template>
  <div
    class="flex flex-wrap items-center w-100"
    :class="{ 'justify-end': message.senderId === userId }">
    <!-- Logged user's message -->
    <template v-if="message.senderId === userId">
      <div class="flex flex-wrap items-center justify-end">
        <div class="message deleted">
          <div class="flex justify-end items-end">
            {{ text }}
          </div>
        </div>
      </div>
    </template>

    <!-- Chat user's message -->
    <template v-else>
      <div class="message deleted">
        <div class="flex justify-end items-end">
          <div class="flex items-center">
            {{ text }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
  name: 'DeletedMessage',
  components: {},
  props: {
    message: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useUserStore, [
      'userId'
    ]),
    text () {
      if (this.message.type === 3) {
        return '[attach deleted]'
      } else {
        return '[message deleted]'
      }
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .message {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative;
  }

  .message.deleted {
    @apply bg-[#d9d9da] text-white;
  }
</style>
