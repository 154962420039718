// Capture the errors that are specific to Vue instances
// https://v3.vuejs.org/api/application-config.html#errorhandler
const errorHandler = function (err) {
  if (!window.logger) {
    return
  }

  const errStr = (() => {
    switch (true) {
      case err instanceof Error:
        return JSON.stringify({
          name: err.name || 'N/A',
          message: err.message || 'N/A',
          stack: err.stack || 'N/A'
        }, null, 4)
      case typeof err === 'string':
        return err
      default:
        return 'UNSUPPORTED ERROR TYPE'
    }
  })()

  window.logger.error(`Uncaught error received from Vue app: ${errStr}`)
}

export default errorHandler
