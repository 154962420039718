<script setup>
import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'

defineProps({
  modelValue: {
    type: String,
    required: true
  }
})

const starPng = require('@/assets/images/emoji/star.png')
const highVoltage = require('@/assets/images/emoji/high-voltage.png')
const hotPng = require('@/assets/images/emoji/fire.png')

const iconByCatalog = ref(new Map([
  ['new', highVoltage],
  ['top', starPng],
  ['hot', hotPng],
  ['all', false]
]))

const authStore = useAuthStore()
const emit = defineEmits(['update:modelValue'])

const creatorsCatalogStore = useCreatorsCatalogStore()

function selectTab (tab) {
  if (!creatorsCatalogStore.isCatalogLoading) {
    emit('update:modelValue', tab)
  }
}
</script>

<template>
  <nav class="grid grid-cols-4 gap-0.5 relative z-10" aria-label="Tabs">
    <button
      v-for="item in authStore.options?.activeCreatorCategories"
      :key="item"
      type="button"
      :class="[modelValue === item ? 'border-primary font-bold' : 'border-transparent']"
      class="group inline-flex items-center justify-center py-3 px-1 border-b-2"
      @click="selectTab(item)">
      <img
        v-if="iconByCatalog.get(item)"
        :src="iconByCatalog.get(item)"
        class="w-5 h-5"
        :alt="item + ' emoji'">

      <span class="ml-1.5 text-sm capitalize cursor-default">{{ item }}</span>
    </button>
  </nav>
</template>
