<template>
  <div class="grid grid-flow-col auto-cols-max gap-3 overflow-auto scrollbar-thin">
    <InvitesItem
      v-if="fakeInvite?.name && !fakeInvite.contact"
      :user="fakeInvite"/>

    <InvitesItem
      v-for="(item, index) in invites"
      v-show="item.user.username !== fakeInvite?.username"
      :key="index"
      :user="item.user"/>
  </div>
</template>

<script>
import InvitesItem from '@/components/Invites/InvitesItem'
import { useDialogsStore } from '@/stores/dialogs'
import { mapActions, mapWritableState, mapState } from 'pinia'

export default {
  name: 'TheInvites',
  components: {
    InvitesItem
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useDialogsStore, [
      'sortedInvites',
      'invitesStatus',
      'invites',
      'options'
    ]),
    ...mapWritableState(useDialogsStore, [
      'fakeInvite'
    ]),
    isLoading () {
      return !['loaded-all', 'loaded'].includes(this.invitesStatus)
    }
  },
  mounted () {
    this.dialogGetList({ name: 'invites', type: 'initial-load' })
  },
  methods: {
    ...mapActions(useDialogsStore, [
      'dialogGetList'
    ])
  }
}
</script>

