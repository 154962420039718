const hidePlaceholder = {
  created (el) {
    const placeholder = el.placeholder

    // https://stackoverflow.com/a/49311941
    el.addEventListener('blur', (e) => {
      e.target.placeholder = placeholder
    }, true)

    el.addEventListener('focus', (e) => {
      e.target.placeholder = ''
    }, true)
  }
}

export default hidePlaceholder
