import app from '@/main'
import { defineStore } from 'pinia'
import { orderBy } from 'lodash'
import router from '@/router'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'
import { useAuthStore } from '@/stores/auth'
import { useEventsStore } from '@/components/Events/Store/events'
import uniqid from 'uniqid'
import { notify } from '@kyvg/vue3-notification'

const filterRelations = new Map([
  ['invites', 1],
  ['contacts', 2]
])

export const useDialogsStore = defineStore({
  id: 'dialogs',
  state: () => ({
    dialogsPerPage: 15,
    contacts: [],
    invites: [],
    // To show creator that was visited by user
    fakeInvite: {
      userId: null,
      contact: null
    },
    // Loading states: loaded-all, loaded, loading, loading-more
    contactsStatus: '',
    invitesStatus: '',
    // Contacts after search form
    foundContacts: [],
    premiumSupportDialog: {}
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'fakeInvite'
        ]
      }
    ]
  },
  getters: {
    sortedContacts (state) {
      return orderBy(state.contacts, ['updated', 'isOnlineOrUrgent'], ['desc', 'asc'])
    },
    sortedInvites: state => {
      return orderBy(state.invites, ['message.created'], ['asc'])
    },
    foundContactsSorted (state) {
      return orderBy(state.foundContacts, ['updated', 'isOnlineOrUrgent'], ['desc', 'asc'])
    }
  },
  actions: {
    resetState () {
      this.contacts = []
      // this.invites = []
      this.contactsStatus = ''
      this.invitesStatus = ''
      this.foundContacts = []
    },
    async dialogGetList ({ type = '', name }) {
      const afterRevision = type === 'pagination' ? this[name][this[name].length - 1].revision : null
      const authStore = useAuthStore()

      // Set loading status
      this[`${name}Status`] = this[name].length ? 'loading-more' : 'loading'

      const invitesOrder = authStore.options.eventsEnabled ? 'asc' : 'desc'
      const orderByRevision = name === 'invites'

      const response = await app.wsp.sendRequest({
        data: {
          afterRevision: afterRevision,
          limit: this.dialogsPerPage,
          filterRelation: filterRelations.get(name),
          orderByRevision: orderByRevision ? invitesOrder : 'desc'
        },
        method: 'dialog.getList'
      })

      if (response.error) return

      const items = response.data.items

      if (items.length) {
        if (type === 'initial-load') {
          this[name] = items
        }
        else {
          this[name].push(...items)
        }

        this[`${name}Status`] = 'loaded'
      }
      else {
        this[`${name}Status`] = 'loaded-all'
      }
    },
    async getSupportDialog (premiumSupportAgentUserId) {
      // Get extended object about message
      const response = await app.wsp.sendRequest({
        data: {
          receiverId: premiumSupportAgentUserId
        },
        method: 'dialog.create'
      })

      if (response.error && response.data.item === undefined) return

      this.premiumSupportDialog = response.data.item
    },
    async findContacts ({ text, name }) {
      const response = await app.wsp.sendRequest({
        data: {
          limit: 20,
          filterRelation: filterRelations.get(name),
          filterName: text
        },
        method: 'dialog.getList'
      })

      if (response.error) return

      this.foundContacts = response.data.items
    },
    //
    // WS Events
    //
    userSync (data = {}) {
      if (!data.data) return
      if (this.router.currentRoute.name !== 'Messages') return

      if (this.premiumSupportDialog.user.userId === data.data.user.userId) {
        this.premiumSupportDialog.user = data.data.user
      }
    },
    userStatus (data = {}) {
      if (!data.data) return

      if (!['offline', 'online'].includes(data.data.status)) {
        return
      }

      const status = data.data.status === 'online'

      // Update status of started chats
      const contacts = this.sortedContacts.filter((element) => Number(element.user.userId) === Number(data.data.userId))

      contacts.forEach((item) => {
        item.online = status
        item.user.online = status
      })

      // Update status of chat requests (chat requests = invites = bombs)
      const invites = this.sortedInvites.filter((element) => Number(element.user.userId) === Number(data.data.userId))

      invites.forEach((item) => {
        item.online = status
        item.user.online = status
      })

      const userStore = useUserStore()

      if (userStore.premiumSupportAgentUserId && this.premiumSupportDialog.user !== undefined) {
        if (Number(userStore.premiumSupportAgentUserId) === Number(data.data.userId)) {
          this.premiumSupportDialog.user.online = status
        }
      }
    },
    // Update outgoing messages from user. In case there are open Message and Chat tabs
    dialogStatus (data) {
      if (this.router.currentRoute.name !== 'Messages') return

      const dialog = data.data.dialog
      const index = this.contacts.findIndex((item) => item.user.userId === dialog.user.userId)

      if (index !== -1) {
        this.contacts.splice(index, 1, dialog)
      }
    },
    dialogRecommended (data) {
      const user = data.data.dialog.user

      const eventsStore = useEventsStore()
      eventsStore.thereAreEvents = true

      // Add messages to recommended invites or create/update invites list
      const index = this.invites.findIndex((item) => item.user.userId === user.userId)

      if (index !== -1) {
        this.invites.splice(index, 1, data.data.dialog)
      }
      else {
        const authStore = useAuthStore()

        if (authStore.options?.eventsEnabled) {
          const routerName = router.currentRoute.value.name

          if (routerName === 'Messages') {
            if (!['loading-more', 'loading'].includes(this.invitesStatus)) {
              this.invites.push(data.data.dialog)
            }
          }
        }
        else {
          if (!['loading-more', 'loading'].includes(this.invitesStatus)) {
            this.invites.unshift(data.data.dialog)
          }
        }
      }

      // Do not show "real" notification invite messages for pages:
      if (['Messages', 'Chat', 'Creator', 'Post'].includes(router.currentRoute.value.name)) {
        return
      }

      const creatorsCatalogStore = useCreatorsCatalogStore()

      // Skip invite if search form is open in Catalog
      if (router.currentRoute.value.name === 'Creators') {
        if (creatorsCatalogStore.searchFormOpen) {
          return
        }
      }

      const appStore = useAppStore()

      // Don't show invites (bombs) if there is secret token
      if (appStore.secretToken) {
        return
      }

      notify({
        id: uniqid(),
        group: 'invites',
        title: `${user.name}, ${user.age}`,
        text: user.welcomeMessageText,
        data: {
          user: user
        }
      })
    },
    async messageDeleted (data) {
      if (this.router.currentRoute.name !== 'Messages') return

      const messageIdToUpdate = data.data.id
      const index = this.contacts.findIndex((item) => item.message.messageId === messageIdToUpdate)

      if (index !== -1) {
        this.contacts[index].message.text = '[message deleted]'
      }
    },
    // Incoming message from creator
    async messageNew (data) {
      // Process new message only if Messages' page open
      if (router.currentRoute.value.fullPath !== '/messages') return

      // Request new dialog's message
      const sender = data.data?.sender

      // В приходящем сообщении нужно проверять его отправителя.
      // Если его отправитель - залогиненный пользователь, dialog.create вызывать не нужно.
      const userStore = useUserStore()

      if (userStore.userId === sender.userId) {
        return
      }

      // Get extended object about message
      const response = await app.wsp.sendRequest({
        data: {
          receiverId: sender.userId
        },
        method: 'dialog.create'
      })

      if (response.error) return

      const dialogItem = response.data.item
      dialogItem.isOnlineOrUrgent = true

      const isPremiumMessage = userStore.premiumSupportAgentUserId.toString() === sender.userId

      if (isPremiumMessage) {
        this.premiumSupportDialog = dialogItem
      }

      // Add messages to existed chat or create/update Invites list
      const index = this.contacts.findIndex((item) => item.user.userId === dialogItem.user.userId)

      if (!isPremiumMessage) {
        if (index !== -1) {
          this.contacts.splice(index, 1, dialogItem)
        }
        else {
          this.contacts.push(dialogItem)
        }
      }
    }
  }
})
