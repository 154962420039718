<template>
  <div>
    <CreatorsPlaceholder v-show="isFirstLoading"/>

    <div v-show="!isFirstLoading">
      <h1
        :class="[['it', 'tr', 'pt'].includes($i18n.locale) ? 'text-[19px]' : 'text-[22px]']"
        class="mb-4 leading-[24px]">
        {{ $t('creators.catalog.heading') }}
      </h1>

      <CreatorsCatalogFilter
        v-if="options?.activeCreatorCategories"
        v-model="acitiveTab"
        class="mb-2"/>

      <CreatorsCardsPlaceholder v-show="constantFilterLoading || filterLoading"/>

      <!--<pre>length: {{ catalogItems.length }}, {{ loadingStatus }}</pre>-->

      <div
        :class="[constantFilterLoading || filterLoading ? 'invisible' : 'visible']"
        class="relative grid grid-cols-2 gap-1.5">
        <router-link
          v-for="(item, index) in catalogItems"
          :key="index"
          :to="'/@' + item.username"
          class="block"
          :class="{ '-mt-[25%]': +index !== 0 && +index >= 3 && +index % 2 !== 0 }">
          <CreatorsCatalogCard
            :key="acitiveTab + index"
            :index="+index"
            :user="item"/>
        </router-link>

        <Observer @intersect="loadMore"/>
      </div>

      <!--<pre>length: {{ catalogItems.length }}, {{ loadingStatus }}</pre>-->

      <div
        v-show="loadingStatus === 'loaded-all' && catalogItems.length > 0"
        class="flex justify-center text-gray-400 text-sm py-1.5">
        {{ $t('common.loaded_all') }}
      </div>
    </div>

    <transition name="fade">
      <CreatorsNoOneIsHere
        v-show="noOneIsHere"
        class="mt-10"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import Observer from '@/components/Functional/Observer'
import CreatorsPlaceholder from '@/components/CreatorsCatalog/CreatorsPlaceholder'
import CreatorsNoOneIsHere from '@/components/CreatorsCatalog/CreatorsNoOneIsHere'
import helpers from '@/helpers'
import CreatorsCatalogCard from '@/components/CreatorsCatalog/CreatorsCatalogCard'
import CreatorsCatalogFilter from '@/components/CreatorsCatalog/Includes/CreatorsCatalogFilter.vue'
import CreatorsCardsPlaceholder from '@/components/CreatorsCatalog/Includes/CreatorsCardsPlaceholder.vue'

export default {
  name: 'CreatorsCatalog',
  components: {
    Observer,
    CreatorsPlaceholder,
    CreatorsNoOneIsHere,
    CreatorsCatalogCard,
    CreatorsCatalogFilter,
    CreatorsCardsPlaceholder
    // ProfilesNav
  },
  data () {
    return {
      constantLoading: true,
      initialLoading: true,
      constantFilterLoading: false,
      filterLoading: false
    }
  },
  computed: {
    ...mapState(useCreatorsCatalogStore, [
      'searchQuesryStr',
      'loadingStatus',
      'isCatalogLoading',
      'searchInputFocused'
    ]),
    ...mapWritableState(useCreatorsCatalogStore, [
      'loadingStatus',
      'catalogItems',
      'loadMoreCounter',
      'acitiveTab'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'beforeAuthOptions',
      'authOptions',
      'options'
    ]),
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ]),
    // Fisrt page loading
    isFirstLoading () {
      return this.constantLoading || this.initialLoading
    },
    noOneIsHere () {
      return this.catalogItems.length === 0 && !this.isFirstLoading  && !this.isCatalogLoading
    }
  },
  watch: {
    loadMoreCounter (value) {
      // Show sign up if load more each 3 times
      if (!this.isAuthenticated) {
        if (value > 0 && value % 3 === 0) {
          helpers.showSignUp()
        }
      }
    },
    async acitiveTab () {
      console.log('acitiveTab')

      if (!this.isFirstLoading) {
        this.filterLoading = true
        this.constantFilterLoading = true
        this.catalogItems = []

        setTimeout(() => {
          this.constantFilterLoading = false
        }, 300)

        try {
          await this.usersFeed({})
        } finally {
          console.log('finally')
          this.filterLoading = false
        }
      }
    }
  },
  async beforeMount () {
    this.catalogItems = []
    this.loadingStatus = ''

    const activeCreatorCategories = this.options?.activeCreatorCategories

    if (activeCreatorCategories) {
      if (activeCreatorCategories.find((item) => item === 'all')) {
        this.acitiveTab = 'all'
      } else {
        this.acitiveTab = activeCreatorCategories[0]
      }
    } else if (!activeCreatorCategories) {
      this.acitiveTab = null
    }

    setTimeout(() => {
      this.constantLoading = false
    }, 500)

    await this.usersFeed({})
      .finally(() => {
        this.initialLoading = false
      })
  },
  methods: {
    ...mapActions(useCreatorsCatalogStore, [
      'usersFeed',
      'usersSearch'
    ]),
    loadMore () {
      console.log('load more')

      if (this.filterLoading || this.constantFilterLoading) {
        return
      }

      if (!this.isFirstLoading && !this.isCatalogLoading && this.loadingStatus !== 'loaded-all') {
        if (this.searchQuesryStr) {
          this.usersSearch({ type: 'load-more' })
        }
        else {
          this.usersFeed({ type: 'load-more' })
        }

        this.loadMoreCounter++
      }
    }
  }
}
</script>

<style>
  .logo:focus {
    outline: none;
    box-shadow: none;
  }
</style>
