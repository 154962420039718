<template>
  <Form
    ref="meForm"
    v-slot="{ errors, setErrors }"
    :initial-values="initialValues"
    @submit="onSubmit">
    <h3 class="mt-8 mb-4">
      {{ $t('user.me.public_info') }}
    </h3>

    <!-- Name -->
    <div class="flex items-center mb-3">
      <div class="w-4/12">
        <label for="name">
          {{ $t("forms.edit_profile.labels.name") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.name">
          <Field
            id="name"
            :placeholder="$t('forms.edit_profile.placeholders.public_name')"
            :rules="rules.name"
            name="name"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input"
            @focus="setErrors(fieldsToReset)"/>
        </YupTooltip>
      </div>
    </div>

    <!-- About -->
    <div class="flex items-center mb-3">
      <div class="w-4/12">
        <label for="username">
          {{ $t("forms.edit_profile.labels.about") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.about">
          <Field
            id="about"
            :placeholder="$t('forms.edit_profile.placeholders.about')"
            :rules="rules.about"
            name="about"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input"
            @focus="setErrors(fieldsToReset)"/>
        </YupTooltip>
      </div>
    </div>

    <!-- Birthdate -->
    <div class="flex items-center mb-3">
      <div class="w-4/12">
        <label for="birth_month">
          {{ $t("forms.edit_profile.labels.birthdate") }}
        </label>
      </div>

      <div class="flex-1 flex items-center justify-between">
        <YupTooltip :i18n="errors.birthMonth">
          <Field
            v-slot="{ field }"
            :rules="rules.birthMonth"
            name="birthMonth">
            <select
              id="birth_month"
              v-bind="field"
              :class="{ 'text-gray-400': field.value === $t('forms.edit_profile.placeholders.mm') }"
              class="form-select"
              @focus="setErrors(fieldsToReset)">
              <option disabled selected>
                {{ $t('forms.edit_profile.placeholders.mm') }}
              </option>

              <option v-for="n in 12" :key="n" :value="n">
                {{ n > 9 ? n : '0' + n }}
              </option>
            </select>
          </Field>
        </YupTooltip>

        <span class="px-1 text-gray-300">/</span>

        <YupTooltip :i18n="errors.birthDay">
          <Field
            v-slot="{ field }"
            :rules="rules.birthDay"
            name="birthDay">
            <select
              id="birth_day"
              aria-label="Birth day"
              v-bind="field"
              :class="{ 'text-gray-400': field.value === $t('forms.edit_profile.placeholders.dd') }"
              class="form-select"
              @focus="setErrors(fieldsToReset)">
              <option disabled selected>
                {{ $t('forms.edit_profile.placeholders.dd') }}
              </option>

              <option v-for="n in 31" :key="n" :value="n">
                {{ n > 9 ? n : '0' + n }}
              </option>
            </select>
          </Field>
        </YupTooltip>

        <YupTooltip :i18n="errors.birthYear">
          <Field
            v-slot="{ field }"
            :rules="rules.birthYear"
            name="birthYear">
            <select
              id="birth_year"
              aria-label="Birth year"
              v-bind="field"
              :class="{ 'text-gray-400': field.value === $t('forms.edit_profile.placeholders.yyyy') }"
              class="form-select"
              @focus="setErrors(fieldsToReset)">
              <option disabled selected>
                {{ $t('forms.edit_profile.placeholders.yyyy') }}
              </option>

              <option v-for="n in yearsRange" :key="n" :value="n">
                {{ n }}
              </option>
            </select>
          </Field>
        </YupTooltip>
      </div>
    </div>

    <!-- Interests -->
    <h3 class="mt-8 mb-4">
      {{ $t("user.me_edit.interests") }}
    </h3>

    <Interests
      v-model:selected-interests="interests"/>

    <h3 class="mt-8 mb-4">
      {{ $t('user.me.account_settings') }}
    </h3>

    <!-- Username -->
    <div class="flex items-center mb-3">
      <div class="w-4/12">
        <label for="username">
          {{ $t("forms.edit_profile.labels.username") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.username">
          <Field
            id="username"
            :placeholder="$t('forms.edit_profile.placeholders.username')"
            :rules="rules.username"
            name="username"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input"
            @focus="setErrors(fieldsToReset)"/>
        </YupTooltip>
      </div>
    </div>

    <!-- Email -->
    <div class="flex items-center mb-3">
      <div class="w-4/12">
        <label for="email">
          {{ $t("forms.edit_profile.labels.email") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip :i18n="errors.email">
          <Field
            id="email"
            :placeholder="$t('forms.edit_profile.placeholders.email')"
            :rules="rules.email"
            name="email"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input"
            @focus="setErrors(fieldsToReset)"/>
        </YupTooltip>
      </div>
    </div>

    <!-- Reset password -->
    <div class="flex items-center mb-3">
      <div class="flex items-center justify-between w-full py-2">
        <label>
          {{ $t('forms.login.labels.password') }}
        </label>

        <router-link
          to="/password-recovery"
          class="text-primary font-semibold">
          {{ $t('user.me.reset_password') }}
        </router-link>
      </div>
    </div>

    <!-- Email notifications -->
    <div class="flex items-center mb-3">
      <div class="flex items-center justify-between w-full py-2">
        <label for="emailNotificationsEnabled">
          {{ $t('forms.edit_profile.labels.email_notifications') }}
        </label>

        <button
          id="emailNotificationsEnabled"
          type="button"
          class="relative inline-flex outline-none flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
          :class="{'bg-primary ': user.emailNotificationsEnabled, 'bg-gray-200': !user.emailNotificationsEnabled }"
          role="switch"
          aria-checked="false"
          aria-labelledby="availability-label"
          @click="user.emailNotificationsEnabled = !user.emailNotificationsEnabled">
          <span class="sr-only">Email notifications</span>

          <span
            aria-hidden="true"
            class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
            :class="{'translate-x-5': user.emailNotificationsEnabled, 'translate-x-0': !user.emailNotificationsEnabled}"/>
        </button>
      </div>
    </div>

    <div class="mt-8 mb-4 text-center ">
      <span
        class="text-gray-400 cursor-pointer"
        @click="openQuizBeforeDelete">
        {{ $t("user.me_edit.delete_account") }}
      </span>
    </div>

    <div class="max-w-md sticky bottom-0 left-0 right-0 -mx-4">
      <button
        :disabled="loading"
        class="btn btn-primary rounded-none btn-block py-4">
        {{ $t("common.confirm") }}
      </button>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="removeAccount"
      name="RemoveAccount">
      <RemoveAccount @close="closeModal()"/>
    </Modal>
  </Form>
</template>

<script>
import { Field, Form } from 'vee-validate'
import { range } from 'lodash'
import Interests from '@/components/Details/Interests'
import Modal from '@/components/Functional/Modal'
import RemoveAccount from '@/components/ModalContents/RemoveAccount'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useQuizStore } from '@/stores/quiz'
import YupTooltip from '@/components/Functional/YupTooltip'

export default {
  name: 'MeForm',
  components: {
    Field,
    Form,
    YupTooltip,
    Interests,
    Modal,
    RemoveAccount
  },
  props: {},
  data () {
    return {
      // emailNotificationsEnabled: false,
      rules: {
        name: this.$yup.string().required(),
        username: this.$yup
          .string()
          .required()
          .matches(/^\S*$/, () => ({ key: 'yup.custom.no_whitespaces', values: {} }))
          .matches(/^[_a-zA-Z\d]+$/, () => ({ key: 'yup.custom.only_latin', values: {} })),
        about: this.$yup.string().nullable(),
        email: this.$yup.string().required().email(),
        birthMonth: this.$yup.mixed().required().notOneOf(['mm']),
        birthDay: this.$yup.mixed().required().notOneOf(['dd']),
        birthYear: this.$yup.mixed().required().notOneOf(['yyyy'])
      },
      fieldsToReset: {
        name: false,
        email: false,
        username: false,
        about: false
      },
      initialValues: {},
      loading: false,
      interests: []
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'user'
    ]),
    ...mapState(useQuizStore, [
      'quizAllowed'
    ]),
    ...mapWritableState(useQuizStore, [
      'surveyOpen',
      'surveyCause'
    ]),
    yearsRange () {
      return range(new Date().getFullYear() - 100 - 18, new Date().getFullYear() + 1 - 18, 1).reverse()
    }
  },
  beforeMount () {
    // Hack for birthday for different timezones
    // https://stackoverflow.com/questions/38310735/date-of-birth-same-date-of-birth-in-every-local
    const birthday = this.$dayjs(this.user.birthday * 1000).add(12, 'hour')

    // console.log(this.user.birthday, birthday)
    console.log(birthday.locale(this.$i18n.locale).format('M'))
    console.log(birthday.locale(this.$i18n.locale).format('D'))
    console.log(birthday.locale(this.$i18n.locale).format('YYYY'))

    // this.emailNotificationsEnabled = this.user.emailNotificationsEnabled
    this.initialValues = {
      name: this.user.name,
      about: this.user.about,
      email: this.user.email,
      username: this.user.username,
      birthMonth: birthday.locale(this.$i18n.locale).format('M'),
      birthDay: birthday.locale(this.$i18n.locale).format('D'),
      birthYear: birthday.locale(this.$i18n.locale).format('YYYY'),
      interests: this.user.interests
    }

    if (this.user.interests) this.interests = this.user.interests.split(', ')
  },
  mounted () {
  },
  methods: {
    ...mapActions(useUserStore, [
      'meSave'
    ]),
    async onSubmit (values) {
      this.loading = true

      const data = {
        name: values.name,
        username: values.username,
        about: values.about,
        birthday: this.$dayjs(`${values.birthYear}-${values.birthMonth}-${values.birthDay}`).unix(),
        email: values.email,
        interests: this.interests.join(', '),
        emailNotificationsEnabled: this.user.emailNotificationsEnabled
      }

      await this.meSave(data).finally(() => {
        this.loading = false
      })

      this.$router.push('/me')
    },
    openQuizBeforeDelete () {
      if (this.quizAllowed) {
        this.surveyOpen = true
        this.surveyCause = 'delete_account'
      } else {
        this.$refs.removeAccount.openModal()
      }
    }
  }
}
</script>
