<template>
  <div
    class="flex flex-col"
    :class="[message.senderId === userId ? 'items-end' : 'items-start']">
    <!--<pre>{{ message }}</pre>-->

    <!-- Logged user's message -->
    <template v-if="message.senderId === userId">
      <div
        v-if="message.attachment?.fields?.links.c320x320"
        class="relative z-0 flex flex-col items-end"
        :class="{ 'tint tinted': message.status === 0 }"
        @click="openAttachmentFromUser(message)">
        <img
          :src="message.attachment.fields?.links.c320x320"
          loading="lazy"
          class="rounded-2xl h-60 w-44 object-cover cursor-pointer"
          :alt="message.senderId === userId ? 'Your image' : 'Companion\'s image'"
          @load="imageLoaded">

        <InlineSvg
          v-if="isCurrentItemVideo && !isLockedAttachment"
          class="absolute top-2.5 right-2.5 z-10 inline-block w-4 h-4 post-shadow text-white"
          :src="require('@/assets/svg/design/play-video.svg')"/>

        <InlineSvg
          v-else-if="isCurrentItemPhoto && !isLockedAttachment"
          class="absolute top-2.5 right-2.5 z-10 inline-block w-4 h-4 post-shadow text-white"
          :src="require('@/assets/svg/design/photo.svg')"/>

        <div
          v-show="message.status === 0"
          class="absolute inset-0 z-20">
          <div class="flex flex-col items-center justify-center w-full h-full cursor-pointer">
            <InlineSvg
              :src="require('@/assets/svg/design/lock.svg')"
              class="text-gray-100 w-4 h-6"/>

            <span class="ml-1.5 text-sm text-gray-200">
              <template v-if="message.attachment.mediaType === 'image'">
                {{ $t('chat.message.send_photo') }}
              </template>

              <template v-else>
                {{ $t('chat.message.send_video') }}
              </template>
            </span>
          </div>
        </div>

        <!-- Delivery/Secret status -->
        <div
          v-if="message.status !== 0"
          class="text-right text-caption text-gray-400 w-full mt-1">
          <div class="flex items-center justify-end">
            <div class="flex items-center">
              <InlineSvg
                v-show="message.status === 9"
                :src="require('@/assets/svg/design/viewed.svg')"/>

              <span class="ml-1">{{ message.status === 8 ? $t('chat.delivered') : $t('chat.viewed') }}&nbsp;</span>
            </div>

            <div
              v-show="message.ttl || message.ttlAfterRead"
              class="flex items-center ml-1">
              <InlineSvg
                class="text-transparent"
                :src="require('@/assets/svg/design/timer.svg')"/>

              <span class="ml-1">
                Secret attachment
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Message from creator -->
    <template v-else>
      <div
        v-if="message.attachment?.fields?.links.c320x320"
        class="relative">
        <div class="drop-shadow w-44 relative">
          <div
            class="relative cursor-pointer"
            @click="openAttachmentFromCreator(message)">
            <img
              :src="message.attachment.fields?.links.c320x320"
              loading="lazy"
              :class="[isAttachmentWithMessage || isPromo || isMass ? 'rounded-t-2xl' : 'rounded-2xl']"
              class="h-60 object-cover"
              :alt="message.senderId === userId ? 'Your image' : 'Companion\'s image'"
              @load="imageLoaded">

            <InlineSvg
              v-if="isCurrentItemVideo && !isLockedAttachment"
              class="absolute top-2.5 right-2.5 z-10 inline-block w-4 h-4 post-shadow text-white"
              :src="require('@/assets/svg/design/play-video.svg')"/>

            <InlineSvg
              v-else-if="isCurrentItemPhoto && !isLockedAttachment"
              class="absolute top-2.5 right-2.5 z-10 inline-block w-4 h-4 post-shadow text-white"
              :src="require('@/assets/svg/design/photo.svg')"/>

            <div
              v-if="isLockedAttachment"
              class="absolute inset-0 flex flex-col items-center justify-center h-full post-shadow">
              <InlineSvg
                v-if="message.attachment?.mediaType === 'video'"
                :src="require('@/assets/svg/design/play-video.svg')"
                class="text-gray-100 mb-1.5 w-8 p-0.5"/>

              <InlineSvg
                v-else
                :src="require('@/assets/svg/design/picture.svg')"
                class="text-gray-100 mb-1.5 w-8"/>

              <div class="flex items-center justify-center">
                <InlineSvg
                  :src="require('@/assets/svg/design/lock.svg')"
                  class="text-gray-100 w-4 h-6"/>

                <span class="ml-1.5 text-sm text-gray-100">{{ message.attachment.price }} credits</span>
              </div>
            </div>
          </div>

          <div
            v-if="isAttachmentWithMessage || isPromo || isMass"
            class="attach-message relative rounded-b-2xl bg-white text-sm px-2 py-1 break-words"
            :class="{
              'promo': isPromo,
              'no-message': !isAttachmentWithMessage,
              'mass': isMass || (options.prelandingId === 'apreland' && !isPremiumSupportUser)
            }">
            <div
              v-if="isAttachmentWithMessage"
              class="leading-5 mb-1"
              v-html="linkify(message.text)"/>

            <div
              v-if="(options.prelandingId === 'apreland' && !isPremiumSupportUser)"
              class="flex items-center gap-1">
              <InlineSvg
                :src="require('@/assets/svg/design/i_autoreply.svg')"
                class="h-2 w-2"/>

              <span class="text-[10px] leading-3 text-[#837d7c]">
                @{{ chatUser.username }} AI assistant
              </span>
            </div>
            
            <div
              v-if="isPromo || isMass"
              class="flex items-center gap-1">
              <InlineSvg
                :src="require('@/assets/svg/design/logo-small.svg')"
                class="h-2 w-2"/>

              <span class="text-[10px] leading-3 text-[#837d7c]">
                {{ isMass ? 'Mass sending' : 'Ad promotion' }}
              </span>
            </div>
          </div>
        </div>

        <!-- Status -->
        <div class="text-right text-caption text-gray-400 w-full noselect mt-1">
          <div
            v-show="message.ttl || message.ttlAfterRead"
            class="flex items-center ml-1">
            <InlineSvg
              class="text-transparent"
              :src="require('@/assets/svg/design/timer.svg')"/>

            <span class="ml-1">
              Secret attachment
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>

  <!-- Attachemtns swiper -->
  <Modal
    ref="modalAttachments"
    v-slot="{ values, closeModal }"
    name="Attachments"
    :is-rounded="false"
    :has-indents="false"
    :is-full-height="true"
    :allow-close-by-overlay="true"
    :show-desktop-close-cross="true"
    content-class="h-full bg-gray-700"
    position="center">
    <ChatAttachmentsSwiper
      :values="values"
      :items="sortedMessagesWithAttachmentsOnly"
      @send-attachment="openAttachmentFromUser"
      @unlock-attachment="openAttachmentFromCreator"
      @close="closeModal"/>
  </Modal>

  <!-- Unblock incoming attachment from creator -->
  <Modal
    v-slot="{ values, closeModal }"
    ref="unlockPhotoContent"
    name="UnlockAttachment">
    <UnlockAttachment
      :values="values"
      @unlocked="unlockedAttachment"
      @close="closeModal"/>
  </Modal>

  <!-- Unblock outgoin attachment from logged user -->
  <Modal
    v-slot="{ values, closeModal }"
    ref="sendPhotoModal"
    name="SendPhoto">
    <SendPhotoContent
      :values="values"
      @unlocked="unlockedAttachment"
      @close="closeModal"/>
  </Modal>
</template>

<script>

import ChatAttachmentsSwiper from '@/components/ChatAttachments/ChatAttachmentsSwiper.vue'
import UnlockAttachment from '@/components/ModalContents/chat/UnlockAttachment'
import { mapState, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useChatStore } from '@/stores/chat'
import SendPhotoContent from '@/components/ModalContents/chat/SendPhotoContent.vue'
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'ChatAttachmentMessage',
  components: {
    UnlockAttachment,
    ChatAttachmentsSwiper,
    SendPhotoContent
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {
          attachment: {
            paid: null,
            fields: {
              links: {
                c320x320: '',
                original: ''
              }
            }
          }
        }
      },
      required: true
    }
  },
  emits: ['loaded'],
  setup () {
    return {}
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useUserStore, [
      'userId',
      'balance',
      'rates',
      'features',
      'options',
      'premiumSupportAgentUserId'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    ...mapState(useChatStore, [
      'sortedMessagesWithAttachmentsOnly',
      'chatId',
      'chatUser'
    ]),
    ...mapWritableState(useChatStore, [
      'priceInCurrencyForAttachment',
      'messages'
    ]),
    isAttachmentWithMessage () {
      return this.message.text
    },
    isCurrentItemVideo () {
      return !!this.message.attachment?.fields?.videoUrl || this.message.attachment?.mediaType === 'video'
    },
    isCurrentItemPhoto () {
      return !this.message.attachment?.fields?.videoUrl
    },
    isLockedAttachment () {
      return this.message.attachment.paid === 0 && this.message.attachment.price
    },
    isPromo () {
      return this.message.designId === 'promo'
    },
    isMass () {
      return this.message.designId === 'mass'
    }
  },
  mounted () {
  },
  methods: {
    imageLoaded () {
      this.$emit('loaded')
    },
    openAttachmentFromUser (message) {
      if (message.status === 0) {
        this.$refs.sendPhotoModal.openModal(message)
      }
      else {
        this.openAttachment(message)
      }
    },
    openAttachmentFromCreator (message) {
      if (message.attachment.paid === 0) {
        this.$refs.unlockPhotoContent.openModal(message)
      }
      else {
        const index = this.sortedMessagesWithAttachmentsOnly.findIndex((item) => {
          return item.messageId === message.messageId
        })

        this.$refs.modalAttachments.openModal({ initialIndex: index })
      }
    },
    unlockedAttachment (message) {
      // Update attachment and open
      const index = this.messages.findIndex((item) => item.messageId === message.messageId)

      if (index !== -1) {
        this.messages.splice(index, 1, message)
      }

      if (message.senderId === this.chatId) {
        this.openAttachment(message)
      }
    },
    openAttachment (message) {
      const index = this.sortedMessagesWithAttachmentsOnly.findIndex((item) => {
        return item.messageId === message.messageId
      })

      this.$refs.modalAttachments.openModal({ initialIndex: index })
    },
    dateToTime (value) {
      const date = this.$dayjs.unix(value)

      return date.locale(this.$i18n.locale).format('h:mm A')
    },
    linkify (inputText) {
      // URLs starting with http://, https://, or ftp://
      const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])/gim
      const result = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

      return sanitizeHtml(result)
    }
  }
}
</script>

<style>
  .tint.tinted:before {
    @apply rounded-2xl;
    background: rgba(0, 0, 0, 0.65);
  }

  .attach-message a {
    @apply text-link
  }

  .attach-message:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  .attach-message.promo {
    background: linear-gradient(180deg, #E1E1E1 0%, #CEC4C2 100%);
  }

  .attach-message.promo:before {
    background-image: url("~@/assets/svg/design/tail_promo.svg");
  }

  .attach-message.promo.no-message:before {
    background-image: url("~@/assets/svg/design/tail_promo_short.svg");
  }

  .attach-message.mass {
    background: linear-gradient(180deg, #FFD8DF 0%, #F1A9B7 100%);
  }

  .attach-message.mass:before {
    background-image: url("~@/assets/svg/design/tail_pink.svg");
  }

  .attach-message.mass.no-message:before {
    background-image: url("~@/assets/svg/design/tail_pink_short.svg");
  }
</style>
