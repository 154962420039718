<template>
  <div class="container h-full flex flex-col justify-between items-center py-4">
    &#8203;

    <div class="flex flex-col items-center justify-center">
      <svg
        width="62"
        height="62"
        class="mb-4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60 31c0 16.016-12.984 29-29 29S2 47.016 2 31 14.984 2 31 2s29 12.984 29 29zm2 0c0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0c17.12 0 31 13.88 31 31zm-16.218-7.376a1 1 0 0 0-1.564-1.248L29.78 40.472 18.731 28.65a1 1 0 1 0-1.462 1.366l11.84 12.667.79.845.722-.904 15.16-19z"
          fill="#00D796"/></svg>


      <h1 class="text-lg mb-2">
        {{ $t("user.card_success.success") }}
      </h1>

      <p class="text-center" v-html="user.card_success.service"/>
    </div>

    <router-link
      to="/"
      class="btn btn-primary btn-block">
      {{ $t("user.card_success.continue") }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'AddCardSuccess',
  methods: {}
}
</script>

