<script setup>
  import AppOpenInBrowserBanner from '@/components/App/AppOpenInBrowserBanner.vue'
  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import OpenInBrowserModal from '@/components/ModalsNext/OpenInBrowserModal.vue'
  import { createComponent } from '@/helpers/utils.js'

  function openPostsModal (index) {
    const modalProps = { wrapperClass: 'max-w-md mx-auto px-2' }

    const OpenInBrowserModalProps = {}

    createComponent(ModalWindow, modalProps, OpenInBrowserModal, OpenInBrowserModalProps)
  }

  openPostsModal()
</script>

<template>
  <div class="max-w-md mx-auto py-6 px-2">
    <AppOpenInBrowserBanner @click="openPostsModal"/>
  </div>
</template>