<template>
  <header class="border-b border-gray-300">
    <div class="container">
      <div class="flex justify-between items-center h-14">
        <LinkBack
          path="/me"
          class="pr-2 text-gray-900"/>

        <h3 class="mb-0">{{ name }}</h3>

        <div>&nbsp;</div>
      </div>
    </div>
  </header>
</template>

<script>

import LinkBack from '@/components/Functional/LinkBack'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'Header',
  components: {
    LinkBack
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>
