<script setup>

</script>

<template>
  <div class="flex items-center justify-center">
    <nav class="flex space-x-1">
      <router-link
        to="/creators"
        active-class="!border-primary font-bold"
        class="text-black border-transparent hover:border-primary whitespace-nowrap border-b-2 py-1.5 px-5 text-sm">
        {{ $t('nav.menu.profiles') }}
      </router-link>

      <router-link
        to="/discover"
        active-class="!border-primary font-bold"
        class="text-black border-transparent hover:border-primary whitespace-nowrap border-b-2 py-1.5 px-5 text-sm">
        {{ $t('nav.menu.discover') }}
      </router-link>
    </nav>
  </div>
</template>