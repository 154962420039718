<template>
  <div class="block relative">
    <img
      v-if="userItem.photos?.items.length > 0"
      class="rounded-full w-full h-full"
      :src="userItem.photos?.items[0].links.c160x160"
      :title="userItem?.name.trim()"
      :alt="userItem?.name ? userItem.name.trim() + '\'s photo' : `User's photo`">

    <img
      v-else
      :src="`/svg/${userItem?.sex === 1 ? 'user' : 'woman'}-placeholder.svg`"
      class="rounded-full border border-gray-100 bg-white w-full"
      :title="userItem?.name.trim()"
      :alt="`${userItem?.sex === 1 ? 'Man' : 'Woman'}`">

    <span
      v-if="showStatus && !secretToken"
      :class="[userItem.online ? 'bg-green-300' : 'bg-red-400']"
      class="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"/>
  </div>
</template>

<script>

import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'

export default {
  name: 'Avatar',
  components: {},
  props: {
    showStatus: {
      type: Boolean,
      default: false
    },
    userItem: {
      type: Object,
      default: () => ({
        name: null,
        sex: null,
        age: null,
        photos: {
          items: []
        }
      }),
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAppStore, [
      'secretToken'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>
