<template>
  <div>
    <template v-if="blurAlternativeEnabled">
      <div
        v-if="videos.length"
        class="grid grid-cols-3 gap-0.5">
        <div
          v-for="(item, index) in videos"
          :key="index"
          class="h-44 relative cursor-pointer"
          @click="handlePost(oddLockedVideosIDs.includes(item.id), item.userId, index);">
          <div
            v-if="item.locked && !item.videoUrl"
            class="bg-[#262626] h-full w-full absolute inset-0">
            <img
              class="w-full h-full pt-4 relative z-10"
              alt="Lock decoration"
              src="@/assets/svg/design/lock-smile-decoration.svg">
          </div>

          <img
            v-else
            class="w-full h-full object-center object-cover"
            :alt="item.description"
            :src="item?.videoPreviewImageUrls?.c320x320 || '/img/unknown_image.png'">

          <div
            v-if="oddLockedVideosIDs.includes(item.id)"
            class="absolute inset-0 h-full w-full flex items-center flex-col justify-center">
            <span>👇</span>

            <span class="text-white text-xs">
              {{ $t('posts.only_in_chat') }} 💬
            </span>
          </div>

          <Lock
            v-else-if="item.locked && !item.videoUrl"
            :has-blur="false"
            :price="item.price"/>

          <div class="absolute z-10 left-2 bottom-2 text-caption text-white post-shadow opacity-80">
            {{ parseDuration(item.videoLength) }}
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-if="videos.length"
        class="grid grid-cols-3 gap-0.5">
        <div
          v-for="(item, index) in videos"
          :key="index"
          :class="{ 'col-span-3': index === 6 && isAuthenticated }">
          <router-link
            v-if="index === 6 && isAuthenticated"
            :to="'/chat/' + creator.userId"
            class="mx-2.5 my-2.5 py-3 pl-3.5 pr-5 flex items-center justify-between rounded-lg shadow-[0px_8px_34px_0px_rgba(0,0,0,0.15)]">
            <div class="flex items-center gap-3">
              <div class="relative" style="min-width: 50px; height: 50px; width: 50px">
                <Avatar :user-item="creator"/>

                <button
                  class="flex rounded-full bg-primary p-0.5 absolute -right-0.5 -top-0.5"
                  type="button">
                  <InlineSvg
                    class="w-3 h-3 text-white"
                    :src="require('@/assets/svg/design/send.svg')"/>
                </button>
              </div>

              <div>
                <h5 class="text-sm font-bold">
                  {{ $t('creator.what_you_like_to_see' ) }}
                </h5>

                <p class="text-sm">
                  {{ $t('creator.send_me_suggestions' ) }}
                </p>
              </div>
            </div>

            <InlineSvg
              class="scale-125"
              :src="require('@/assets/svg/design/arrow-right.svg')"/>
          </router-link>

          <div
            v-else
            class="h-44 relative cursor-pointer"
            @click="handlePost(oddLockedVideosIDs.includes(item.id), item.userId, index);">
            <img
              class="w-full h-full object-center object-cover"
              :alt="item.description"
              :src="item?.videoPreviewImageUrls?.c320x320 || '/img/unknown_image.png'">

            <div
              v-if="oddLockedVideosIDs.includes(item.id)"
              class="absolute inset-0 h-full w-full flex items-center justify-center flex-col">
              <span>👇</span>

              <span class="text-white text-xs">
                {{ $t('posts.only_in_chat') }} 💬
              </span>
            </div>

            <Lock
              v-else-if="item.locked && !item.videoUrl"
              :price="item.price"/>

            <div class="absolute left-2 bottom-2 text-caption text-white post-shadow opacity-80">
              {{ parseDuration(item.videoLength) }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="videosLoadingStatus === 'loaded-all'"
      class="my-4 leading-10 w-full text-caption text-gray-400 text-center"
      :class="{'border-b border-grey-600': videos.length > 0}">
      {{ videos.length > 0 ? $t('common.end_of_list') : $t('common.no_content') }}
    </div>

    <div
      v-show="~videosLoadingStatus.indexOf('loading')"
      class="h-24 w-full flex">
      <Spinner size="big"/>
    </div>

    <Observer @intersect="getUserPosts"/>

    <Modal
      ref="modalPosts"
      v-slot="{ values, closeModal }"
      name="ThePostsSwiper"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      :show-close-cross="false"
      :show-desktop-close-cross="true"
      z-index-class="z-10"
      content-class="h-full bg-gray-700"
      position="center">
      <ThePostsSwiper
        :values="values"
        :posts="videos"
        :users="[creator]"
        :show-close="true"
        @slide-changed="slideChanged"
        @reach-end="reachEnd"
        @close="closeModal"
        @liked="liked"
        @shared="shared"
        @avatar-clicked="closeModal"
        @unlocked="unlocked"
        @subscribed="subscribed"/>
    </Modal>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      overflow-class="overflow-y-auto"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :user="creator"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>

<script>
import Observer from '@/components/Functional/Observer'
import Spinner from '@/components/Functional/Spinner'
import Modal from '@/components/Functional/Modal'
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import Lock from '@/components/Functional/Lock'
import { mapState, mapActions, mapWritableState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import { useAuthStore } from '@/stores/auth'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import helpers from '@/helpers'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'
import uniqid from 'uniqid'
import Avatar from '@/components/Functional/Avatar.vue'

export default {
  name: 'Videos',
  components: {
    Observer,
    Spinner,
    Modal,
    ThePostsSwiper,
    Lock,
    SubscribeContent,
    Avatar
  },
  props: {
    username: {
      type: String,
      default: null,
      required: true
    }
  },
  emits: ['update-tab'],
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'authOptions'
    ]),
    ...mapState(useSubscriptionsStore, [
      'userHasPaidSubscription'
    ]),
    ...mapWritableState(useCreatorStore, [
      'creator',
      'videosLoadingStatus',
      'videos'
    ]),
    blurAlternativeEnabled () {
      return this.authOptions?.blurAlternativeEnabled
    },
    lockedVideos () {
      return this.videos.filter((item) => item.locked && !item.videoUrl && item.price)
    },
    datingItems () {
      if (this.userHasPaidSubscription) {
        return this.videos
      } else {
        return this.videos.slice(0, (this.isAuthenticated ? 7 : 5))
      }
    },
    oddLockedVideosIDs () {
      const odds = this.lockedVideos.filter((item, index) => {
        return (index + 1) % 2 === 1
      })

      return odds.map((item) => item.id)
    }
  },
  beforeMount () {
    this.videosLoadingStatus = ''
    this.videos = []
  },
  mounted () {
    this.getUserPosts()
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userPostsGet',
      'shareCount',
      'likeToggle'
    ]),
    openOrShowSubcribe (isLocked, index) {
      if (this.isAuthenticated) {
        if (isLocked) {
          this.$refs.subscribeModal.openModal()
        } else {
          this.openPost(index)
        }
      } else {
        helpers.showOnbordingOrSignUp()
      }
    },
    async getUserPosts () {
      if (~this.videosLoadingStatus.indexOf('loading')) return

      if (this.videosLoadingStatus !== 'loaded-all') {
        const params = {
          username: this.username,
          contentType: 'videos',
          limit: 9,
          // beforeNumber: this.videos ? this.videos[this.videos.length - 1]?.number : null
          orderBy: 'score',
          belowScore: this.videos ? this.videos[this.videos.length - 1]?.score : null
        }

        await this.userPostsGet(params)

        if (!this.videos.length) {
          this.$emit('update-tab')
        }
      }
    },
    async handlePost (isOnlyInChat, userId, index) {
      if (this.isAuthenticated) {
        if (isOnlyInChat) {
          if (this.creator.subscribed) {
            await this.wsp.sendRequest({
              data: {
                text: 'Hi! I would like to see exclusive content available only in chat',
                receiverId: userId,
                clientId: uniqid(),
                ttlAfterRead: null,
                highlighted: true,
                surveyResultId: '33033'
              },
              method: 'message.send'
            }).then(() => {
              this.$router.push(`/chat/${userId}`)

              // this.wsp.sendRequest({
              //   data: {
              //     questionNumber: "3",
              //     answerNumber: "33033"
              //   },
              //   method: 'survey.questionAnswered'
              // })
            })
          } else {
            this.$refs.subscribeModal.openModal()
          }
        } else {
          this.openPost(index)
        }
      } else {
        helpers.showOnbordingOrSignUp()
      }
    },
    openPost (index) {
      this.$refs.modalPosts.openModal({ initialIndex: index })
    },
    async slideChanged (index) {
      if (this.videosLoadingStatus === 'loaded-all') return

      if (index % 3 === 0) {
        if (this.videos.length - index <= 3) {
          await this.getUserPosts()
        }
      }
    },
    async reachEnd () {
      if (this.videosLoadingStatus !== 'loaded-all') {
        await this.getUserPosts()
      }
    },
    liked (postId) {
      this.likeToggle({ postId, type: 'videos' })
    },
    shared (postId) {
      this.shareCount({ type: 'videos', postId: postId })
    },
    async unlocked (data) {
      const index = this.videos.findIndex((item) => item.id === data?.post.id)

      if (index !== -1) {
        this.videos.splice(index, 1, data?.post)
      }
    },
    async subscribed () {
      await this.$refs.modalPosts.closeModal()
      this.videosLoadingStatus = ''
      this.videos = []
      await this.getUserPosts()
      this.creator.subscribed = true
    },
    parseDuration (seconds) {
      return helpers.parseDuration(seconds)
    }
  }
}
</script>
