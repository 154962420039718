<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <template v-if="post.locked && post.videoUrl && isEnded">
      <div class="h-full w-full tint tinted play-prevent">
        <div class="px-8 h-full w-full text-white flex flex-col items-center justify-center relative z-20">
          <h3 class="h2 mb-8 post-shadow">
            {{ $t('posts.to_see_full_video') }}
          </h3>

          <div class="w-full">
            <button
              v-if="post.locked && post.price"
              class="btn btn-primary btn-block mb-5"
              @click="unlockVideo(post.id)">
              <template v-if="isAuthenticated">
                {{ $t('posts.unlock_video_for', { price: post.price }) }}
              </template>

              <template v-else>
                {{ $t('posts.sing_up_for_unlock') }}
              </template>
            </button>

            <button
              v-else-if="!user.subscribed"
              class="btn btn-primary btn-block mb-5"
              @click="subscribe(user.userId)">
              <template v-if="isAuthenticated">
                <template v-if="subscriptionPriceInCurrency">
                  {{ $t('creator.subscribe.for_price_to_unlock', { price: Number(subscriptionPriceInCurrency) }) }}
                </template>

                <template v-else>
                  {{ $t('creator.subscribe.to_unlock') }}
                </template>
              </template>

              <template v-else>
                {{ $t('creator.subscribe.to_unlock') }}
              </template>
            </button>

            <button
              class="btn btn-secondary btn-block text-gray-300 hover:text-gray-700 post-shadow"
              @click="$emit('replay')">
              {{ $t('posts.replay') }}
            </button>
          </div>
        </div>
      </div>
    </template>

    <Modal
      v-slot="{ closeModal }"
      ref="unlockPostContent"
      name="UnlockPost">
      <UnlockPostContent
        :post="post"
        @close="closeModal"
        @unlocked="$emit('unlocked', $event); $emit('modal-closed')"/>
    </Modal>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      overflow-class="overflow-y-auto"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :user="user"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="$emit('subscribed', $event)"/>
    </Modal>
  </div>
</template>

<script>

import Modal from '@/components/Functional/Modal'
import UnlockPostContent from '@/components/ModalContents/post/UnlockPostContent'
import SubscribeContent from '@/components/ModalContents/SubscribeContent'
import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import helpers from '@/helpers'

// Post Availability Layer with text
export default {
  name: 'PostAvailability',
  components: {
    Modal,
    UnlockPostContent,
    SubscribeContent
  },
  props: {
    post: {
      type: Object,
      default: () => {},
      required: true
    },
    isEnded: {
      type: Boolean,
      default: false,
      required: false
    },
    user: {
      type: Object,
      default: () => ({
        userId: null,
        username: null
      }),
      required: true
    }
  },
  emits: ['unlocked', 'subscribed', 'replay', 'modal-closed'],
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isAuthenticated']),
    ...mapWritableState(useAuthStore, ['signupIsVisible', 'pathToRedirect']),
    subscriptionPriceInCurrency () {
      return this.user.subscriptionPlans?.length ? this.user.subscriptionPlans[0].priceInCurrency : this.user.subscriptionPriceInCurrency
    }
  },
  mounted () {
  },
  methods: {
    unlockVideo (postId) {
      if (this.isAuthenticated) {
        this.$refs.unlockPostContent.openModal()
      } else {
        if (postId) this.pathToRedirect = `/${postId}`
        helpers.showSignUp()
      }
    },
    subscribe (userId) {
      if (this.isAuthenticated) {
        this.$refs.subscribeModal.openModal()
      } else {
        if (userId) this.pathToRedirect = `/chat/${userId}`
        helpers.showSignUp()
      }
    }
  }
}
</script>

