<script setup>
  import { useUserStore } from '@/stores/user'
  import { storeToRefs } from 'pinia'
  import Avatar from '@/components/Functional/Avatar'
  import { computed } from 'vue'

  const userStore = useUserStore()
  const {
    user,
    profileCompletionPercentage,
    options
  } = storeToRefs(userStore)

  function pointSlope (x1, y1, x2, y2, x) {
    const m = (y2 - y1) / (x2 - x1)
    const x0 = y1 - (m * x1)

    return m * x + x0
  }

  // https://nikitahl.com/svg-circle-progress
  const strokeDashoffset = computed(() => {
    return pointSlope(0, 330, 100, 0, profileCompletionPercentage.value)
  })
</script>

<template>
  <div
    class="relative flex items-center justify-center"
    style="width: 5.375rem; height: 5.375rem">
    <Avatar
      class="w-20 h-20"
      :user-item="user"/>

    <svg
      v-if="options.profileCompletenessEnabled && profileCompletionPercentage && profileCompletionPercentage !== 100"
      width="100%"
      height="100%"
      class="absolute inset-0"
      viewBox="8 8 109 109"
      xmlns="http://www.w3.org/2000/svg"
      style="transform:rotate(-90deg)">
      <circle
        r="52.5"
        cx="62.5"
        cy="62.5"
        stroke="#f23459"
        stroke-width="4"
        stroke-linecap="round"
        :stroke-dashoffset="strokeDashoffset + 'px'"
        fill="transparent"
        stroke-dasharray="329.7px"/>
    </svg>
  </div>
</template>