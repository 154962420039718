<script setup>

</script>

<template>
  <div class="flex flex-col items-center mt-26 mb-6">
    <img
      class="w-[95px] h-[95px] mb-3"
      src="@/assets/images/no-items-shame.png"
      alt="No items emoji">

    <h4 class="h2 mb-2">
      {{ $t('creators.catalog.no_one_here') }}
    </h4>

    <p class="text-center max-w-[280px]">
      {{ $t('creators.catalog.cant_find_anyone') }}
    </p>
  </div>
</template>
