import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useQuizStore } from '@/stores/quiz'
import { useUserStore } from '@/stores/user'
import { watch } from 'vue'
import helpers from '@/helpers'
import app from '@/main'
import allowedUsersForNikly from '@/router/allowed-users-for-nikly'
// import allowedUsersForSlmalkmmm from '@/router/allowed-users-for-slmalkmmm.js'

// Views
import Test from '../views/Test'
import Home from '../views/Home'
import Me from '../views/Me'
import Following from '@/views/Following'
import PasswordRecovery from '../views/PasswordRecovery'
import CreatorView from '../views/CreatorView'
import Messages from '../views/Messages.vue'
import Discover from '../views/Discover.vue'
import DiscoverTag from '../views/DiscoverTag.vue'
import MeEdit from '@/views/MeEdit'
import Chat from '@/views/Chat'
import Credits from '@/views/Credits'
import SavedCards from '@/views/SavedCards'
import Post from '@/views/Post'
import Details from '@/views/Details'
import YourInterestsView from '@/views/YourInterestsView'
import PhotoUpload from '@/views/PhotoUpload'
import Subscriptions from '@/views/Subscriptions'
import Page404 from '@/views/Page404'
import AddCardSuccess from '@/views/AddCardSuccess'
import SignUp from '@/views/SignUp'
import LogIn from '@/views/LogIn'
import StripeCheckoutResult from '@/views/StripeCheckoutResult'
import Payments from '@/views/Payments'
import Creators from '@/views/Creators'
import ChatAttachments from '@/views/ChatAttachments.vue'
import ServiceUnavailableView from '@/views/ServiceUnavailableView.vue'
import EventsView from '@/views/EventsView.vue'

const showQuiz = (to, from, next) => {
  const quizStore = useQuizStore()

  if (to.name === 'Subscriptions') {
    quizStore.surveyCause = 'show_subscriptions'
  }
  else if (to.name === 'Payments') {
    quizStore.surveyCause = 'show_purchases'
  }
  else if (to.name === 'SavedCards') {
    quizStore.surveyCause = 'show_payment_methods'
  }

  if (quizStore.quizAllowed) {
    quizStore.surveyOpen = true
  }
  else {
    next()
  }
}

// Show page only for guests (For example: /signIn)
const unauthorizedOnly = (to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.isTokenExist) {
    next('/')
  }
  else {
    next()
  }
}

// Allow only signedIn with started session users to see page
const authorizedOnly = async (to, from, next) => {
  const authStore = useAuthStore()

  if (process.env.NODE_ENV === 'development') {
    await new Promise(resolve => setTimeout(resolve, 666))
  }

  if (authStore.isTokenExist) {
    if (authStore.isConnected && authStore.isAuthenticated) {
      next()
    }
    else {
      // Wait session to be started
      const unwatch = watch(authStore,
        (state) => {
          if (state.isConnected && state.isAuthenticated) {
            unwatch()
            next()
          }
        }
      )
    }
  }
  else {
    if (to.name !== 'Messages' && to.name !== 'LogIn' && to.name !== 'Signup') {
      authStore.pathToRedirect = to.fullPath
    }

    // For unsigned redirect to home
    next('/')
  }
}

// Only check whether socket is connected or not
// Allow to enter unauthorized in users
const isSocketConnected = (to, from, next) => {
  const authStore = useAuthStore()

  if (authStore.token && authStore.token.length > 0) {
    if (authStore.isConnected && authStore.isAuthenticated) {
      next()
    }
    else {
      const unwatch = watch(authStore,
        (state) => {
          if (state.isConnected && state.isAuthenticated) {
            unwatch()
            next()
          }
        },
        { deep: true }
      )
    }
  }
  else if (authStore.isConnected) {
    next()
  }
  else {
    const unwatch = watch(authStore,
      (state) => {
        if (state.isConnected) {
          unwatch()
          next()
        }
      },
      { deep: true }
    )
  }
}

const body = document.body
const html = document.documentElement

function fixedLayout () {
  const appStore = useAppStore()
  appStore.isFixedLayout = true

  html.classList.remove('min-h-screen')
  html.classList.add('app-height', 'overflow-hidden')
  body.classList.add('fixed')

  // Prevent state when popup wasn't closed properly
  body.style.top = ''
}

function normalLayout () {
  const appStore = useAppStore()
  appStore.isFixedLayout = false

  html.classList.add('min-h-screen')
  html.classList.remove('app-height', 'overflow-hidden')
  body.classList.remove('fixed')

  // Prevent state when popup wasn't closed properly
  const scrollY = body.style.top
  body.style.position = ''
  body.style.top = ''

  window.scrollTo(0, parseInt(scrollY || '0') * -1)
}

const beforeCreator = (to, from, next) => {
  const { nickname } = to.params
  const authStore = useAuthStore()
  const userStore = useUserStore()

  if (nickname && nickname.toLowerCase() === 'nikly') {
    if (authStore.isAuthenticated && allowedUsersForNikly.includes(userStore.userId)) {
      next()
    }
    else {
      next('/404')
    }
  }

  next()
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: [isSocketConnected, fixedLayout]
  },
  {
    path: '/creators',
    name: 'Creators',
    component: Creators,
    beforeEnter: [isSocketConnected, normalLayout]
  },
  {
    path: '/following',
    component: Following,
    name: 'Following',
    beforeEnter: [authorizedOnly, fixedLayout]
  },
  {
    path: '/test',
    component: Test,
    name: 'Test',
    beforeEnter: [isSocketConnected, normalLayout]
  },
  {
    path: '/me',
    component: Me,
    name: 'Me',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/signup',
    component: SignUp,
    name: 'Signup',
    beforeEnter: [unauthorizedOnly, isSocketConnected, normalLayout]
  },
  {
    path: '/login',
    component: LogIn,
    name: 'LogIn',
    beforeEnter: [unauthorizedOnly, isSocketConnected, normalLayout]
  },
  {
    path: '/404',
    beforeEnter: [isSocketConnected, fixedLayout],
    component: Page404,
    name: 'Page404'
  },
  {
    path: '/service-unavailable',
    beforeEnter: [fixedLayout],
    component: ServiceUnavailableView,
    name: 'PageServiceUnavailable'
  },
  {
    path: '/password-recovery',
    beforeEnter: [isSocketConnected, normalLayout],
    component: PasswordRecovery,
    name: 'PasswordRecovery'
  },
  {
    path: '/discover',
    component: Discover,
    name: 'Discover',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/discover/:tagname',
    component: DiscoverTag,
    name: 'DiscoverTag',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/messages',
    component: Messages,
    name: 'Messages',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/me/edit',
    component: MeEdit,
    name: 'MeEdit',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/add-card-success',
    component: AddCardSuccess,
    name: 'AddCardSuccess',
    beforeEnter: [authorizedOnly, fixedLayout]
  },
  {
    path: '/saved-cards',
    component: SavedCards,
    name: 'SavedCards',
    beforeEnter: [showQuiz, authorizedOnly, normalLayout]
  },
  {
    path: '/checkout/result',
    component: StripeCheckoutResult,
    name: 'StripeCheckoutResult',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/credits',
    component: Credits,
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/subscriptions',
    component: Subscriptions,
    name: 'Subscriptions',
    beforeEnter: [showQuiz, authorizedOnly, normalLayout]
  },
  {
    path: '/chat/:chatId',
    component: Chat,
    name: 'Chat',
    beforeEnter: [isSocketConnected, fixedLayout]
  },
  {
    path: '/chat/:chatId/attachments',
    component: ChatAttachments,
    name: 'ChatAttachments',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/@:nickname',
    component: CreatorView,
    name: 'CreatorView',
    beforeEnter: [isSocketConnected, normalLayout, beforeCreator]
  },
  {
    path: '/:postId',
    component: Post,
    name: 'Post',
    beforeEnter: [isSocketConnected, fixedLayout]
  },
  {
    path: '/details',
    component: Details,
    name: 'Details',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/your-interests',
    component: YourInterestsView,
    name: 'YourInterests',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/photo-upload',
    component: PhotoUpload,
    name: 'PhotoUpload',
    beforeEnter: [authorizedOnly, normalLayout]
  },
  {
    path: '/payments',
    component: Payments,
    name: 'Payments',
    beforeEnter: [showQuiz, authorizedOnly, normalLayout]
  },
  {
    path: '/events',
    component: EventsView,
    name: 'Events',
    beforeEnter: [authorizedOnly, normalLayout]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  app.$addEvent([`Enter ${to.name} page`])

  const appStore = useAppStore()

  // To activate secret state
  if (to.query?.link || to.query?.lnk) {
    appStore.secretToken = to.query?.link || to.query?.lnk

    if (to.query?.lnk) {
      appStore.altSecretState = true
    }

    if (process.env.NODE_ENV === 'production') {
      const event = to.query?.link ? 'link' : 'lnk'
      const eventLabel = to.query?.link ? 'Secret link' : 'Secret lnk'

      // eslint-disable-next-line no-undef
      gtag('event', event, {
        event_category: 'url',
        event_label: eventLabel
      })
    }
  }

  const authStore = useAuthStore()

  if (to.query.signin_token) {
    authStore.signinToken = to.query.signin_token
  }

  if (to.query.open_disable_email_notifications_popup === '1') {
    appStore.openDisableEmailNotificationsPopup = true
  }

  // Save original href for future redirect to main domain
  if (!authStore.hrefOrigin) {
    authStore.hrefOrigin = window.location.href
  }

  // See file "Сегмент пути.md" in docs
  if (to.fullPath.includes('ts:')) {
    const fullPath = to.fullPath + '/'
    const tsMatch = fullPath.match(/\/(ts:.*?)\//i)

    if (tsMatch !== null) {
      const value = tsMatch[1].split(':')
      authStore.trackingQuery = helpers.prepareTrackingQuery(to.query)
      // Save ts value as trafficSourceId
      authStore.trackingQuery.trafficSourceId = value[1]

      const cleanUrl = to.fullPath.replace('/' + tsMatch[1], '') + '/'
      next(cleanUrl)
      return
    }
  }

  app.invites.clearTimeouts()

  // Save first page user visited before registration
  if (!authStore.isTokenExist && !authStore.landingPath.path && to.path && to.name) {
    authStore.landingPath = {
      path: to.path + '',
      name: to.name + ''
    }
  }

  // Rewrite landing path if there is lnk query
  if (!authStore.isTokenExist && to.path && to.name && to.query?.lnk) {
    authStore.landingPath = {
      path: to.path + '',
      name: to.name + ''
    }
  }

  //
  // Query params handling
  //
  if (to.name === 'Page404' || Object.keys(to.query).length === 0) {
    next()
    return
  }

  if (to.query.signup === 'true') {
    if (!authStore.isTokenExist) {
      authStore.signupIsVisible = true
    }
  }

  // Save tracking query params from URL to store
  authStore.trackingQuery = helpers.prepareTrackingQuery(to.query)

  next()
})

export default router

