<template>
  <span
    v-show="count > 0"
    :class="{ 'is-big': isCountBig }"
    class="circle bg-primary">{{ count }}</span>
</template>

<script>

export default {
  name: 'CounterBadge',
  components: {},
  props: {
    count: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  computed: {
    isCountBig () {
      return this.count > 99
    }
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .circle {
    min-width: 20px;
    width: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 12px;
  }
  .circle.is-big {
    min-width: 30px;
    width: 30px;
    line-height: 30px;
  }
</style>
