<template>
  <div>
    <TransitionRoot
      as="template"
      :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40"
        @close="sidebarOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
          <DialogOverlay
            v-touch:swipe.left="swipeHandler"
            class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
        </TransitionChild>

        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full">
          <div
            v-touch:swipe.left="swipeHandler"
            class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <div class="flex-1 h-0 overflow-y-auto">
              <slot
                name="sidebarContent"
                :sidebar-open="sidebarOpen"
                :toggle-sidebar="toggleSidebar"/>
            </div>
          </div>
        </TransitionChild>

        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="h-full">
      <slot
        :sidebar-open="sidebarOpen"
        :toggle-sidebar="toggleSidebar"/>
    </div>
  </div>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  name: 'Sidebar',
  components: {
    Dialog, DialogOverlay, TransitionChild, TransitionRoot
  },
  data () {
    return {
      sidebarOpen: false
    }
  },
  computed: {},
  methods: {
    toggleSidebar () {
      this.sidebarOpen = !this.sidebarOpen
    },
    swipeHandler () {
      this.sidebarOpen = false
    }
  }
}
</script>

<style scoped>
  .app-height {
    height: var(--app-height);
  }
</style>
