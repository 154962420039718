<template>
  <form
    class="flex items-center"
    @submit.prevent="sendMessage">
    <FormValidationTooltip
      class="w-full"
      :content="inputError">
      <textarea
        id="chat_input"
        ref="textForm"
        type="text"
        :class="{
          'form-input-post post-shadow placeholder-gray-300': type === 'post',
          'w-full': isActive && type === 'post',
          'w-3/5': !isActive && type === 'post'
        }"
        rows="1"
        class="min-h-[40px] h-full form-input rounded-3xl text-base py-2 mr-1 transition-width duration-500 ease-in-out hide-scrollbar resize-none"
        style="-webkit-transform: translateZ(0);"
        :placeholder="placeholder"
        aria-label="Enter chat message"
        aria-describedby="send-button"
        :disabled="chatUser.deleted"
        @keydown="onKeyDown"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @input="onInput($event); message = $event.target.value"/>
    </FormValidationTooltip>

    <ChatEmoji
      v-if="type === 'chat'"
      class="hidden sm:block"/>

    <button
      v-if="!options.tipsEnabled || messageToSend"
      type="submit"
      :disabled="!messageToSend || isFormError"
      :class="[
        type === 'chat' ? 'text-primary' : 'text-gray-300',
        showSendButton ? 'visible' : 'invisible'
      ]"
      class="btn p-1 pr-0">
      <svg
        v-if="type === 'post'"
        width="28"
        height="26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="m20.2 12.5.075.997a1 1 0 0 0-.009-1.995l-.066.998zm-14.338-.949-.066.998.066-.998zm-1.67 12.314.412.911-.412-.91zM25.503 10.92l-.412.911.412-.91zm0 3.289-.412-.911.412.91zm.413-4.2L4.604.352 3.78 2.174l21.312 9.657.825-1.822zM.816 3.882l2.535 6.842 1.875-.694-2.534-6.843-1.876.695zm4.98 8.667 14.338.949.132-1.996-14.338-.949-.132 1.996zm14.33-1.046-14.35 1.069.149 1.994 14.35-1.069-.15-1.994zM3.354 14.395.816 21.247l1.876.695 2.538-6.853-1.876-.694zm1.25 10.381 21.312-9.657-.825-1.821-21.312 9.657.825 1.821zm1.172-12.204a2.805 2.805 0 0 0-2.422 1.823l1.876.694c.11-.295.38-.5.695-.523l-.149-1.995zm-2.425-1.848a2.805 2.805 0 0 0 2.445 1.825l.132-1.996a.805.805 0 0 1-.702-.523l-1.875.694zM4.604.352C2.334-.676-.049 1.545.816 3.882l1.876-.695a.805.805 0 0 1 1.087-1.013L4.604.352zM.816 21.247c-.865 2.337 1.518 4.558 3.788 3.53l-.825-1.822a.805.805 0 0 1-1.087-1.013l-1.876-.695zm24.275-9.416a.805.805 0 0 1 0 1.467l.825 1.821c2.197-.995 2.197-4.114 0-5.11l-.825 1.822z"
          fill="#fff"/>
      </svg>

      <svg
        v-else
        width="26"
        height="24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.653 13.209c1.414-.64 1.414-2.648 0-3.289L3.341.263C1.881-.399.347 1.031.904 2.534L3.436 9.37c.248.671.87 1.132 1.583 1.175l16.82 1.02-16.82 1.019a1.805 1.805 0 0 0-1.583 1.174L.904 20.594c-.557 1.504.977 2.933 2.437 2.271l21.312-9.656z"/>
      </svg>
    </button>

    <TipsModal
      v-else-if="!chatUser?.deleted && chatUser?.username && !isPremiumSupportUser"
      class="w-[32px]"
      :user="chatUser"/>
  </form>
</template>

<script>

import ChatEmoji from '@/components/Chat/ChatForm/ChatEmoji'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import helpers from '@/helpers'
import FormValidationTooltip from '@/components/Functional/FormValidationTooltip'
import TipsModal from '@/components/TipsModal'
import { onCodeDown, cmd, ctrl, code_Enter } from 'on-hotkey'

let unsubscribeCodeDown = null

export default {
  name: 'ChatTextForm',
  components: {
    ChatEmoji,
    FormValidationTooltip,
    TipsModal
  },
  props: {
    type: {
      type: String,
      default: 'chat'
    },
    userId: {
      type: String,
      default: ''
    }
  },
  emits: ['sent', 'update:is-focused'],
  data () {
    return {
      isFocused: false,
      inputError: '',
      textAreaHeight: 0
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'options'
    ]),
    ...mapWritableState(useChatStore, [
      'chatId',
      'messageToSend'
    ]),
    ...mapState(useChatStore, [
      'chatUser'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'chatPriceEnabled',
      'rates'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    placeholder () {
      if (this.premiumSupportAgentUserId.toString() === this.chatUser.userId) {
        return this.$t('chat.message.send_a_message')
      }

      if (this.type === 'chat') {
        if (this.chatPriceEnabled) {
          const cost = this.rates.costOfflineChat || this.rates.costOnlineChat

          return this.$t('chat.credit_per_message', { const: cost })
        }

        return this.$t('chat.message.send_a_message_with_price')
      } else {
        return this.$t('chat.message.message_me')
      }
    },
    message: {
      get () {
        return this.messageToSend
      },
      set (value) {
        this.messageToSend = value
      }
    },
    showSendButton () {
      if (this.type === 'chat') return true

      return this.isFocused || this.messageToSend
    },
    isActive () {
      return this.isFocused || this.messageToSend
    },
    isFormError () {
      return this.inputError.length > 0
    }
  },
  watch: {
    // Hack for iOS, issue with dynamic updating data
    message (value, oldValue) {
      this.$refs.textForm.value = value

      this.inputError = value.length > 300 ? this.$t('chat.message.at_most_300_characters') : ''

      if (oldValue && !value) {
        this.$refs.textForm.style.height = 'auto'
      }
    }
  },
  beforeMount () {},
  async mounted () {
    this.messageToSend = ''

    if (this.type === 'post' && this.userId) {
      this.chatId = this.userId
    }

    unsubscribeCodeDown = onCodeDown({
      keys: [{
        key: code_Enter,
        mods: [cmd]
      }, {
        key: code_Enter,
        mods: [ctrl]
      }]
    }, () => {
      this.sendMessage()
    })
  },
  unmounted () {
    unsubscribeCodeDown()
  },
  methods: {
    ...mapActions(useChatStore, [
      'messageSend'
    ]),
    async sendMessage () {
      if (this.isAuthenticated) {
        if (this.messageToSend) {
          this.messageSend()

          if (this.type === 'post') {
            this.$refs.textForm.blur()
          }

          this.$emit('sent')
          this.$refs.textForm.style.height = 'auto'
        }
      } else {
        helpers.showSignUp()
      }
    },
    onKeyDown (event) {
      // Get the code of pressed key
      // const keyCode = event.which || event.keyCode
      //
      // if (keyCode === 13 && !event.shiftKey) {
      //   // Don't generate a new line
      //   event.preventDefault()
      //   this.sendMessage()
      // }
    },
    onInput (event) {
      const element = this.$refs.textForm
      const offset = element.offsetHeight - element.clientHeight

      event.target.style.height = 'auto'
      event.target.style.height = event.target.scrollHeight + offset + 'px'
    }
  }
}
</script>

<style scoped>
  .form-input-post {
    caret-color: #0A84FF;
    @apply bg-transparent border-gray-300 focus:border-gray-300 focus:ring-opacity-0 text-white;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .resize-none {
    resize: none;
  }
</style>
