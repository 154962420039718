<template>
  <div class="mt-1 relative mx-0.5">
    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <SearchIcon/>
    </div>

    <input
      id="search"
      v-model="text"
      type="text"
      name="search"
      class="pl-10 form-input rounded-full text-sm py-2"
      :placeholder="placeholder">
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg-vue/SearchIcon'
import { debounce } from 'lodash'

export default {
  name: 'SearchInput',
  components: {
    SearchIcon
  },
  props: {
    placeholder: {
      type: String,
      default: 'Search'
    }
  },
  emits: ['update:text-changed'],
  data () {
    return {
      text: ''
    }
  },
  computed: {},
  watch: {
    text (value, oldValue) {
      this.debouncedSearch()
    }
  },
  mounted () {
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.$emit('update:text-changed', this.text)
    }, 300),
    clearInput () {
      this.text = ''
    }
  }
}
</script>

