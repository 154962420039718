/**
 * This script contains:
 * - Logger definition & global instance creation. Logger collects log entries
 *   from JavaScript and sends them to the server.
 * - Error reporting setup. Listeners for `error` and `unhandledrejection` events
 *   on the `window` object are added to report uncaught errors to the server via
 *   the logger.
 *
 * Usage:
 * - This script must be included synchronously into a webpage before any other
 *   scripts.
 * - After this script is executed, `window.logger` variable will contain global
 *   instance of the logger which can be used to submit log entries to the server.
 *   Methods `error` and `info` are available on the logger instance to submit log
 *   entries with corresponding log levels. Both methods accept one string parameter
 *   containing the message to log.
 *
 * IMPORTANT: This script is intentionally written in ES5 for maximum compatibility.
 * Do not use ES2015+ features unless this script is transpiled before including into
 * a webpage.
 */
export default {
  install: (app) => {
    function createLogger (url) {
      function Logger (url) {
        this.url = url
      }

      Logger.prototype.log = function (level, message) {
        const handleError = function (error) {
          console.error('An error occurred while sending log entry to the server: ', error)
        }

        try {
          const xhr = new XMLHttpRequest()

          xhr.open('POST', this.url, true)
          xhr.setRequestHeader('Content-Type', 'application/json')

          xhr.addEventListener('load', function () {
            if (String(this.status).charAt(0) === '2') {
              return
            }

            handleError(new Error(
              'Error response received.\n' +
              'HTTP status code: ' + this.status + '\n' +
              'Response body: ' + this.responseText
            ))
          })

          xhr.addEventListener('error', function () {
            handleError(new Error('Request error'))
          })

          xhr.addEventListener('timeout', function () {
            handleError(new Error('Request timeout'))
          })

          xhr.send(JSON.stringify({ level: level, message: message }))
        } catch (error) {
          handleError(error)
        }
      }

      Logger.prototype.error = function (message) {
        this.log('error', message)
      }

      Logger.prototype.info = function (message) {
        this.log('info', message)
      }

      return new Logger(url)
    }

    function setUpErrorReporting (logger) {
      function stringify (value) {
        return JSON.stringify(value, null, 4)
      }

      window.addEventListener('error', function (e) {
        const eventStr = (() => {
          switch (true) {
            case e instanceof ErrorEvent:
              return stringify({
                message: e.message,
                file: e.filename,
                line: e.lineno,
                column: e.colno,
                error: e.error instanceof Error
                  ? {
                      name: e.error.name || 'N/A',
                      message: e.error.message || 'N/A',
                      stack: e.error.stack || 'N/A'
                    }
                  : 'N/A'
              })
            case e instanceof Event:
              return 'Event of unexpected type (' + (e.type || 'N/A') + ')'
            default:
              return 'Event of unexpected format. Listener call arguments: ' +
                stringify(arguments)
          }
        })()

        logger.error('`error` event received in global scope. Event: ' + eventStr)
      })

      window.addEventListener('unhandledrejection', function (e) {
        logger.error(
          '`unhandledrejection` event received in global scope. Event: ' +
          stringify({
            reason: e.reason instanceof Error
              ? {
                  message: e.reason.message,
                  stack: e.reason.stack
                }
              : e.reason
          })
        )
      })
    }

    const logger = createLogger(process.env.VUE_APP_LOGGER_URL)

    setUpErrorReporting(logger)

    if (window.logger === undefined) {
      window.logger = logger
    } else {
      console.error('window.logger is already defined')
    }

    app.config.globalProperties.$logger = logger
    app.$logger = app.config.globalProperties.invites
  }
}
