import * as yup from 'yup'

export default {
  install: (app) => {
    yup.setLocale({
      mixed: {
        default: ({ path }) => ({ key: 'yup.mixed.default', values: { path } }),
        required: ({ path }) => ({ key: 'yup.mixed.required', values: { path } }),
        notOneOf: ({ values }) => ({ key: 'yup.mixed.notOneOf', values: { values } })
      },
      string: {
        email: ({ path }) => ({ key: 'yup.string.email', values: { path } }),
        min: ({ min }) => ({ key: 'yup.string.min', values: { min } })
      },
      number: {
        default: ({ path }) => ({ key: 'yup.number.default', values: { path } }),
        integer: ({ path }) => ({ key: 'yup.number.integer', values: { path } }),
        min: ({ min }) => ({ key: 'yup.number.min', values: { min } }),
        max: ({ max }) => ({ key: 'yup.number.max', values: { max } })
      }
    })

    app.config.globalProperties.$yup = yup
    app.$yup = app.config.globalProperties.$yup
  }
}
