<!--suppress JSUnusedLocalSymbols -->
<template>
  <div class="w-full h-full">
    <video
      ref="video"
      loop
      playsinline
      :class="[maximizeVideo ? 'object-cover' : 'object-contain']"
      class="h-full w-full"
      webkit-playsinline="true"/>
  </div>
</template>

<script>
import Plyr from 'plyr'
import Hls from 'hls.js'

let hls = null

export default {
  name: 'VideoNext',
  components: {},
  props: {
    src: {
      type: String,
      default: '',
      required: true
    },
    isPartlyAvailable: {
      type: Boolean,
      default: false,
      required: false
    },
    isRawVideo: {
      type: Boolean,
      default: false
    },
    maximizeVideo: {
      type: Boolean,
      default: true
    },
    playAllowed: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'paused',
    'update:current-time',
    'update:buffer-progress',
    'ended',
    'playing',
    'waiting',
    'canplay'
  ],
  data () {
    return {
      isLoading: true,
      player: null,
      isAutoPlayPrevented: false,
      isPaused: false
    }
  },
  computed: {},
  watch: {
    src (value) {
      if (value) {
        if (!this.playAllowed) {
          return
        }

        this.$emit('update:buffer-progress', 0)
        this.$emit('update:current-time', 0)
        this.changeSrc(value)
      }
    },
    isPartlyAvailable (value) {
      if (!this.player) return
      this.player.loop = value !== true

      console.log('isPartlyAvailable watch. Current loop:', this.player.loop)
    }
  },
  async beforeUnmount () {
    removeEventListener('visibilitychange', this.onVisibilityChange)

    if (this.player) this.player.destroy()
  },
  created () {
    addEventListener('visibilitychange', this.onVisibilityChange)
  },
  async mounted () {
    const video = this.$refs.video
    await this.$nextTick()

    this.player = new Plyr(video, {
      controls: [],
      clickToPlay: false,
      fullscreen: { enabled: false, fallback: false, iosNative: false, container: null }
    })

    if (this.player && this.isPartlyAvailable) {
      this.player.loop = false
    }

    if (this.src) {
      video.src = this.src
    }

    this.player.on('loadeddata', event => {
      this.isLoading = false
    })

    this.player.on('waiting', event => {
      this.$emit('waiting')
    })

    this.player.on('canplay', event => {
      this.$emit('canplay')
    })

    // Progress downloading the media
    this.player.on('progress', event => {
      this.$emit('update:buffer-progress', this.player.buffered)
    })

    this.player.on('ended', event => {
      this.$emit('ended', this.player.currentTime)
    })

    this.player.on('play', event => {
      this.$emit('paused', false)
    })

    this.player.on('playing', event => {
      this.$emit('playing')

      this.$emit('update:current-time', this.player.currentTime)
      if (this.player && this.player?.paused) {
        this.$emit('paused', this.player?.paused)
      }
      this.isAutoPlayPrevented = false
      this.isPaused = false
    })

    this.player.on('pause', event => {
      this.$emit('update:current-time', this.player.currentTime)
      if (this.player && this.player?.paused) {
        this.$emit('paused', this.player?.paused)
      }
      this.isPaused = true
    })

    this.player.on('ready', event => {
      const playPromise = this.player.play()

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.isAutoPlayPrevented = false
            if (this.player && this.player?.paused) {
              this.$emit('paused', this.player?.paused)
            }
            console.log('Automatic playback started!')
          })
          .catch(() => {
            this.isAutoPlayPrevented = true
            this.isLoading = false
            // console.log('Auto-play was prevented')
            // await this.player.play()
          })
      }

      const source = video.src

      if (!Hls.isSupported() || this.isRawVideo) {
        video.src = source
      }
      else {
        hls = new Hls({})
        hls.loadSource(source)
        hls.attachMedia(video)
        window.hls = hls
      }
    })
  },
  methods: {
    // Stop video if user changed tab
    onVisibilityChange () {
      if (this.player && this.src.length) {
        if (!this.isAutoPlayPrevented) {
          document.hidden ? this.pauseVideo() : this.playVideo()
        }
        else if (document.hidden) {
          this.pauseVideo()
        }
      }
    },
    async toggleVideo () {
      if (this.player.playing) {
        await this.pauseVideo()
      }
      else {
        await this.playVideo()
      }
    },
    async playVideo () {
      if (this.player && this.player?.paused && !this.player?.playing && this.src.length) {
        await this.player.play()
      }
    },
    async pauseVideo () {
      if (this.player && !this.player?.paused && this.player?.playing && this.src.length) {
        console.log('should pause')
        await this.player?.pause()
      }
    },
    async changeSrc (src) {
      // Set timeout for "Cannot read property 'loadSource' of null" bug
      setTimeout(async () => {
        await this.pauseVideo()

        const video = this.$refs.video
        video.src = src

        if (!Hls.isSupported() || this.isRawVideo) {
          video.src = src
        }
        else {
          hls.loadSource(src)
          hls.attachMedia(video)
        }

        await this.playVideo().catch((error) => {
          console.log(error)
        })
      }, 100)
    }
  }
}
</script>

<!--
Object-fit: cover - is not working with video tags without border-radius
https://stackoverflow.com/questions/43693558/object-fit-cover-is-not-working-with-video-tags-without-border-radius
-->
<style>
  .plyr {
    height: 100% !important;
    --plyr-video-background: transparent;
    /*background: transparent !important;*/
  }

  .plyr__video-wrapper {
    height: 100%;
  }
</style>
