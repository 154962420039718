<template>
  <Header
    name="Sign in"
    class="mb-6"/>

  <div class="container pb-4">
    <h2 class="h1 mb-6">
      {{ $t("common.login") }}
    </h2>

    <LoginForm :show-heading="false"/>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'
import Header from '@/components/Functional/Header'

export default {
  name: 'LogIn',
  components: {
    LoginForm,
    Header
  },
  data () {
    return {}
  },
  computed: {},
  created () {},
  methods: {}
}
</script>

