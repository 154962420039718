<script setup>
  function reloadPage () {
    window.location.reload()
  }
</script>

<template>
  <div class="container h-full flex flex-col items-center justify-between">
    <div/>

    <div class="text-center ">
      <img
        src="@/assets/images/emoji/sleepy-face.png"
        class="mb-6 w-[90px] mx-auto"
        alt="Sleepy face emoji">

      <h2 class="mb-2">
        {{ $t('service.we_will_be_right_back') }}
      </h2>

      <p class="text-center max-w-[300px] mx-auto leading-6 whitespace-pre-line">
        {{ $t('service.is_temporarily_unavailable') }}
      </p>
    </div>

    <button
      class="btn btn-primary btn-block mb-8 py-3"
      @click="reloadPage">
      {{ $t('service.reload') }}
    </button>
  </div>
</template>
