<script setup>
  import { onBeforeMount, ref } from 'vue'
  import { useUserStore } from '@/stores/user.js'

  const emit = defineEmits(['close'])
  const userStore = useUserStore()

  const emailNotificationsEnabled = ref(null)

  async function toggleEmailNotificationsEnabled () {
    await userStore.meSave({
      emailNotificationsEnabled: !emailNotificationsEnabled.value
    })

    emit('close')
  }

  onBeforeMount(async () => {
    const data = await userStore.meGet()

    emailNotificationsEnabled.value = data.emailNotificationsEnabled
  })
</script>

<template>
  <div>
    <template v-if="emailNotificationsEnabled">
      <div class="flex items-start justify-between mb-2">
        <h2 class="h2">
          Do you want to unsubscribe from email notifications?
        </h2>

        <button
          class="btn p-1"
          @click="$emit('close')">
          <InlineSvg
            class="h-4 w-4"
            :src="require('@/assets/svg/design/cross.svg')"/>
        </button>
      </div>

      <p class="mb-5">
        {{ $t('modals.delete_account.description') }}
      </p>

      <button
        type="button"
        class="btn btn-primary btn-block mb-3"
        @click="toggleEmailNotificationsEnabled">
        {{ $t('common.yes') }}
      </button>

      <button
        type="button"
        class="btn btn-secondary btn-block"
        @click="$emit('close')">
        {{ $t('common.no') }}
      </button>
    </template>

    <template v-else>
      <div class="flex items-start justify-between mb-8">
        <h2 class="h2">
          Turn on email notifications and stay updated!
        </h2>

        <button
          class="btn p-1"
          @click="$emit('close')">
          <InlineSvg
            class="h-4 w-4"
            :src="require('@/assets/svg/design/cross.svg')"/>
        </button>
      </div>

      <button
        type="button"
        class="btn btn-primary btn-block mb-3"
        @click="toggleEmailNotificationsEnabled">
        Enable
      </button>

      <button
        type="button"
        class="btn btn-secondary btn-block"
        @click="$emit('close')">
        Close
      </button>
    </template>
  </div>
</template>
