<template>
  <div class="inline-block relative">
    <slot :set-loaded="setLoaded"/>

    <LightSpinner
      v-show="!isLoaded"
      :class="[spinnerClass.length ? spinnerClass : 'h-5 w-5 bg-gray-300']"
      class="absolute inset-0 inline-block dark:bg-gray-500"/>
  </div>
</template>

<script>

import LightSpinner from '@/components/Functional/LightSpinner'

export default {
  name: 'ImageLoader',
  components: {
    LightSpinner
  },
  props: {
    spinnerClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoaded: false
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    setLoaded () {
      this.isLoaded = true
    }
  }
}
</script>

