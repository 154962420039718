<script setup>
  import { useUserStore } from '@/stores/user'
  import { storeToRefs } from 'pinia'
  import { computed, watch } from 'vue'
  import { useRouter } from 'vue-router'

  const userStore = useUserStore()
  const { user, profileCompletionPercentage } = storeToRefs(userStore)
  const router = useRouter()
  
  const completionPercentages = new Map([
    [0, 75],
    [1, 85],
    [2, 95],
    [3, 100]
  ])

  const completionPercentage = computed(() => {
    const about = user.value?.about
    const interests = user.value?.interests
    const photos = user.value?.photos?.items.length

    const array = [about, interests, photos]
    const count = array.filter(value => !!value).length

    return completionPercentages.get(count)
  })

  watch(completionPercentage, (value) => {
    if (value) {
      profileCompletionPercentage.value = value
      console.log(profileCompletionPercentage.value)
    }
  }, { immediate: true })
  
  function goTo () {
    const about = user.value?.about
    const interests = user.value?.interests
    const photos = user.value?.photos?.items.length

    if (!about || !interests) {
      router.push('/details?r=me')
    } else if (!photos) {
      router.push('/photo-upload?r=me')
    } else {
      router.push('/')
    }
  }
</script>

<template>
  <a
    v-if="completionPercentage !== 100"
    class="flex items-center justify-between hover:text-link cursor-pointer"
    @click="goTo">
    <div class="flex items-center">
      <div class="w-7">
        <InlineSvg
          class="w-4 h-4 text-[#86878B]"
          :src="require('@/assets/svg/solid/circle-three-quarters-stroke.svg')"/>
      </div>

      <div class="flex-1">
        {{ $t('user.me.your_profile_is_complete', { percentage: completionPercentage }) }}
      </div>
    </div>

    <InlineSvg :src="require('@/assets/svg/design/arrow-right.svg')"/>
  </a>
</template>