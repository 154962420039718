import dayjs from 'dayjs'
import 'dayjs/locale/ar'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/tr'

import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'

dayjs.extend(localizedFormat)
dayjs.extend(isToday)

export default {
  install: (app) => {
    app.config.globalProperties.$dayjs = dayjs
    app.$dayjs = app.config.globalProperties.$dayjs
  }
}
