<template>
  <div class="full-height">
    <Header :name="$t('auth.details.about_me')"/>

    <div class="container pt-5 pb-5 h-full">
      <h1 class="mb-5">
        {{ $t("auth.details.your_interests") }}
      </h1>

      <YourInterests/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import YourInterests from '@/components/Details/YourInterests'

export default {
  name: 'YourInterestsView',
  components: {
    Header,
    YourInterests
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

