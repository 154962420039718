export default {
  data () {
    return {
      quizData: new Map([
        [101, {
          emoji: 'wrapped-gift',
          question: {
            number: 11,
            text: this.$t('quiz.question_11')
          },
          answers: [
            {
              number: 1101,
              text: this.$t('quiz.answer_1101')
            },
            {
              number: 1102,
              text: this.$t('quiz.answer_1102')
            },
            {
              number: 1103,
              text: this.$t('quiz.answer_1103')
            }
          ]
        }],
        // Like in white preland:
        // - Add "Exclusive content"
        // - Remove "Hot content" and "Exclusive Tiktoks" answers (1202, 1204)
        [102, {
          emoji: 'camera-with-flash',
          question: {
            number: 12,
            text: this.$t('quiz.question_12')
          },
          answers: [
            {
              number: 1201,
              text: this.$t('quiz.answer_1201')
            },
            {
              number: 1203,
              text: this.$t('quiz.answer_1203')
            },
            {
              number: 1602,
              text: this.$t('quiz.answer_1602')
            }
          ]
        }],
        [103, {
          emoji: 'speech-balloon',
          question: {
            number: 13,
            text: this.$t('quiz.question_13')
          },
          answers: [
            {
              number: 1301,
              text: this.$t('quiz.answer_1301')
            },
            {
              number: 1302,
              text: this.$t('quiz.answer_1302')
            },
            {
              number: 1303,
              text: this.$t('quiz.answer_1303')
            },
            {
              number: 1304,
              text: this.$t('quiz.answer_1304')
            }
          ]
        }],
        [104, {
          emoji: 'woman-and-man-holding-hands',
          question: {
            number: 14,
            text: this.$t('quiz.question_14')
          },
          answers: [
            {
              number: 1401,
              text: this.$t('quiz.answer_1401')
            },
            {
              number: 1402,
              text: this.$t('quiz.answer_1402')
            },
            {
              number: 1403,
              text: this.$t('quiz.answer_1403')
            }
          ]
        }],
        [105, {
          emoji: 'wrapped-gift',
          question: {
            number: 15,
            text: this.$t('quiz.question_15')
          },
          answers: [
            {
              number: 1501,
              text: this.$t('quiz.answer_1501')
            },
            {
              number: 1502,
              text: this.$t('quiz.answer_1502')
            },
            {
              number: 1503,
              text: this.$t('quiz.answer_1503')
            }
          ]
        }],
        [1101, {
          emoji: 'speech-balloon',
          question: {
            number: 111,
            text: this.$t('quiz.question_111')
          },
          answers: [
            {
              number: 11101,
              text: this.$t('quiz.answer_11101')
            },
            {
              number: 11102,
              text: this.$t('quiz.answer_11102')
            }
          ]
        }],
        [1102, {
          emoji: 'camera-with-flash',
          question: {
            number: 112,
            text: this.$t('quiz.question_112')
          },
          answers: [
            {
              number: 11201,
              text: this.$t('quiz.answer_11201')
            },
            {
              number: 11202,
              text: this.$t('quiz.answer_11202')
            },
            {
              number: 11203,
              text: this.$t('quiz.answer_11203')
            }
          ]
        }],
        [1103, {
          emoji: 'speech-balloon',
          question: {
            number: 113,
            text: this.$t('quiz.question_113')
          },
          answers: [
            {
              number: 11301,
              text: this.$t('quiz.answer_11301')
            },
            {
              number: 11302,
              text: this.$t('quiz.answer_11302')
            }
          ]
        }],
        [1201, {
          emoji: 'sun',
          question: {
            number: 121,
            text: this.$t('quiz.question_121')
          },
          answers: [
            {
              number: 12101,
              text: this.$t('quiz.answer_12101')
            },
            {
              number: 12102,
              text: this.$t('quiz.answer_12102')
            },
            {
              number: 12103,
              text: this.$t('quiz.answer_12103')
            }
          ]
        }],
        [1202, {
          emoji: 'fire',
          question: {
            number: 122,
            text: this.$t('quiz.question_122')
          },
          answers: [
            {
              number: 12201,
              text: this.$t('quiz.answer_12201')
            },
            {
              number: 12202,
              text: this.$t('quiz.answer_12202')
            },
            {
              number: 12203,
              text: this.$t('quiz.answer_12203')
            }
          ]
        }],
        [1203, {
          emoji: 'woman-elf',
          question: {
            number: 123,
            text: this.$t('quiz.question_123')
          },
          answers: [
            {
              number: 12301,
              text: this.$t('quiz.answer_12301')
            },
            {
              number: 12302,
              text: this.$t('quiz.answer_12302')
            }
          ]
        }],
        [1204, {
          emoji: 'woman-dancing',
          question: {
            number: 124,
            text: this.$t('quiz.question_124')
          },
          answers: [
            {
              number: 12401,
              text: this.$t('quiz.answer_12401')
            },
            {
              number: 12402,
              text: this.$t('quiz.answer_12402')
            }
          ]
        }],
        [1301, {
          emoji: 'woman-and-man-holding-hands',
          question: {
            number: 131,
            text: this.$t('quiz.question_131')
          },
          answers: [
            {
              number: 13101,
              text: this.$t('quiz.answer_13101')
            },
            {
              number: 13102,
              text: this.$t('quiz.answer_13102')
            },
            {
              number: 13103,
              text: this.$t('quiz.answer_13103')
            },
            {
              number: 13104,
              text: this.$t('quiz.answer_13104')
            }
          ]
        }],
        [1302, {
          emoji: 'revolving-hearts',
          question: {
            number: 132,
            text: this.$t('quiz.question_132')
          },
          answers: [
            {
              number: 13201,
              text: this.$t('quiz.answer_13201')
            },
            {
              number: 13202,
              text: this.$t('quiz.answer_13202')
            }
          ]
        }],
        [1303, {
          emoji: 'thinking-face',
          question: {
            number: 133,
            text: this.$t('quiz.question_133')
          },
          answers: [
            {
              number: 13301,
              text: this.$t('quiz.answer_13301')
            },
            {
              number: 13302,
              text: this.$t('quiz.answer_13302')
            },
            {
              number: 13303,
              text: this.$t('quiz.answer_13303')
            },
            {
              number: 13304,
              text: this.$t('quiz.answer_13304')
            }
          ]
        }],
        [1304, {
          emoji: 'hugging-face',
          question: {
            number: 134,
            text: this.$t('quiz.question_134')
          },
          answers: [
            {
              number: 13401,
              text: this.$t('quiz.answer_13401')
            },
            {
              number: 13402,
              text: this.$t('quiz.answer_13402')
            },
            {
              number: 13403,
              text: this.$t('quiz.answer_13403')
            }
          ]
        }],
        [1401, {
          emoji: 'cityscape',
          question: {
            number: 141,
            text: this.$t('quiz.question_141')
          },
          answers: [
            {
              number: 14101,
              text: this.$t('quiz.answer_14101')
            },
            {
              number: 14102,
              text: this.$t('quiz.answer_14102')
            }
          ]
        }],
        [1402, {
          emoji: 'partying-face',
          question: {
            number: 142,
            text: this.$t('quiz.question_142')
          },
          answers: [
            {
              number: 14201,
              text: this.$t('quiz.answer_14201')
            },
            {
              number: 14202,
              text: this.$t('quiz.answer_14202')
            }
          ]
        }],
        [1403, {
          emoji: 'airplane',
          question: {
            number: 143,
            text: this.$t('quiz.question_143')
          },
          answers: [
            {
              number: 14301,
              text: this.$t('quiz.answer_14301')
            },
            {
              number: 14302,
              text: this.$t('quiz.answer_14302')
            }
          ]
        }],
        [1501, {
          emoji: 'camera-with-flash',
          question: {
            number: 151,
            text: this.$t('quiz.question_151')
          },
          answers: [
            {
              number: 15101,
              text: this.$t('quiz.answer_15101')
            },
            {
              number: 15102,
              text: this.$t('quiz.answer_15102')
            },
            {
              number: 15103,
              text: this.$t('quiz.answer_15103')
            }
          ]
        }],
        [1502, {
          emoji: 'camera-with-flash',
          question: {
            number: 152,
            text: this.$t('quiz.question_152')
          },
          answers: [
            {
              number: 15201,
              text: this.$t('quiz.answer_15201')
            },
            {
              number: 15202,
              text: this.$t('quiz.answer_15202')
            },
            {
              number: 15203,
              text: this.$t('quiz.answer_15203')
            },
            {
              number: 15204,
              text: this.$t('quiz.answer_15204')
            }
          ]
        }],
        [1503, {
          emoji: 'camera-with-flash',
          question: {
            number: 153,
            text: this.$t('quiz.question_153')
          },
          answers: [
            {
              number: 15301,
              text: this.$t('quiz.answer_15301')
            },
            {
              number: 15302,
              text: this.$t('quiz.answer_15302')
            }
          ]
        }],
        [1602, {
          emoji: 'fire',
          question: {
            number: 622,
            text: this.$t('quiz.answer_12601')
          },
          answers: [
            {
              number: 12601,
              text: this.$t('quiz.answer_12601')
            },
            {
              number: 12602,
              text: this.$t('quiz.answer_12602')
            },
            {
              number: 12603,
              text: this.$t('quiz.answer_12603')
            },
            {
              number: 12604,
              text: this.$t('quiz.answer_12604')
            }
          ]
        }]
      ]),
      // https://www.mindmeister.com/app/map/2703055230
      messageAfterQuiz: new Map([
        [11101, (username) => this.$t('quiz.message_after_quiz_11101', { username: username })],
        [11102, (username) => this.$t('quiz.message_after_quiz_11102', { username: username })],
        [11201, (username) => this.$t('quiz.message_after_quiz_11201', { username: username })],
        [11202, (username) => this.$t('quiz.message_after_quiz_11202', { username: username })],
        [11203, (username) => this.$t('quiz.message_after_quiz_11203', { username: username })],
        [11301, (username) => this.$t('quiz.message_after_quiz_11301', { username: username })],
        [11302, (username) => this.$t('quiz.message_after_quiz_11302', { username: username })],
        [12101, (username) => this.$t('quiz.message_after_quiz_12101', { username: username })],
        [12102, (username) => this.$t('quiz.message_after_quiz_12102', { username: username })],
        [12103, (username) => this.$t('quiz.message_after_quiz_12103', { username: username })],
        [12201, (username) => this.$t('quiz.message_after_quiz_12201', { username: username })],
        [12202, (username) => this.$t('quiz.message_after_quiz_12202', { username: username })],
        [12203, (username) => this.$t('quiz.message_after_quiz_12203', { username: username })],
        [12301, (username) => this.$t('quiz.message_after_quiz_12301', { username: username })],
        [12302, (username) => this.$t('quiz.message_after_quiz_12302', { username: username })],
        [12401, (username) => this.$t('quiz.message_after_quiz_12401', { username: username })],
        [12402, (username) => this.$t('quiz.message_after_quiz_12402', { username: username })],
        [13101, (username) => this.$t('quiz.message_after_quiz_13101', { username: username })],
        [13102, (username) => this.$t('quiz.message_after_quiz_13102', { username: username })],
        [13103, (username) => this.$t('quiz.message_after_quiz_13103', { username: username })],
        [13104, (username) => this.$t('quiz.message_after_quiz_13104', { username: username })],
        [13201, (username) => this.$t('quiz.message_after_quiz_13201', { username: username })],
        [13202, (username) => this.$t('quiz.message_after_quiz_13202', { username: username })],
        [13301, (username) => this.$t('quiz.message_after_quiz_13301', { username: username })],
        [13302, (username) => this.$t('quiz.message_after_quiz_13302', { username: username })],
        [13303, (username) => this.$t('quiz.message_after_quiz_13303', { username: username })],
        [13304, (username) => this.$t('quiz.message_after_quiz_13304', { username: username })],
        [13401, (username) => this.$t('quiz.message_after_quiz_13401', { username: username })],
        [13402, (username) => this.$t('quiz.message_after_quiz_13402', { username: username })],
        [13403, (username) => this.$t('quiz.message_after_quiz_13403', { username: username })],
        [14101, (username) => this.$t('quiz.message_after_quiz_14101', { username: username })],
        [14102, (username) => this.$t('quiz.message_after_quiz_14102', { username: username })],
        [14201, (username) => this.$t('quiz.message_after_quiz_14201', { username: username })],
        [14202, (username) => this.$t('quiz.message_after_quiz_14202', { username: username })],
        [14301, (username) => this.$t('quiz.message_after_quiz_14301', { username: username })],
        [14302, (username) => this.$t('quiz.message_after_quiz_14302', { username: username })],
        [15101, (username) => this.$t('quiz.message_after_quiz_15101', { username: username })],
        [15102, (username) => this.$t('quiz.message_after_quiz_15102', { username: username })],
        [15103, (username) => this.$t('quiz.message_after_quiz_15103', { username: username })],
        [15201, (username) => this.$t('quiz.message_after_quiz_15201', { username: username })],
        [15202, (username) => this.$t('quiz.message_after_quiz_15202', { username: username })],
        [15203, (username) => this.$t('quiz.message_after_quiz_15203', { username: username })],
        [15204, (username) => this.$t('quiz.message_after_quiz_15204', { username: username })],
        [15301, (username) => this.$t('quiz.message_after_quiz_15301', { username: username })],
        [15302, (username) => this.$t('quiz.message_after_quiz_15302', { username: username })],
        [12601, (username) => this.$t('quiz.message_after_quiz_12601', { username: username })],
        [12602, (username) => this.$t('quiz.message_after_quiz_12602', { username: username })],
        [12603, (username) => this.$t('quiz.message_after_quiz_12603', { username: username })],
        [12604, (username) => this.$t('quiz.message_after_quiz_12604', { username: username })],
        // AI quiz
        [16101, (username) => `Hi! I'm ${username}, I want to feel love and warmth. Can you make my day a little more romantic?`],
        [16102, (username) => `Hi, I'm ${username}, I want to have fun. Let's brighten up this day!`],
        [16103, (username) => `Hi, I'm ${username}, I want to find a new friend. Can you help with this?`],
        [16104, (username, creatorName) => `Hi, I'm ${username}. I'm so interested in learning all the secrets you can share. Can you tell me more about ${creatorName}?`],
        [16201, (username, creatorName) => `Hello, I'm ${username} and I want to get a photo for my support. Can you send me a picture of ${creatorName}?`],
        [16202, (username, creatorName) => `Hi! I'm ${username}, I want to get a photo for my support. Can you send me any lifestyle photo of ${creatorName}, please?`],
        [16203, (username, creatorName) => `Hi! I'm ${username} and I want to get an exclusive photo of ${creatorName} for my support. Can you send it to me, please?`],
        [16301, (username) => `Hi, I'm ${username}. I want to get your attention for my support. What can you do for me?`],
        [16302, (username, creatorName) => `Hi, I'm ${username}. I want to get your attention for my support. Can you tell me more about ${creatorName}?`],
        [17101, (username) => `Hi, I'm ${username}. I like lifestyle content and I want to see more of it.`],
        [17102, (username, creatorName) => `Hi, I'm ${username}. I want to learn more about ${creatorName}. What can you show me?`],
        [17103, (username) => `Hi, I'm ${username}. I want to see lifestyle content. Can you show me something?`],
        [17201, (username) => `Hello, I'm ${username}. Can I describe my idea? I want to get custom content.`],
        [17202, (username) => `Hello, I'm ${username}. I want to get custom content but I do not have an idea. Maybe there are some options?`],
        [17301, (username) => `Hi, I'm ${username} and I want to see some content with new clothes. Can you send me something?`],
        [17302, (username) => `Hi, I'm ${username} and I want to see some content with cosplay. Is it possible?`],
        [17303, (username) => `Hi, I'm ${username} and I want to see an exclusive TikTok. Can you send me something?`],
        [17304, (username) => `Hi, I'm ${username} and I want to see bloopers from TikToks. Can you send me something?`],
        [18101, (username) => `Hi! I'm ${username} and I want to chat. Can you talk to me everyday?`],
        [18102, (username, creatorName) => `Hi! I'm ${username} and I want to learn more about ${creatorName}. Can you tell me something?`],
        [18103, (username) => `Hi! I'm ${username} and I want to get rid of loneliness. Can you help me?`],
        [18104, (username, creatorName) => `Hi! I'm ${username} and I want to make friends with ${creatorName}. Is it possible?`],
        [18201, (username, creatorName) => `Hello! I'm ${username} and I want to ask a question about ${creatorName}. Can you answer it?`],
        [18202, (username, creatorName) => `Hello! I'm ${username} and I want to know everything about ${creatorName}. Can you tell me something?`],
        [18301, (username) => `Hello, I'm ${username}. I have some questions for you. Can I ask them?`],
        [18302, (username, creatorName) => `Hello, I'm ${username}. I have a question for you. Can you show me what ${creatorName} is wearing?`],
        [18303, (username, creatorName) => `Hello, I'm ${username}. I want to know more about ${creatorName}. Can you tell me about travelling plans?`],
        [18304, (username) => `Hello, I'm ${username}. I have a question for you. Can I ask it?`],
        [18401, (username) => `Hi, I'm ${username}. I want your care for me. Can you text me every day?`],
        [18402, (username) => `Hi, I'm ${username}. I want your care for me. It would be so nice if you listen to me and be interested in me!`],
        [18403, (username) => `Hi, I'm ${username}. I want your care for me. Will you be my virtual gf`]
      ])
    }
  }
}
