import { defineStore } from 'pinia'
import app from '@/main'
import uniqid from 'uniqid'
import { filter, groupBy, orderBy, uniqBy } from 'lodash'
import dayjs from 'dayjs'
import { Howl } from 'howler'
import { useUserStore } from '@/stores/user'

const newMessageSound = new Howl({
  src: ['/sound/juntos-607.mp3'],
  loop: false,
  volume: 0.8
})

export const useChatStore = defineStore({
  id: 'chat',
  state: () => ({
    chatId: null,
    chatUser: {
      username: null,
      subscribed: null,
      userId: null,
      age: null,
      name: '',
      sex: null,
      online: null,
      location: {
        name: ''
      },
      photos: {
        items: []
      },
      attachmentSupport: {
        photo: null
      },
      videoCallAvailability: null
    },
    language: {
      code: null
    },
    // Messages limit for first load and pagination
    limit: 10,
    messageToSend: '',
    allMessages: {},
    ttlAfterRead: {}, // in how many seconds to delete the message after the receiver reads it
    onlyAttachments: false,
    paginationState: 'loading', // loaded, loading-more, loaded-all
    autoTranslate: false,
    // Uses for showing buy photo modal
    messageToBuy: {},
    chatLoading: true,
    firstLoad: true,
    attachmentSizeUploadError: null // Maximum file size is 200MB
  }),
  getters: {
    currentTTL: (state) => state.ttlAfterRead[state.chatId] || 0,
    attachmentSupport: (state) => state.chatUser.attachmentSupport,
    messages: (state) => state.allMessages[state.chatId],
    // Additional filter: leave chat messages only for current chat, not for another
    filteredMessages: (state) => {
      return filter(state.messages, (item) => {
        const userStore = useUserStore()

        const userId = userStore.userId
        const chatId = state.chatId
        const receiverId = item.receiverId
        const senderId = item.senderId

        return (senderId === chatId && receiverId === userId) || (senderId === userId && receiverId === chatId)
      })
    },
    sortedMessages: (state) => {
      const orderByCreatedMessage = orderBy(state.filteredMessages, ['created'], ['asc'])

      // Prevent case when open two same chats in one browser
      return uniqBy(orderByCreatedMessage, 'messageId')
    },
    sortedMessagesWithAttachmentsOnly: (state) => {
      return filter(state.sortedMessages, (item) => item.type === 3)
    },
    sortedMessagesPostsType: (state) => {
      return filter(state.sortedMessages, (item) => item.type === 14)
    },
    groupByDateMessages (state) {
      return groupBy(state.sortedMessages, (item) => {
        const date = dayjs.unix(item.created)

        return date.format('MM/DD/YYYY')
      })
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'ttlAfterRead'
        ]
      }
    ]
  },
  actions: {
    //
    // WS Requests
    //
    async userStatus (status, id) {
      await app.wsp
        .sendRequest({
          data: {
            status: status,
            receiverId: id || this.chatId
          },
          method: 'user.status'
        })
    },
    /*
    * messages.markAsRead
    * https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/messages.markAsRead.md
    * */
    async messagesMarkAsRead (messageId) {
      await app.wsp.sendRequest({
        data: {
          userId: this.chatId,
          lteId: messageId
        },
        method: 'messages.markAsRead'
      })
    },
    // Get chat user
    userGet () {
      return app.wsp
        .sendRequest({
          data: {
            id: this.chatId
          },
          method: 'user.get'
        })
        .then(response => {
          if (response.error === undefined) {
            this.chatUser = response.data.user
          }

          return response
        })
    },
    async messageDelete (messageId) {
      await app.wsp
        .sendRequest({
          data: {
            userId: this.chatId,
            id: messageId
          },
          method: 'message.delete'
        })
    },
    messageSend () {
      if (this.messageToSend) {
        return app.wsp
          .sendRequest({
            data: {
              text: this.messageToSend,
              receiverId: this.chatId,
              clientId: uniqid(),
              ttlAfterRead: this.currentTTL ? this.currentTTL : null
            },
            method: 'message.send'
          })
          .then(response => {
            if (response.error === undefined) {
              this.messageToSend = ''
            }
          })
      }
    },
    async messageSendPhoto (imageDescriptorString) {
      if (!imageDescriptorString) return

      await app.wsp
        .sendRequest({
          data: {
            text: '',
            receiverId: this.chatId,
            imageDescriptorString: imageDescriptorString,
            clientId: uniqid(),
            ttlAfterRead: this.currentTTL ? this.currentTTL : null
          },
          method: 'message.send'
        })
    },
    /*
    * messages.get
    * https://github.com/wiggumlab/fansy-server/blob/next/doc/API/methods/messages.get.md
    * */
    async messagesGet (ltId = null) {
      const res = await app.wsp.sendRequest({
        data: {
          ltId: ltId,
          limit: this.limit,
          onlyWithAttachments: this.onlyAttachments,
          userId: this.chatId
        },
        method: 'messages.get'
      })

      if (res.error) {
        throw res.error
      }

      this.firstLoad = false

      if (this.sortedMessages.length === 0) {
        // Set messages
        this.allMessages[this.chatId] = res.data.messages
        this.paginationState = 'loaded'
      } else {
        // Infinite scroll
        if (res.data.messages.length === 0) {
          this.paginationState = 'loaded-all'
        } else {
          this.allMessages[this.chatId].push(...res.data.messages)
          this.paginationState = 'loaded'
        }
      }
    },
    async sendVideo (video) {
      const res = await app.wsp.sendRequest({ method: 'video.getUploadUrl' })
      const url = res.data.uploadUrl
      let videoRes = null

      try {
        videoRes = await app.axios
          .post(url, video, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })

        if (!videoRes.data?.videoDescriptorString) {
          return
        }
      } catch (error) {
        throw new Error(error.message, { cause: error })
      }

      await app.wsp
        .sendRequest({
          data: {
            text: '',
            receiverId: this.chatId,
            videoDescriptorString: videoRes.data.videoDescriptorString,
            clientId: uniqid(),
            ttlAfterRead: this.currentTTL ? this.currentTTL : null,
            attachment: {
              fields: videoRes.data.videoPreviewImageUrls,
              videoUrl: videoRes.data.videoUrl
            }
          },
          method: 'message.send'
        })
    },
    async sendPhoto (photo) {
      // 1. Choose file
      // 2. Get link for upload: image.getUploadUrl
      // 3. Upload file to link from step 2 and get it's imageDescriptorString.
      // 4. Send message with text "[Photo]", and id of file.

      const urlRes = await app.wsp.sendRequest({ method: 'image.getUploadUrl' })
      const url = urlRes.data.uploadUrl
      let photoRes = null

      try {
        // Upload photo to url from uploadGetUrl and get it's id
        photoRes = await app.axios
          .post(url, photo, {
            headers: {
              'Content-Type': 'application/octet-stream'
            }
          })

        if (!photoRes.data?.imageDescriptorString) {
          return
        }
      } catch (error) {
        throw new Error(error.message, { cause: error })
      }

      await this.messageSendPhoto(photoRes.data.imageDescriptorString)
    },
    //
    // WS Events
    //
    userSync (data = {}) {
      if (!data.data) return

      if (this.chatUser.userId === data.data.user.userId) {
        this.chatUser = data.data.user
      }
    },
    // Сообщение от юзера криейтору
    async messageStatus (data) {
      if (this.router.currentRoute.path !== `/chat/${this.chatId}`) return

      const message = data.data
      const index = this.messages.findIndex((item) => item.messageId === message.messageId)

      const userStore = useUserStore()

      const userId = userStore?.userId
      const chatId = this.chatId
      const receiverId = message.receiverId
      const senderId = message.senderId

      if ((senderId === chatId && receiverId === userId) || (senderId === userId && receiverId === chatId)) {
        // Update or add message to chat
        if (index === -1) {
          this.allMessages[this.chatId].push(...[message])

          if (message.type === 3 && message.status === 0) {
            //
            //
            //
            this.messageToBuy = message
          }
        } else {
          // Update message's status
          const item = Object.assign({}, this.allMessages[this.chatId][index])
          item.status = message.status

          this.allMessages[this.chatId].splice(index, 1, item)
        }
      }

      // Update balance
      userStore.user.balance = message.userBalance
    },
    async attachmentStatus (data) {
      const attachment = data.data

      // Update balance
      const userStore = useUserStore()
      userStore.user.balance = attachment.userBalance

      if (this.router.currentRoute.path !== `/chat/${this.chatId}`) {
        return
      }

      const index = this.messages.findIndex((item) => item.messageId === attachment.messageId)

      // Update status of attachment
      const item = Object.assign({}, this.allMessages[this.chatId][index])

      item.attachment.fields = attachment.fields
      item.attachment.paid = attachment.paid

      this.allMessages[this.chatId].splice(index, 1, item)
    },
    dialogUserStatus (data) {
      if (!data?.data) return

      if (Number(this.chatUser.userId) !== Number(data.data.userId)) {
        return
      }

      if (!['offline', 'online'].includes(data.data.status)) {
        return
      }

      this.chatUser = {
        ...this.chatUser,
        online: data.data.status === 'online'
      }
    },
    dialogStatus (data) {
      // const message = data.data?.dialog.message
      //
      // // Add tip message to chat
      // if (message && message.type === 15 && message.tipAmount) {
      //   this.allMessages[this.chatId].push(...[message])
      // }
    },
    // Сообщение от криейтора юзеру
    async messageNew (data) {
      if (this.router.currentRoute.path !== `/chat/${this.chatId}`) return

      const message = data.data.message

      const index = this.messages.findIndex((item) => item.messageId === message.messageId)

      // Play sound for new messages except "info" messages
      if (![9, 14].includes(message.type)) {
        newMessageSound.play()
      }

      const userStore = useUserStore()

      const userId = userStore.userId
      const chatId = this.chatId
      const receiverId = message.receiverId
      const senderId = message.senderId

      // If chat opened in the same chat as arrived message
      if ((senderId === chatId && receiverId === userId) || (senderId === userId && receiverId === chatId)) {
        // Send a read status since the message has arrived in the current dialogue
        if (this.router.currentRoute.path === `/chat/${this.chatId}`) {
          if (!document.hidden) {
            await this.messagesMarkAsRead(message.messageId)
          }
        }

        if (index === -1) {
          this.allMessages[this.chatId].push(...[message])
        } else {
          // Remove old, set new at index if exists
          this.allMessages[this.chatId].splice(index, 1, message)
        }
      }
    },
    async messageDeleted (data) {
      if (this.router.currentRoute.path !== `/chat/${this.chatId}`) return

      const messageIdToUpdate = data.data.id

      const index = this.messages.findIndex((item) => item.messageId === messageIdToUpdate)

      if (index !== -1) {
        // Message deleted and show trail that message deleted
        this.allMessages[this.chatId][index].isDeleted = true
      }
    }
  }
})
