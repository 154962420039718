<template>
  <div class="h-full flex flex-col">
    <div class="flex-grow">
      <div>
        <h3 class="h3 mb-4">
          {{ $t('user.me_edit.i_am_a') }}
        </h3>

        <div class="mb-6 grid grid-cols-2 gap-8">
          <a
            :class="[sex === 1 ? 'border-[#f23459]' : 'border-gray-400 grayscale opacity-60']"
            class="aspect-[4/3] flex items-center justify-center rounded-[10px] border-2 cursor-pointer"
            @click="sex = 1">
            <div class="flex flex-col items-center justify-center">
              <img
                src="@/assets/images/emoji/man-raising-hand.png"
                class="h-[44px] w-[44px] text-0 mb-2.5"
                alt="Man raising hand">

              <h4 class="text-primary font-bold">
                {{ $t('common.man') }}
              </h4>
            </div>
          </a>
          <a
            :class="[sex === 2 ? 'border-[#f23459]' : 'border-gray-400 grayscale opacity-60']"
            class="aspect-[4/3] flex items-center justify-center rounded-[10px] border-2 cursor-pointer"
            @click="sex = 2">
            <div class="flex flex-col items-center justify-center">
              <img
                src="@/assets/images/emoji/woman-raising-hand.png"
                class="h-[44px] w-[44px] text-0 mb-2.5"
                alt="Woman raising hand">

              <h4 class="text-primary font-bold">
                {{ $t('common.woman') }}
              </h4>
            </div>
          </a>
        </div>
      </div>

      <div class="mb-6">
        <h3 class="h3 mb-4">
          {{ $t('user.me_edit.seeking_a') }}
        </h3>

        <div class="grid grid-cols-2 gap-8">
          <a
            :class="[sexFilter === 1 ? 'border-[#f23459]' : 'border-gray-400 grayscale opacity-60']"
            class="aspect-[4/3] flex items-center justify-center rounded-[10px] border-2 cursor-pointer"
            @click="sexFilter = 1">
            <div class="flex flex-col items-center justify-center">
              <img
                src="@/assets/images/emoji/man-raising-hand.png"
                class="h-[44px] w-[44px] text-0 mb-2.5"
                alt="Man raising hand">

              <h4 class="text-primary font-bold">
                {{ $t('common.man') }}
              </h4>
            </div>
          </a>

          <a
            :class="[sexFilter === 2 ? 'border-[#f23459]' : 'border-gray-400 grayscale opacity-60']"
            class="aspect-[4/3] flex items-center justify-center rounded-[10px] border-2 cursor-pointer"
            @click="sexFilter = 2">
            <div class="flex flex-col items-center justify-center">
              <img
                src="@/assets/images/emoji/woman-raising-hand.png"
                class="h-[44px] w-[44px] text-0 mb-2.5"
                alt="Woman raising hand">

              <h4 class="text-primary font-bold">
                {{ $t('common.woman') }}
              </h4>
            </div>
          </a>
        </div>
      </div>
    </div>

    <button
      class="btn btn-block btn-primary py-3.5 mb-28"
      type="button"
      @click="saveInterests">
      Continue
    </button>
  </div>
</template>

<script>
import { mapActions, mapWritableState } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'

export default {
  name: 'YourInterests',
  components: {},
  data () {
    return {
      sex: 1
    }
  },
  computed: {
    ...mapWritableState(useCreatorsCatalogStore, [
      'sexFilter'
    ])
  },
  methods: {
    ...mapActions(useUserStore, [
      'meSave'
    ]),
    async saveInterests () {
      await this.meSave({
        sex: this.sex
      }).finally(() => {
        this.$router.push('/details')
      })
    }
  }
}
</script>

