<script setup>
  import Avatar from '@/components/Functional/Avatar.vue'
  import { useEventsStore } from '@/components/Events/Store/events.js'

  const eventsStore = useEventsStore()
  
  defineProps({
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  })
</script>

<template>
  <router-link
    :to="`/chat/${item.user.userId}`"
    class="flex items-center justify-between gap-2 cursor-pointer rounded-full"
    @click="eventsStore.acceptInviteClicked(item.user.userId)">
    <div class="flex items-center gap-2">
      <Avatar
        class="w-14 h-14 shrink-0"
        :user-item="item.user"
        :show-status="true"/>

      <div class="min-w-0">
        <div class="flex flex-wrap items-center gap-x-1">
          <h4
            :title="item.user.name"
            class="font-semibold text-sm line-clamp-1">
            {{ item.user.name }}
          </h4>

          <InlineSvg
            v-show="item.user.tikTokFollowerCount"
            :src="require('@/assets/svg/design/verified.svg')"
            class="shrink-0"/>

          <span class="text-sm font-semibold text-gray-400 leading-3">
            @{{ item.user.username }}
          </span>
        </div>

        <p class="text-sm line-clamp-2 text-gray-900">
          {{ $t('events.is_inviting_you_to_chat') }}
        </p>
      </div>
    </div>

    <button
      class="btn btn-primary py-1.5 px-3.5 text-caption font-semibold"
      type="button">
      {{ $t('common.accept') }}
    </button>
  </router-link>
</template>