<template>
  <div
    v-click-outside="() => {activeTab = ''}"
    class="relative w-full">
    <div class="min-h-[2.5rem] py-1 gap-1 px-2 flex items-center justify-around border-r border-l border-b border-gray-100">
      <button
        type="button"
        class="text-sm leading-4 rounded-lg hover:ring-1 ring-gray-200 py-0.5 px-1.5"
        :class="{ 'ring-1 ring-gray-200': activeTab === 'Account' }"
        @click.stop="activeTab = 'Account'">
        Account
      </button>

      <button
        type="button"
        class="text-sm leading-4 rounded-lg hover:ring-1 ring-gray-200 py-0.5 px-1.5"
        :class="{ 'ring-1 ring-gray-200': activeTab === 'Payment and Refund' }"
        @click.stop="activeTab = 'Payment and Refund'">
        Payment and Refund
      </button>

      <button
        type="button"
        class="text-sm leading-4 rounded-lg hover:ring-1 ring-gray-200 py-0.5 px-1.5"
        :class="{ 'ring-1 ring-gray-200': activeTab === 'Creator' }"
        @click.stop="activeTab = 'Creator'">
        Creator
      </button>

      <button
        type="button"
        class="text-sm leading-4 rounded-lg hover:ring-1 ring-gray-200 py-0.5 px-1.5"
        :class="{ 'ring-1 ring-gray-200': activeTab === 'Technical issues' }"
        @click.stop="activeTab = 'Technical issues'">
        Issues
      </button>
    </div>

    <transition name="fade">
      <div
        v-if="activeTab"
        class="w-full absolute bottom-10"
        @click.stop>
        <div class="relative bg-white p-4 m-4 rounded-md border border-gray-100">
          <h3 class="mb-3 px-1">
            Select the issue
          </h3>

          <ul>
            <li
              v-for="(item, index) in issues"
              :key="index">
              <button
                type="button"
                class="text-sm block text-left rounded-lg hover:ring-1 my-1 ring-gray-200 py-0.5 px-1.5"
                @click="sendMessage(item)">
                {{ item.title }}
              </button>
            </li>
          </ul>

          <button
            class="absolute right-4 top-4 text-gray-500 p-1"
            type="button"
            @click="activeTab = ''">
            <InlineSvg
              class="w-3 h-3"
              :src="require('@/assets/svg/design/cross.svg')"/>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useUserStore } from '@/stores/user'
import { useQuizStore } from '@/stores/quiz'
import uniqid from 'uniqid'

const issues = new Map([
  ['Account', [
    {
      title: 'Delete the card',
      number: 31012
    },
    {
      title: 'Delete account',
      number: 31013
    },
    {
      title: 'Password recovery',
      number: 31014
    },
    {
      title: 'Question about the site',
      number: 31015
    },
    {
      title: 'The principle of the secret chat',
      number: 31016
    },
    {
      title: 'Cancellation of subscription',
      number: 31017
    },
    {
      title: 'How to get credits?',
      number: 31018
    }
  ]],
  ['Payment and Refund', [
    {
      title: 'Billing of the dialogues',
      number: 32014
    },
    {
      title: 'Refund for subscription',
      number: 32015
    },
    {
      title: 'Refund for content',
      number: 32016
    },
    {
      title: 'Refund for debiting from a remote account',
      number: 32017
    },
    {
      title: 'Payments problem',
      number: 32018
    }
  ]],
  ['Creator', [
    {
      title: 'Report on the creator',
      number: 29015
    },
    {
      title: 'How to monetize earnings?',
      number: 29016
    },
    {
      title: 'How to become a creator?',
      number: 29017
    },
    {
      title: 'Complaint about creator',
      number: 29018
    }
  ]],
  ['Technical issues', [
    {
      title: 'Other problems',
      number: 28015
    },
    {
      title: 'Login problem',
      number: 28016
    },
    {
      title: 'I can\'t link a card',
      number: 28017
    }
  ]]
])

export default {
  name: 'ChatSuggestSupport',
  components: {},
  data () {
    return {
      activeTab: '',
      loading: false
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ]),
    ...mapWritableState(useChatStore, [
      'messageToSend'
    ]),
    issues () {
      return issues.get(this.activeTab)
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions(useQuizStore, [
      'surveyQuestionAnswered'
    ]),
    ...mapActions(useChatStore, [
      'messageSend'
    ]),
    async sendMessage (message) {
      if (this.loading) {
        return
      }

      this.loading = true

      this.wsp.sendRequest({
        data: {
          text: message.title,
          receiverId: this.premiumSupportAgentUserId,
          clientId: uniqid(),
          ttlAfterRead: null,
          highlighted: true,
          surveyResultId: message.number.toString()
        },
        method: 'message.send'
      })
        .then(() => {
          this.surveyQuestionAnswered(
            {
              questionNumber: 1,
              answerNumber: message.number
            })
        })
        .finally(() => {
          this.loading = false
          this.activeTab = ''
        })
    }
  }
}
</script>

