import { Invites } from '@/Invites'
import { useAuthStore } from '@/stores/auth'
import { watch } from 'vue'

export default {
  install: (app) => {
    const invites = new Invites()

    const authStore = useAuthStore()

    watch(
      () => authStore.isTokenExist,
      () => {
        invites.clearTimeouts()
      }
    )

    app.config.globalProperties.invites = invites
    app.invites = app.config.globalProperties.invites
  }
}
