import { useAuthStore } from '@/stores/auth'
import { notify } from '@kyvg/vue3-notification'
import uniqid from 'uniqid'

export default {
  install: (app) => {
    const auth = useAuthStore()
    //
    // HTTP requests interceptor
    //
    app.axios.interceptors.request.use(function (config) {
      console.log('Making request to:', config.url)

      if (auth.isTokenExist) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    }, function (error) {
      return Promise.reject(error)
    })

    //
    // Axios' response interceptors for catching error messages
    //
    app.axios.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error !== undefined) {
        if (error.message) {
          console.log('Interceptor:', error.message)
        }

        // Show business error message
        if (error.response?.status) {
          if (error.response?.data?.description && error.response?.data?.message) {
            notify({
              id: uniqid(),
              group: 'errors',
              title: `${error.response.status}: ${error.response.data.message}`,
              text: error.response.data.description
            })
          } else if (error.response?.data?.error?.message) {
            notify({
              id: uniqid(),
              group: 'errors',
              title: error.response.status,
              text: error.response.data.message
            })
          }
        }
      }

      return Promise.reject(error)
    })
  }
}
