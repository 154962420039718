<template>
  <div class="h-full w-full max-w-full flex flex-col placeholder">
    <div
      style="width: 150px; height: 22px; margin: 2px 0;"
      class="placeholder-item rounded-full"/>

    <div class="grid grid-flow-col auto-cols-max gap-3 overflow-hidden scrollbar-none h-30 pt-2 mb-6">
      <div class="flex flex-col">
        <div class="placeholder-item rounded-full h-17 w-17"/>

        <div class="flex items-center justify-center mt-0.5">
          <span class="mt-1 block h-1.5 min-w-[0.375rem] w-1.5 rounded-full placeholder-item"/>

          <div
            style="width: 60px;"
            class="mt-1 placeholder-item rounded-full ml-0.5 mr-1.5 h-3"/>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="placeholder-item rounded-full h-17 w-17"/>

        <div class="flex items-center justify-center mt-0.5">
          <span class="mt-1 block h-1.5 min-w-[0.375rem] w-1.5 rounded-full placeholder-item"/>

          <div
            style="width: 60px;"
            class="mt-1 placeholder-item rounded-full ml-0.5 mr-1.5 h-3"/>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="placeholder-item rounded-full h-17 w-17"/>

        <div class="flex items-center justify-center mt-0.5">
          <span class="mt-1 block h-1.5 min-w-[0.375rem] w-1.5 rounded-full placeholder-item"/>

          <div
            style="width: 60px;"
            class="mt-1 placeholder-item rounded-full ml-0.5 mr-1.5 h-3"/>
        </div>
      </div>
    </div>

    <div
      style="width: 100px; height: 22px; padding: 2px 0;"
      class="placeholder-item rounded-full mb-2 mt-4"/>

    <div
      style="height: 40px"
      class="rounded-full placeholder-item mt-1 mb-4"/>


    <ul class="list-group list-group-flush">
      <li>
        <div class="flex items-center border-b border-gray-100 pb-3 mb-3">
          <div
            class="mr-2.5 placeholder-item rounded-full"
            style="min-width: 50px; height: 50px; width: 50px;"/>

          <div class="flex flex-1 flex-col">
            <div class="flex justify-between">
              <div class="w-8/12">
                <div
                  class="flex items-center">
                  <div
                    style="width: 78px; height: 16px; margin: 1px 0"
                    class="placeholder-item rounded-full"/>

                  <div
                    style="width: 58px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                    class="placeholder-item rounded-full ml-1"/>
                </div>
              </div>

              <div
                style="width: 70px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                class="placeholder-item rounded-full"/>
            </div>

            <div class="flex flex-col mt-1.5">
              <div
                style="width: 100%; max-width: 350px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>

              <div
                style="width: 100%; max-width: 100px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center border-b border-gray-100 pb-3 mb-3">
          <div
            class="mr-2.5 placeholder-item rounded-full"
            style="min-width: 50px; height: 50px; width: 50px;"/>

          <div class="flex flex-1 flex-col">
            <div class="flex justify-between">
              <div class="w-8/12">
                <div
                  class="flex items-center">
                  <div
                    style="width: 78px; height: 16px; margin: 1px 0"
                    class="placeholder-item rounded-full"/>

                  <div
                    style="width: 58px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                    class="placeholder-item rounded-full ml-1"/>
                </div>
              </div>

              <div
                style="width: 70px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                class="placeholder-item rounded-full"/>
            </div>

            <div class="flex flex-col mt-1.5">
              <div
                style="width: 100%; max-width: 350px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>

              <div
                style="width: 100%; max-width: 100px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="flex items-center border-b border-gray-100 pb-3 mb-3">
          <div
            class="mr-2.5 placeholder-item rounded-full"
            style="min-width: 50px; height: 50px; width: 50px;"/>

          <div class="flex flex-1 flex-col">
            <div class="flex justify-between">
              <div class="w-8/12">
                <div
                  class="flex items-center">
                  <div
                    style="width: 78px; height: 16px; margin: 1px 0"
                    class="placeholder-item rounded-full"/>

                  <div
                    style="width: 58px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                    class="placeholder-item rounded-full ml-1"/>
                </div>
              </div>

              <div
                style="width: 70px; height: 16px; margin-top: 1px; margin-bottom: 1px;"
                class="placeholder-item rounded-full"/>
            </div>

            <div class="flex flex-col mt-1.5">
              <div
                style="width: 100%; max-width: 350px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>

              <div
                style="width: 100%; max-width: 100px; height: 16px; margin: 2px 0;"
                class="placeholder-item rounded-full"/>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'DialogsPlaceholder',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

