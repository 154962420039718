<script setup>
import { ref } from 'vue'
import EmojiPicker from '@/components/Chat/ChatForm/EmojiPicker.vue'
import { useChatStore  } from '@/stores/chat'
const isOpen = ref(false)

const chatStore = useChatStore()

function close () {
  isOpen.value = false
}
</script>

<template>
  <div class="relative z-20">
    <button
      ref="emojiTrigger"
      tabindex="0"
      :disabled="chatStore.chatUser.deleted"
      type="button"
      class="btn p-1"
      @click.stop="isOpen = !isOpen">
      <svg
        width="25"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.175.042C18.78.042 24.133 5.396 24.133 12S18.78 23.958 12.175 23.958.217 18.604.217 12 5.57.042 12.175.042zM8.392 6.813a1.38 1.38 0 0 0-1.397 1.384 1.397 1.397 0 1 0 2.385-.984 1.38 1.38 0 0 0-.988-.4zm9.849 6.828c-.504 3.026-3.131 5.553-6.306 5.553-3.15 0-5.762-2.49-6.296-5.482-.108-.747.442-.923.96-.784 1.734.558 3.532.829 5.337.805a16.71 16.71 0 0 0 5.324-.802c.504-.142 1.043.019.981.71zm-2.275-6.828a1.381 1.381 0 0 0-1.397 1.384 1.397 1.397 0 1 0 2.794 0 1.38 1.38 0 0 0-1.397-1.384z"
          fill="#C0C2C1"/>
      </svg>
    </button>

    <transition name="fade">
      <EmojiPicker
        v-show="isOpen"
        v-click-outside="close"
        class="absolute z-50 bottom-13 -left-[170px]"/>
    </transition>
  </div>
</template>
