<template>
  <div>
    <button
      v-if="0"
      type="button"
      class="btn btn-secondary my-5"
      @click="$refs.modalOnboarding.openModal();isReachedEnd=false">
      Open onbording
    </button>

    <Modal
      ref="modalOnboarding"
      name="Onboarding"
      z-index-class="z-[99999]"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      :allow-close-by-overlay="false"
      :show-desktop-close-cross="false"
      content-class="h-full"
      position="center"
      @close="onboardingClosed"
      @opened="opened">
      <div class="app-height overflow-hidden bg-white">
        <div class="h-full bg-white flex flex-col">
          <div class="flex-grow flex flex-col justify-between overflow-hidden">
            <header class="relative">
              <img
                src="@/assets/images/onboarding/logo.svg"
                class="p-4 mb-2 w-34"
                alt="one2fan logo">

              <div
                :style="{ 'transform': `translate(${xyEmoji02[0]}px, ${xyEmoji02[1]}px)` }"
                class="animated-item w-16 absolute -left-4 bottom-0">
                <transition name="fade">
                  <img
                    v-if="decorationsVisible"
                    :src="require(`@/assets/images/onboarding/emoji_4/emoji-${activeIndex}.png`)"
                    class="inline-block"
                    alt="Emoji">
                </transition>
              </div>

              <img
                :style="{ 'transform': `translate(${xyCircle03[0]}px, ${xyCircle03[1]}px)` }"
                src="@/assets/images/onboarding/circle_03.png"
                class="animated-item absolute top-5 right-1/2 w-7"
                alt="Circle">

              <img
                :style="{ 'transform': `translate(${xyCircle04[0]}px, ${xyCircle04[1]}px)` }"
                src="@/assets/images/onboarding/circle_04.png"
                class="animated-item absolute top-10 right-22 w-12"
                alt="Circle">

              <img
                :style="{ 'transform': `translate(${xyCircle06[0]}px, ${xyCircle06[1]}px)` }"
                src="@/assets/images/onboarding/circle_06.png"
                class="animated-item absolute -top-8 -right-7 w-22"
                alt="Circle">

              <div class="relative">
                <div class="relative inline-block -ml-5">
                  <Avatar
                    class="w-44 w-full h-full"
                    :user-item="user"/>

                  <div class="absolute left-48 bottom-14">
                    <h4 class="h2 mb-1 w-max">
                      {{ user.name }}
                    </h4>

                    <p class="text-caption text-[#86878B]">
                      @{{ user.username }}
                    </p>
                  </div>

                  <div
                    :style="{ 'transform': `translate(${xyEmoji01[0]}px, ${xyEmoji01[1]}px)` }"
                    class="animated-item w-16 absolute left-48 bottom-28">
                    <transition name="fade">
                      <img
                        v-if="decorationsVisible"
                        :src="require(`@/assets/images/onboarding/emoji/emoji-${activeIndex}.png`)"
                        class="inline-block"
                        alt="Emoji">
                    </transition>
                  </div>

                  <img
                    src="@/assets/images/onboarding/circle_01.png"
                    class="animated-item absolute right-0 bottom-8 w-6"
                    :style="{ 'transform': `translate(${xyCircle01[0]}px, ${xyCircle01[1]}px)` }"
                    alt="Circle">

                  <img
                    src="@/assets/images/onboarding/circle_02.png"
                    class="animated-item absolute top-0 right-0 w-10"
                    :style="{ 'transform': `translate(${xyCircle02[0]}px, ${xyCircle02[1]}px)` }"
                    alt="Circle">
                </div>

                <img
                  :style="{ 'transform': `translate(${xyCircle05[0]}px, ${xyCircle05[1]}px)` }"
                  src="@/assets/images/onboarding/circle_05.png"
                  class="animated-item absolute w-20 -right-1 -bottom-2"
                  alt="Circle">

                <div
                  :style="{ 'transform': `translate(${xyEmoji03[0]}px, ${xyEmoji03[1]}px)` }"
                  class="animated-item w-16 absolute right-14 bottom-40">
                  <transition name="fade">
                    <img
                      v-if="decorationsVisible"
                      :src="require(`@/assets/images/onboarding/emoji_3/emoji-${activeIndex}.png`)"
                      class="inline-block"
                      alt="Emoji">
                  </transition>
                </div>

                <div
                  :style="{ 'transform': `translate(${xyEmoji04[0]}px, ${xyEmoji04[1]}px)` }"
                  class="animated-item w-16 absolute -right-4 bottom-24">
                  <transition name="fade">
                    <img
                      v-if="decorationsVisible"
                      :src="require(`@/assets/images/onboarding/emoji_2/emoji-${activeIndex}.png`)"
                      class="inline-block "
                      alt="Emoji">
                  </transition>
                </div>
              </div>

              <div class="flex px-4 -mt-10">
                <div class="w-2/12"/>

                <div class="relative w-10/12 onboarding-welcome rounded-2xl px-4 py-3">
                  <img
                    :src="require(`@/assets/images/onboarding/tail.svg`)"
                    class="absolute top-0 -left-[6px]"
                    alt="Chat tail">

                  <h3
                    class="max-w-[250px]xx"
                    :class="{ 'mb-1': user.welcomeMessageText }">
                    <TextClamp
                      :auto-resize="true"
                      :text="`Greetings from ${user.name}!`"
                      :max-lines="1">
                      <template #after>
                        🖐
                      </template>
                    </TextClamp>
                  </h3>

                  <p v-show="user.welcomeMessageText">
                    <TextClamp
                      :auto-resize="true"
                      :text="user.welcomeMessageText"
                      :max-lines="5"/>
                  </p>
                </div>
              </div>
            </header>

            <swiper
              v-if="items.length"
              class="h-full w-full"
              :auto-height="false"
              :keyboard="true"
              @slide-change-transition-start="slideChangeTransitionStart"
              @slide-change-transition-end="slideChangeTransitionEnd"
              @reach-end="reachEnd"
              @active-index-change="activeIndexChange"
              @swiper="onSwiper">
              <swiper-slide
                v-for="(item) in items"
                :key="item.id">
                <div class="h-full flex flex-col justify-between">
                  <div/>

                  <div class="p-4">
                    <h3 class="mb-2 h2">
                      {{ item.heading }}
                    </h3>

                    <p>
                      {{ item.text }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>

            <div class="px-4 flex items-center justify-center">
              <div class="flex items-center justify-center space-x-2">
                <div
                  v-for="(item, index) in items"
                  :key="index"
                  :class="{ 'active': index === activeIndex }"
                  class="onboarding-pagination"/>
              </div>
            </div>

            <footer class="px-4 pb-4 pt-2 mt-2">
              <button
                class="btn btn-block btn-primary"
                @click="checkSwiperState">
                {{ isReachedEnd ? $t('onboarding.get_started') : `${$t('onboarding.next')} →` }}
              </button>
            </footer>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Keyboard } from 'swiper'
import Avatar from '@/components/Functional/Avatar'
import { random } from 'lodash'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { mapState } from 'pinia'
import TextClamp from '@/components/Functional/TextClamp'
import helpers from '@/helpers'


export default {
  components: {
    Swiper,
    SwiperSlide,
    Avatar,
    TextClamp
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {
      }
    }
  },
  data () {
    return {
      modules: [Keyboard, Navigation],
      decorationsVisible: false,
      isReachedEnd: false,
      swiperInstance: null,
      items: [
        {
          heading: this.$t('onboarding.heading_1'),
          text: this.$t('onboarding.text_1')
        },
        {
          heading: this.$t('onboarding.heading_2'),
          text: this.$t('onboarding.text_2')
        },
        {
          heading: this.$t('onboarding.heading_3'),
          text: this.$t('onboarding.text_3')
        },
        {
          heading: this.$t('onboarding.heading_4'),
          text: this.$t('onboarding.text_4')
        },
        {
          heading: this.$t('onboarding.heading_5'),
          text: this.$t('onboarding.text_5')
        }
      ],
      activeIndex: 0,
      xyCircle01: [0, 0],
      xyCircle02: [0, 0],
      xyCircle03: [0, 0],
      xyCircle04: [0, 0],
      xyCircle05: [0, 0],
      xyCircle06: [0, 0],
      xyEmoji01: [0, 0],
      xyEmoji02: [0, 0],
      xyEmoji03: [0, 0],
      xyEmoji04: [0, 0]
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'installId',
      'isAuthenticated'
    ])
  },
  watch: {
    // Update coordinates for animation
    // https://stackoverflow.com/a/10250833/3266562
    activeIndex (value, oldValue) {
      if (value !== oldValue) {
        this.xyCircle01 = [random(-10, 10), random(-10, 10)]
        this.xyCircle02 = [random(-10, 10), random(-10, 10)]
        this.xyCircle03 = [random(-10, 10), random(-10, 10)]
        this.xyCircle04 = [random(-10, 10), random(-10, 10)]
        this.xyCircle05 = [random(-10, 10), random(-10, 10)]
        this.xyCircle06 = [random(-10, 10), random(-10, 10)]
        this.xyEmoji01 = [random(-10, 10), random(-10, 10)]
        this.xyEmoji02 = [random(-10, 10), random(-10, 10)]
        this.xyEmoji03 = [random(-10, 10), random(-10, 10)]
        this.xyEmoji04 = [random(-10, 10), random(-10, 10)]
      }
    }
  },
  beforeMount () {
    // Preload all Emoji's
    async function preloadImage (folderName, index) {
      const preloadingImg = new Image()

      preloadingImg.src = require(`@/assets/images/onboarding/${folderName}/emoji-${index}.png`)

      preloadingImg.onload = function (event) {
        // console.log(`Image ${index}-${folderName} loaded`)
      }
    }

    const imagesToPreload = ['emoji', 'emoji_2', 'emoji_3', 'emoji_4']

    imagesToPreload.forEach(item => {
      for (let i = 0; i < 5; i++) {
        preloadImage(item, i)
      }
    })
  },
  async mounted () {
    const authStore = useAuthStore()

    if (authStore.installId) {
      await authStore.sessionGet()
    }
  },
  methods: {
    checkSwiperState () {
      // Gtag for first click
      if (this.activeIndex === 0) {
        if (process.env.NODE_ENV === 'production') {
          // eslint-disable-next-line no-undef
          gtag('event', 'Click', {
            event_category: 'Button',
            event_label: 'Start onboarding slide'
          })
        }
      }

      if (this.isReachedEnd) {
        const authStore = useAuthStore()

        authStore.onboardingIsVisible = false

        this.$refs.modalOnboarding.closeModal()

        if (process.env.NODE_ENV === 'production') {
          // eslint-disable-next-line no-undef
          gtag('event', 'Click', {
            event_category: 'Button',
            event_label: 'Onboarding reach end'
          })
        }
      } else {
        this.swiperInstance.slideNext()
      }
    },
    activeIndexChange (swiper) {
      this.activeIndex = swiper.realIndex
    },
    reachEnd () {
      this.isReachedEnd = true
    },
    slideChangeTransitionEnd () {
      this.decorationsVisible = true
    },
    slideChangeTransitionStart () {
      this.decorationsVisible = false
    },
    onSwiper (swiper) {
      this.swiperInstance = swiper
      this.decorationsVisible = true
    },
    opened () {
      const appStore = useAppStore()

      appStore.onboardingShown = true
    },
    openOnboarding () {
      this.$refs.modalOnboarding.openModal()
    },
    async onboardingClosed () {
      await this.$nextTick()

      setTimeout(() => {
        helpers.showSignUp()
      }, 600)
    }
  }
}
</script>

<style>
  .onboarding-welcome {
    background: linear-gradient(180deg, #FFD8DF 0%, #F1A9B7 100%), #D9D9D9;
  }

  .animated-item {
    transition: transform 1s ease;
  }

  .onboarding-pagination {
    height: 9px;
    width: 9px;
    background-color: #cccccc;
    border-radius: 50%;
    display: inline-block;
  }

  .onboarding-pagination.active {
    height: 11px;
    width: 11px;
    background-color: #f23459;
  }
</style>
