<template>
  <div
    id="messages"
    ref="messages"
    class="chat-messages">
    <div :class="{ 'flex flex-col justify-between h-full -my-1': !messagesCount }">
      <div>
        <Observer
          v-if="isAuthenticated"
          @intersect="intersected"/>

        <ChatBodyPosts
          v-if="isAuthenticated && chatUser?.username && options.chatPostsEnabled"/>

        <ChatHelloMessage
          v-if="chatUser.welcomeMessageText && messagesCount < 10"
          class="mb-2 mt-3 align-top"/>

        <template v-if="isAuthenticated">
          <Observer @intersect="intersected"/>

          <div
            v-if="messagesCount > 10"
            :class="[paginationState === 'loaded-all' ? 'block' : 'hidden']"
            class="text-sm text-gray-500 text-center pt-3">
            {{ $t('common.end_of_list') }}
          </div>

          <Spinner
            v-if="messagesCount > 0"
            :class="[paginationState === 'loading-more' ? 'visible' : 'invisible']"/>
        </template>
      </div>

      <template v-if="isAuthenticated">
        <div
          v-for="(messages, date, index) in groupByDateMessages"
          :key="index">
          <div class="text-caption text-gray-400 text-center">
            {{ dateToLocalized(date) }}
          </div>

          <div
            v-for="(item, i) in messages"
            :id="`message_${item.messageId}`"
            :ref="`message_${item.messageId}`"
            :key="i"
            class="my-2 w-full max-w-full rounded-2xl">
            <div v-show="item.isDeleted">
              <DeletedMessage
                v-if="item.isDeleted"
                :message="item"/>
            </div>

            <div v-show="!item.isDeleted">
              <ChatPostMessage
                v-if="item.type === 14"
                :message="item"/>

              <ChatAttachmentMessage
                v-else-if="item.type === 3"
                :message="item"/>

              <ChatTextMessage
                v-else
                :message="item"/>

              <!--<pre>{{ item.designId }}</pre>-->
            </div>
          </div>
        </div>

        <div ref="bottomAnchor"/>

        <span
          v-if="chatUser.deleted"
          class="uppercase w-max inline-flex mx-auto items-center gap-x-1.5 mb-2 rounded-full bg-red-400 h-8 py-1 px-3 text-white">
          <InlineSvg
            :src="require('@/assets/svg/regular/trash.svg')"
            class="w-3.5 h-3.5 mb-0.5"/>

          User was deleted
        </span>
        
        <ChatSuggestMessages v-if="!messagesCount && !isPremiumSupportUser && !chatUser.deleted"/>
      </template>
    </div>
  </div>
</template>

<script>

import ChatTextMessage from '@/components/Chat/ChatMessages/ChatTextMessage'
import ChatPostMessage from '@/components/Chat/ChatMessages/ChatPostMessage'
import ChatHelloMessage from '@/components/Chat/ChatMessages/ChatHelloMessage'
import Observer from '@/components/Functional/Observer'
import Spinner from '@/components/Functional/Spinner'
import { mapActions, mapState } from 'pinia'
import { useChatStore } from '@/stores/chat'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import ChatSuggestMessages from '@/components/Chat/ChatMessages/ChatSuggestMessages'
import DeletedMessage from '@/components/Chat/ChatMessages/DeletedMessage'
import ChatAttachmentMessage from '@/components/Chat/ChatMessages/ChatAttachmentMessage.vue'
import ChatBodyPosts from '@/components/Chat/ChatBodyPosts.vue'

export default {
  name: 'ChatMessages',
  components: {
    ChatTextMessage,
    ChatPostMessage,
    ChatHelloMessage,
    Observer,
    Spinner,
    ChatSuggestMessages,
    DeletedMessage,
    ChatAttachmentMessage,
    ChatBodyPosts
  },
  data () {
    return {
      touchstartY: 0,
      touchendY: 0,
      constantLoading: true
    }
  },
  computed: {
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    },
    ...mapState(useAppStore, [
      'isMobile'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'options'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'userId'
    ]),
    ...mapState(useChatStore, [
      'chatUser',
      'chatId',
      'messages',
      'filteredMessages',
      'groupByDateMessages',
      'sortedMessages',
      'paginationState',
      'firstLoad'
    ]),
    before () {
      return this.sortedMessages.length > 0 ? this.sortedMessages[0].messageId : null
    },
    messagesCount () {
      if (this.messages) {
        return this.messages.length
      } else {
        return 0
      }
    }
  },
  async mounted () {
    setTimeout(() => {
      this.constantLoading = false
    }, 1000)

    setTimeout(async () => {
      await this.$nextTick()
      await this.scrollToBottom()
    }, 666)

    // Scroll to bottom if new message arrived or sent
    this.wsp.onUnpackedMessage.addListener(async message => {
      await this.$nextTick()

      if (message.method === 'message.send') {
        setTimeout(async () => {
          await this.scrollToBottom()
        }, 600)
      } else if (message.method === 'message.new') {
        setTimeout(async () => {
          await this.scrollToBottom()
        }, 600)
      } else if (message.method === 'dialog.status') {
        const tipAmount = message.data?.dialog?.message?.tipAmount

        if (tipAmount) {
          setTimeout(async () => {
            await this.scrollToBottom()
          }, 600)
        }
      }
    })

    // Detect swipe events
    // https://gist.github.com/SleepWalker/da5636b1abcbaff48c4d?permalink_comment_id=2275980#gistcomment-2275980
    const gestureZone = this.$refs.messages

    gestureZone.addEventListener('touchstart', this.touchstartHandler, false)
    gestureZone.addEventListener('touchend', this.touchendHandler, false)
  },
  beforeUnmount () {
    const gestureZone = this.$refs.messages

    gestureZone.removeEventListener('touchstart', this.touchstartHandler, true)
    gestureZone.removeEventListener('touchend', this.touchendHandler, true)
  },
  methods: {
    ...mapActions(useChatStore, [
      'messageGetList',
      'messagesGet'
    ]),
    touchstartHandler (event) {
      this.touchstartY = event.changedTouches[0].screenY
    },
    touchendHandler (event) {
      this.touchendY = event.changedTouches[0].screenY
      this.handleGesture()
    },
    handleGesture () {
      // Swiped down
      if (this.touchendY >= this.touchstartY) {
        if (['loaded-all', 'loading-more', 'loading'].includes(this.paginationState)) {
          return
        }

        const el = this.$refs.messages

        if (el.scrollTop <= 0) {
          if (this.before) {
            this.messagesGet(this.before)
          }
        }
      }
    },
    async intersected () {
      if (this.paginationState === 'loaded-all' || this.constantLoading) {
        return
      }

      if (this.before) {
        await this.messagesGet(this.before)

        // Load to the latest message after pagination
        const el = document.getElementById(`message_${this.before}`)

        if (el && !this.firstLoad && !this.isMobile && !this.constantLoading) {
          el.scrollIntoView({
            behavior: 'auto',
            block: 'start'
          })
        }
      }
    },
    async scrollToBottom () {
      const el = this.$refs.bottomAnchor

      if (el) {
        el.scrollIntoView({ block: 'end' })
      }
    },
    dateToLocalized (value) {
      if (!value) {
        return ''
      }

      const date = this.$dayjs(value, 'MM/DD/YYYY')

      return date.locale(this.$i18n.locale).format('dddd, MMMM D')
    }
  }
}
</script>

<style>
  .chat-messages {
    @apply px-4 pt-2 pb-0 h-full overflow-x-hidden;
    overscroll-behavior: revert;
    scroll-behavior: revert;
    scroll-snap-type: block;
  }

  @keyframes flash {
    0% {
      @apply bg-gray-200
    }
    100% {
      background: none;
    }
  }
</style>
