<template>
  <div class="flex flex-col justify-between items-center">
    <div/>

    <div class="flex flex-col items-center text-center">
      <img
        class="w-28 mb-5"
        src="@/assets/images/emoji/camera.png"
        alt="Photo emoji">

      <h4 class="font-semibold h2 mb-1">
        {{ $t('chat.attachments.you_will_see_all_media') }}
      </h4>

      <router-link
        v-if="name && username"
        class="cursor-default"
        :to="'/@' + username">
        <span v-html="$t('chat.attachments.no_attachments', { name: `<b class='font-normal cursor-pointer'>@${username}</b>` })"/>
      </router-link>
    </div>

    <div class="w-full space-y-2 pb-6">
      <router-link
        :to="`/chat/${$route.params.chatId}`"
        class="btn btn-block btn-primary !rounded-md"
        type="button">
        {{ $t('chat.attachments.start_chatting') }}
      </router-link>

      <CretorInfoMoreContentButton
        v-if="options.moreContentEnabled"
        class="w-full"
        :user-id="$route.params.chatId"/>
    </div>
  </div>
</template>

<script>
import { getUser } from '@/helpers/methods'
import CretorInfoMoreContentButton from '@/components/Creator/CreatorInfo/CretorInfoMoreContentButton.vue'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'ChatAttachmentsNoItems',
  components: {
    CretorInfoMoreContentButton
  },
  data () {
    return {
      name: null,
      username: null
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'options'
    ])
  },
  async mounted () {
    const chatId = this.$route.params.chatId

    if (!chatId) {
      return
    }

    if (chatId) {
      const { data } = await getUser({ id: chatId })

      if (data?.user) {
        this.name = data?.user.name
        this.username = data?.user.username
      }
    }
  },
  methods: {}
}
</script>

