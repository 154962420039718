<template>
  <div
    class="flex flex-col items-center justify-center relative pt-2 cursor-pointer"
    @click="checkPathToPush(user); recommendedDialogClicked(user.userId, 'list_item')">
    <div class="h-17 w-17 brand-rounded-avatar">
      <img
        v-if="user.photos.items.length > 0"
        :src="user.photos.items[0].links.c160x160"
        class="rounded-full h-full w-full object-top object-cover border-3 border-white"
        :title="user.name.trim()"
        :alt="user.name.trim() + '\'s avatar'">

      <img
        v-else
        :src="`/svg/${user.sex === 1 ? 'user' : 'woman'}-placeholder.svg`"
        class="rounded-full h-full w-full object-center object-cover border-3 border-white"
        :title="user.name.trim()"
        :alt="`${user.name.trim()}'s placeholder avatar`">
    </div>

    <div class="flex items-center justify-center mt-0.5">
      <span
        v-show="user.online"
        class="bg-green-300 block h-1.5 min-w-[0.375rem] w-1.5 rounded-full ring-1.5 ring-white"/>

      <small
        style="max-width: 68px"
        class="pl-0.5 text-caption font-semibold whitespace-nowrap overflow-ellipsis overflow-hidden">
        <a :title="user.name">{{ user.name }}</a>
      </small>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'InvitesItem',
  components: {
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        userId: null,
        name: '',
        online: null,
        sex: null,
        photos: {
          items: []
        }
      }),
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'authOptions'
    ])
  },
  mounted () {
  },
  methods: {
    checkPathToPush (user) {
      const recommendedDialogListItem = this.authOptions?.clickDestinations?.recommendedDialogListItem

      if (recommendedDialogListItem ===  'profile') {
        this.$router.push(`/@${user.username}`)
      } else if (recommendedDialogListItem === 'chat') {
        this.$router.push(`/chat/${user.userId}`)
      } else {
        this.$router.push(`/@${user.username}`)
      }
    },
    async recommendedDialogClicked (userId, elementType) {
      const res = await this.wsp.sendRequest({
        data: {
          userId,
          elementType
        },
        method: 'recommendedDialog.clicked'
      })

      console.log(res)
    }
  }
}
</script>
