<template>
  <div>
    <Sidebar
      ref="sidebar"
      v-touch:swipe.right="swipeHandler">
      <template #sidebarContent="{ toggleSidebar }">
        <SidebarContent
          :close-sidebar="toggleSidebar"
          @open-lang-switcher="$refs.langSwitcher.openModal()"/>
      </template>

      <template #default="{ toggleSidebar }">
        <main class="max-w-md mx-auto w-full relative">
          <div class="pt-4 px-3 mb-22">
            <CreatorsCatalogHeader
              class="mb-4"
              @hamburger="toggleSidebar"/>

            <ProfilesNav
              v-if="options.eventsEnabled"
              class="my-5"/>

            <div
              v-else
              class="my-5"/>

            <CreatorsCatalog/>
          </div>
        </main>
      </template>
    </Sidebar>

    <Navbar/>

    <Modal
      v-slot="{ closeModal }"
      ref="signUpGiftForm"
      name="SignUpGiftForm"
      :has-indents="false"
      rounded-class="rounded-t-2.5xl"
      position="bottom">
      <SignUpGiftForm @close="closeModal()"/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="langSwitcher"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      name="LangSwitcher">
      <LangSwitcherContent @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import SignUpGiftForm from '@/components/SignUpGiftForm'
import Modal from '@/components/Functional/Modal'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { usePaymentStore } from '@/stores/payment'
import Sidebar from '@/components/Functional/Sidebar'
import barsSVG from '@/assets/svg/light/bars.svg'
import SidebarContent from '@/components/ModalContents/SidebarContent'
import isMobile from 'ismobilejs'
import LangSwitcherContent from '@/components/ModalContents/LangSwitcherContent'
import CreatorsCatalogHeader from '@/components/CreatorsCatalog/CreatorsCatalogHeader'
import CreatorsCatalog from '@/components/CreatorsCatalog/CreatorsCatalog'
import ProfilesNav from '@/components/Common/ProfilesNav.vue'

export default {
  name: 'Creators',
  components: {
    ProfilesNav,
    Navbar,
    SignUpGiftForm,
    Modal,
    Sidebar,
    SidebarContent,
    LangSwitcherContent,
    CreatorsCatalogHeader,
    CreatorsCatalog
  },
  data () {
    return {
      barsSVG: barsSVG,
      isMobile: isMobile(window.navigator).any
    }
  },
  computed: {
    ...mapState(usePaymentStore, [
      'paymentProcessor'
    ]),
    ...mapState(useAuthStore, [
      'showGiftPopup',
      'options'
    ])
  },
  beforeCreate () {
    document.title = 'One2fan – get closer to the creators'
  },
  mounted () {
    this.$router.replace({ query: null })

    if (this.showGiftPopup && this.paymentProcessor === 'stripe') {
      setTimeout(this.$refs.signUpGiftForm.openModal, 1500)
    }

    this.invites.initInvites(this.$route)
  },
  methods: {
    swipeHandler (event) {
      this.$refs.sidebar.toggleSidebar(event === 'right')
    }
  }
}
</script>
