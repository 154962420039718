<template>
  <div class="h-full w-full max-w-full flex flex-col flex-1 placeholder">
    <div class="h-60 md:h-72 placeholder-item"/>

    <div class="px-4">
      <div class="flex items-center justify-between mt-5">
        <div class="flex items-center">
          <div
            style="height: 26px; width: 100px"
            class="placeholder-item rounded-full"/>

          <div
            class="flex items-center justify-center ml-1.5">
            <span
              style="height: 18px; width: 45px"
              class="placeholder-item rounded-full"/>

            <span class="placeholder-item ml-1 flex-shrink-0 inline-block h-2 w-2 rounded-full"/>
          </div>
        </div>
      </div>

      <div class="flex items-center mt-3 mb-4">
        <div
          style="height: 34px; width: 87px"
          class="flex items-center rounded-lg placeholder-item p-2 mr-2"/>

        <div
          style="height: 34px; width: 87px"
          class="flex items-center rounded-lg placeholder-item p-2 mr-2"/>

        <div
          style="height: 34px; width: 87px"
          class="flex items-center rounded-lg placeholder-item p-2"/>
      </div>

      <ul class="space-y-0.5">
        <li
          style="height: 18px; width: 163px"
          class="flex items-center placeholder-item rounded-full"/>

        <li
          style="height: 18px; width: 163px"
          class="flex items-center placeholder-item rounded-full"/>
      </ul>

      <div class="flex w-full mt-4 space-x-2">
        <div
          style="height: 34px; width: 146px"
          class="placeholder-item rounded"/>
      </div>
    </div>

    <div class="mt-7 mb-0.5">
      <nav class="grid grid-cols-3 gap-0.5" aria-label="Tabs">
        <a
          class="border-gray-900 text-gray-900 group inline-flex items-center justify-center py-2.5 px-1 border-b-2 border-[#ced4da] cursor-pointer">
          <div class="h-5 w-5 placeholder-item rounded-full"/>
        </a>

        <a
          class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center justify-center py-2.5 px-1 border-b-2 cursor-pointer">
          <div class="h-5 w-5 placeholder-item rounded-full"/>
        </a>

        <a
          class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 group inline-flex items-center justify-center py-2.5 px-1 border-b-2 cursor-pointer">
          <div class="h-5 w-5 placeholder-item rounded-full"/>
        </a>
      </nav>
    </div>

    <div class="grid grid-cols-3 gap-0.5">
      <div class="h-44 placeholder-item"/>
      <div class="h-44 placeholder-item"/>
      <div class="h-44 placeholder-item"/>
      <div class="h-44 placeholder-item"/>
      <div class="h-44 placeholder-item"/>
      <div class="h-44 placeholder-item"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CreatorPlaceholder',
  components: {},
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

