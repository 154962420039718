const clickOutside = {
  created (el, binding) {
    const onResizeCallback = binding.value

    window.addEventListener('resize', () => {
      const width = el.clientWidth
      const height = el.clientHeight

      onResizeCallback({ width, height })
    })
  },
  unmounted (el) {
    document.removeEventListener('resize', el)
  }
}

export default clickOutside
