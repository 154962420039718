<template>
  <Header
    :name="$t('user.me.payments')"
    class="mb-4"/>

  <div class="container">
    <PaymentsContent/>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import PaymentsContent from '@/components/PaymentsContent'

export default {
  name: 'Payments',
  components: {
    Header,
    PaymentsContent
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

