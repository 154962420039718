<template>
  <div class="w-full flex items-center justify-center post-shadow">
    <template v-if="isDatingEnabled">
      <router-link
        class="feed-link py-1.5 font-bold"
        exact-active-class="active-feed-link"
        to="/">
        {{ $t('common.moments') }}
      </router-link>
    </template>

    <template v-else>
      <router-link
        class="feed-link py-1.5"
        exact-active-class="active-feed-link"
        to="/">
        {{ $t('posts.for_you') }}
      </router-link>

      <div class="border border-r border-l-0 border-gray-300 h-4 mx-1.5"/>

      <router-link
        class="feed-link py-1.5 relative"
        exact-active-class="active-feed-link"
        to="/following">
        {{ $t('posts.following') }}

        <span
          v-if="newPostExist"
          class="absolute top-2 -right-2 block h-2 w-2 rounded-full bg-[#1CD4ED]"/>
      </router-link>
    </template>
  </div>
</template>

<script>

import { mapState, mapWritableState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { usePostStore } from '@/stores/post'
import { useAppStore } from '@/stores/app'
import helpers from '@/helpers'

export default {
  name: 'FeedLinks',
  components: {},
  data () {
    return {
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isTokenExist'
    ]),
    ...mapState(usePostStore, [
      'newPostExist'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible'
    ]),
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ])
  },
  mounted () {
  },
  methods: {
    showLogin () {
      if (!this.isTokenExist) {
        helpers.showSignUp()
      }
    }
  }
}
</script>

<style scoped>
  .feed-link.active-feed-link {
    @apply text-white;
  }
  .feed-link {
    @apply text-gray-300
  }
</style>

