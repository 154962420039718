<template>
  <div class="h-60 md:h-72 relative">
    <div
      :class="{ 'white-header': !hasPhotos }"
      class="header absolute w-full h-14 flex items-center justify-between px-4 z-10">
      <LinkBack
        :path="isUserFeedEnabled ? '/creators' : '/'"
        :class="[hasPhotos ? 'text-white' : 'text-gray-900']"/>

      <h3
        :class="[hasPhotos ? 'text-white' : 'text-gray-900']"
        class="text-center flex-1 flex justify-center items-center">
        @{{ creator.username }}

        <InlineSvg
          v-if="!options.verificationEnabled && creator.tikTokFollowerCount"
          :src="require('@/assets/svg/design/verified.svg')"
          class="ml-1.5"/>
      </h3>

      <div class="flex items-center gap-3.5">
        <button
          v-if="isAbleToShare"
          type="button"
          class="btn p-0"
          @click="shareProfile">
          <InlineSvg
            class="text-white hover:text-gray-300 w-5"
            :src="require('@/assets/svg/design/share2.svg')"/>
        </button>

        <Dropdown
          :items="[
            { text: $t('creator.block.block_this_creator'), callback: blockCreator },
            { text: $t('creator.block.report_this_creator'), callback: reportCreator }
          ]">
          <!-- dots ... -->
          <button
            id="options-menu"
            type="button"
            :class="[hasPhotos ? 'text-white hover:text-gray-300' : 'text-gray-900 hover:text-gray-500']"
            class="rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
            <svg
              class="h-5 w-5"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M6.17993 10.5C6.17993 11.0304 5.96922 11.5391 5.59415 11.9142C5.21907 12.2893 4.71036 12.5 4.17993 12.5C3.6495 12.5 3.14079 12.2893 2.76572 11.9142C2.39065 11.5391 2.17993 11.0304 2.17993 10.5C2.17993 9.96957 2.39065 9.46086 2.76572 9.08579C3.14079 8.71071 3.6495 8.5 4.17993 8.5C4.71036 8.5 5.21907 8.71071 5.59415 9.08579C5.96922 9.46086 6.17993 9.96957 6.17993 10.5ZM12.1799 10.5C12.1799 11.0304 11.9692 11.5391 11.5941 11.9142C11.2191 12.2893 10.7104 12.5 10.1799 12.5C9.6495 12.5 9.14079 12.2893 8.76572 11.9142C8.39065 11.5391 8.17993 11.0304 8.17993 10.5C8.17993 9.96957 8.39065 9.46086 8.76572 9.08579C9.14079 8.71071 9.6495 8.5 10.1799 8.5C10.7104 8.5 11.2191 8.71071 11.5941 9.08579C11.9692 9.46086 12.1799 9.96957 12.1799 10.5ZM18.1799 10.5C18.1799 11.0304 17.9692 11.5391 17.5941 11.9142C17.2191 12.2893 16.7104 12.5 16.1799 12.5C15.6495 12.5 15.1408 12.2893 14.7657 11.9142C14.3906 11.5391 14.1799 11.0304 14.1799 10.5C14.1799 9.96957 14.3906 9.46086 14.7657 9.08579C15.1408 8.71071 15.6495 8.5 16.1799 8.5C16.7104 8.5 17.2191 8.71071 17.5941 9.08579C17.9692 9.46086 18.1799 9.96957 18.1799 10.5Z"/>
            </svg>
          </button>
        </Dropdown>
      </div>
    </div>

    <div class="relative h-full">
      <img
        v-if="creator.photos.items.length === 1"
        :src="creator.photos.items[0].links.r640x960"
        class="w-full h-60 md:h-72 object-top object-cover"
        :title="creator.name.trim()"
        :alt="creator.name ? creator.name.trim() + '\'s photo' : `User's photo`">

      <img
        v-else-if="creator.photos.items.length === 0"
        :src="`/svg/${creator.sex === 1 ? 'user' : 'woman'}-placeholder.svg`"
        class="w-full h-72 object-bottom object-contain"
        :title="creator.name.trim()"
        :alt="`${creator.sex === 1 ? 'Man' : 'Woman'}`">

      <div
        v-else-if="creator.photos.items.length > 1"
        class="h-full">
        <CreatorPhotosSwiper
          v-if="creator.photos.items.length > 1 && (options.multipleProfilePhotosEnabled || beforeAuthOptions.multipleProfilePhotosEnabled)"
          :items="creator.photos.items"/>

        <img
          v-else
          :src="creator.photos.items[0].links.r640x960"
          class="w-full h-60 md:h-72 object-top object-cover"
          :title="creator.name.trim()"
          :alt="creator.name ? creator.name.trim() + '\'s photo' : `User's photo`">
      </div>

      <Tops
        v-if="0 && options.topPayersEnabled"
        class="absolute left-4 bottom-4"/>
    </div>

    <div
      v-if="iconByCatalog.has(creator.creatorCategory) && options?.activeCreatorCategories"
      class="shadow shadow-gray-800 rounded-full bg-white h-7 px-2 absolute z-10 left-4 bottom-4 flex gap-1.5 items-center justify-center">
      <img
        :src="iconByCatalog.get(creator.creatorCategory)"
        class="w-3.5 h-3.5"
        alt="emoji">

      <span class="text-sm capitalize">{{ creator.creatorCategory }}</span>
    </div>

    <!--<pre class="bg-white relative z-50">{{ beforeAuthOptions }}</pre>-->
  </div>

  <Modal
    v-slot="{ values, closeModal }"
    ref="blockModal"
    name="BlockUser">
    <BlockUserContent
      :values="values"
      :heading="$t('creator.block.block_user')"
      @close="closeModal()"/>
  </Modal>

  <Modal
    v-slot="{ values, closeModal }"
    ref="reportModal"
    name="ReportUser">
    <BlockUserContent
      :heading="$t('creator.block.report_user')"
      :values="values"
      type="report"
      @close="closeModal()"/>
  </Modal>
</template>

<script>
import LinkBack from '@/components/Functional/LinkBack'
import Dropdown from '@/components/Functional/Dropdown'
import Modal from '@/components/Functional/Modal'
import BlockUserContent from '@/components/ModalContents/BlockUserContent'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import { useCreatorStore } from '@/stores/creator'
import helpers from '@/helpers'
import Tops from '@/components/Tops/Tops'
import CreatorPhotosSwiper from '@/components/Creator/Includes/CreatorPhotosSwiper.vue'
import uniqid from 'uniqid'

export default {
  name: 'CreatorHeader',
  components: {
    LinkBack,
    Dropdown,
    Modal,
    BlockUserContent,
    Tops,
    CreatorPhotosSwiper
  },
  data () {
    return {
      shareData: {},
      iconByCatalog: new Map([
        ['new', require('@/assets/images/emoji/high-voltage.png')],
        ['top', require('@/assets/images/emoji/star.png')],
        ['hot', require('@/assets/images/emoji/fire.png')],
        ['all', false]
      ])
    }
  },
  computed: {
    ...mapState(useAuthStore, [
      'isAuthenticated',
      'isTokenExist',
      'isUserFeedEnabled',
      'options',
      'beforeAuthOptions'
    ]),
    ...mapWritableState(useCreatorStore, [
      'creator'
    ]),
    ...mapWritableState(useUserStore, [
      'options'
    ]),
    ...mapWritableState(useAuthStore, [
      'signupIsVisible'
    ]),
    hasPhotos () {
      return this.creator.photos.items.length
    },
    isAbleToShare () {
      return navigator.canShare
    }
  },
  mounted () {
    this.shareData = {
      title: 'Profile on One2Fan',
      text: 'Have a look at the profile on One2Fan',
      url: `https://one2fan.com/@${this.creator.username}`
    }
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userShared'
    ]),
    async shareProfile () {
      if (!this.isAbleToShare) {
        this.$notify({
          id: uniqid(),
          group: 'errors',
          title: this.$t('common.warn'),
          text: this.$t('notifications.no_sharing_support')
        })

        return
      }

      await navigator.share(this.shareData).finally(() => {
        this.userShared(this.creator.userId)
      })
    },
    blockCreator () {
      if (this.isTokenExist) {
        if (!this.creator.userId) return

        this.$refs.blockModal.openModal({ userId: this.creator.userId })
      } else {
        helpers.showOnbordingOrSignUp()
      }
    },
    reportCreator () {
      if (this.isTokenExist) {
        if (!this.creator.userId) {
          return
        }

        this.$refs.reportModal.openModal({
          userId: this.creator.userId,
          username: this.creator.username
        })
      } else {
        helpers.showOnbordingOrSignUp()
      }
    }
  }
}
</script>

<style scoped>
  .header {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) -3.59%, rgba(0, 0, 0, 0) 100%);
  }
  .white-header {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) -3.59%, rgba(0, 0, 0, 0) 100%);
  }
</style>
