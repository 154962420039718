<template>
  <div class="h-full px-4 z-0">
    <div
      v-show="attachmentSizeUploadError"
      class="mb-1.5 text-sm text-orange-500 flex gap-2 justify-between items-center">
      {{ attachmentSizeUploadError }}

      <InlineSvg
        :src="require('@/assets/svg/design/cross.svg')"
        class="text-orange-500 cursor-pointer"
        @click="attachmentSizeUploadError = null"/>
    </div>

    <div class="flex items-center justify-between">
      <ChatAttachmentUpload
        class="mr-2.5 overflow-hidden"/>

      <button
        v-if="messageTtlAfterReadAvailable && !isPremiumSupportUser"
        type="button"
        :disabled="chatUser.deleted"
        @click="toggleTimer">
        <InlineSvg
          :class="[
            { 'stroke-[#05AED3]': isTimerVisible && !currentTTL },
            { 'stroke-[#F23459]': currentTTL },
            { 'stroke-[#B7BDBF]': !currentTTL && !isTimerVisible },
            { 'opacity-30': chatUser.deleted },
            { 'hover:stroke-[#05AED3]': !chatUser.deleted && !isMobile && (!currentTTL && !isTimerVisible) }
          ]"
          :src="require('@/assets/svg/design/clock.svg')"
          class="cursor-pointer mr-2.5"/>
      </button>

      <div class="flex-1">
        <ChatTextForm/>
      </div>
    </div>

    <div
      v-if="messageTtlAfterReadAvailable"
      v-show="isTimerVisible">
      <p class="text-center py-3 text-gray-400 text-sm">
        {{ $t('chat.timer.next_messages') }}
      </p>

      <div class="grid grid-cols-4 gap-4 mb-2">
        <div
          v-for="item in clockVariants"
          :key="item"
          :class="[currentTTL === item.seconds ? 'bg-[#05aed3] text-white' : 'bg-gray-100 text-gray-400']"
          class="rounded-2xl flex justify-center items-center cursor-pointer"
          @click="selectClockTimer(item.seconds, item.name)">
          <span class="text-center py-3 text-sm">{{ item.name }}</span>
        </div>
      </div>
    </div>

    <teleport to="body">
      <ClockTimerMessage ref="timerMessage"/>
    </teleport>
  </div>
</template>

<script>
import ChatTextForm from '@/components/Chat/ChatForm/ChatTextForm'
import ChatAttachmentUpload from '@/components/Chat/ChatForm/ChatAttachmentUpload.vue'
import { useChatStore } from '@/stores/chat'
import { useUserStore } from '@/stores/user'
import { useAppStore } from '@/stores/app'
import { mapState, mapWritableState } from 'pinia'
import ClockTimerMessage from '@/components/Notifications/ClockTimerMessage'
import { notify } from '@kyvg/vue3-notification'

export default {
  name: 'ChatForm',
  components: {
    ChatTextForm,
    ChatAttachmentUpload,
    ClockTimerMessage
  },
  props: {},
  data () {
    return {
      isTimerVisible: false,
      clockVariants: [
        {
          seconds: 10,
          name: `10 ${this.$t('chat.timer.sec')}`
        },
        {
          seconds: 60,
          name: `60 ${this.$t('chat.timer.sec')}`
        },
        {
          seconds: 60 * 10,
          name: `10 ${this.$t('chat.timer.min')}`
        },
        {
          seconds: 0,
          name: this.$t('common.off')
        }
      ],
      selectedTimer: this.$t('common.off')
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId',
      'messageTtlAfterReadAvailable'
    ]),
    ...mapState(useChatStore, [
      'chatId',
      'chatUser',
      'currentTTL'
    ]),
    ...mapWritableState(useChatStore, [
      'ttlAfterRead',
      'attachmentSizeUploadError'
    ]),
    ...mapState(useAppStore, [
      'isMobile'
    ]),
    isPremiumSupportUser () {
      return this.premiumSupportAgentUserId.toString() === this.chatUser.userId
    }
  },
  methods: {
    toggleTimer () {
      this.isTimerVisible = !this.isTimerVisible
    },
    async selectClockTimer (seconds, name) {
      const previousTimer = this.currentTTL
      this.selectedTimer = seconds

      this.ttlAfterRead[this.chatId] = seconds
      this.isTimerVisible = false

      if (previousTimer !== seconds) {
        this.$refs.timerMessage.close()

        await new Promise(resolve => setTimeout(resolve, 210))

        const minOrSec = seconds >= 60 ? seconds / 60 : seconds
        const timeText = this.$t('chat.timer.next_message_delete', { time: minOrSec })
        const minOrSecText = seconds >= 60 ? this.$t('chat.timer.min') : this.$t('chat.timer.sec')

        const message = (name === this.$t('common.off')) ? this.$t('chat.timer.timer_is_off') : `${timeText} ${minOrSecText}`

        notify({
          group: 'timer',
          title: this.$t('chat.timer.timer_set'),
          text: message
        })
      }
    }
  }
}
</script>
