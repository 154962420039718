<script setup>
  import { inject } from 'vue'
  import app from '@/main'
  import InlineSvg from 'vue-inline-svg'

  const starStruckPng = require('@/assets/images/emoji/star-struck.png')
  const close = inject('close')
</script>

<template>
  <div 
    class="rounded-t-xl bg-white p-6 shadow-lg w-full self-end relative"
    @click.stop>
    <button
      class="text-gray-950 absolute top-4 right-4 p-2"
      type="button"
      @click="close">
      <InlineSvg
        :src="require('@/assets/svg/design/cross.svg')"
        class="w-3 h-3"/>
    </button>

    <div class="flex items-center gap-4 mb-4">
      <img
        class="h-16 w-16"
        alt="Star-struck emoji"
        :src="starStruckPng">

      <span class="font-bold text-2xl max-w-[180px]">
        {{ app.$i18n.t('external.browser.unlock_full') }}
      </span>
    </div>

    <p class="font-bold mb-2">
      {{ app.$i18n.t('external.browser.limited_version') }}
    </p>

    <p class="text-gray-500 mb-4">
      {{ app.$i18n.t('external.browser.open_in_browser') }}
    </p>

    <ol class="list-decimal pl-5 mt-2">
      <li>
        {{ app.$i18n.t('external.browser.point1') }}
      </li>

      <li>
        {{ app.$i18n.t('external.browser.point2') }}
      </li>

      <li>
        {{ app.$i18n.t('external.browser.point3') }}
      </li>
    </ol>
  </div>
</template>