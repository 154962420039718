<template>
  <div class="dialogs">
    <OnlineSupport
      v-if="premiumSupportDialog.user !== undefined"
      class="mt-2 mb-4"
      :item="premiumSupportDialog"/>

    <SearchInput
      v-model:text-changed="search"
      :placeholder="$t('user.messages.search_by_name')"
      class="mb-4"/>

    <Spinner
      v-if="contactsStatus === 'loading'"
      size="big"/>

    <div v-if="search">
      <div
        v-if="foundContactsSorted.length > 0"
        :class="{ 'with-overlay': contactsStatus === 'loading-more'}">
        <ul class="list-group list-group-flush">
          <CardListItem
            v-for="(item, index) in foundContactsSorted"
            :key="index"
            :item="item"/>
        </ul>
      </div>

      <div
        v-if="foundContactsSorted.length === 0"
        class="text-secondary">
        {{ $t('user.messages.nobody_here') }}
      </div>
    </div>

    <div v-else>
      <div
        v-if="sortedContacts.length > 0"
        :class="{ 'with-overlay': contactsStatus === 'loading-more'}">
        <ul class="list-group list-group-flush">
          <CardListItem
            v-for="(item, index) in sortedContacts"
            :key="index"
            :item="item"/>
        </ul>

        <Observer @intersect="intersected"/>

        <Spinner
          v-show="contactsStatus === 'loading-more'"
          class="dialogs-spinner"/>
      </div>

      <div
        v-if="sortedContacts.length === 0 && !contactsStatus.includes('loading')"
        class="text-secondary">
        {{ $t('user.messages.nobody_here') }}
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Functional/Spinner'
import CardListItem from '@/components/Dialogs/CardListItem'
import Observer from '@/components/Functional/Observer'
import SearchInput from '@/components/Dialogs/SearchInput'
import { mapActions, mapState } from 'pinia'
import { useDialogsStore } from '@/stores/dialogs'
import { useUserStore } from '@/stores/user'
import OnlineSupport from '@/components/Dialogs/OnlineSupport'

export default {
  name: 'TheContacts',
  components: {
    Spinner,
    CardListItem,
    Observer,
    SearchInput,
    OnlineSupport
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState(useDialogsStore, [
      'sortedContacts',
      'contactsStatus',
      'foundContactsSorted',
      'premiumSupportDialog'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ])
  },
  watch: {
    search (val) {
      this.findContacts({
        text: val,
        name: 'contacts'
      })
    }
  },
  mounted () {
    this.meGet()
    this.dialogGetList({ name: 'contacts' })

    if (this.premiumSupportAgentUserId) {
      this.getSupportDialog(this.premiumSupportAgentUserId)
    }
  },
  methods: {
    ...mapActions(useDialogsStore, [
      'dialogGetList',
      'findContacts',
      'getSupportDialog'
    ]),
    ...mapActions(useUserStore, [
      'meGet'
    ]),
    intersected () {
      if (this.contactsStatus === 'loaded') {
        this.dialogGetList({
          type: 'pagination',
          name: 'contacts'
        })
      }
    }
  }
}
</script>

<style scoped>
  .dialogs {
    max-height: 100%;
    overflow-y: auto;
  }

  .with-overlay:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    background-color: rgba(223, 223, 223, 0.1);
    z-index: 1;
  }
</style>
