<template>
  <Form
    v-slot="{ errors, setErrors }"
    class="h-full"
    :initial-values="initialValues"
    @submit="onSubmit">
    <div class="flex items-center mb-5">
      <div class="w-4/12">
        <label for="about">
          {{ $t("forms.details.labels.about_me") }}
        </label>
      </div>

      <div class="flex-1">
        <YupTooltip
          :i18n="errors.about">
          <Field
            id="about"
            :placeholder="$t('forms.details.placeholders.about_me')"
            :rules="rules.about"
            name="about"
            :validate-on-blur="false"
            :validate-on-change="false"
            :validate-on-input="false"
            type="text"
            class="form-input"
            @focus="setErrors(fieldsToReset)"/>
        </YupTooltip>
      </div>
    </div>

    <h2 class="mb-3">
      {{ $t("auth.details.interests") }}
    </h2>

    <Interests
      v-model:selected-interests="interests"/>

    <button
      :disabled="loading"
      class="btn btn-primary rounded-full btn-block mt-10">
      {{ $t("auth.details.its_all_about_me") }}
    </button>
  </Form>
</template>

<script>

import { Field, Form } from 'vee-validate'
import Interests from '@/components/Details/Interests'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import YupTooltip from '@/components/Functional/YupTooltip'

export default {
  name: 'TheDetails',
  components: {
    Field,
    Form,
    YupTooltip,
    Interests
  },
  data () {
    return {
      rules: {
        about: this.$yup.string()
          .nullable()
          .required()
      },
      fieldsToReset: {
        about: false
      },
      // Set initial values
      initialValues: {
        about: ''
      },
      interests: [],
      loading: false
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'user'
    ]),
    photosLength () {
      return this.user.photos?.items.length
    }
  },
  beforeMount () {
    this.initialValues.about = this.user.about
    if (this.user.interests) this.interests = this.user.interests.split(', ')
  },
  mounted () {},
  methods: {
    ...mapActions(useUserStore, ['meSave']),
    async onSubmit (values) {
      this.loading = true

      const data = {
        about: values.about,
        interests: this.interests.join(', ')
      }

      await this.meSave(data).finally(() => {
        this.loading = false
      })

      if (this.photosLength) {
        if (this.$route.query?.r === 'me') {
          await this.$router.push('/me')
        } else {
          await this.$router.push('/')
        }
      } else {
        await this.$router.push('/photo-upload')
      }
    }
  }
}
</script>

