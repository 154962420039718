<template>
  <div class="container px-0 pb-11 bg-white">
    <CreatorPlaceholder v-show="isLoading"/>

    <TheCreator v-if="creator.userId && !isLoading"/>

    <div
      v-else-if="!creator.userId && !isLoading"
      class="h-72 flex justify-center items-center">
      <div class="grid text-center justify-items-center gap-4">
        <svg
          width="55"
          height="66"
          viewBox="0 0 55 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M27 32C35.2843 32 42 25.2843 42 17C42 8.71573 35.2843 2 27 2C18.7157 2 12 8.71573 12 17C12 25.2843 18.7157 32 27 32Z"
            stroke="#898C95"
            fill="transparent"
            stroke-width="3"/>
          <path
            d="M53 64C49.2881 50.0176 39.2727 40 27.5 40C15.7273 40 5.71187 50.0176 2 64"
            stroke="#898C95"
            fill="transparent"
            stroke-width="3"/>
        </svg>

        <h3>
          {{ $t('creator.profile.no_account') }}
        </h3>

        <p class="text-sm text-gray-500">
          <VRuntimeTemplate
            :template="$t('creator.profile.looking_for_videos', {
              hashtags: `<router-link class='text-link' to='/discover'>${$t('creator.profile.hashtags')}</router-link>`})"/>
        </p>
      </div>
    </div>

    <OnboardingModal
      v-if="!isAuthenticated && !onboardingShown && beforeAuthOptions?.onboardingEnabled"
      ref="onboardingModal"
      :user="creator"/>
  </div>
</template>

<script>
import TheCreator from '@/components/Creator/TheCreator'
import CreatorPlaceholder from '@/components/Creator/CreatorPlaceholder'
import { mapActions, mapWritableState, mapState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import { useDialogsStore } from '@/stores/dialogs'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import OnboardingModal from '@/components/OnboardingModal'
import helpers from '@/helpers'

export default {
  name: 'CreatorBase',
  components: {
    TheCreator,
    CreatorPlaceholder,
    OnboardingModal
  },
  data () {
    return {
      loading: true,
      constantLoading: true
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'onboardingShown'
    ]),
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ]),
    ...mapState(useAuthStore, [
      'onboardingIsVisible',
      'isAuthenticated',
      'beforeAuthOptions'
    ]),
    ...mapState(useCreatorStore, [
      'creator'
    ]),
    isLoading () {
      return this.loading || this.constantLoading
    },
    ...mapWritableState(useDialogsStore, [
      'fakeInvite'
    ])
  },
  watch: {
    // Show onboarding
    onboardingIsVisible (value) {
      if (value) {
        this.$refs.onboardingModal.openOnboarding()
      }
    }
  },
  async beforeMount () {
    setTimeout(() => {
      this.constantLoading = false
    }, 600)

    await this.userGet({
      username: this.$route.params.nickname
    }).finally(async () => {
      await this.$nextTick()
      this.loading = false
    })

    this.invites.initInvites(this.$route, this.creator.userId, this.creator.contact, this.creator)

    // Save fake invite to show it im /messages recommended items
    if ((this.fakeInvite.userId === this.creator.userId) || !this.fakeInvite.userId) {
      this.fakeInvite = this.creator
    }
  },
  mounted () {
    this.$router.replace({ query: null })
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userGet'
    ]),
    showOnbordingOrSignUp () {
      helpers.showOnbordingOrSignUp()
    }
  }
}
</script>
