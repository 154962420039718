<template>
  <div v-if="!loading">
    <div
      v-if="paymentMethods.length"
      class="space-y-2">
      <div
        v-for="item in paymentMethods"
        :key="item.id"
        :class="[activePaymentMethod.id === item.id ? 'border-2 border-primary' : 'border-2 border-gray-100']"
        class="flex flex-col justify-between rounded-2xl px-4 pt-6 pb-1 h-52">
        <div class="border-b border-gray-200 flex items-center justify-between whitespace-nowrap">
          <span>{{ $t('user.your_cards.card_number') }}:</span>

          <span class="ml-2 font-mono text-caption text-gray-500">
            <template v-if="paymentProcessor === 'stripe'">
              **** **** **** {{ item.card.last4 }}
            </template>

            <template v-else-if="paymentProcessor === 'exactly'">
              {{ item.card.maskedNumber }}
            </template>
          </span>
        </div>

        <InlineSvg
          :src="require(`@/assets/svg/cards/${item.card.brand.toLowerCase()}.svg`)"
          class="w-20 h-auto"/>

        <div class="flex justify-between -mx-1.5">
          <button
            type="button"
            class="btn text-primary-press px-1.5 py-1 mt-1 text-caption"
            @click="$refs.removeCard.openModal({ paymentMethodId: item.id })">
            {{ $t('user.your_cards.remove_card') }}
          </button>

          <span
            v-if="activePaymentMethod.id === item.id"
            class="text-caption text-gray-600 px-1.5 flex items-center justify-center">
            {{ $t('user.your_cards.primary') }}
          </span>

          <button
            v-else
            type="button"
            class="btn text-link px-1.5 py-1 mt-1 text-caption"
            @click="selectedPaymentMethod = item">
            {{ $t('user.your_cards.set_primary') }}
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <p class="my-2">{{ $t('user.your_cards.no_saved_cards') }}</p>
    </div>
  </div>

  <Modal
    v-slot="{ closeModal, values }"
    ref="removeCard"
    name="RemoveCard">
    <RemoveCardContent
      :values="values"
      @close="closeModal()"/>
  </Modal>
</template>

<script>
import Modal from '@/components/Functional/Modal'
import RemoveCardContent from '@/components/ModalContents/RemoveCardContent'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'

export default {
  name: 'CardsList',
  components: {
    Modal,
    RemoveCardContent
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'activePaymentMethod',
      'paymentMethods'
    ]),
    ...mapWritableState(usePaymentStore, [
      'selectedPaymentMethod'
    ])
  },
  async beforeMount () {
    // Check if saved card is exists and get if not
    if (!this.activePaymentMethod.id) {
      this.loading = true

      await this.paymentMethodsGet().finally(() => {
        this.loading = false
      })
    }
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'paymentMethodsGet'
    ])
  }
}
</script>

