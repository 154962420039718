<template>
  <div
    class="flex flex-wrap items-center w-100">
    <div class="message hello">
      <div class="flex justify-end items-end">
        <div>
          <h4 class="mb-0.5 font-bold">Welcome message from {{ chatUser.name }} ✋</h4>

          {{ chatUser.welcomeMessageText }}
        </div>

        <div class="flex items-center">
          <div class="text-caption text-gray-400 px-1 whitespace-nowrap flex justify-center content-center"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'pinia'
import { useChatStore } from '@/stores/chat'

export default {
  name: 'ChatHelloMessage',
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapState(useChatStore, [
      'chatUser'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .message {
    @apply rounded-2xl py-2 my-0.5 px-3.5 w-max relative;
    max-width: 80%;
  }

  .message.hello {
    background: linear-gradient(#fedbe1, #f1a9b7);
    filter: drop-shadow(0px 5px 10px rgba(188, 203, 209, 0.4));
  }

  .message.hello:before {
    content: ' ';
    display: block;
    width: 13px;
    height: 18px;
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("~@/assets/svg/design/tail_hi.svg");
    position: absolute;
    bottom: 0;
    left: -6px;
  }
</style>
