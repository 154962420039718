<template>
  <div class="flex">
    <router-link :to="`/discover/${tag.name}`" class="font-bold m-auto ml-0">
      #{{ tag.name }}
    </router-link>

    <router-link :to="`/discover/${tag.name}`" class="text-caption bg-gray-100 rounded py-1 px-1.5  mr-4 flex">
      <span class="m-auto mr-2">{{ getFormattedCount(tag.postViewCount) }}</span>

      <svg
        class="m-auto"
        width="7"
        height="11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M.97.47a.75.75 0 0 1 1.06 0l4.147 4.146a1.25 1.25 0 0 1 0 1.768L2.03 10.53A.75.75 0 1 1 .97 9.47L4.94 5.5.97 1.53a.75.75 0 0 1 0-1.06z"
          fill="#86878B"/>
      </svg>
    </router-link>
  </div>

  <div class="grid grid-flow-col auto-cols-max gap-0.5 overflow-auto mt-2 scrollbar-none">
    <div
      v-for="(post, index) in tag.posts"
      :key="post.key"
      class="relative"
      @click="openPost(index)">
      <Lock
        v-if="post.locked && !post.videoUrl"
        :price="post.price"/>

      <img
        class="w-28 h-36 object-center object-cover"
        alt="placeholder photo"
        title="Title"
        :src="post.videoPreviewImageUrl">

      <div class="absolute left-2 bottom-2 text-caption text-white post-shadow opacity-80">
        {{ parseDuration(post.videoLength) }}
      </div>
    </div>

    <Observer
      v-if="tag.posts.length > 3 &&
        tag.posts.length < maxPostsToLoad &&
        tagPostsStatuses[tag.name] === 'loaded'"
      @intersect="fetchPosts"/>

    <router-link
      v-if="tag.posts.length >= maxPostsToLoad"
      :to="`/discover/${tag.name}`"
      class="w-28 h-36 flex justify-center items-center relative p-1">
      <span class="absolute top-4 text-gray-300 uppercase text-sm">
        {{ $t('creator.tags.see_more') }}
      </span>

      <span
        class="bg-primary text-sm text-white py-1 px-2 rounded-full overflow-hidden overflow-ellipsis whitespace-nowrap max-w-full">{{
        tag.name
      }}</span>
    </router-link>

    <Modal
      ref="modalPosts"
      v-slot="{ values, closeModal }"
      name="ThePostsSwiper"
      :is-rounded="false"
      :has-indents="false"
      :show-close-cross="false"
      :show-desktop-close-cross="true"
      :allow-close-by-overlay="true"
      :is-full-height="true"
      content-class="h-full bg-gray-700"
      position="center">
      <ThePostsSwiper
        :posts="tag.posts"
        :users="tagUsers"
        :show-close="true"
        :values="values"
        @close="closeModal"
        @slide-changed="slideChanged"
        @reach-end="reachEnd"
        @liked="liked"
        @shared="shared"
        @unlocked="unlocked"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>

<script>
import Lock from '@/components/Functional/Lock.vue'
import Modal from '@/components/Functional/Modal'
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import Observer from '@/components/Functional/Observer'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useTagsStore } from '@/stores/tags'
import helpers from '@/helpers'


export default {
  name: 'TagContent',
  components: {
    Lock,
    Modal,
    ThePostsSwiper,
    Observer
  },
  props: {
    tag: {
      type: Object,
      default: () => ({})
    },
    fetchTags: {
      type: Function,
      default: null
    }
  },
  emits: ['subscribed'],
  data () {
    return {
      maxPostsToLoad: 20,
      requestParams: {
        name: this.tag.name,
        limit: 10,
        offset: this.tag.posts.length
      }
    }
  },
  computed: {
    ...mapState(usePostStore, [
      'tagUsers'
    ]),
    ...mapState(useTagsStore, [
      'loadingStatus',
      'tagPostsStatuses'
    ]),
    ...mapWritableState(useTagsStore, [
      'tags'
    ])
  },
  watch: {
    'tag.posts' (val) {
      this.requestParams.offset = val.length
    }
  },
  methods: {
    ...mapActions(useTagsStore, [
      'tagPostsGet',
      'shareCount',
      'likeToggle'
    ]),
    getFormattedCount (count) {
      let firstNumber = ''
      let lastNumber = ''
      let letter = ''

      if (count >= 1000000) {
        firstNumber = Math.floor(count / 1000000)
        lastNumber = count % 1000000
        if (lastNumber >= 100000) {
          lastNumber = '.' + String(lastNumber)[0]
        } else {
          lastNumber = ''
        }
        letter = 'M'
      } else if (count >= 1000) {
        firstNumber = Math.floor(count / 1000)
        lastNumber = count % 1000
        if (lastNumber >= 100) {
          lastNumber = '.' + String(lastNumber)[0]
        } else {
          lastNumber = ''
        }
        letter = 'K'
      } else {
        firstNumber = count
      }

      return `${firstNumber}${lastNumber}${letter}`
    },
    async fetchPosts () {
      if (this.loadingStatus === 'loading') return

      await this.tagPostsGet(this.requestParams)
    },
    openPost (index) {
      this.$refs.modalPosts.openModal({ initialIndex: index })
    },
    async reachEnd () {
      if (this.loadingStatus !== 'loaded-all') {
        await this.fetchPosts()
      }
    },
    async slideChanged (index) {
      if (this.loadingStatus === 'loaded-all') return

      if (index >= this.tag.posts.length - 3) {
        await this.fetchPosts()
      }
    },
    liked (postId) {
      this.likeToggle({
        tagName: this.tag.name,
        postId
      })
    },
    shared (postId) {
      this.shareCount({
        tagName: this.tag.name,
        postId
      })
    },
    subscribed (user) {
      this.$refs.modalPosts.closeModal()
      this.$router.push(`/@${user.username}`)
    },
    async unlocked ({ post, index }) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.tag.name)
      if (!tagIndex) return

      this.tags[tagIndex].posts.forEach((item, index) => {
        if (item.id === post.id) {
          this.tags[tagIndex].posts[index] = post
        }
      })
    },
    parseDuration (seconds) {
      return helpers.parseDuration(seconds)
    }
  }
}
</script>
