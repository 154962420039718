<template>
  <div class="flex flex-col justify-between h-screen pb-4">
    <div>
      <Header
        :name="isAuthenticated ? $t('user.me.reset_password') : $t('auth.login.forgot_password')"
        type="password-recovery"
        force-link-back
        class="mb-6"/>

      <div class="container">
        <h1 class="mb-5">
          {{ $t('auth.recovery.heading') }}
        </h1>

        <div
          v-if="recoverEmail"
          class="my-2"
          v-html="$t('auth.recovery.message_send', { recovery_email: `<span class='text-primary'>${recoverEmail}</span>` })"/>

        <div v-else>
          <p class="mb-4">
            {{ $t("auth.recovery.description") }}
          </p>

          <Form
            id="recover_password"
            ref="signInForm"
            v-slot="{ errors, setErrors }"
            :initial-values="initialValues"
            @submit="onSubmit">
            <div class="flex items-center mb-3">
              <div class="w-3/12">
                <label for="email">
                  {{ $t("forms.recovery.labels.email") }}
                </label>
              </div>

              <div class="flex-1">
                <yupTooltip :i18n="errors.email">
                  <Field
                    id="email"
                    :placeholder="$t('forms.recovery.placeholders.email')"
                    :rules="emailRules"
                    name="email"
                    :validate-on-blur="false"
                    :validate-on-change="false"
                    :validate-on-input="false"
                    type="text"
                    class="form-input"
                    @focus="setErrors(fields)"/>
                </yupTooltip>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>

    <div class="container w-full">
      <button
        v-if="!recoverEmail"
        :disabled="isLoading"
        form="recover_password"
        type="submit"
        class="btn btn-primary btn-block mb-3">
        {{ $t("common.send") }}
      </button>

      <button
        v-else
        disabled
        form="recover_password"
        type="submit"
        class="btn btn-primary btn-block mb-3"
        @click.prevent="resetRecoverEmail">
        {{ $t("auth.signup.recovery_sent") }}
      </button>

      <div class="text-center my-2">
        <a
          class="text-caption text-primary"
          target="_blank"
          :href="`${$window.one2fan.origin}/terms`">
          {{ $t("common.terms_of_service") }}
        </a>

        <span class="text-caption text-gray-500">&nbsp;{{ $t("common.and") }}&nbsp;</span>

        <a
          class="text-caption text-primary"
          target="_blank"
          :href="`${$window.one2fan.origin}/policy`">
          {{ $t("common.privacy_policy") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import { Field, Form } from 'vee-validate'
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useUserStore } from '@/stores/user'
import helpers from '@/helpers'
import yupTooltip from '@/components/Functional/YupTooltip'

export default {
  name: 'PasswordRecovery',
  components: {
    Header,
    Field,
    Form,
    yupTooltip
  },
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      fields: {
        email: false
      },
      isLoading: false,
      initialValues: {},
      emailRules: this.$yup.string().required().matches(/^\S*$/, () => ({ key: 'yup.mixed.default', values: {} })).email()
    }
  },
  computed: {
    ...mapWritableState(useAuthStore, [
      'recoverEmail',
      'loginIsVisible'
    ]),
    ...mapState(useUserStore, [
      'user'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated'
    ])
  },
  async beforeMount () {
    if (this.isAuthenticated) {
      this.initialValues = {
        email: this.user.email
      }
    }
  },
  async beforeUnmount () {
    if (!this.isAuthenticated) {
      await helpers.showLogIn()
    }
  },
  beforeCreate () {
    document.title = 'Password Recovery'
  },
  created () {
  },
  methods: {
    ...mapActions(useAuthStore, [
      'meRecoverPassword'
    ]),
    async onSubmit (values) {
      this.isLoading = true
      await this.meRecoverPassword(values).finally(() => {
        this.isLoading = false
      })
    },
    resetRecoverEmail () {
      this.recoverEmail = ''
    }
  }
}
</script>
