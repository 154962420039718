<template>
  <div class="flex items-center justify-between mb-2">
    <h2 class="h2">
      {{ $t('creator.unsubscribe.heading') }}
    </h2>

    <button
      class="btn py-2 pl-3 pr-0 cursor-pointer"
      @click="emitClose">
      <ImageLoader v-slot="{ setLoaded }">
        <InlineSvg
          :src="crossSVG"
          class="text-gray-100"
          @loaded="setLoaded"/>
      </ImageLoader>
    </button>
  </div>

  <div v-if="subscription">
    <p
      v-if="subscription.expirationTs"
      class="mb-5">
      {{ $t('creator.unsubscribe.disable_renewal', { date: dateToTime(subscription.expirationTs) }) }}
    </p>

    <p
      v-else
      class="mb-5">
      {{ $t('creator.unsubscribe.cancel_subscription') }}
    </p>
  </div>

  <div v-else class="mb-5 mt-5">
    <Spinner/>
  </div>


  <button
    type="submit"
    class="btn btn-block btn-primary mb-3"
    :disabled="isLoading"
    @click="unsubscribe">
    {{ $t('common.yes') }}
  </button>

  <button
    type="submit"
    class="btn btn-block btn-secondary"
    :disabled="isLoading"
    @click="emitClose">
    {{ $t('common.cancel') }}
  </button>
</template>

<script>
import ImageLoader from '@/components/Functional/ImageLoader'
import crossSVG from '@/assets/svg/design/cross.svg'
import Spinner from '@/components/Functional/Spinner'
import { mapActions, mapState } from 'pinia'
import { useSubscriptionsStore } from '@/stores/subscriptions'
import app from '@/main'

export default {
  name: 'UnsubscribeContent',
  components: {
    ImageLoader,
    Spinner
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    }
  },
  emits: ['close', 'unsubscribed'],
  data () {
    return {
      crossSVG: crossSVG,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useSubscriptionsStore, ['subscriptions']),
    subscription () {
      return this.subscriptions.find((subscription) => subscription.publisherUser.userId === this.values.userId)
    }
  },
  async beforeMount () {
    if (this.subscription) return

    await this.subscriptionsGet()
  },
  methods: {
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet'
    ]),
    async emitClose () {
      await this.$emit('close')
    },
    async unsubscribe () {
      if (!this.values.userId) return

      this.isLoading = true

      // Delete subscription
      await this.subscriptionDelete(this.values.userId).finally(() => {
        this.isLoading = false
      })

      // Get fresh user item and emit
      const user = await this.userGet(this.values.userId)
      await this.$emit('unsubscribed', user)

      await this.emitClose()
    },
    dateToTime (value) {
      const date = this.$dayjs.unix(value)

      return date.locale(this.$i18n.locale).format('MMM D, YYYY')
    },
    async subscriptionDelete (userId) {
      const res = await app.wsp.sendRequest({
        data: {
          publisherUserId: userId
        },
        method: 'subscription.delete'
      })

      if (res.error) {
        console.log(res.error.message)
      }
    },
    async userGet (userId) {
      const res = await app.wsp.sendRequest({ data: { id: userId }, method: 'user.get' })

      if (res.error) {
        console.log(res.error.message)
      }

      return res.data.user
    }
  }
}
</script>

