<template>
  <div>
    <div class="flex items-center justify-between mb-6">
      <h2 class="h1">
        {{ $t("common.login") }}
      </h2>

      <CloseCrossButton @click="emitClose"/>
    </div>

    <LoginForm/>
  </div>
</template>

<script>

import LoginForm from '@/components/LoginForm'
import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'

export default {
  name: 'LoginContent',
  components: {
    LoginForm,
    CloseCrossButton
  },
  emits: ['close'],
  data () {
    return {}
  },
  methods: {
    async emitClose () {
      await this.$emit('close')
    }
  }
}
</script>
