import uniqid from 'uniqid'
import { notify } from '@kyvg/vue3-notification'
import creatorsDump from '@/assets/creators-dump.json'
import { sample, random } from 'lodash'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { useInvitesStore } from '@/stores/invites'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'

export class Invites {
  constructor () {
    this.firstTimeout = null
    this.regularTimeout = null
  }

  getRandomUserWithInviteMessage () {
    const user = sample(creatorsDump)

    if (!user.welcomeMessageText) {
      return this.getRandomUserWithInviteMessage(creatorsDump)
    }
    else {
      return user
    }
  }

  getUserByIdWithInviteMessage (userId) {
    const index = creatorsDump.findIndex((item) => item.userId === userId + '')

    if (index !== -1 && creatorsDump[index].welcomeMessageText) {
      return creatorsDump[index]
    }
    else {
      console.log('No user or invite message')
    }
  }

  showInvite (user) {
    notify({
      id: uniqid(),
      group: 'invites',
      title: `${user.name}, ${user.age}`,
      text: user.welcomeMessageText,
      data: {
        user: user
      }
    })
  }

  clearTimeouts () {
    if (this.firstTimeout) clearTimeout(this.firstTimeout)
    if (this.regularTimeout) clearTimeout(this.regularTimeout)
  }

  // If there's user object therefore show invite from object
  initInvites (route, userId, contact, user = undefined) {
    const authStore = useAuthStore()
    const invitesStore = useInvitesStore()
    const appStore = useAppStore()

    // Don't show any invites (bombs) if secret state
    if (appStore.secretToken) {
      return
    }

    if (['Creators', 'Home', 'Following', 'Discover', 'DiscoverTag'].includes(route.name)) {
      if (!authStore.isTokenExist) {
        // Show invite from user's object (From API Object) else show from dump
        if (user !== undefined) {
          this.realInvites({
            userId: userId,
            user: user,
            randomRange: [120000, 200000]
          })
        }
        else {
          this.firstTimeout = setTimeout(() => {
            this.fakeInvites({ randomRange: [120000, 200000] })
          }, 60000)
        }
      }
    }
    else if (['Creator', 'Post'].includes(route.name) && userId) {
      if (authStore.isTokenExist) {
        if (!contact) {
          // Show invite from user's object (From API Object) else show from dump
          if (user !== undefined) {
            this.firstTimeout = setTimeout(() => {
              this.realInvites({
                userId: userId,
                randomRange: [120000, 120000],
                user: user
              })
            }, invitesStore.initialTimeAuth[userId] || 25000)
          }
          else {
            this.firstTimeout = setTimeout(() => {
              this.fakeInvites({
                userId: userId,
                randomRange: [120000, 120000]
              })
            }, invitesStore.initialTimeAuth[userId] || 25000)
          }
        }
        else {
          // console.log('No invites. User contacted')
          // No invites. User contacted
        }
      }
      else {
        // User without auth
        // Show invite from user's object (From API) else show from dump
        if (user !== undefined) {
          this.firstTimeout = setTimeout(() => {
            this.realInvites({
              userId: userId,
              randomRange: [80000, 80000],
              user: user
            })
          }, invitesStore.initialTime[userId] || 10000)
        }
        else {
          this.firstTimeout = setTimeout(() => {
            this.fakeInvites({
              userId: userId,
              randomRange: [80000, 80000]
            })
          }, invitesStore.initialTime[userId] || 10000)
        }
      }
    }
  }

  // Get real invite from user's object
  realInvites ({ userId, user, randomRange }) {
    const authStore = useAuthStore()
    const invitesStore = useInvitesStore()

    authStore.isTokenExist
      ? invitesStore.initialTimeAuth[userId] = 120000
      : invitesStore.initialTime[userId] = 80000

    const randomTime = random(randomRange[0], randomRange[1])

    if (user !== undefined) {
      const creatorsCatalogStore = useCreatorsCatalogStore()
      const appStore = useAppStore()

      if (!creatorsCatalogStore.searchFormOpen && !appStore.secretToken) {
        this.showInvite(user)
      }

      this.regularTimeout = setTimeout(this.realInvites.bind(this, { userId, randomRange }),
        randomTime)
    }
  }

  // Get fake invite from dump
  fakeInvites ({ userId = null, randomRange }) {
    const authStore = useAuthStore()
    const invitesStore = useInvitesStore()

    if (userId) {
      authStore.isTokenExist
        ? invitesStore.initialTimeAuth[userId] = 120000
        : invitesStore.initialTime[userId] = 80000
    }

    const randomTime = random(randomRange[0], randomRange[1])

    const user = userId === null
      ? this.getRandomUserWithInviteMessage()
      : this.getUserByIdWithInviteMessage(userId)

    if (user !== undefined) {
      const creatorsCatalogStore = useCreatorsCatalogStore()
      const appStore = useAppStore()

      // Don't show fake invites (bombs) if search form is open in Creators Catalog
      if (!creatorsCatalogStore.searchFormOpen && !appStore.secretToken) {
        this.showInvite(user)
      }

      this.regularTimeout = setTimeout(this.fakeInvites.bind(this, {
        userId,
        randomRange
      }), randomTime)
    }
    else {
      console.log('No invite to show')
    }
  }
}
