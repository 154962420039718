<template>
  <li>
    <router-link
      :to="`/chat/${item.user.userId}`"
      class="flex items-center border-b border-gray-100 pb-3 mb-3">
      <div class="mr-2.5" style="min-width: 50px; height: 50px; width: 50px">
        <Avatar :user-item="item.user" :show-status="true"/>
      </div>

      <div class="flex flex-1 flex-col" style="width: calc(100% - 60px)">
        <div class="flex justify-between">
          <div class="whitespace-nowrap w-8/12">
            <h3
              :title="item.user.name + ` (${item.user.username})`"
              :class="{ 'has-unread': item.numberUnreadMessages > 0 }"
              class="overflow-ellipsis overflow-hidden text-sm font-semibold">
              {{ item.user.name }}

              <InlineSvg
                v-if="options.verificationEnabled && item.user?.tikTokFollowerCount"
                :src="require('@/assets/svg/design/verified.svg')"
                class="inline mb-1 -ml-0.5 mr-0.5"/>

              <span v-if="item.user?.username" class="text-gray-400 inline">@{{ item.user.username }}</span>
            </h3>
          </div>

          <time
            v-if="createdDate"
            :datetime="createdDate"
            :title="createdDateWithTime"
            class="leading-5 text-caption text-gray-600 whitespace-nowrap">
            {{ createdDateFormatted }}
          </time>
        </div>

        <div class="flex justify-between items-center">
          <small
            class="text-sm line-clamp-2"
            :class="{
              'text-gray-400': !message.text || message.text === '[message deleted]'
            }">
            {{ textMessage }}
          </small>

          <CounterBadge :count="item.numberUnreadMessages"/>
        </div>
      </div>
    </router-link>
  </li>
</template>

<script>

import Avatar from '@/components/Functional/Avatar'
import CounterBadge from '@/components/Functional/CounterBadge'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'CardListItem',
  components: {
    Avatar,
    CounterBadge
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'options'
    ]),
    message () {
      return this.item.message ? this.item.message : {}
    },
    isAttachmentWithMessage () {
      return this.message.type === 3 && this.message.text && !(['[Photo]', '[Video]'].includes(this.message.text))
    },
    isCurrentItemVideo () {
      return !!this.message.attachment?.fields?.videoUrl || this.message.attachment?.mediaType === 'video'
    },
    isCurrentItemPhoto () {
      return !this.message.attachment?.fields?.videoUrl && this.message.type === 3
    },
    textMessage () {
      if (this.isAttachmentWithMessage) {
        return this.message.text
      } else if (this.isCurrentItemVideo) {
        return '[Video]'
      } else if (this.isCurrentItemPhoto) {
        return '[Photo]'
      } else if (this.message.text) {
        return this.message.text
      } else {
        return 'No messages yet'
      }
    },
    createdDateFormatted () {
      if (!this.message.created) {
        return ''
      }

      const date = this.$dayjs(this.message.created * 1000)
      return date.isToday() ? date.locale(this.$i18n.locale).format('h:mm A') : date.locale(this.$i18n.locale).format('MMM, D YYYY')
    },
    createdDate () {
      if (!this.message.created) {
        return ''
      }

      return this.$dayjs(this.message.created * 1000).locale(this.$i18n.locale).format('YYYY-MM-DD')
    },
    createdDateWithTime () {
      // Nov 28, 2016 14:00
      return this.$dayjs(this.message.created * 1000).locale(this.$i18n.locale).format('MMM, D YYYY HH:mm')
    }
  },
  mounted () {
  },
  methods: {}
}
</script>
