<template>
  <div id="legal-information-1">
    <table class="mt-10 w-full text-caption table-fixed border-separate">
      <thead>
        <tr>
          <th class="w-1/4"/>
          <th class="w-2/5"/>
        </tr>
      </thead>

      <tbody class="align-top">
        <tr>
          <td id="legal" ref="legal">
            {{ $t("user.credits.legal.billing_descriptor") }}:
          </td>

          <td class="text-gray-500 pb-2">One2fan</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.company") }}:
          </td>

          <td class="text-gray-500 pb-2">Culturika s.r.o.</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.registered") }}:
          </td>

          <td class="text-gray-500 pb-2">July 19, 2018</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.registration_number") }}:
          </td>

          <td class="text-gray-500 pb-2">C 298635</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.address") }}:
          </td>

          <td class="text-gray-500 pb-2">7a Narozni 2787/7a, Praha 5, Czech Republic</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.mailing_address") }}:
          </td>

          <td class="text-gray-500 pb-2">7a Narozni 2787/7a, Praha 5 158 00, Czech Republic</td>
        </tr>

        <tr>
          <td>
            {{ $t("user.credits.legal.official_representative") }}:
          </td>

          <td class="text-gray-500 pb-2">Culturika s.r.o.</td>
        </tr>
      </tbody>
    </table>

    <p
      class="text-gray-500 text-sm mt-2 py-3"
      v-html="$t('user.credits.legal.support_information', {email: support})"/>
  </div>
</template>

<script>

export default {
  name: 'LegalInformation',
  components: {},
  data () {
    return {
      support: '<a class="text-primary" href="mailto:support@one2fan.com">support@one2fan.com</a>'
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

