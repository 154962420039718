<template>
  <div class="relative h-full w-full">
    <FeedLinks class="absolute top-1 h-10 z-10"/>

    <PostPlaceholder v-if="isLoading"/>

    <ThePostsSwiper
      v-else
      :posts="publicFeed"
      :users="publicUsers"
      @after-init="afterInit"
      @reach-end="reachEnd"
      @unlocked="unlocked"
      @slide-changed="slideChanged"
      @liked="liked"
      @subscribed="subscribed"
      @shared="shared"/>
  </div>
</template>

<script>
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import FeedLinks from '@/components/Feed/FeedLinks'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useFeedStore } from '@/stores/feed'
import PostPlaceholder from '@/components/Post/PostPlaceholder'

export default {
  name: 'TheFollowing',
  components: {
    ThePostsSwiper,
    FeedLinks,
    PostPlaceholder
  },
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      constantLoading: true,
      loading: true
    }
  },
  computed: {
    ...mapState(useFeedStore, [
      'publicUsers'
    ]),
    ...mapWritableState(useFeedStore, [
      'publicFeed',
      'publicUsers',
      'publicViewedIds',
      'loadingStatus'
    ]),
    isLoading () {
      return this.constantLoading || this.loading
    }
  },
  async beforeMount () {
    setTimeout(() => {
      this.constantLoading = false
    }, 500)

    this.loadingStatus = ''
    this.publicFeed = []
    this.publicUsers = []

    await this.feed({ type: 'public', limit: 10 }).finally(() => {
      this.loading = false
    })
  },
  mounted () {
  },
  methods: {
    ...mapActions(useFeedStore, [
      'feed',
      'setItemStatusByIndex',
      'likeToggle',
      'shareCount'
    ]),
    afterInit ({ index }) {
      if (this.publicFeed[index].status !== 'past') {
        this.setItemStatusByIndex({ type: 'public', index, status: 'viewed' })

        this.publicViewedIds.push(this.publicFeed[index].id)
      }
    },
    slideChanged ({ index }) {
      if (this.publicFeed[index].status !== 'past') {
        this.setItemStatusByIndex({ type: 'public', index, status: 'viewed' })

        this.publicViewedIds.push(this.publicFeed[index].id)
      }

      // Upload more posts before slider reaches the end
      if (this.publicFeed.length - index <= 5) {
        // console.log(1, 'slideChanged', 'Загрузить больше постов', this.publicFeed.length - index, '<= 5')
        this.feed({ type: 'public', limit: 10 })
      }
    },
    reachEnd () {
      // console.log(2, 'reachEnd', 'Загрузить больше постов', '<= 5')
      this.feed({ type: 'public', limit: 10 })
    },
    liked (postId) {
      this.likeToggle({ type: 'public', postId })
    },
    shared (postId) {
      this.shareCount({ type: 'public', postId })
    },
    subscribed (user) {
      this.$router.push(`/@${user.username}`)
    },
    unlocked ({ post }) {
      // Update all Posts (even duplicates)
      this.publicFeed.forEach((item, index) => {
        if (item.id === post.id) {
          this.publicFeed[index] = post
        }
      })
    }
  }
}
</script>
