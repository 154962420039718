<template>
  <notifications
    v-touch:swipe.left="swipeHandler"
    v-touch:swipe.right="swipeHandler"
    v-touch:swipe.top="swipeHandler"
    v-touch:swipe.bottom="swipeHandler"
    group="success"
    :class="fadeOutClasses"
    :width="getScreenWidth() > 1000 ? '342px' : '100%'"
    :style="{ top: '16px' }"
    :duration="5000"
    :max="getScreenWidth() > 1000 ? 2 : 1"
    position="top left">
    <template #body="props">
      <div
        ref="messageBody"
        :data-message-id="props.item.id"
        class="fancy-message rounded-xl relative">
        <button
          class="absolute right-1 top-1 p-0 m-0"
          @click="close">
          <svg
            class="text-gray-600 opacity-50 inline-block w-6 h-6 p-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path
              d="m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"/>
          </svg>
        </button>

        <div
          class="flex justify-center w-full no-underline"
          @click="props.close">
          <svg
            class="h-10 w-10 mr-2 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.31 195.31-184 184a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62z"
              style="opacity:.4"/>
            <path
              d="M227.31 387.31a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62l-184 184z"/>
          </svg>

          <div class="flex flex-col flex-1 justify-center">
            <h3
              v-if="props.item.title"
              class="mt-0 text-base mr-3">
              {{ props.item.title }}
            </h3>

            <p class="line-clamp-2 p-0 m-0 text-sm">
              <a :title="props.item.text">{{ props.item.text }}</a>
            </p>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import '../../../node_modules/animate.css/animate.css'

export default {
  name: 'SuccessMessage',
  components: {},
  data () {
    return {
      textWidth: 0,
      fadeOutClasses: '',
      screenWidth: 1000
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    getScreenWidth () {
      return window.screen.width
    },
    swipeHandler (direction, event) {
      let animationDirection = ''

      if (direction === 'bottom') {
        animationDirection = 'Down'
      } else if (direction === 'top') {
        animationDirection = 'Up'
      } else if (direction === 'left') {
        animationDirection = 'Left'
      } else if (direction === 'right') {
        animationDirection = 'Right'
      }

      this.fadeOutClasses = `animate__fadeOut${animationDirection} animate__animated animate__faster`

      this.$notify({
        group: 'success',
        clean: true
      })

      const vm = this

      setTimeout(() => {
        vm.fadeOutClasses = ''
      }, 500)
    },
    close () {
      this.$notify({
        group: 'success',
        clean: true
      })
    }
  }
}
</script>

<style scoped>
  .fancy-message {
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.13);
    background: #FFFFFF;
    border-radius: 10px;
    padding: 12px 15px;
    margin: 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

