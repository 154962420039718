<template>
  <div class="grid grid-flow-col auto-cols-max gap-1 overflow-auto mb-6 scrollbar-none">
    <router-link
      v-for="(tag, index) in filteredTags"
      :key="tag.name"
      :to="`/discover/${tag.name}`"
      class="px-3 py-1 text-sm border border-gray-300 rounded-full cursor-pointer"
      :class="{ 'bg-primary text-white': index === 0 }">
      {{ tag.name }}
    </router-link>

    <div
      v-show="~loadingStatus.indexOf('loading')"
      class="h-8 w-full flex">
      <Spinner/>
    </div>

    <Observer v-if="loadingStatus && loadingStatus !== 'loaded-all'" @intersect="fetchTags"/>
  </div>

  <div
    v-for="tag in filteredTags"
    :key="tag"
    class="mt-5">
    <TagContent
      :tag="tag"
      @subscribed="subscribed"/>
  </div>

  <div
    v-if="loadingStatus === 'loaded-all'"
    class="my-4 leading-10 w-full text-caption text-gray-400 text-center"
    :class="{'border-b border-grey-600': filteredTags.length > 0}">
    {{ filteredTags.length > 0 ? $t('common.end_of_list') : $t('common.no_content') }}
  </div>

  <div
    v-show="~loadingStatus.indexOf('loading')"
    class="h-24 w-full flex">
    <Spinner size="big"/>
  </div>

  <Observer
    v-if="loadingStatus !== 'loaded-all'"
    @intersect="fetchTags"/>
</template>

<script>
import Observer from '@/components/Functional/Observer'
import TagContent from './TagContent'
import Spinner from '@/components/Functional/Spinner'
import { mapActions, mapState } from 'pinia'
import { useTagsStore } from '@/stores/tags'

export default {
  name: 'TheDiscover',
  components: {
    Observer,
    TagContent,
    Spinner
  },
  data () {
    return {
      tagsGetParams: {
        limit: 2,
        offset: 0,
        postLimitPerTag: 10
      }
    }
  },
  computed: {
    ...mapState(useTagsStore, [
      'tags',
      'loadingStatus'
    ]),
    filteredTags () {
      return this.tags.filter((tag) => tag.posts.length > 0)
    }
  },
  watch: {
    tags (val) {
      this.tagsGetParams.offset = val.length
    }
  },
  async beforeUnmount () {
    useTagsStore().$reset()
  },
  async mounted () {
    await this.fetchTags()
    await this.fetchTags()
    await this.fetchTags()

    this.tagsGetParams.limit = 4
  },
  methods: {
    ...mapActions(useTagsStore, [
      'tagsGet'
    ]),
    async fetchTags () {
      if (this.loadingStatus === 'loading') return

      await this.tagsGet(this.tagsGetParams)
    },
    async subscribed () {
      await useTagsStore().$reset()
      await this.fetchTags()
    }
  }

}
</script>

<style scoped>
  .search {
    background: url('~@/assets/svg/regular/search.svg') no-repeat 10px;
    background-size: 17px;
    background-position: right;
  }

  .scrollBar-none::-webkit-scrollbar {
    display: none;
  }

  .bg-primary {
    border-color: #f23459;
  }
</style>
