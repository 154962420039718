<template>
  <div class="flex items-center justify-between mb-5">
    <h2 class="h2">
      {{ heading }}
    </h2>

    <CloseCrossButton @click="emitClose"/>
  </div>

  <button
    type="submit"
    class="btn btn-block btn-primary mb-3"
    :disabled="isLoading"
    @click="blockUser">
    {{ $t('common.yes') }}
  </button>

  <button
    type="submit"
    class="btn btn-block btn-secondary"
    @click="emitClose">
    {{ $t('common.cancel') }}
  </button>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'
import uniqid from 'uniqid'

export default {
  name: 'BlockUserContent',
  components: {
    CloseCrossButton
  },
  props: {
    heading: {
      type: String,
      default: 'Block user?'
    },
    values: {
      type: Object,
      required: false,
      default: () => ({
        userId: null
      })
    },
    userId: {
      type: String,
      default: null
    },
    // report
    type: {
      type: String,
      default: null
    }
  },
  emits: ['close'],
  data () {
    return {
      isVisible: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'premiumSupportAgentUserId'
    ])
  },
  methods: {
    ...mapActions(useUserStore, [
      'userBlock'
    ]),
    async emitClose () {
      await this.$emit('close')
    },
    async blockUser () {
      this.isLoading = true

      const userId = this.values.userId || this.userId

      if (!userId) {
        return
      }

      if (this.type === 'report') {
        if (!this.premiumSupportAgentUserId || !this.values.username) {
          return
        }

        await this.wsp.sendRequest({
          data: {
            text: `I want to report creator @${this.values.username} (ID:${userId})`,
            receiverId: this.premiumSupportAgentUserId,
            clientId: uniqid(),
            ttlAfterRead: null,
            highlighted: true,
            surveyResultId: '33030'
          },
          method: 'message.send'
        }).then(() => {
          this.emitClose()
          this.$router.push(`/chat/${this.premiumSupportAgentUserId}`)
        })
      } else {
        await this.userBlock(userId)
          .finally(() => {
            this.isLoading = false
            this.$addEvent(['User block', { userId: userId }])
          })
      }

      await this.emitClose()
    }
  }
}
</script>
