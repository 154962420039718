<template>
  <div
    :class="{ 'big': size === 'big' }"
    class="sk-pulse sk-center"/>
</template>

<script>

export default {
  name: 'Spinner',
  components: {},
  props: {
    size: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

<style>
  .sk-pulse.big {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }
</style>
