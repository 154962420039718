import { getUserLocale } from 'get-user-locale'
import { useAppStore } from '@/stores/app'
import supportedLocales from '@/assets/supported-locales.json'

/*
* i18n helpers from Phrase team
* https://phrase.com/blog/posts/ultimate-guide-to-vue-localization-with-vue-i18n/
* */
const i18nHelpers = {
  loadLocaleMessages () {
    const locales = require.context(
      './locales',
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
    )

    const messages = {}

    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)

      if (matched && matched.length > 1) {
        const locale = matched[1]

        messages[locale] = locales(key)
      }
    })

    return messages
  },
  setDocumentLang (lang) {
    document.documentElement.lang = lang
  },
  getBrowserLocale (options = {}) {
    const defaultOptions = { countryCodeOnly: false }
    const opt = { ...defaultOptions, ...options }

    const navigatorLocale = getUserLocale()

    if (!navigatorLocale) {
      return undefined
    }

    return opt.countryCodeOnly
      ? navigatorLocale.trim().split(/[-_]/)[0]
      : navigatorLocale.trim()
  },
  getStartingLocale () {
    const appStore = useAppStore()

    const prelandLocale = localStorage.getItem('prelandLocale')

    if (prelandLocale) {
      appStore.selectedLocale = prelandLocale
      localStorage.removeItem('prelandLocale')
    }

    const selectedLocale = appStore.selectedLocale
    const browserLocale = this.getBrowserLocale({ countryCodeOnly: true })

    if (selectedLocale) {
      return selectedLocale
    } else {
      if (supportedLocales.find(item => item.code === browserLocale)) {
        return browserLocale
      } else {
        return 'en'
      }
    }
  },
  setDocumentDirectionPerLocale (locale) {
    document.dir = locale === 'ar' ? 'rtl' : 'ltr'
  }
}

export default i18nHelpers
