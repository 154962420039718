<template>
  <div
    v-click-outside="reset"
    class="relative">
    <LongTap @long-tap="longTap">
      <slot/>
    </LongTap>

    <transition name="fade">
      <div
        v-show="tooltipVisible"
        class="chat-tooltip noselect"
        @click="tooltipVisible = false">
        <div class="flex items-center">
          <div class="flex items-center" @click="messageDelete(message.messageId)">
            <InlineSvg
              class="w-3 mr-1.5"
              :src="require('@/assets/svg/fontawesome/trash.svg')"/>
            Delete
          </div>

          <div class="border-r border-black h-4 mx-2.5"/>

          <div
            class="js-clipboard flex items-center"
            :data-clipboard-text="message.text">
            <InlineSvg
              class="w-3 mr-1.5"
              :src="require('@/assets/svg/fontawesome/clipboard.svg')"/>
            Copy
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LongTap from '@/components/Functional/LongTap'
import { useChatStore } from '@/stores/chat'
import { mapActions } from 'pinia'

export default {
  name: 'ChatDeleteMessage',
  components: {
    LongTap
  },
  props: {
    message: {
      default: () => {},
      validator: prop => typeof prop === 'object' || prop === null,
      required: true
    }
  },
  data () {
    return {
      tooltipVisible: false
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    ...mapActions(useChatStore, [
      'messageDelete'
    ]),
    longTap () {
      if (this.message !== null) {
        this.tooltipVisible = true
      }
    },
    reset () {
      this.tooltipVisible = false
    }
  }
}
</script>

<style scoped>
  .chat-tooltip {
    position: absolute;
    right: 20%;
    bottom: -25px;
    box-shadow: 0 5px 10px rgba(188, 203, 209, 0.4);
    @apply rounded-full bg-white px-3 py-2 text-black z-10 text-caption whitespace-nowrap cursor-pointer
  }
</style>
