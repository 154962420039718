import app from '@/main'
import { defineStore } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

export const useTagsStore = defineStore({
  id: 'tags',
  state: () => ({
    tags: [],
    tagPostsStatuses: {},
    loadingStatus: ''
  }),
  actions: {
    addTagPosts (payload) {
      const tag = this.tags.find((tag) => tag.name === payload.tagName)

      if (!tag) return

      tag.posts = [...tag.posts, ...payload.posts]
    },
    likeToggle ({ tagName, postId }) {
      const tag = this.tags.find((tag) => tag.name === tagName)
      if (!tag) return

      const post = tag.posts.find((element) => element.id === postId)
      if (!post) return

      post.liked = !post.liked
      post.likeCount = post.liked ? post.likeCount + 1 : post.likeCount - 1
    },
    async tagsGet (params) {
      this.loadingStatus = 'loading'

      const appStore = useAppStore()
      const authStore = useAuthStore()

      if (appStore.secretToken) {
        params.postUnlockToken = appStore.secretToken
      }

      params.installId = authStore.installId ? authStore.installId : null

      const response = await app.wsp.sendRequest({
        data: params,
        method: 'tags.get'
      })

      if (response.error) {
        this.loadingStatus = 'loaded'
        console.log(response.error.message)
      }

      if (response.data.tags.length === 0) {
        this.loadingStatus = 'loaded-all'
      } else {
        this.loadingStatus = 'loaded'
      }

      const postStore = usePostStore()
      postStore.addTagsUsers(response)

      response.data.tags.forEach((tag) => {
        this.tagPostsStatuses[tag.name] = params.postLimitPerTag > tag.posts.length ? 'loaded-all' : 'loaded'
      })

      const sortedTags = response.data.tags.sort((tag, nextTag) => nextTag.posts.length - tag.posts.length)
      this.tags = [...this.tags, ...sortedTags]
    },
    async tagPostsGet (params) {
      this.tagPostsStatuses[params.name] = 'loading'

      const appStore = useAppStore()
      const authStore = useAuthStore()

      if (appStore.secretToken) {
        params.postUnlockToken = appStore.secretToken
      }

      params.installId = authStore.installId ? authStore.installId : null

      const response = await app.wsp.sendRequest({
        data: params,
        method: 'tag.posts.get'
      })

      if (response.error) {
        this.tagPostsStatuses[params.name] = 'loaded'
        console.log(response.error.message)
      }

      if (response.data.posts.length < params.limit) {
        this.tagPostsStatuses[params.name] = 'loaded-all'
      } else {
        this.tagPostsStatuses[params.name] = 'loaded'
      }

      this.addTagPosts({ tagName: params.name, posts: response.data.posts })
    },
    async shareCount ({ tagName, postId }) {
      const tag = this.tags.find((tag) => tag.name === tagName)
      if (!tag) return

      const post = tag.posts.find((element) => element.id === postId)

      if (!post) return

      post.shareCount++
    }
  }
})
