<script setup>

</script>

<template>
  <div class="h-full w-full max-w-full flex flex-col placeholder">
    <div class="grid grid-cols-2 gap-1.5">
      <div
        v-for="(item, index) in 8"
        :key="item">
        <div
          :class="[
            [+index === 1 ? 'aspect-w-1 aspect-h-1' : 'aspect-[4/5]'],
            { '-mt-[25%]': +index !== 0 && +index >= 3 && +index % 2 !== 0 }
          ]"
          class="rounded-lg placeholder-item"/>
      </div>
    </div>
  </div>
</template>
