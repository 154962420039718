import { defineStore } from 'pinia'
import MobileDetect from 'mobile-detect'
import helpers from '@/helpers'
import supportedLocales from '@/assets/supported-locales.json'
import Cookies from 'js-cookie'
import { useAuthStore } from './auth'
import dayjs from 'dayjs'

const md = new MobileDetect(window.navigator.userAgent)

const cookiesStorage = {
  setItem (key, state) {
    return Cookies.set(key, state, { expires: 7 }, { sameSite: 'strict' })
  },
  getItem (key) {
    return Cookies.get(key) ? JSON.stringify(JSON.parse(Cookies.get(key))) : null
  }
}

export const useAppStore = defineStore({
  id: 'app',
  state: () => ({
    isIPhone: md.is('iPhone'),
    isSafari: md.userAgent() ? md.userAgent().toLowerCase().includes('safari') : false,
    isMobile: md.tablet() || md.phone() || md.mobile(),
    isiOS: md.os() ? md.os().toLowerCase().includes('ios') : false,
    isAndroid: md.os() ? md.os().toLowerCase().includes('android') : false,
    isCurrentDevicePWA: helpers.isCurrentDevicePWA(),
    // See helpers.isPWAInstalled() in App.vue
    isPWAInstalled: false,
    isAbleToInstallPWA: false,
    deferredPromptPWAFunc: null,
    pwaPromptsCounter: 0, // 1 - after page loaded, 2 - after registration
    isFixedLayout: true,
    selectedLocale: null,
    showCookiesReminder: !helpers.isWebView(),
    supportedLocales: supportedLocales,
    onboardingShown: false,
    isDebugVisible: true,
    // State when images arrive without blur, hidden some parts, no bombs.
    // To activate add to the url query param ?secret=true
    secretToken: null,
    altSecretState: false, // If secret token came from lnk query param
    currentTime: dayjs().unix(),
    endlessPaymentCreated: false,
    openDisableEmailNotificationsPopup: false
  }),
  getters: {
    // Show or not appeal to install in /me and PWA popup in App.vue
    showAppealPWA: (state) => {
      return !state.isCurrentDevicePWA &&
        !state.isPWAInstalled &&
        ((state.isiOS && state.isSafari) || !state.isiOS) &&
        state.isMobile &&
        !state.isiOS // Do not show PWA in iOS (Until PWA became stable there)
    },
    showPWAPrompt (state) {
      return state.showAppealPWA || state.isAbleToInstallPWA
    },
    isDatingEnabled (state) {
      const authStore = useAuthStore()

      return (!!authStore.authOptions?.dating?.header && !state.secretToken)
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'pwaPromptsCounter',
          'onboardingShown',
          'isDebugVisible',
          'endlessPaymentCreated'
        ]
      },
      {
        storage: cookiesStorage,
        paths: [
          'showCookiesReminder',
          'selectedLocale',
          'secretToken',
          'altSecretState'
        ]
      }
    ]
  }
})
