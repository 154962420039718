<template>
  <div class="h-full overflow-hidden">
    <swiper
      v-if="items.length && values.initialIndex"
      class="h-full"
      direction="vertical"
      :speed="400"
      :pagination="{ clickable: true }"
      :watch-slides-visibility="true"
      :short-swipes="true"
      :keyboard="true"
      :initial-slide="values.initialIndex"
      @slider-move="sliderMove"
      @touch-end="touchEnd"
      @slide-change-transition-start="slideChangeTransitionStart"
      @slide-change-transition-end="slideChangeTransitionEnd">
      <swiper-slide
        v-for="item in items"
        :key="item.id">
        <InlineSvg
          :src="trashSVG"
          class="absolute z-30 left-4 top-4 w-5 cursor-pointer text-white post-shadow"
          @click="trashPhoto(item.id)"/>

        <ImageLoader
          v-slot="{ setLoaded }"
          spinner-class="h-10 w-10 bg-gray-100"
          class="w-full h-full">
          <img
            class="block object-cover w-full h-full object-center object-cover"
            :src="item?.links.r640x960"
            alt=""
            @load="setLoaded()">
        </ImageLoader>
      </swiper-slide>
    </swiper>

    <!-- Close -->
    <button
      class="btn p-1 absolute top-1.5 right-3 z-20 post-shadow"
      @click="emitClose">
      <svg
        class="h-8 text-gray-200"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512">
        <path
          d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/>
      </svg>
    </button>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Pagination, Keyboard } from 'swiper'
import ImageLoader from '@/components/Functional/ImageLoader'
import trashSVG from '@/assets/svg/design/trash.svg'


SwiperCore.use([Pagination, Keyboard])

export default {
  name: 'MePhotosContent',
  components: {
    Swiper,
    SwiperSlide,
    ImageLoader
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  emits: ['close', 'trashPhoto'],
  data () {
    return {
      inMove: false,
      inTransition: false,
      trashSVG: trashSVG
    }
  },
  computed: {},
  mounted () {
  },
  methods: {
    sliderMove () {
      this.inMove = true
    },
    touchEnd () {
      this.inMove = false
    },
    slideChangeTransitionStart () {
      this.inTransition = true
    },
    slideChangeTransitionEnd () {
      this.inTransition = false
    },
    trashPhoto (photoId) {
      this.$emit('trashPhoto', photoId)
    },
    async emitClose () {
      await this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    position: relative;
  }
  .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
  }
</style>
