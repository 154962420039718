<template>
  <Header :name="$tc('user.your_cards.sub_heading', paymentMethods.length)"/>

  <div class="container my-5">
    <h1 class="mb-5">
      {{ $tc('user.your_cards.heading', paymentMethods.length) }}
    </h1>

    <CardsList v-if="paymentMethods.length"/>

    <p v-else>
      {{ $t('user.your_cards.no_saved_cards') }}
      <template v-if="['stripe'].includes(paymentProcessor)">
        <a
          class="cursor-pointer text-link"
          @click="addCard">{{ $t('user.your_cards.add_card') }}</a>.
      </template>
    </p>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import CardsList from '@/components/Credits/CardsList'
import { mapActions, mapState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'

export default {
  name: 'SaveCards',
  components: {
    Header,
    CardsList
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(usePaymentStore, [
      'paymentProcessor',
      'paymentMethods'
    ])
  },
  beforeCreate () {
    document.title = 'Checkout'
  },
  mounted () {
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'checkoutSessionCreate'
    ]),
    async addCard () {
      await this.checkoutSessionCreate({
        successUrl: `${window.origin}/checkout/result?is=success&type=add-card`,
        cancelUrl: `${window.origin}/checkout/result?is=cancel&type=add-card`,
        savePaymentMethod: true
      })
    }
  }
}
</script>

