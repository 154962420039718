import { defineStore } from 'pinia'
import app from '@/main'
import { get } from 'lodash'
import qs from 'qs'

import { useUserStore } from '@/stores/user'

export const useCreditsStore = defineStore({
  id: 'credits',
  state: () => ({
    selectedTariff: {
      id: null
    },
    savedCards: [],
    autopay: false
  }),
  getters: {
    savedCard: (state) => state.savedCards.length ? state.savedCards[0] : { id: null }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'selectedTariff',
          'savedCards',
          'autopay'
        ]
      }
    ]
  },
  actions: {
    cauriCardsGet () {
      return new Promise((resolve, reject) => {
        app.wsp
          .sendRequest({
            data: {
              appId: process.env.VUE_APP_ID
            },
            method: 'cauri.cards.get'
          })
          .then(res => {
            if (res.error === undefined) {
              this.savedCards = res.data.cards
              resolve(res.data.cards)
            } else {
              reject(res.error)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getToken ({ card }) {
      return new Promise((resolve, reject) => {
        const userStore = useUserStore()

        app.axios
          .post('https://api.cauri.com/rest-v1/card/getToken',
            qs.stringify({
              project: userStore.cauriPublicKey,
              number: card.cardnumber,
              expiration_month: card.ccMonth,
              expiration_year: '20' + card.ccYear,
              security_code: card.cvc
            }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async cauriCardsDelete () {
      try {
        const res = await app.wsp
          .sendRequest({
            data: {
              appId: process.env.VUE_APP_ID,
              ids: [this.savedCard.id]
            },
            method: 'cauri.cards.delete'
          })

        this.savedCards = []

        return res
      } catch (err) {
        console.log(err)
      }
    },
    async cauriAutoPaymentDelete () {
      try {
        return await app.wsp
          .sendRequest({
            data: {
              appId: process.env.VUE_APP_ID
            },
            method: 'cauri.autoPayment.delete'
          })
      } catch (err) {
        console.log(err)
      }
    },
    async cauriAutoPaymentGet () {
      return await app.wsp
        .sendRequest({
          data: {
            appId: process.env.VUE_APP_ID
          },
          method: 'cauri.autoPayment.get'
        })
    },
    async cauriPurchase (card) {
      const data = {
        appId: process.env.VUE_APP_ID,
        productId: this.selectedTariff.id,
        productType: 1,
        setUpAutoPayment: get(card, 'autopay', false)
      }

      if (this.savedCard.id) {
        data.cardId = this.savedCard.id
      } else {
        const token = await this.getToken({ card })

        // Remember or not card by default
        data.rememberCard = false
        data.cardToken = token.id
        data.acsAuthEndUrl = `${window.origin}/checkout/result`
      }

      const res = await app.wsp.sendRequest({
        data: data,
        method: 'cauri.purchase'
      })

      if (res.error && res.error.message) {
        await this.router.push('/checkout/result?result=' + JSON.stringify({ error: res.error.message }))
        console.log(res.error.message)
      }

      // With 3ds
      if (res.data.acsAuthStartUrl) {
        window.location.replace(res.data.acsAuthStartUrl)
      }

      // If no 3ds
      if (res.data.result !== undefined) {
        this.router.push('/checkout/result?result=' + JSON.stringify(res.data.result))
      }
    }
  }
})
