import { defineStore } from 'pinia'
import app from '@/main'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

const contentType = new Map([
  ['videos', {
    loadingType: 'videosLoadingStatus',
    backendValue: 0
  }],
  ['images', {
    loadingType: 'imagesLoadingStatus',
    backendValue: 1
  }]
])

export const useCreatorStore = defineStore({
  id: 'creator',
  state: () => ({
    creator: {
      userId: null,
      age: null,
      name: '',
      sex: null,
      online: null,
      location: {
        name: ''
      },
      photos: {
        items: []
      },
      attachmentSupport: {
        photo: null
      },
      postLikeCount: null,
      postShareCount: null,
      shareCount: null,
      contact: null,
      unreadIncomingMessageCount: null
    },
    images: [],
    videos: [],
    imagesLoadingStatus: '',
    videosLoadingStatus: '',
    activeTab: 'videos',
    respondsTime: {},
    // Emulation of creator likes
    likes: {}
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'respondsTime',
          'likes'
        ]
      }
    ]
  },
  actions: {
    async userGet (params) {
      const res = await app.wsp.sendRequest({ data: params, method: 'user.get' })

      if (res.error) {
        throw res.error
      }

      this.creator = res.data.user
    },
    async userPostsGet (params) {
      const data = { ...params }

      const type = contentType.get(params.contentType).loadingType

      // Set loading status
      this[type] = this[params.contentType].length > 0 ? 'loading-more' : 'loading'

      data.contentType = contentType.get(params.contentType).backendValue

      const appStore = useAppStore()
      const authStore = useAuthStore()

      if (appStore.secretToken) {
        data.postUnlockToken = appStore.secretToken
      }

      data.installId = authStore.installId ? authStore.installId : null

      const response = await app.wsp.sendRequest({
        data: data,
        method: 'user.posts.get'
      })

      // Set loading status
      this[type] = response.data.posts.length > 0 ? 'loaded' : 'loaded-all'

      if (response.error) return

      if (params.contentType === 'videos') {
        this.videos.push(...response.data.posts)
      } else {
        this.images.push(...response.data.posts)
      }
    },
    likeToggle ({ type, postId }) {
      const post = this[type].find((post) => post.id === postId)
      if (!post) return

      post.liked = !post.liked
      post.likeCount = post.liked ? post.likeCount + 1 : post.likeCount - 1
    },
    async userShared (userId) {
      const res = await app.wsp.sendRequest({
        data: { userId },
        method: 'user.shared'
      })

      if (res.error) {
        console.log(res.error.message)
      }

      this.creator.shareCount++
    },
    async postShared ({ type, postId }) {
      const post = this[type].find((element) => element.id === postId)
      if (!post) return

      post.shareCount++
    },
    //
    // WS Events
    //
    userStatus (data = {}) {
      if (!data.data) return

      const { userId, status } = data.data

      if (Number(this.creator.userId) !== Number(userId)) {
        return
      }

      if (!['offline', 'online'].includes(data.data.status)) {
        return
      }

      this.creator.online = status === 'online'
    },
    // Update post in list
    postPurchase (data = {}) {
      const indexImages = this.images.findIndex((item) => item.id === data?.data.post.id)

      if (indexImages !== -1) {
        // Remove old, set new at index if exists
        this.images.splice(indexImages, 1, data?.data.post)
      }

      const indexVideos = this.videos.findIndex((item) => item.id === data?.data.post.id)

      if (indexVideos !== -1) {
        this.videos.splice(indexVideos, 1, data?.data.post)
      }
    },
    messageNew (data) {
      // Process new message only if Creator's page open
      if (this.router.currentRoute.name !== 'Creator') {
        return
      }

      const senderId = data.data.message.senderId
      const unreadIncomingMessageCount = data.data.sender.unreadIncomingMessageCount

      // Update number of unread messages from creator
      if (senderId === this.creator.userId) {
        this.creator.unreadIncomingMessageCount = unreadIncomingMessageCount
      }
    }
  }
})
