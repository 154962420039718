<template>
  <div>
    <div class="flex items-center justify-between mb-6">
      <div/>

      <h2 class="text-base">
        {{ $t("common.language") }}
      </h2>

      <CloseCrossButton @click="emitClose"/>
    </div>

    <fieldset>
      <div class="mt-4 border-t border-b border-gray-100 divide-y divide-gray-100">
        <div
          v-for="item in sortedSupportedLocales"
          :key="item.code"
          class="relative flex items-start">
          <div class="min-w-0 flex-1 text-sm">
            <label
              :for="item.code"
              class="flex text-gray-700 select-none py-4"
              @click="closeWithDelay">
              <span class="text-[20px]">
                {{ item.flag }}
              </span>

              <span
                :class="{ 'font-bold': $i18n.locale === item.code }"
                class="pl-2 text-base flex">
                {{ item.name }}
              </span>
            </label>
          </div>

          <div class="ml-3 flex items-center h-5 my-4">
            <input
              :id="item.code"
              v-model="$i18n.locale"
              :value="item.code"
              :name="item.name"
              type="radio"
              class="focus:ring-primary h-4 w-4 text-primary border-gray-300"
              @click="closeWithDelay">
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapState, mapWritableState } from 'pinia'
import { useAppStore } from '@/stores/app'
import { sortBy } from 'lodash'

export default {
  name: 'LangSwitcherContent',
  components: {
    CloseCrossButton
  },
  emits: ['close'],
  data () {
    return {}
  },
  computed: {
    ...mapState(useAppStore, [
      'supportedLocales'
    ]),
    ...mapWritableState(useAppStore, [
      'selectedLocale'
    ]),
    sortedSupportedLocales () {
      return sortBy(this.supportedLocales, ['name'])
    },
    currentLanguage () {
      return this.supportedLocales.find(item => item.code === this.$i18n.locale)
    }
  },
  methods: {
    async emitClose () {
      await this.$emit('close')
    },
    closeWithDelay () {
      setTimeout(() => {
        this.emitClose()
      }, 300)
    }
  }
}
</script>
