<template>
  <div>
    <Sidebar
      ref="sidebar"
      v-touch:swipe.right="swipeHandler">
      <template #sidebarContent="{ toggleSidebar }">
        <SidebarContent
          :close-sidebar="toggleSidebar"
          @open-lang-switcher="$refs.langSwitcher.openModal()"/>
      </template>

      <template #default="{ toggleSidebar }">
        <div class="max-w-md mx-auto px-3 mt-4 pb-20 bg-white">
          <CreatorsCatalogHeader
            @hamburger="toggleSidebar"/>

          <ProfilesNav
            v-if="options.eventsEnabled"
            class="my-5"/>
          
          <div
            v-else
            class="my-4"/>

          <DiscoverPlaceholder v-if="isLoading"/>

          <div v-show="!isLoading">
            <h1 class="mb-4">
              {{ $t('nav.menu.discover') }}
            </h1>

            <TheDiscover/>
          </div>
        </div>
      </template>
    </Sidebar>

    <Navbar/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import TheDiscover from '@/components/Discover/TheDiscover'
import { useMeta } from 'vue-meta'
import { mapState } from 'pinia'
import { useTagsStore } from '@/stores/tags'
import DiscoverPlaceholder from '@/components/Discover/DiscoverPlaceholder'
import ProfilesNav from '@/components/Common/ProfilesNav.vue'
import Sidebar from '@/components/Functional/Sidebar.vue'
import SidebarContent from '@/components/ModalContents/SidebarContent.vue'
import CreatorsCatalogHeader from '@/components/CreatorsCatalog/CreatorsCatalogHeader.vue'
import { useAuthStore } from '@/stores/auth'


export default {
  name: 'Discover',
  components: {
    CreatorsCatalogHeader,
    SidebarContent,
    Sidebar,
    Navbar,
    TheDiscover,
    DiscoverPlaceholder,
    ProfilesNav
  },
  setup () {
    useMeta({
      title: 'Discover',
      og: {
        title: 'Discover',
        description: 'One2fan – get closer to the creators',
        site_name: 'Fancy',
        type: 'website'
      }
    })
  },
  data () {
    return {
      constantLoading: true,
      firstLoading: true
    }
  },
  computed: {
    ...mapState(useTagsStore, [
      'loadingStatus'
    ]),
    ...mapState(useAuthStore, [
      'options'
    ]),
    isLoading () {
      return (this.loadingStatus === 'loading' || this.constantLoading) && this.firstLoading
    }
  },
  watch: {
    isLoading (value) {
      if (!value) {
        this.firstLoading = false
      }
    }
  },
  mounted () {
    this.invites.initInvites(this.$route)

    setTimeout(() => {
      this.constantLoading = false
    }, 500)
  },
  methods: {}
}
</script>

