<script setup>
  import Modal from '@/components/Functional/Modal'
  import { useUserStore } from '@/stores/user'
  import { storeToRefs } from 'pinia'
  import { ref } from 'vue'
  import NoCreditsContent from '@/components/ModalContents/chat/NoCreditsContent.vue'
  import { clientEventTrack } from '@/helpers/methods'

  const {
    balance
  } = storeToRefs(useUserStore())

  const notEnoughCreditsModal = ref(null)
</script>

<template>
  <div>
    <button
      type="button"
      class="px-2 flex gap-1 items-center"
      @click="notEnoughCreditsModal.openModal();clientEventTrack({ typeName: 'chat__credit_balance_clicked' })">
      <span class="font-bold text-sm pt-0.5">
        {{ balance }}
      </span>

      <InlineSvg
        :src="require('@/assets/svg/design/coin.svg')"
        class="w-5 h-5"/>
    </button>

    <Modal
      v-slot="{ closeModal }"
      ref="notEnoughCreditsModal"
      name="NoCredits"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white p-5"
      wrapper-class=""
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <NoCreditsContent @close="closeModal()"/>
    </Modal>
  </div>
</template>