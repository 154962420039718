<template>
  <div>
    <div
      class="p-3 flex flex-col items-center justify-center"
      style="background-color: #dbdaff; border-radius: 10px">
      <div class="mb-0.5 text-intermediate">⚡⚡⚡</div>

      <div
        class="text-center mb-1.5"
        style="color: #4756da">
        <h3 class="font-bold text-intermediate">{{ $t("modals.pwa.heading") }}</h3>
        <p>{{ $t("modals.pwa.descriptions") }}</p>
      </div>

      <button
        style="max-width: 185px; width: 100%"
        class="btn btn-purple pt-0.5 py-1 px-4 rounded-2xl text-caption"
        @click="$refs.AppealToPWAModal.openModal()">
        <div class="flex items-center">
          <InlineSvg
            class="mr-0.5 text-transparent"
            :src="require('@/assets/svg/design/pwa/download-2.svg')"/>

          <span class="ml-1 font-semibold">{{ $t("modals.pwa.install_app") }}</span>
        </div>
      </button>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="AppealToPWAModal"
      name="AppealToPWA"
      wrapper-class="mb-4"
      content-class="bg-white p-4"
      position="bottom"
      :has-indents="true">
      <AppealToPWAContent @close="closeModal"/>
    </Modal>
  </div>
</template>

<script>

import Modal from '@/components/Functional/Modal'
import AppealToPWAContent from '@/components/ModalContents/AppealToPWAContent'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'

export default {
  name: 'Test',
  components: {
    Modal,
    AppealToPWAContent
  },
  data () {
    return {
      greeting: 'Hello'
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isiOS',
      'isAndroid',
      'isCurrentDevicePWA',
      'isPWAInstalled',
      'showAppealPWA',
      'isMobile'
    ])
  },
  mounted () {
  },
  methods: {}
}
</script>

