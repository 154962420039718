<template>
  <div class="px-4 flex flex-col h-full w-full">
    <div
      ref="wrapper"
      v-resize="onResize"
      class="flex-grow">
      <h3 class="text-xl pr-7 mb-6">
        {{ $t('creator.tops.fan_rating') }}
      </h3>

      <!-- Navigation -->
      <nav class="flex justify-between bg-gray-100 rounded-full p-0.5 mb-7">
        <button
          v-for="item in tabs"
          :key="item"
          type="button"
          :class="[activeTab.id === item.id ? 'text-gray-900 bg-white font-bold' : 'text-gray-500']"
          class="w-full text-sm rounded-full py-1"
          @click="activeTab = item">
          {{ item.title }}
        </button>
      </nav>

      <div
        v-if="topPayers.length"
        :class="{ 'pb-20': allowPagination }"
        class="space-y-5">
        <div
          v-for="(item, index) in topPayers"
          :key="index + 'payer'"
          class="flex items-center justify-between">
          <div class="flex items-center">
            <div
              class="relative cursor-pointer w-8 h-8"
              @click="openUserProfile({ user: item.user, realCreditsSpent: item.realCreditsSpent, index: index })">
              <Avatar
                class="w-8 h-full w-full"
                :user-item="item.user"/>

              <div class="absolute bottom-0 right-0 text-caption">
                <InlineSvg
                  v-if="index < 3"
                  class="w-4"
                  :src="require(`@/assets/svg/design/tops/${coins[index]}-medal.svg`)"/>
              </div>
            </div>

            <div
              class="ml-2 cursor-pointer"
              @click="openUserProfile({ user: item.user, realCreditsSpent: item.realCreditsSpent, index: index })">
              <h4 class="text-sm leading-4 truncate w-[220px]">
                <a :title="item.user.name">
                  {{ item.user.name }}
                </a>
              </h4>

              <h5 class="text-caption text-gray-400 font-semibold leading-4 truncate w-[220px]">
                <a title="@martini_rond">
                  @{{ item.user.username }}
                </a>
              </h5>
            </div>
          </div>

          <a
            class="text-caption flex items-center cursor-pointer font-semibold text-[#ACAEB0]"
            @click="openUserProfile({ user: item.user, realCreditsSpent: item.realCreditsSpent, index: index })">
            #{{ index + 1 }}
          </a>
        </div>
      </div>

      <p
        v-else-if="!loading"
        class="text-center text-gray-400 text-caption mt-2">
        {{ $t('creator.tops.no_contributions_yet') }}
      </p>

      <Spinner
        v-else/>
    </div>

    <Observer @intersect="loadMore"/>

    <button
      v-show="topPayers.length === limit && !allowPagination"
      type="button"
      class="btn btn-block text-gray-400 font-semibold text-caption z-10 fixed bottom-14 left-0 right-0"
      @click="paginate">
      {{ $t('common.see_all') }}
    </button>

    <router-link
      v-if="isAuthenticated"
      to="/credits"
      :style="{ width: width + 'px' }"
      class="btn btn-primary btn-block w-full fixed bottom-4 mt-6">
      {{ $t('creator.tops.get_to_the_top') }}
    </router-link>

    <button
      v-else
      type="button"
      :style="{ width: width + 'px' }"
      class="btn btn-primary btn-block w-full fixed bottom-4 mt-6 "
      @click="showSignUp">
      {{ $t('creator.tops.get_to_the_top') }}
    </button>

    <Modal
      v-slot="{ values, closeModal }"
      ref="topsUserProfile"
      name="TopsUserProfile"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white py-4 px-6"
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <div
        class="absolute top-6 right-5 cursor-pointer z-10"
        @click="closeModal">
        <InlineSvg
          :src="require('@/assets/svg/design/cross.svg')"
          class="inline-block text-gray-900 p-1 w-5 h-5"/>
      </div>

      <TopsUserProfile :values="values"/>
    </modal>
  </div>
</template>

<script>
import Avatar from '@/components/Functional/Avatar'
import { mapState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import { useAuthStore } from '@/stores/auth'
import Observer from '@/components/Functional/Observer'
import TopsUserProfile from '@/components/Tops/TopsUserProfile'
import helpers from '@/helpers'
import Spinner from '@/components/Functional/Spinner'

export default {
  name: 'ContributionRanking',
  components: {
    Avatar,
    Observer,
    TopsUserProfile,
    Spinner
  },
  data () {
    return {
      activeTab: {
        title: 'Weekly',
        id: 'all-time'
      },
      tabs: [
        {
          title: 'Weekly',
          id: 'last-7d'
        },
        {
          title: 'Monthly',
          id: 'last-30d'
        },
        {
          title: 'All time',
          id: 'all-time'
        }
      ],
      topPayers: [],
      limit: 5,
      coins: {
        0: 'gold',
        1: 'silver',
        2: 'copper'
      },
      allowPagination: false,
      loading: true,
      width: 0
    }
  },
  computed: {
    ...mapState(useCreatorStore, [
      'creator'
    ]),
    ...mapState(useAuthStore, [
      'isAuthenticated'
    ])
  },
  watch: {
    activeTab (value) {
      this.topPayers = []
      this.getTops()
      this.loading = true
      this.allowPagination = false
    }
  },
  mounted () {
    this.width = this.$refs.wrapper.clientWidth

    this.getTops()
  },
  methods: {
    showSignUp () {
      if (!this.isAuthenticated) {
        helpers.showSignUp()
      }
    },
    openUserProfile ({ user, realCreditsSpent, index }) {
      if (user.name) {
        this.$refs.topsUserProfile.openModal({
          user, realCreditsSpent, index
        })
      }
    },
    onResize ({ width }) {
      this.width = width
    },
    paginate () {
      this.allowPagination = true
      this.loadMore()
    },
    loadMore () {
      if (!this.loading && this.allowPagination) {
        this.getTops()
      }
    },
    async getTops () {
      if (this.creator.userId) {
        const res = await this.wsp.sendRequest({
          data: {
            userId: this.creator.userId,
            period: this.activeTab.id,
            limit: this.limit,
            offset: this.topPayers.length
          },
          method: 'topPayers.get'
        })

        const items = res.data?.topPayers || []

        if (this.topPayers.length) {
          this.topPayers.push(...items)
        } else {
          this.topPayers = items || []
        }

        this.loading = false
      }
    }
  }
}
</script>

