<template>
  <!-- border border-[#4756DA] -->
  <div class="rounded-3xl px-5 py-3 bg-gray-50  border-[#4756DA]">
    <router-link
      :to="`/chat/${item.user.userId}`"
      class="flex items-center">
      <div
        class="mr-2.5"
        style="min-width: 50px; height: 50px; width: 50px">
        <Avatar :user-item="item.user" :show-status="true"/>
      </div>

      <div class="flex flex-1 flex-col" style="width: calc(100% - 60px)">
        <div class="flex justify-between">
          <div class="whitespace-nowrap w-8/12">
            <h3
              :title="item.user.name + ` (${item.user.username})`"
              :class="{ 'has-unread': item.numberUnreadMessages > 0 }"
              class="overflow-ellipsis overflow-hidden text-sm font-semibold">
              {{ item.user.name }} <span v-if="item.user?.username" class="text-gray-400">@{{ item.user.username }}</span>
            </h3>
          </div>

          <time
            :datetime="createdDate"
            :title="createdDateWithTime"
            class="leading-5 text-caption text-gray-600 whitespace-nowrap">
            {{ createdDateFormatted }}
          </time>
        </div>

        <div class="flex justify-between items-center">
          <small class="text-sm line-clamp-2">
            {{ item.message.text }}
          </small>

          <CounterBadge :count="item.numberUnreadMessages"/>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>

import Avatar from '@/components/Functional/Avatar'
import CounterBadge from '@/components/Functional/CounterBadge'

export default {
  name: 'OnlineSupport',
  components: {
    Avatar,
    CounterBadge
  },
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {
    createdDateFormatted () {
      const date = this.$dayjs(this.item.message.created * 1000)
      return date.isToday() ? date.locale(this.$i18n.locale).format('h:mm A') : date.locale(this.$i18n.locale).format('MMM, D YYYY')
    },
    createdDate () {
      // 2017-02-14
      return this.$dayjs(this.item.message.created * 1000).locale(this.$i18n.locale).format('YYYY-MM-DD')
    },
    createdDateWithTime () {
      // Nov 28, 2016 14:00
      return this.$dayjs(this.item.message.created * 1000).locale(this.$i18n.locale).format('MMM, D YYYY HH:mm')
    }
  }
}
</script>

<style scoped>
  /*.line-clamp-2 {*/
  /*  display: -webkit-box;*/
  /*  max-width: 200px;*/
  /*  -webkit-line-clamp: 2;*/
  /*  -webkit-box-orient: vertical;*/
  /*  overflow: hidden;*/
  /*  width: 100%;*/
  /*  margin: ;*/
  /*  max-width: 100%;*/
  /*}*/
</style>
