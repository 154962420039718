<script setup>
import { createPicker } from 'picmo'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useChatStore } from '@/stores/chat'

let picker = null
const rootElement = ref(null)

onMounted(() => {
  picker = createPicker({ rootElement: rootElement.value })

  picker.addEventListener('emoji:select', event => {
    console.log('Emoji selected:', event.emoji)

    const chatStore = useChatStore()

    if (chatStore.messageToSend && chatStore.messageToSend.length > 0) {
      chatStore.messageToSend = chatStore.messageToSend.concat(chatStore.messageToSend.slice(-1) === ' ' ? event.emoji : ` ${event.emoji}`)
    }
    else {
      chatStore.messageToSend = chatStore.messageToSend.concat(event.emoji)
    }
  })
})

onBeforeUnmount(() => {
  if (picker) {
    picker.destroy()
    picker = null
  }
})
</script>

<template>
  <div ref="rootElement"/>
</template>
