<template>
  <div class="flex justify-between items-end pt-6 mb-4">
    <div>
      <h2 class="h2 mb-2">
        {{ $t('user.refresh.heading') }}
      </h2>

      <p class="mb-4">
        {{ $t('user.refresh.description') }}
      </p>
    </div>

    <div class="p-1">
      <img
        src="/img/refresh-clock.png"
        class="h-24 mb-1 object-contain"
        alt="">
    </div>
  </div>

  <button
    type="submit"
    class="btn btn-block btn-primary mb-3"
    @click="refresh">
    {{ $t('user.refresh.continue') }}
  </button>
</template>

<script>

export default {
  name: 'RefreshContent',
  components: {},
  emits: ['close'],
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {
    async emitClose () {
      await this.$emit('close')
    },
    refresh () {
      this.$addEvent(['Refresh'])
      this.$router.go()
    }
  }
}
</script>

