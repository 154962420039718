<template>
  <div class="grid grid-flow-col auto-cols-max gap-2 overflow-auto scrollbar-none bg-[#f9fafb] -mx-4 px-4 mb-1.5">
    <a
      v-for="(item, index) in chatSuggestsArray"
      :key="index"
      class="whitespace-nowrap px-4 py-2 font-semibold text-white text-base rounded-full cursor-pointer bg-gradient-to-b from-[#08c6df] to-[#0daed3]"
      @click="sendMessage(item)">
      {{ item }}
    </a>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia'
import { useChatStore } from '@/stores/chat'

export default {
  name: 'ChatSuggestMessages',
  components: {},
  data () {
    return {
      chatSuggests: '😍 Hi!; 🥰 Hi! I like your videos!; 👋 Hey! Why are you here?; 😻 Hi, how are you?; 😊 Hey, what\'s up?; ❤️‍🔥 Hello! I\'m your big fan; 😘 Hello, thank you for your content!; 🫶 Hello! I’m so glad to write you!'
    }
  },
  computed: {
    chatSuggestsArray () {
      return this.chatSuggests.split(';').map(function (item) {
        return item.trim()
      })
    },
    ...mapWritableState(useChatStore, [
      'messageToSend'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(useChatStore, [
      'messageSend'
    ]),
    async sendMessage (message) {
      this.messageToSend = message

      if (this.messageToSend) {
        setTimeout(() => {
          this.messageSend()
        }, 500)
      }
    }
  }
}
</script>

