<template>
  <div class="h-full relative">
    <!-- Navigation buttons -->
    <div class="absolute -left-10 bottom-0 top-0 z-10 h-full flex flex-col justify-center">
      <button
        type="button"
        :disabled="isFirstSlide"
        :class="{ 'opacity-50': isFirstSlide }"
        class="swiper-button-prev-unique my-1">
        <InlineSvg
          :src="require('@/assets/svg/light/arrow-circle-up.svg')"
          class="text-gray-300 w-8 h-8"/>
      </button>

      <button
        type="button"
        :disabled="isLastSlide"
        :class="{ 'opacity-50': isLastSlide }"
        class="swiper-button-next-unique my-1">
        <InlineSvg
          :src="require('@/assets/svg/light/arrow-circle-down.svg')"
          class="text-gray-300 w-8 h-8"/>
      </button>
    </div>

    <!-- Close -->
    <button
      v-show="isMobile"
      class="btn p-1 absolute top-2 left-2 z-50 post-shadow"
      @click="emitClose">
      <InlineSvg
        :src="require('@/assets/svg/design/close-arrow.svg')"
        class="text-gray-100 w-6 h-6 post-shadow"/>
    </button>

    <!-- Maximize/Minimize attachment -->
    <button
      type="button"
      class="absolute z-40 right-2 top-2 text-white post-shadow p-1 text-xl"
      @click="maximize = !maximize">
      <InlineSvg
        :src="require(`@/assets/svg/design/${ maximize ? 'collapse' : 'expand' }.svg`)"
        class="w-6 h-6"/>
    </button>

    <swiper
      v-if="items.length"
      class="h-full"
      direction="vertical"
      :speed="400"
      :pagination="{
        enabled: false
      }"
      :watch-slides-progress="true"
      :short-swipes="true"
      :navigation="{
        nextEl: '.swiper-button-next-unique',
        prevEl: '.swiper-button-prev-unique'
      }"
      :keyboard="true"
      :initial-slide="values.initialIndex"
      @reach-end="reachEnd"
      @slide-change="onSlideChange"
      @slider-move="sliderMove"
      @touch-end="touchEnd"
      @active-index-change="activeIndexChange"
      @after-init="afterInit"
      @slide-change-transition-start="slideChangeTransitionStart"
      @slide-change-transition-end="slideChangeTransitionEnd">
      <!-- v-slot="{ isActive, isPrev, isNext, isVisible }" -->
      <swiper-slide
        v-for="(item, index) in items"
        :key="item.id"
        :class="{ 'tint tinted': !item.attachment.paid }"
        @click="click(index)">
        <!-- Cover -->
        <img
          :class="[maximize ? 'object-cover' : 'object-contain']"
          class="absolute inset-0 z-10 w-full h-full block object-center"
          :src="item.attachment?.fields?.videoFirstFrameImageUrl || item.attachment?.fields?.links?.r640x960"
          alt="">

        <!-- Text content -->
        <div class="absolute z-30 inset-0 w-full h-full flex flex-col justify-center items-center">
          <div
            v-show="!item.attachment.paid"
            class="p-4 flex flex-col items-center justify-center">
            <div class="flex items-center">
              <InlineSvg
                :src="require('@/assets/svg/design/lock.svg')"
                class="text-gray-100 w-4 h-6"/>

              <b class="font-bold text-white ml-2">
                <template v-if="item.senderId === userId">
                  <template v-if="isCurrentItemPhoto">
                    {{ $t('chat.message.send_photo') }}
                  </template>

                  <template v-else>
                    {{ $t('chat.message.send_video') }}
                  </template>
                </template>

                <template v-else>
                  {{ $t('chat.attachments.private_content') }}
                </template>
              </b>
            </div>

            <div class="mt-7 relative z-30">
              <button
                v-if="item.senderId === userId"
                class="btn-primary btn px-8 py-1 text-caption my-2"
                @click.prevent="$emit('send-attachment', item)">
                <template v-if="isCurrentItemPhoto">
                  {{ $t('chat.message.send_photo') }}
                </template>

                <template v-else>
                  {{ $t('chat.message.send_video') }}
                </template>
              </button>

              <button
                v-else
                class="btn-primary btn px-8 py-1 text-caption my-2"
                @click.prevent="$emit('unlock-attachment', item)">
                <template v-if="isCurrentItemVideo">
                  {{ $t('chat.attachments.unlock_video', { price: item.attachment.price }) }}
                </template>

                <template v-else-if="isCurrentItemPhoto">
                  {{ $t('chat.attachments.unlock_picture', { price: item.attachment.price }) }}
                </template>
              </button>
            </div>
          </div>
        </div>

        <!-- PlayLoader -->
        <div
          v-show="loaderAllowed && isCurrentItemVideo && !(inTransition || inMove) && !isPaused && !isClosing && !isPlaying"
          class="absolute z-20 inset-0 p-4 flex flex-col items-center justify-center">
          <span class="loader !border-2 w-12 h-12"/>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- Video wrapper -->
  <div
    v-if="items.length"
    :class="{ 'opacity-0': (inTransition || inMove) || !isCurrentItemVideo || !currentItem.attachment.paid }"
    class="absolute inset-0 z-20 pointer-events-none max-w-md mx-auto h-full">
    <VideoNext
      ref="video"
      v-model:buffer-progress="buffered"
      :maximize-video="maximize"
      :is-raw-video="true"
      :src="currentItem.attachment?.fields?.videoUrl"
      :play-allowed="isAllowedToPlay"
      @ended="ended"
      @playing="isPlaying = true"
      @paused="isPaused = $event; isPlaying = false"/>
  </div>

  <!-- Play icon -->
  <div
    v-if="isPaused && isCurrentItemVideo && !(inTransition || inMove) && currentItem.attachment.paid"
    class="absolute z-20 inset-0 flex items-center justify-center pointer-events-none post-shadow">
    <InlineSvg
      :src="require('@/assets/svg/duotone/play.svg')"
      class="h-20 w-20 text-white"/>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Keyboard } from 'swiper'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import VideoNext from '@/components/PostsSwiper/VideoNext.vue'

SwiperCore.use([Keyboard])
let timeoutID = null

export default {
  name: 'ChatAttachmentsSwiper',
  components: {
    Swiper,
    SwiperSlide,
    VideoNext
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        initialIndex: null
      })
    },
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  emits: [
    'close',
    'unlock-attachment',
    'reachEnd',
    'slideChanged',
    'send-attachment'
  ],
  data () {
    return {
      inMove: false,
      inTransition: false,
      isPaused: true,
      isEnded: false,
      buffered: 0,
      currentIndex: 0,
      maximize: true,
      loaderAllowed: false,
      isClosing: false,
      isPlaying: false
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isMobile'
    ]),
    ...mapState(useUserStore, [
      'userId'
    ]),
    currentItem () {
      return this.items[this.currentIndex]
    },
    isCurrentItemVideo () {
      return !!this.currentItem.attachment?.fields?.videoUrl || this.currentItem.attachment?.mediaType === 'video'
    },
    isCurrentItemPhoto () {
      return !this.currentItem.attachment?.fields?.videoUrl && this.currentItem.attachment?.mediaType === 'image'
    },
    isFirstSlide () {
      return this.currentIndex === 0
    },
    isLastSlide () {
      return this.currentIndex === this.items.length - 1
    },
    isAllowedToPlay () {
      return this.isCurrentItemVideo && this.currentItem.attachment.paid
    }
  },
  watch: {
    currentIndex () {
      if (!this.isAllowedToPlay) {
        this.$refs.video.pauseVideo()
      }
    }
  },
  mounted () {
  },
  methods: {
    sliderMove () {
      this.inMove = true
    },
    touchEnd () {
      this.inMove = false
    },
    slideChangeTransitionStart () {
      this.inTransition = true
    },
    afterInit () {
      this.currentIndex = this.values.initialIndex

      this.handleLoaderAvailability()
    },
    slideChangeTransitionEnd () {
      this.inTransition = false
    },
    activeIndexChange (swiper) {
      this.currentIndex = swiper.realIndex
    },
    onSlideChange (swiper) {
      this.currentIndex = swiper.realIndex
      this.handleLoaderAvailability()
      this.$emit('slideChanged')
    },
    async emitClose () {
      await this.$emit('close')

      this.isClosing = true
    },
    click (index) {
      this.currentIndex = index

      if (this.currentIndex === undefined || this.currentItem === undefined) {
        return
      }

      if ((this.currentItem.attachment.paid)) {
        if (!this.$refs.video) {
          return
        }

        // Play video if slider is not in move
        if (!this.inMove && !this.inTransition) {
          this.$refs.video.toggleVideo()
        }
      }
    },
    ended () {
      this.isEnded = true
    },
    replay () {
      this.$refs.video.playVideo()
      this.isEnded = false
    },
    reachEnd () {
      this.$emit('reachEnd')
    },
    handleLoaderAvailability () {
      if (!this.isAllowedToPlay) {
        return
      }

      this.loaderAllowed = false

      if (timeoutID) {
        clearTimeout(timeoutID)
      }
      timeoutID = setTimeout(() => {
        this.loaderAllowed = true
      }, 2000)
    }
  }
}
</script>

<style scoped>
  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    position: relative;
  }

  .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
  }

  .tint.tinted:before {
    background: rgba(0, 0, 0, 0.65);
    @apply z-20 rounded-none
  }
</style>
