<template>
  <div class="flex items-start justify-between mb-2">
    <h2 class="h2">
      {{ $t("modals.delete_account.heading") }}
    </h2>

    <CloseCrossButton @click="$emit('close')"/>
  </div>

  <p :class="[hasSubscriptions ? 'mb-2' : 'mb-5']">
    {{ $t("modals.delete_account.description") }}
  </p>

  <div
    v-if="hasSubscriptions"
    class="rounded-md p-4 bg-red-50 mb-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <InlineSvg
          class="h-5 w-5 text-red-400"
          :src="require('@/assets/svg/design/cross-circle.svg')"/>
      </div>

      <div class="ml-3">
        <h3 class="text-red-800 text-sm font-medium">
          <router-link to="/subscriptions">
            You have active subscriptions, delete <span class="underline">them</span> first
          </router-link>
        </h3>
      </div>
    </div>
  </div>

  <button
    type="button"
    :disabled="hasSubscriptions"
    class="btn btn-primary btn-block mb-3"
    @click="removeAccount">
    {{ $t("common.yes") }}
  </button>

  <button
    type="button"
    class="btn btn-secondary btn-block"
    @click="$emit('close')">
    {{ $t("common.no") }}
  </button>
</template>

<script>

import CloseCrossButton from '@/components/ModalContents/parts/CloseCrossButton'
import { mapActions } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useSubscriptionsStore } from '@/stores/subscriptions'

export default {
  name: 'RemoveAccount',
  components: {
    CloseCrossButton
  },
  emits: ['close'],
  data () {
    return {
      hasSubscriptions: false
    }
  },
  methods: {
    ...mapActions(useUserStore, [
      'meDelete'
    ]),
    ...mapActions(useSubscriptionsStore, [
      'subscriptionsGet'
    ]),
    async removeAccount () {
      const subscriptions = await this.subscriptionsGet()

      if (subscriptions && subscriptions.length && subscriptions.some(item => !!item.price)) {
        this.hasSubscriptions = true
      } else {
        this.$addEvent(['Remove account'])
        await this.meDelete()
      }
    }
  }
}
</script>

