import UAParser from 'ua-parser-js'
import MobileDetect from 'mobile-detect'
import { identity, pickBy } from 'lodash'
import camelcaseKeys from 'camelcase-keys'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'
import isWebview from 'is-ua-webview'

dayjs.extend(duration)
const isDev = process.env.NODE_ENV === 'development' || process.env.VUE_APP_TEST_SERVER === 'yes'

const helpers = {
  async signUpTrackingParams () {
    const md = new MobileDetect(window.navigator.userAgent)

    const parser = new UAParser()
    const parserResult = parser.getResult()

    const isDebug = process.env.VUE_APP_IS_DEBUG === 'yes'
    const isDev = process.env.NODE_ENV === 'development'

    const fingerprint = await getCurrentBrowserFingerPrint()

    return {
      visitorId: fingerprint,
      // systemLocale: window.navigator.language || window.navigator.browserLanguage,
      // clientLocale: 'en-US', // Client localization e.g. Vue I18n
      deviceType: md.tablet() ? 'tablet' : md.mobile() ? 'phone' : 'desktop',
      deviceVendor: parserResult.device.vendor,
      deviceModel: parserResult.device.model,
      deviceScreenResolution: window.screen.width * window.devicePixelRatio + 'x' + window.screen.height * window.devicePixelRatio,
      osName: parserResult.os.name,
      osVersion: parserResult.os.version,
      browserName: parserResult.browser.name,
      browserVersion: parserResult.browser.version,
      browserUserAgent: window.navigator.userAgent,
      clientType: 'web',
      clientVersion: '1.0.0',
      clientBuildType: isDebug ? 'debug' : isDev ? 'dev' : 'release'
    }
  },
  prepareTrackingQuery (query) {
    if (query) {
      query = pickBy(camelcaseKeys(query), identity)

      query.trafficSourceId = query.networkId
      query.trafficSourceName = query.networkKey

      delete query.networkId
      delete query.networkKey

      return query
    }
  },
  parseDuration (seconds) {
    if (seconds) {
      return dayjs.duration(seconds * 1000).format('mm:ss')
    }
  },
  showOnbordingOrSignUp () {
    const authStore = useAuthStore()
    const appStore = useAppStore()

    // Show onbording if: 1 User is not logged in 2 Onbording hasn't opened yet 3 Onboarding feature is enabled
    if (!authStore.isAuthenticated && !appStore.onboardingShown && authStore.beforeAuthOptions?.onboardingEnabled) {
      authStore.onboardingIsVisible = true
    }
    else {
      this.showSignUp()
    }
  },
  showSignUp () {
    const authStore = useAuthStore()

    authStore.signupIsVisible = true
  },
  async showLogIn () {
    const authStore = useAuthStore()

    authStore.loginIsVisible = true
  },
  isCurrentDevicePWA () {
    if (window.navigator.standalone) { // For iOS
      return true
    }
    else if (window.matchMedia('(display-mode: standalone)').matches) { // For Android
      return true
    }

    return false
  },
  // https://web.dev/get-installed-related-apps/
  async isPWAInstalled () {
    const appStore = useAppStore()

    if ('getInstalledRelatedApps' in window.navigator) {
      const relatedApps = await navigator.getInstalledRelatedApps()

      relatedApps.forEach((app) => {
        // If PWA exists in the array it is installed
        if (app.platform === 'webapp') {
          appStore.isPWAInstalled = true
        }
      })
    }

    return false
  },
  beforeInstallPWAPrompt () {
    const appStore = useAppStore()

    window.addEventListener('beforeinstallprompt', (e) => {
      // ! Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault()

      appStore.isAbleToInstallPWA = true

      console.log('beforeinstallprompt', appStore.isAbleToInstallPWA)

      // Stash the event so it can be triggered later.
      appStore.deferredPromptPWAFunc = e
    })
  },
  // https://www.npmjs.com/package/is-ua-webview
  isWebView () {
    const ua = window.navigator.userAgent

    const rules = [
      // if it says it's a webview, let's go with that
      'WebView',
      // iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      // Android KitKat to lollipop webview will put {version}.0.0.0
      'Android.*(wv|.0.0.0)',
      // old chrome android webview agent
      'Linux; U; Android'
    ]

    const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig')

    return !!ua.match(webviewRegExp) || isWebview(window.navigator.userAgent)
  },
  addScript (src) {
    const s = document.createElement('script')
    s.setAttribute('src', src)
    document.body.appendChild(s)
  },
  addTextScript (code) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.textContent = code
    document.body.appendChild(script)
  }
}

export default helpers
