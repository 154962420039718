<template>
  <div v-if="topPayers">
    <button
      type="button"
      class="text-lg post-shadow font-bold text-white mb-2"
      @click="$refs.contributionRankingModal.openModal()">
      {{ $t('creator.tops.tops') }}
    </button>

    <div
      class="flex"
      @click="$refs.contributionRankingModal.openModal()">
      <div
        v-for="(item, index) in topPayers"
        :key="index + 'payer'">
        <div
          :style="{
            backgroundColor: backgrounds[index],
            zIndex: zIndices[index]
          }"
          :class="{ '-ml-2': index > 0 }"
          class="h-11 w-11 rounded-full bg-no-repeat bg-center border-3 border-transparent relative">
          <Avatar
            class="rounded-full h-full w-full"
            :show-status="false"
            :user-item="item.user"/>

          <div class="absolute -right-1 -bottom-1">
            <InlineSvg
              class="w-4"
              :src="require(`@/assets/svg/design/tops/${coins[index]}-medal.svg`)"/>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-slot="{ closeModal }"
      ref="contributionRankingModal"
      name="Tops"
      position="bottom"
      overflow-class="overflow-y-auto"
      content-class="bg-white py-4"
      wrapper-class="h-full max-h-[500px]"
      rounded-class="rounded-t-2.5xl"
      :show-close-cross="false"
      :has-indents="false">
      <InlineSvg
        :src="require('@/assets/svg/design/cross.svg')"
        class="text-gray-900 absolute top-0 right-0 p-1 w-5 h-5 mt-6 mr-5 cursor-pointer"
        @click="closeModal"/>
      <ContributionRanking/>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import Avatar from '@/components/Functional/Avatar'
import ContributionRanking from '@/components/Tops/ContributionRanking'

export default {
  name: 'Tops',
  components: {
    Avatar,
    ContributionRanking
  },
  data () {
    return {
      topPayers: null,
      backgrounds: {
        0: '#ffd43a',
        1: '#e7e7e7',
        2: '#ffb55e'
      },
      coins: {
        0: 'gold',
        1: 'silver',
        2: 'copper'
      },
      zIndices: {
        0: 3,
        1: 2,
        2: 1
      }
    }
  },
  computed: {
    ...mapState(useCreatorStore, [
      'creator'
    ])
  },
  mounted () {
    this.getTops()
  },
  methods: {
    async getTops () {
      if (this.creator.userId) {
        const res = await this.wsp.sendRequest({
          data: {
            userId: this.creator.userId,
            period: 'all-time',
            limit: 3
          },
          method: 'topPayers.get'
        })

        this.topPayers = res.data.topPayers
      }
    }
  }
}
</script>

