<template>
  <div class="simple-tooltip">
    <slot/>

    <div
      class="simple-tooltip-content"
      :style="{
        width: width + 'px',
        marginLeft: -(width / 2) + 'px',
        textAlign: textAlign
      }">
      <template v-if="text">
        {{ text }}
      </template>

      <slot
        v-else
        name="content"/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SimpleTooltip',
  components: {},
  props: {
    text: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 160
    },
    textAlign: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

<style scoped>
  .simple-tooltip {
    position: relative;
    display: inline-block;
  }

  .simple-tooltip .simple-tooltip-content {
    visibility: hidden;
    background-color: rgba(51,51,51, 0.9);
    color: #fff;
    border-radius: 6px;
    padding: 5px 8px;
    position: absolute;
    z-index: 111111;
    bottom: 120%;
    left: 50%;
    @apply text-sm leading-5
  }

  .simple-tooltip .simple-tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(51,51,51, 0.9) transparent transparent transparent;
  }

  .simple-tooltip.bottom .simple-tooltip-content {
    bottom: initial;
    top: 120%;
  }

  .simple-tooltip.bottom .simple-tooltip-content::after {
    top: initial;
    bottom: 100%;
    border-color: transparent transparent rgba(51,51,51, 0.9) transparent;
  }

  .simple-tooltip:hover .simple-tooltip-content {
    visibility: visible;
  }
</style>

