<template>
  <div class="relative h-[36px]">
    <div class="flex items-center justify-between h-[36px] relative">
      <button
        :class="{'invisible': searchFormOpen }"
        type="button"
        @click="$emit('hamburger')">
        <InlineSvg
          class="inline-block"
          :src="require('@/assets/svg/design/hamburger.svg')"/>
      </button>

      <div class="h-6 inline-block">
        <InlineSvg
          class="inline-block"
          :src="require('@/assets/svg/design/logo-name.svg')"/>
      </div>

      <label
        v-if="$route.name === 'Creators'"
        for="search_input"
        :class="{'invisible': searchFormOpen }"
        type="button"
        @click.stop="searchFormOpen = true">
        <InlineSvg
          class="inline-block w-[25px]"
          :src="require('@/assets/svg/design/search.svg')"/>
      </label>

      <router-link
        v-else-if="$route.name === 'Discover'"
        to="/creators"
        for="search_input"
        :class="{'invisible': searchFormOpen }"
        type="button"
        @click.stop="searchFormOpen = true;">
        <InlineSvg
          class="inline-block w-[25px]"
          :src="require('@/assets/svg/design/search.svg')"/>
      </router-link>

      <span v-else/>
    </div>

    <transition name="fade-scale-50">
      <form
        v-show="searchFormOpen"
        class="absolute inset-0 h-[36px]"
        @submit.prevent="doSearch">
        <label for="search_input" class="block text-sm sr-only" @click.stop>Search input</label>

        <div class="relative ">
          <input
            id="search_input"
            ref="searchInput"
            v-model="searchQuesryStr"
            v-click-outside="resetWhenClickOutside"
            type="text"
            :placeholder="$t('creators.catalog.search_creators')"
            name="search_input"
            class="px-9 w-full py-1.5 bg-[#f3f5f4] placeholder-gray-400 rounded-full border-transparent shadow-none focus:border-blue-300 focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
            @focus="focused"
            @blur="blured"
            @input="debounceSearch">

          <InlineSvg
            class="inline-block w-4 absolute top-0 bottom-0 left-3 h-full"
            :src="require('@/assets/svg/design/search.svg')"
            @click.stop/>

          <Spinner
            v-show="isCatalogLoading"
            class="absolute top-0 bottom-0 right-2 z-10"/>

          <button
            v-show="!isCatalogLoading"
            type="button"
            class="absolute top-0 bottom-0 right-2 py-1 px-2 flex items-center justify-center"
            @click="close">
            <InlineSvg
              class="inline-block w-3"
              :src="require('@/assets/svg/design/cross2.svg')"/>
          </button>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatorsCatalogStore } from '@/stores/creatorsCatalog'
import Spinner from '@/components/Functional/Spinner'

export default {
  name: 'CreatorsCatalogHeader',
  components: {
    Spinner
  },
  emits: [
    'hamburger'
  ],
  data () {
    return {
      catalogItemsBeforeSearch: []
    }
  },
  computed: {
    ...mapState(useCreatorsCatalogStore, [
      'isCatalogLoading'
    ]),
    ...mapWritableState(useCreatorsCatalogStore, [
      'searchQuesryStr',
      'catalogItems',
      'loadingStatus',
      'searchInputFocused',
      'searchFormOpen'
    ])
  },
  watch: {
    searchFormOpen (value) {
      if (value) {
        this.catalogItemsBeforeSearch = this.catalogItems
      } else {
        this.catalogItemsBeforeSearch = []
      }
    }
  },
  mounted () {
    if (this.searchFormOpen) {
      this.$refs.searchInput.focus()
    }
  },
  methods: {
    ...mapActions(useCreatorsCatalogStore, [
      'usersSearch'
    ]),
    close () {
      if (this.searchFormOpen) {
        this.searchFormOpen = false
        this.catalogItems = this.catalogItemsBeforeSearch
        this.loadingStatus = 'loaded'

        setTimeout(() => {
          this.searchQuesryStr = ''
        }, 300)
      }
    },
    resetWhenClickOutside () {
      if (!this.searchQuesryStr) {
        this.close()
      }
    },
    async doSearch () {
      if (this.searchQuesryStr) {
        await this.$nextTick()
        await this.usersSearch({ type: 'init', limit: 20 })
      } else {
        await this.$nextTick()
        this.catalogItems = this.catalogItemsBeforeSearch
        this.loadingStatus = 'loaded'
      }
    },
    debounceSearch () {
      this.loadingStatus = 'typing'
      if (this.debounce) clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        this.doSearch()
      }, 500)
    },
    focused () {
      this.searchInputFocused = true
    },
    blured () {
      this.searchInputFocused = false
    }
  }
}
</script>

<style scoped>
</style>

