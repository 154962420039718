<template>
  <div class="container page404 h-full flex flex-col items-center justify-center">
    <span class="text-[2rem] mb-5">😔</span>

    <h1 class="mb-2">
      {{ $t('page404.heading') }}
    </h1>

    <p class="text-center mb-8">
      {{ $t('page404.description') }}
    </p>

    <router-link
      to="/"
      class="btn btn-primary btn-block">
      {{ $t('page404.back_to_homepage') }}
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Page404',
  components: {},
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {}
}
</script>

<style>
  .page404 {
    background-image: url('../../public/svg/page-404-background.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>

