<template>
  <div class="relative h-full w-full">
    <FeedLinks class="absolute top-1 h-10 z-10"/>

    <PostPlaceholder v-if="isLoading"/>

    <ThePostsSwiper
      v-else
      :posts="privateFeed"
      :users="privateUsers"
      @after-init="afterInit"
      @reach-end="reachEnd"
      @unlocked="unlocked"
      @slide-changed="slideChanged"
      @liked="liked"
      @subscribed="subscribed"
      @shared="shared"/>
  </div>
</template>

<script>
import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import FeedLinks from '@/components/Feed/FeedLinks'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useFeedStore } from '@/stores/feed'
import PostPlaceholder from '@/components/Post/PostPlaceholder'

export default {
  name: 'TheFollowing',
  components: {
    ThePostsSwiper,
    FeedLinks,
    PostPlaceholder
  },
  data () {
    return {
      constantLoading: true,
      loading: true
    }
  },
  computed: {
    ...mapState(useFeedStore, [
      'privateUsers'
    ]),
    ...mapWritableState(useFeedStore, [
      'privateFeed',
      'privateViewedIds',
      'loadingStatus'
    ]),
    isLoading () {
      return this.constantLoading || this.loading
    }
  },
  async beforeMount () {
    setTimeout(() => {
      this.constantLoading = false
    }, 500)

    this.loadingStatus = ''
    this.privateFeed = []

    await this.feed({ type: 'private', limit: 10 }).finally(() => {
      this.loading = false
    })
  },
  mounted () {},
  methods: {
    ...mapActions(useFeedStore, [
      'feed',
      'setItemStatusByIndex',
      'likeToggle',
      'shareCount'
    ]),
    afterInit ({ index }) {
      if (this.privateFeed[index].status !== 'past') {
        this.setItemStatusByIndex({ type: 'private', index: index, status: 'viewed' })

        this.privateViewedIds.push(this.privateFeed[index].id)
      }
    },
    slideChanged ({ index }) {
      if (this.privateFeed[index].status !== 'past') {
        this.setItemStatusByIndex({ type: 'private', index: index, status: 'viewed' })

        this.privateViewedIds.push(this.privateFeed[index].id)
      }

      // Upload more posts before slider reaches the end
      if (this.privateFeed.length - index <= 5) {
        this.feed({ type: 'private', limit: 10 })
      }
    },
    reachEnd () {
      this.feed({ type: 'private', limit: 10 })
    },
    liked (postId) {
      this.likeToggle({ type: 'private', postId: postId })
    },
    shared (postId) {
      this.shareCount({ type: 'private', postId: postId })
    },
    subscribed (user) {
      this.$router.push(`/@${user.username}`)
    },
    unlocked ({ post, index }) {
      this.privateFeed.splice(index, 1, post)
    }
  }
}
</script>

