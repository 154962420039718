<template>
  <div class="w-full grid gap-1 grid-cols-4 sm:grid-cols-4 md:grid-cols-5 grid-flow-row">
    <div
      v-for="(item, index) in photos"
      v-show="index !== 0"
      :key="item.id"
      class="h-20"
      @click="openModal(index)">
      <img
        class="object-cover h-full w-full"
        :src="item.links.c320x320"
        :alt="`${user.name}\`s photo`">
    </div>

    <PhotoUploader class="h-20"/>

    <Modal
      ref="mePhotos"
      v-slot="{ values, closeModal }"
      name="MePhotos"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      content-class="h-full bg-gray-700"
      position="center">
      <MePhotosContent
        :values="values"
        :items="photos"
        @trash-photo="trashPhoto"
        @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>
import PhotoUploader from '@/components/Me/PhotoUploader'
import Modal from '@/components/Functional/Modal'
import MePhotosContent from '@/components/ModalContents/MePhotosContent'
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user'

export default {
  name: 'MePhotos',
  components: {
    PhotoUploader,
    Modal,
    MePhotosContent
  },
  data () {
    return {
      removePhotoLoading: false
    }
  },
  computed: {
    ...mapState(useUserStore, [
      'user',
      'photos',
      'filteredPhotos'
    ])
  },
  mounted () {
  },
  methods: {
    ...mapActions(useUserStore, [
      'photosDelete'
    ]),
    openModal (index) {
      this.$refs.mePhotos.openModal({ initialIndex: index })
    },
    async trashPhoto (id) {
      if (!this.removePhotoLoading) {
        this.removePhotoLoading = true
        await this.photosDelete(id)
        this.removePhotoLoading = false
      }
    }
  }
}
</script>

