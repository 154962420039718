<template>
  <div class="h-full w-full bg-white">
    <ChatPlaceholder v-show="isLoading && !isError"/>

    <p v-if="isError">
      {{ $t('common.error_occurs') }}
    </p>

    <TheChat v-else/>
  </div>
</template>

<script>
import TheChat from '@/components/Chat/TheChat'
import { useChatStore } from '@/stores/chat'
import { mapState, mapActions, mapWritableState } from 'pinia'
import ChatPlaceholder from '@/components/Chat/ChatPlaceholder'

export default {
  name: 'Chat',
  components: {
    ChatPlaceholder,
    TheChat
  },
  data () {
    return {
      constantLoading: true,
      isError: false,
      isFirstVisit: true
    }
  },
  computed: {
    ...mapWritableState(useChatStore, [
      'chatUser'
    ]),
    ...mapState(useChatStore, [
      'chatId',
      'chatLoading'
    ]),
    isLoading () {
      return this.chatLoading || this.constantLoading
    }
  },
  beforeCreate () {
    const chatStore = useChatStore()

    chatStore.chatId = this.$route.params.chatId
  },
  async mounted () {
    if (this.chatId) {
      this.chatUser = {
        userId: null,
        age: null,
        name: '',
        sex: null,
        online: null,
        location: {
          name: ''
        },
        photos: {
          items: []
        },
        attachmentSupport: {
          photo: null
        },
        videoCallAvailability: null
      }

      setTimeout(() => {
        this.constantLoading = false
      }, 800)

      try {
        const res = await this.userGet()

        if (res?.error) {
          this.isError = true
        }
      } catch (e) {
        this.isError = true
      }
    }
  },
  methods: {
    ...mapActions(useChatStore, [
      'userGet'
    ])
  }
}
</script>
