<template>
  <button
    class="btn cursor-pointer"
    type="button"
    :class="btnClass">
    <ImageLoader v-slot="{ setLoaded }">
      <InlineSvg
        :src="crossSVG"
        class="text-gray-900"
        @loaded="setLoaded"/>
    </ImageLoader>
  </button>
</template>

<script>

import ImageLoader from '@/components/Functional/ImageLoader'
import crossSVG from '@/assets/svg/design/cross.svg'

export default {
  name: 'CloseCrossButton',
  components: {
    ImageLoader
  },
  props: {
    btnClass: {
      type: String,
      default: 'py-2 pl-3 pr-0'
    }
  },
  data () {
    return {
      crossSVG: crossSVG
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

