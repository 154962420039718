<template>
  <div
    v-if="hasBlur"
    class="absolute inset-0 z-10 custom-filter bg-black bg-opacity-10"/>

  <div class="absolute inset-0 z-10 flex flex-col items-center justify-center w-full">
    <ImageLoader
      v-slot="{ setLoaded }"
      class="w-4 h-6">
      <InlineSvg
        :src="lockSVG"
        class="text-gray-100 w-4 h-6"
        @loaded="setLoaded"/>
    </ImageLoader>

    <p
      v-if="price"
      class="text-white text-xs mt-1 whitespace-nowrap">
      {{ formattedStr(price) }}
    </p>

    <p
      v-else
      class="text-white text-xs mt-1 whitespace-nowrap">
      {{ $t('creator.profile.for_subscribers') }}
    </p>
  </div>
</template>

<script>
import lockSVG from '@/assets/svg/design/lock.svg'
import ImageLoader from '@/components/Functional/ImageLoader'

export default {
  name: 'Lock',
  components: {
    ImageLoader
  },
  props: {
    price: {
      type: Number,
      default: null
    },
    hasBlur: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      lockSVG: lockSVG
    }
  },
  methods: {
    formattedStr (count) {
      return `${count} ${this.$tc('user.credits.credits', count)}`
    }
  }
}
</script>

<style>
  .custom-filter {
    backdrop-filter: blur(5px);
  }
</style>
