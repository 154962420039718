<script setup>
  import { onMounted, ref, computed } from 'vue'
  import { useChatStore } from '@/stores/chat.js'
  import ChatBodyPostsItem from '@/components/Chat/ChatBodyPosts/ChatBodyPostsItem.vue'
  import Modal from '@/components/Functional/Modal.vue'
  import SubscribeContent from '@/components/ModalContents/SubscribeContent.vue'
  import chatState from '@/components/Chat/Store/chat-state.js'
  import { userPostsGet } from '@/components/Chat/Store/chat-actions.js'

  const chatStore = useChatStore()

  const {
    posts
  } = chatState

  onMounted(() => {
    userPostsGet()
  })

  async function subscribed () {
    await userPostsGet()
  }

  const subscribeModal = ref(null)

  const link = computed(() => {
    if (chatStore.chatUser.subscribed) {
      return `/@${chatStore.chatUser.username}`
    } else {
      return ''
    }
  })

  const subscriptionPlansFirstPrice = computed(() => {
    return chatStore.chatUser.subscriptionPlans?.length ? chatStore.chatUser.subscriptionPlans[0].priceInCurrency : null
  })
</script>

<template>
  <div class="-mx-1.5">
    <div
      v-if="posts && posts.length"
      class="grid grid-flow-col auto-cols-max gap-2 overflow-auto scrollbar-thin p-0.5">
      <ChatBodyPostsItem
        v-for="(item, index) in posts"
        :key="item.id"
        :index="index"
        :item="item"/>

      <router-link
        :to="link"
        type="button"
        class="h-24 w-24 rounded bg-primary text-white flex flex-col items-center justify-center p-1"
        @click="subscribeModal.openModal()">
        <div
          v-if="chatStore.chatUser.subscribed"
          class="text-center font-bold leading-5 p-1.5">
          {{ $t('common.show_more') }}
        </div>

        <div
          v-else-if="subscriptionPlansFirstPrice"
          class="text-center flex flex-col">
          <span class="text-xs font-bold">
            {{ $t('creator.subscribe.for_price', { price: subscriptionPlansFirstPrice }) }}
          </span>
        </div>
      </router-link>
    </div>

    <Modal
      ref="subscribeModal"
      v-slot="{ closeModal, isVisible }"
      name="Subscribe modal"
      overflow-class="overflow-y-auto"
      position="bottom"
      rounded-class="rounded-t-2.5xl"
      content-class="bg-white px-6 pt-5 pb-4"
      :has-indents="false">
      <SubscribeContent
        :user="chatStore.chatUser"
        :is-visible="isVisible"
        :close="closeModal"
        @subscribed="subscribed"/>
    </Modal>
  </div>
</template>