import { useLogStore } from '@/stores/logbook'

export default {
  install: (app) => {
    const logbookStore = useLogStore()

    app.config.globalProperties.$addEvent = logbookStore.addEvent
    app.$addEvent = app.config.globalProperties.$addEvent
  }
}
