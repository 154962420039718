<template>
  <div class="relative inline-block text-left">
    <div
      class="handle"
      @click="closeModal">
      <slot/>
    </div>

    <transition name="fade-scale">
      <div
        v-if="isOpened"
        class="menu origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu">
        <div
          class="py-1.5"
          role="none">
          <span
            v-for="(item, index) in items"
            :key="index"
            class="cursor-pointer block px-4 py-2 text-sm font-bold text-gray-700 hover:bg-gray-100"
            @click="item.callback">
            {{ item.text }}
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isOpened: false
    }
  },
  mounted () {
    document.addEventListener('click', this.clickOutside)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.clickOutside)
  },
  methods: {
    closeDropdown () {
      this.isOpened = false
    },
    closeModal () {
      console.log('close dropdown')
      this.isOpened = !this.isOpened
    },
    clickOutside (event) {
      if (!this.isOpened ||
        event.target.closest('.handle')) {
        return
      }

      this.closeModal()
    }
  }
}
</script>
