<template>
  <div class="h-full w-full max-w-full flex flex-col placeholder">
    <div
      style="min-height: 144px;"
      class="flex flex-col justify-center items-center mb-6">
      <div
        class="mb-2">
        <div class="inline-block h-20 w-20 rounded-full placeholder-item"/>
      </div>

      <div
        style="width: 66px; height: 18px; margin: 2px 0"
        class="rounded-full placeholder-item"/>

      <div
        style="width: 80px; height: 18px; margin: 2px 0"
        class="rounded-full placeholder-item"/>
    </div>

    <div
      style="border-radius: 10px; height: 84px"
      class="py-4 placeholder-item mb-10"/>

    <div class="space-y-4">
      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 100px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 140px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 120px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 130px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 100px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 100px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 130px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 135px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>

      <div class="flex items-center">
        <div class="w-7"><div class="rounded-full placeholder-item h-5 w-5"/></div>

        <div
          style="width: 90px; height: 18px; margin: 2px 0"
          class="rounded-full placeholder-item"/>
      </div>
    </div>

    <div
      style="border-radius: 6px; height: 46px"
      class="mt-6 placeholder-item"/>
  </div>
</template>

<script>

export default {
  name: 'Test',
  components: {},
  props: {
    type: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      greeting: 'Hello'
    }
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

