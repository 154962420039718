<template>
  <div class="overflow-hidden h-full">
    <swiper
      v-if="items.length"
      class="h-full"
      :speed="400"
      :pagination="{
        clickable: true,
        bulletActiveClass: 'swiper-pagination-bullet-active'
      }"
      :watch-slides-visibility="true"
      :short-swipes="true"
      :keyboard="true"
      :initial-slide="0">
      <swiper-slide
        v-for="item in items"
        :key="item">
        <ImageLoader
          v-slot="{ setLoaded }"
          spinner-class="h-10 w-10 bg-gray-100"
          class="w-full h-full">
          <img
            class="block object-cover w-full h-full object-center"
            :src="item?.links.r640x960"
            alt=""
            @load="setLoaded()">
        </ImageLoader>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Keyboard, Pagination } from 'swiper'
import ImageLoader from '@/components/Functional/ImageLoader.vue'

SwiperCore.use([Pagination, Keyboard])

export default {
  name: 'CreatorPhotosSwiper',
  components: {
    Swiper,
    SwiperSlide,
    ImageLoader
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {}
  },
  computed: {},
  mounted () {
  },
  methods: {}
}
</script>

<style>
  .swiper-pagination-bullet-active {
    @apply !bg-white drop-shadow;
  }
</style>

