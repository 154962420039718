<template>
  <div class="flex flex-wrap">
    <button
      v-for="(item, i) in interests"
      :key="i"
      type="button"
      class="my-0.5 mr-0.5 inline-flex items-center px-2 py-1.5 border  shadow-sm text-sm leading-4 font-medium rounded-full focus:outline-none"
      :class="selectedInterests.includes(item) ? 'border-transparent text-white bg-primary hover:bg-primary-press' : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50'"
      @click="selectInterest(item)">
      {{ item }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'Interests',
  components: {},
  props: {
    selectedInterests: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  emits: ['update:selected-interests'],
  data () {
    return {
      interests: [
        'Biking',
        'Camping',
        'Cars',
        'Cooking',
        'Dancing',
        'Diving',
        'Fashion',
        'Fishing & Hunting',
        'Games',
        'Hobbies & Crafts',
        'Hockey',
        'Lying on the beach',
        'Meditation & Yoga',
        'Movies',
        'Museums & Art',
        'Music & Concerts',
        'Nature',
        'Party & Night Clubs',
        'Reading books',
        'Sailing',
        'Shopping',
        'Sports',
        'Travelling',
        'Watching TV'
      ]
    }
  },
  computed: {},
  mounted () {
    const isMatchWithInterests = !this.selectedInterests.some((item) => this.interests.includes(item))

    // If interests exists and there's no match with default interests list - clean up
    if (this.selectedInterests.length && isMatchWithInterests) {
      this.$emit('update:selected-interests', [])
    }
  },
  methods: {
    selectInterest (interest) {
      const selectedItems = this.selectedInterests

      if (selectedItems.includes(interest)) {
        const indexToRemove = selectedItems.findIndex((element) => {
          return element === interest
        })

        selectedItems.splice(indexToRemove, 1)
      } else {
        selectedItems.push(interest)
      }

      // Two-way data binding (:selected-interests.sync)
      this.$emit('update:selected-interests', selectedItems)
    }
  }
}
</script>

