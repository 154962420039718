<template>
  <div class="max-w-md mx-auto px-4 py-4">
    <div class="flex flex-col items-center">
      <img
        src="@/assets/images/onboarding/logo.svg"
        class="p-2 mb-2"
        alt="One2Fan logo">

      <h1 class="h2 mb-6">
        {{ $t('creators.catalog.heading' ) }}
      </h1>

      <div class="p-1 rounded-full border-dashed border-2 border-[#fbbdc9] mb-4">
        <Avatar
          class="w-44 h-44"
          :show-status="true"
          :user-item="creator"/>
      </div>

      <h2 class="mb-0.5">
        {{ creator.name }}
      </h2>

      <p class="text-[#6f6f6f] font-bold mb-4 text-sm">
        @{{ creator.username }}
      </p>

      <p class="text-center text-[#4c4c4c] text-[13px] leading-4 mb-4">
        {{ creator.about }}
      </p>

      <!-- Tiktok username -->
      <div
        v-if="creator.tikTokFollowerCount"
        class="flex items-center my-3">
        <div class="flex items-center rounded-lg bg-[#f6f8fa] py-1 pl-1 pr-1.5 mr-2">
          <InlineSvg
            class="inline-block w-6 h-6 mr-0.5 text-[#6d6d6e]"
            :src="require('@/assets/svg/design/tiktok2.svg')"/>

          <a
            target="_blank"
            :title="creator.tikTokUsername"
            :href="`https://tiktok.com/${getTikTokUsername()}`"
            class="text-md text-[#4a4a4b] font-bold">
            @{{ creator.tikTokUsername }}
          </a>
        </div>
      </div>

      <!-- Followers -->
      <div
        v-if="creator.tikTokFollowerCount"
        class="flex items-center  py-1 pl-1 pr-1.5 mr-2 mb-6">
        <div class="flex items-center text-[13px] text-[#4c4c4c] leading-4">
          <InlineSvg
            class="inline-block mr-0.5 w-4 h-4 p-0.5"
            :src="require('@/assets/svg/design/user.svg')"/>

          {{ countNumberFormat(creator.tikTokFollowerCount) }} followers
        </div>
      </div>

      <router-link
        to="/creators"
        class="btn rounded-none btn-block btn-primary rounded-[4px] mb-4">
        {{ $t('onboarding.get_started') }}
      </router-link>

      <p class="text-[13px] text-center mb-1">
        © {{ year }} One2fan.com
      </p>

      <div class="flex items-center space-x-3 mb-3">
        <a
          class="text-[13px] text-link"
          :href="`${$window.one2fan.origin}/terms`"
          target="_blank">
          Terms of Service
        </a>

        <a
          class="text-[13px] text-link"
          :href="`${$window.one2fan.origin}/policy`"
          target="_blank">
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useCreatorStore } from '@/stores/creator'
import Avatar from '@/components/Functional/Avatar'

export default {
  name: 'CreatorAlt',
  components: {
    Avatar
  },
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    ...mapState(useCreatorStore, [
      'creator'
    ])
  },
  async mounted () {
    await this.userGet({
      username: this.$route.params.nickname
    }).finally(async () => {
      await this.$nextTick()
      this.loading = false
    })
  },
  methods: {
    ...mapActions(useCreatorStore, [
      'userGet'
    ]),
    getTikTokUsername () {
      return this.creator.tikTokUsername.charAt(0) === '@' ? this.creator.tikTokUsername : `@${this.creator.tikTokUsername}`
    }
  }
}
</script>

