import { watch } from 'vue'
import { createPinia } from 'pinia'
import router from '@/router'

export const pinia = createPinia()

function piniaRouterPlugin () {
  return { router: router }
}

pinia.use(piniaRouterPlugin)

// https://github.com/Seb-L/pinia-plugin-persist/blob/bdcae29d547b42c2603256f6bd1430bbf27b2447/src/index.ts
const updateStorage = (strategy, store) => {
  const storage = strategy.storage
  const storeKey = strategy.key || store.$id

  if (strategy.paths) {
    const partialState = strategy.paths.reduce((finalObj, key) => {
      finalObj[key] = store.$state[key]
      return finalObj
    }, {})

    storage.setItem(storeKey, JSON.stringify(partialState))
  } else {
    storage.setItem(storeKey, JSON.stringify(store.$state))
  }
}

export function piniaStoragePlugin ({ options, store }) {
  if (options.persist?.enabled) {
    options.persist?.strategies.forEach((strategy) => {
      const storage = strategy.storage
      const storeKey = strategy.key || store.$id
      const storageResult = storage.getItem(storeKey)

      if (storageResult) {
        store.$patch(JSON.parse(storageResult))

        storage.setItem(storeKey, JSON.stringify(store.$state))
        updateStorage(strategy, store)
      }
    })

    watch(() => store.$state, () => {
      options.persist?.strategies.forEach((strategy) => {
        updateStorage(strategy, store)
      })
    }, { deep: true })
  }
}

pinia.use(piniaStoragePlugin)
