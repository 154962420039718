<template>
  <div class="h-full w-full flex items-center justify-center">
    <ThePostsSwiper
      v-if="post"
      :posts="[post]"
      :users="[user]"
      :show-close="true"
      @liked="liked"
      @shared="shared"
      @subscribed="subscribed"
      @unlocked="unlocked"
      @message-sent="$emit('close')"
      @close="$emit('close')"/>

    <span v-if="noPost" class="h2 text-white">
      {{ $t('posts.content_is_missing') }}
    </span>
  </div>
</template>

<script>

import ThePostsSwiper from '@/components/PostsSwiper/ThePostsSwiper'
import app from '@/main'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'
import { mapState } from 'pinia'

export default {
  name: 'ChatPostContent',
  components: {
    ThePostsSwiper
  },
  props: {
    values: {
      type: Object,
      required: true,
      default: () => ({
        userId: null
      })
    }
  },
  emits: ['close'],
  data () {
    return {
      post: null,
      user: null,
      isPaused: true,
      buffered: 0,
      noPost: false
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'secretToken'
    ]),
    ...mapState(useAuthStore, [
      'installId'
    ])
  },
  async beforeMount () {
    this.user = this.values.user
    await this.getPost(this.values.post.id)

    if (!this.post) {
      this.noPost = true
    }
  },
  methods: {
    async getPost (id) {
      if (!id) {
        return
      }

      const res = await app.wsp
        .sendRequest({
          data: {
            id: id,
            postUnlockToken: this.secretToken,
            installId: this.installId ? this.installId : null
          },
          method: 'post.get'
        })

      if (res.error) {
        console.log(res.error.message)
      }

      this.post = res.data.post
    },
    liked () {
      this.post.liked = !this.post.liked
      this.post.likeCount = this.post.liked ? this.post.likeCount + 1 : this.post.likeCount - 1
    },
    shared () {
      this.post.shareCount++
    },
    subscribed () {
      this.getPost(this.values.post.id)
      this.user.subscribed = true
    },
    unlocked () {
      this.getPost(this.values.post.id)
    }
  }
}
</script>

