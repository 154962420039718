<template>
  <div class="relative cursor-pointer">
    <div
      v-if="iconByCatalog.has(user.creatorCategory) && options?.activeCreatorCategories"
      class="shadow rounded-full bg-white h-6 w-6 absolute z-10 left-2.5 top-2.5 flex items-center justify-center">
      <img
        :src="iconByCatalog.get(user.creatorCategory)"
        class="w-3.5 h-3.5"
        alt="Star emoji">
    </div>

    <img
      v-if="user?.photos && user.photos.items.length"
      :class="[index === 1 ? 'aspect-w-1 aspect-h-1' : 'aspect-[4/5]']"
      :src="index === 1 ? user.photos.items[0].links.c320x320 : user.photos.items[0].links.c320x320"
      alt=""
      class="w-full object-cover rounded-lg"
      @load="loaded">

    <div
      v-else
      :class="[index === 1 ? 'aspect-w-1 aspect-h-1' : 'aspect-[4/5]']"
      class="rounded-lg bg-gray-100"/>

    <div
      v-show="isImageLoaded"
      class="absolute inset-0 rounded-lg flex flex-col justify-end p-2">
      <h4 class="flex mb-0.5">
        <TextClamp
          :auto-resize="true"
          class="text-[17px] text-white font-bold post-shadow"
          :text="user.name"
          :max-lines="1">
          <template #after>
            <span
              v-if="!secretToken"
              v-show="user.online"
              class="bg-green-300 ml-1 flex-shrink-0 inline-block h-2 w-2 rounded-full"/>
          </template>
        </TextClamp>
      </h4>

      <div class="flex items-center">
        <template v-if="(user.tikTokUsername && user.tikTokFollowerCount) || (user.tikTokUsername && !user.instagramFollowerCount)">
          <a
            target="_blank"
            :href="`https://www.tiktok.com/@${user.tikTokUsername}`"
            class="mt-1 mr-1 flex text-[10px] font-semibold bg-[#F6F8FA] rounded px-1.5 py-0.5 leading-4"
            @click.stop>
            <TextClamp
              :auto-resize="true"
              class="text-[#4a4a4b]"
              :text="'@' + user.tikTokUsername"
              :max-lines="1">
              <template #before>
                <InlineSvg
                  class="inline-block mr-0.5 text-black w-3"
                  :src="require('@/assets/svg/design/tiktok2.svg')"/>
              </template>
            </TextClamp>
          </a>

          <div
            v-if="user.tikTokFollowerCount"
            class="mt-1 flex items-center">
            <div
              class="mr-1 flex items-center text-[10px] text-[#f4f3f3] font-semibold bg-black bg-opacity-30 rounded px-1.5 py-0.5 leading-4">
              <InlineSvg
                class="inline-block mr-1 w-3"
                :src="require('@/assets/svg/design/user.svg')"/>

              {{ countNumberFormat(user.tikTokFollowerCount) }}
            </div>

            <InlineSvg
              :src="require('@/assets/svg/design/verified.svg')"
              class="inline-block"/>
          </div>
        </template>

        <template v-else>
          <a
            v-if="user.instagramUsername"
            target="_blank"
            :href="`https://www.instagram.com/${user.instagramUsername}/`"
            class="mt-1 mr-1 flex text-[10px] font-semibold bg-[#F6F8FA] rounded px-1.5 py-0.5 leading-4"
            @click.stop>
            <TextClamp
              :auto-resize="true"
              class="text-[#4a4a4b]"
              :text="user.instagramUsername"
              :max-lines="1">
              <template #before>
                <InlineSvg
                  class="inline-block mr-0.5 text-black w-2.5"
                  :src="require('@/assets/svg/design/instagram.svg')"/>
              </template>
            </TextClamp>
          </a>

          <div
            v-if="user.instagramFollowerCount"
            class="mt-1 flex items-center">
            <div
              class="mr-1 flex items-center text-[10px] text-[#f4f3f3] font-semibold bg-black bg-opacity-30 rounded px-1.5 py-0.5 leading-4">
              <InlineSvg
                class="inline-block mr-1 w-3"
                :src="require('@/assets/svg/design/user.svg')"/>

              {{ countNumberFormat(user.instagramFollowerCount) }}
            </div>

            <InlineSvg
              :src="require('@/assets/svg/design/verified.svg')"
              class="inline-block"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TextClamp from '@/components/Functional/TextClamp'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'CreatorsCatalogCard',
  components: {
    TextClamp
  },
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isImageLoaded: false,
      iconByCatalog: new Map([
        ['new', require('@/assets/images/emoji/high-voltage.png')],
        ['top', require('@/assets/images/emoji/star.png')],
        ['hot', require('@/assets/images/emoji/fire.png')],
        ['all', false]
      ])
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'secretToken'
    ]),
    ...mapState(useAuthStore, [
      'options'
    ])
  },
  beforeMount () {
    if (!this.user?.photos) {
      this.isImageLoaded = true
    }
  },
  mounted () {
  },
  methods: {
    loaded () {
      this.isImageLoaded = true
    }
  }
}
</script>
